import React from 'react';
import store from '../../../Store';
import getIntent from '../../../ajax/actions/fetchintent/fetch_intent_post';
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";

class BillingWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getIntent());
        store.dispatch(getDemoSite())

    }

    render() {
        return null
    }
}

export default BillingWorker;
