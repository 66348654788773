import React from 'react';
import {names} from "../../../helpers/StringHelper";

class TableHead extends React.Component
{
    render()
    {
        let { fields, params, onClick, noActions } = this.props;

        return (

            <thead>
            <tr>
                { fields&& fields.map( field => {
                    return (

                        <th scope="col"
                            key={ field.field }
                            className={ `pointer ${names(
                                params.sort_id === field.field && params.sort_order === `desc` && 'th-active-desc',
                                params.sort_id === field.field && params.sort_order === `asc` && 'th-active-asc',
                                field.sort && `th-sortable`
                            )}` }
                            onClick={ () => field.sort && onClick && onClick( field.field ) }>{ field.label }</th>
                    )})
                }
                { !noActions && <th>Actions</th> }
            </tr>
            </thead>

        )
    }
}
export default TableHead;