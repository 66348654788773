import scrollToTheElement from 'scroll-to-element'

export const scrollToElement = element => {
  scrollToTheElement(element, { offset: -80 })
}
export const scrollToError = () => {
  scrollToTheElement('.has-error', { offset: -80 })
}
export const slideAndScrollTo = (element, offset, delay) => {
  setTimeout(() => scrollToTheElement(element, { offset: offset || 800 }), delay || 500)
}