export function toggleAddCardFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-ADD-CARD-FORM-MODAL'});
    }
}

export function couponAddedMessage() {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-CREATE'});
    }
}

export function setDefaultCouponAddMessage() {
    return function (dispatch) {
        dispatch({type: 'DEFAULT-COUPON-MESSAGE'});
    }
}