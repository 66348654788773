export function passwordProtectionFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PASSWORD-PROTECTION-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPasswordProtectionFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PASSWORD-PROTECTION-FORM-SUBMIT'})
    }
}

export function onPasswordProtectionFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PASSWORD-PROTECTION-FORM-CHANGE', name, value});
    }
}
export function onPasswordProtectionFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-PASSWORD-PROTECTION-FORM-MODE-CHANGE', mode});
    }
}

export function cancelPasswordProtectionFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PASSWORD-PROTECTION-FORM-DISPATCH'});
    }
}

export function setDefaultPasswordProtectionForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-PASSWORD-PROTECTION-FORM'});
    }
}


export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID',site_id});
    }
}
