import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import ManualBackupFormWorker from './ManualBackupFormWorker'
import {
    onManualBackupFormChange,
    onManualBackupFormSubmit
} from "./ManualBackupForm-Action";
import TextField from "../../../../components/shared/form/TextField";
import Loading from "../../../../components/shared/loaders/Loading";
import {toggleBackupFormModal} from "../list/Backup-Action";
import {withRouter} from "react-router";

class ManualBackupForm extends React.Component {
    manualBackupFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onManualBackupFormSubmit());
    };

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onManualBackupFormChange(e.target.name, e.target.value));
    };


    render() {
        let {
            createBackup,name,errors,getSite,message
        } = this.props;
        let {processing} = createBackup;
        return (
            <>
                <ManualBackupFormWorker/>
                    <p className="text-left">Are you sure you want to create Manual Backup?</p>
                    <form className="mt-30" onSubmit={this.manualBackupFormSubmit}>

                            <TextField name="name"
                                       className="form-control"
                                       type="text"
                                       value={name}
                                       label="Name (Optional)"
                                       placeholder="name"
                                       message={message}
                                       onChange={this.onChange}/>
                            <div className="button-wrapper inline-buttons text-center">
                                <button className="btn-theme btn-outline" type="reset"
                                        onClick={() => store.dispatch(toggleBackupFormModal(getSite && getSite.success && getSite.success.id))}>Cancel
                                </button>
                                <button type="submit" className="btn-theme btn-blue "
                                        >
                                    {processing ? <Loading/>  : `Create Backup`}
                                </button>
                        </div>
                    </form>
                    </>
        )
    }
}

function mapStateToProps(state) {
    let {manualBackupForm, createBackup, getSite} = state;
    let {payload,errors,message} = manualBackupForm.data

    let {
        name
    } = payload;
    return{
        manualBackupForm, createBackup,name,errors,getSite,message
    }

}

export default  withRouter(connect(mapStateToProps)(ManualBackupForm));