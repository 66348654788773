import axios from 'axios';
import {apiBaseUrl, endOfURL} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
import store from "../../../Store"
import {setSSLFilteredData} from "../../../pages/site/ssl/list/SSL-Action"
import getSiteStatus from "../sites/site_status_get";

function _success(success)
{
  return { type: 'GET_SITE_DOMAIN_NO_PAGINATION_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_SITE_DOMAIN_NO_PAGINATION_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_SITE_DOMAIN_NO_PAGINATION_PROCESSING', processing };
}

function getSiteDomainAll( id= null)
{
  return ( dispatch, getState  ) =>
  {
    dispatch(_processing(true));
    dispatch(_error(null));
      const lang = localStorage.getItem('lang') || 'en'

    let { domainList } = getState();
    let {  site_id } = domainList.data;

    let s_id = id || site_id

   axios({
      url: apiBaseUrl(`customer/sites/${s_id}/domains`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +loginToken(),
          'lang': lang}
    })
      .then(function(res){
          dispatch(_success(res));
          dispatch(_processing( false ));
          store.dispatch(setSSLFilteredData());
          store.dispatch(getSiteStatus(s_id));
          // handleSuccess( res )

      }).catch(function( error ){
       handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
      });
  }
}
export default getSiteDomainAll;