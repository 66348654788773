import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'
import getSiteStatus from "../sites/site_status_get";
import store from '../../../Store'


function _success(success) {
  return { type: 'RESTORE_BACKUP_SUCCESS', success }
}

function _error(error) {
  return { type: 'RESTORE_BACKUP_ERROR', error }
}

function _processing(processing) {
  return { type: 'RESTORE_BACKUP_PROCESSING', processing }
}

function restoreBackup(backup_id) {
  return (dispatch, getState) => {

    let {backupList,
    } = getState();

    let {params, site_id} = backupList.data;
    const lang = localStorage.getItem('lang') || 'en'

    dispatch(_success(null))
    dispatch(_error(null))
    dispatch(_processing(true))

    axios({
      url: apiBaseUrl(`customer/sites/${site_id}/backups/${backup_id}/restore`),
      method: 'post',
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'lang': lang
      }
    })
      .then(function(res) {
        dispatch(_success(res))
        dispatch(_processing(false))
        handleSuccess("Backup restore request sent successfully.")
        store.dispatch(getSiteStatus(site_id));

      }).catch(function(error) {
        dispatch(_error(error.response.data.message))
        dispatch(_processing(false))
        handleError(error)
      })
  }
}

export default restoreBackup
