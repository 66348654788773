import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
    return {type: 'DEBUG_SUCCESS', success}
}

function _error(error) {
    return {type: 'DEBUG_ERROR', error}
}

function _processing(processing) {
    return {type: 'DEBUG_PROCESSING', processing}
}

function debug(payload,id=null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let {SftpList} = getState();
        let site_id = id || SftpList.data.site_id

        // let {site_id} = SftpList.data;

        axios({
            url: apiBaseUrl(`customer/sites/${site_id}/tools/debug`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(getSiteStatus(site_id));
                store.dispatch(getSite(site_id))

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        });
    }
}

export default debug;