import React from "react";
import Modal from "react-responsive-modal";
import TextField from "../form/TextField";

class ResponsiveModalForm extends React.Component {
  render() {
    const {
      visible,
      closeModal,
      children,
      extraClass,
      backdrop,
      handleClose,
      closeIcon,
      closeOnEsc,
      closeOnOverlayClick,
      emailVerifyPopup,
      sslPopup,
      redirectPopup,
      domainPopup,
    } = this.props;
    let extraClassCss = "";
    if (typeof extraClass != "undefined") {
      extraClassCss = extraClass;
    }

    let backdropD = true;
    if (typeof backdrop != "undefined") {
      backdropD = backdrop;
    }

    return (
      <Modal
        open={!!visible}
        onClose={closeModal}
        onHide={handleClose}
        showCloseIcon={closeIcon}
        closeOnEsc={closeOnEsc}
        closeOnOverlayClick={closeOnOverlayClick}
        animationDuration={200}
        backdrop={backdropD}
        keyboard={false}
        classNames={{
          overlay: `modal-overlay`,
          modal: emailVerifyPopup
            ? `modal-content custom-popup confirmation-popup`
            : domainPopup
            ? `modal-content custom-popup domainPopup`
            : sslPopup || redirectPopup
            ? `modal-content custom-popup ssl-popup`
            : `modal-content custom-popup ${extraClassCss}`,
        }}
        center
      >
        <div className="modal-body">{children}</div>
      </Modal>
    );
  }
}

export default ResponsiveModalForm;
