import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Site from "../pages/site/list/Site";
import SiteForm from "../pages/site/form/SiteForm";
import UserForm from "../pages/user/form/UserForm";
import YourActivityLog from "../pages/activity-log/yourActivityList/Activity";
import Support from "../pages/support/Support";

import Backup from "../pages/site/backup/list/BackupList";
import Domain from "../pages/site/domain/list/Domain";
import DangerZone from "../pages/site/danger-zone/DangerZone";
import Info from "../pages/site/lunchpad/LunchPad";
import Tools from "../pages/site/feature-block/list/FeatureBlock";
import AccountDashboard from "../pages/account/AccountDashboard";
import OverView from "../pages/account/overview/Overview";
import Payment from "../pages/account/payment/Payment";
import OrganizationForm from "../pages/oragnization/form/OrganizationForm";
import Cache from "../pages/site/cache/list/Cache";
import Ssl from "../pages/site/ssl/list/SSL";

import Logs from "../pages/site/logs/list/LogsList";
import ActivateAccount from "../pages/account/activate-account/ActivateAccount";

import UserActivation from "../pages/LoggedInUserActivation/UserActivation";
import Redirects from "../pages/site/redirects/list/Redirects";
import Migration from "../pages/manual-migration/list/Migration";
import MigrationForm from "../pages/manual-migration/MigrationForm";
import MigrationDetails from "../pages/manual-migration/form/MigrationForm";
import Plan from "../pages/site/plan/Plan";

class AdminRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Site} />
        <Route exact path="/login" render={() => <Redirect to="/" />} />
        <Route exact path="/verify/:token" component={UserActivation} />

        <Route exact path="/redirect" render={() => <Redirect to="/" />} />
        <Route exact path="/sites" component={Site} />
        <Route exact path="/sites/create" component={SiteForm} />
        <Route
          exact
          path="/users/:id([0-9]{0,9})/update"
          component={UserForm}
        />
        <Route exact path="/account/detail" component={AccountDashboard} />
        <Route exact path="/account/billing" component={OrganizationForm} />
        <Route exact path="/account/overview" component={OverView} />
        <Route exact path="/account/payment" component={Payment} />
        <Route
          exact
          path="/account/activate-account"
          component={ActivateAccount}
        />
        <Route exact path="/activitylog" component={YourActivityLog} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/migration" component={Migration} />
        <Route exact path="/migration/create" component={MigrationForm} />
        <Route
          exact
          path="/migration/:id([0-9]{0,9})/detail"
          component={MigrationDetails}
        />

        <Route exact path="/sites/:id([0-9]{0,9})/backups" component={Backup} />
        <Route exact path="/sites/:id([0-9]{0,9})/domain" component={Domain} />
        <Route
          exact
          path="/sites/:id([0-9]{0,9})/dangerzone"
          component={DangerZone}
        />
        <Route exact path="/sites/:id([0-9]{0,9})/launchpad" component={Info} />
        <Route exact path="/sites/:id([0-9]{0,9})/tools" component={Tools} />
        <Route exact path="/sites/:id([0-9]{0,9})/logs" component={Logs} />
        <Route exact path="/sites/:id([0-9]{0,9})/cache" component={Cache} />
        <Route exact path="/sites/:id([0-9]{0,9})/ssl" component={Ssl} />
        <Route
          exact
          path="/sites/:id([0-9]{0,9})/redirects"
          component={Redirects}
        />

        <Route exact path="/sites/:id([0-9]{0,9})/plan" component={Plan} />

        <Route exact path="/sites/demo/backups" component={Backup} />
        <Route exact path="/sites/demo/domain" component={Domain} />
        <Route exact path="/sites/demo/redirects" component={Redirects} />
        <Route exact path="/sites/demo/ssl" component={Ssl} />
        <Route exact path="/sites/demo/dangerzone" component={DangerZone} />
        <Route exact path="/sites/demo/launchpad" component={Info} />
        <Route exact path="/sites/demo/tools" component={Tools} />
        <Route exact path="/sites/demo/logs" component={Logs} />
        <Route exact path="/sites/demo/plan" component={Plan} />
        <Route exact path="/sites/demo/cache" component={Cache} />

        <Route exact path="/sites/demo/backups" component={Backup} />
        <Route exact path="/sites/demo/domain" component={Domain} />
        <Route exact path="/sites/demo/redirects" component={Redirects} />
        <Route exact path="/sites/demo/ssl" component={Ssl} />
        <Route exact path="/sites/demo/dangerzone" component={DangerZone} />
        <Route exact path="/sites/demo/launchpad" component={Info} />
        <Route exact path="/sites/demo/tools" component={Tools} />
        <Route exact path="/sites/demo/logs" component={Logs} />
        <Route exact path="/sites/demo/plan" component={Plan} />
        <Route exact path="/sites/demo/cache" component={Cache} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default AdminRoutes;
