import React from 'react';
import store from '../../../../Store';
import {setSiteIDLogs} from "./Logs-Action";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getAccessLogs from '../../../../ajax/actions/logs/access_logs'
import getSite from '../../../../ajax/actions/sites/site_get'
import getSiteStatus from '../../../../ajax/actions/sites/site_status_get'

class LogsWorker extends React.Component {

    componentDidMount() {
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/logs'
        if(current_path !== demo_site_path){
            store.dispatch(setSiteIDLogs(this.props.match.params.id))
            store.dispatch(getAccessLogs(this.props.match.params.id))
            store.dispatch(getSite(this.props.match.params.id));
            store.dispatch(getSiteStatus(this.props.match.params.id));
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let { getSiteStatus, getAccessLogs, getSite} = state;
    return { getSiteStatus, getAccessLogs, getSite};
}

export default withRouter(connect(mapStateToProps)(LogsWorker));