import React from 'react';
import {withRouter} from 'react-router';
import store from '../../../Store';
import {connect} from 'react-redux';
import getCard from "../../../ajax/actions/card/cards_get";
// import getInvoice from "../../../ajax/actions/invoice/invoice_get";
import getSites from "../../../ajax/actions/sites/sites_get";
import getPayments from "../../../ajax/actions/invoice/payment_get";
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";

class PaymentWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getCard());
        store.dispatch(getSites())
        // store.dispatch(getInvoice());
        store.dispatch(getPayments())
        store.dispatch(getDemoSite())

    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser,singleInvoicePay} = state;
    return {getLoggedInUser,singleInvoicePay}
}

export default withRouter(connect(mapStateToProps)(PaymentWorker));