import React from 'react'
import {MigrationFormState} from './MigrationForm-State'
import {errorsList, errorsPresent} from '../../helpers/ErrorHelper'
import MigrationDetailsForm from "./migration-details-form/MigrationDetailsForm";
import CurrentHostform from "./current-host-form/CurrentHostForm";
import MigrateForm from "./migration-final-form/MigrateForm";

export default function migrationFormReducer(state = MigrationFormState, action) {

    let {data} = {...state};
    let {
        mode,
        currentStep,
        initialLoading,
        currentHostForm1,
        migrationDetailsForm2,
        migrateForm3,
        formMode,
    } = action;
    switch (action.type) {

        case 'SET-MIGRATION-FORM-STEP-ON-CHANGE':
            if (mode !== `previous`) {
                data.submitted[`step${currentStep}`] = true;
            }

            if (currentStep === 0
                && currentHostForm1 &&  currentHostForm1.errorsPresent !== true && !data.errorsPresent
            ) {
                if (mode === `next` && data.validateFirst === true
                ) {
                    data.currentStep++;
                }
                else{
                    data.currentStep = 0
                }
            }

            if (currentStep === 1
                &&
                currentHostForm1.errorsPresent !== true && !data.errorsPresent &&
                migrationDetailsForm2.errorsPresent !== true && !data.errorsPresent
                && mode !== `previous`
            ) {
                if (mode === `next`
                    && data.validateSecond
                ) {
                    data.currentStep++;
                } else if (mode === `previous`) {
                    data.currentStep--;
                    data.validateSecond=false;
                }
            }

            if (currentStep === 1 &&
                mode === `previous`
            ) {
                if (mode === `previous`) {
                    data.currentStep--;
                    data.validateSecond=false;
                    data.validateFirst=false;
                }
            }

            if (currentStep === 2
            ) {
                if (mode === `next` && initialLoading) {
                    data.currentStep++;
                } else if (mode === `previous`) {
                    data.currentStep--;
                    data.errorsPresent = false;
                    data.validateFirst=true;
                    data.validateSecond=false;

                } else if (mode === `submit` && initialLoading) {
                    data.dispatchAPI = true;
                    data.validateSecond=false;
                    data.validateFirst=false;
                } else {
                    data.mode = "submit"
                    data.dispatchAPI = true;
                    data.validateSecond=false;
                    data.validateFirst=false;
                }
            }
            return {...state, data};


        case 'SET-MIGRATION-FORM-MODE':
            data.mode = formMode;
            return {...state, data};

        case 'STEP-CHANGE':
            data.currentStep = 0;
            data.errorsPresent = true;
            return {...state, data};

        case 'STEP-CHANGE-SECOND-STEP':
            data.currentStep = 1;
            data.errorsPresent = true;
            return {...state, data};

            case 'STEP-CHANGE-THIRD-STEP':
            data.currentStep = 2;
            data.errorsPresent = true;
            return {...state, data};

        case 'ON-CHANGE':
            data.validateFirst = false;
            data.validateSecond = false;
            return {...state, data};

        case 'ON-CHANGE-SECOND':
            data.validateSecond = false;
            return {...state, data};

        case 'ON-MIGRATION-FORM-SUBMIT':
            buildErrors();
            if (!currentHostForm1.errorsPresent
                && !migrationDetailsForm2.errorsPresent
                && !migrateForm3.errorsPresent
            ) {
                data.submitted.step0 = true;
                data.submitted.step1 = true;
                data.submitted.step2 = true;
                data.initialLoading = false;
            }
            return {...state, data};

        case 'SET-DEFAULT-MIGRATION-DATA':
            data = {
                payload: {},
                currentStep: 0,
                message: ``,
                errors: {},
                errorsPresent: false,
                validateFirst: false,
                validateSecond: false,
                serverValidationErrors: {},
                mode: ``,
                submitted: {
                    step0: false,
                    step1: false,
                    step2: false,
                },
                steps: [
                    {
                        content: <MigrationDetailsForm/>,
                    },
                    {
                        content: <CurrentHostform/>,
                    },
                    {
                        content: <MigrateForm/>,
                    },
                ]
            };
            return {...state, data};

        case 'CANCEL-MIGRATION-FORM-DISPATCH':
            data.dispatchAPI = false;
            return {...state, data};

        case 'FIRST-VALIDATION-SUCCESS':
            data.validateFirst = true;
            data.dispatchAPI = false;
            data.errorsPresent = false;
            data.currentStep = 1;
            return {...state, data};

        case 'SECOND-VALIDATION-SUCCESS':
            data.validateSecond = true;
            data.dispatchAPI = false;
            data.errorsPresent = false;
            data.currentStep = 2;
            return {...state, data};

        case 'MIGRATION-FORM-SERVER-VALIDATION-ERRORS':
            data.dispatchAPI = false;
            data.errorsPresent = true;
            data.initialLoading = false;
            data.currentStep = 0
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)

        data.errorsPresent = errorsPresent(errors);
    }

    function errors(field) {
    }
}