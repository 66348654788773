const DefaultPhpVersionFormState = {
    payload: {
        php_version: ``,
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
};

export const PhpVersionFormState = {data: DefaultPhpVersionFormState};