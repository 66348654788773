import React from 'react';
import {endOfURL, pushToPage, secondToLastItemInURL} from "../../../helpers/UrlHelper";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class NotVerifiedRedirect extends React.Component {
    render() {

        const current_path = this.props.location.pathname;
        const account_paths = ['/sites'];
        let {action, role, getLoggedInUser} = this.props;

        const containActivationLink = endOfURL();

        if (getLoggedInUser.success) {
            if (!getLoggedInUser.success.data.data.verified) {
                // console.log(` <|current_path  |> `, current_path)
                if (!account_paths.includes(current_path)) {
                    if (secondToLastItemInURL() === "verify" && containActivationLink) {
                        // pushToPage(this.props, current_path)
                    } else {
                        pushToPage(this.props, `/sites`)

                    }

                }
            }
        }


        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, getLoggedInUser} = state;

    let {action, routes_url} = route.data;

    let role = route && route.data.user && route.data.user.role.slug;

    return {route, action, routes_url, getLoggedInUser, role};
}

export default withRouter(connect(mapStateToProps)(NotVerifiedRedirect));


