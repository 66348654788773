import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import DomainFormWorker from './DomainFormWorker'
import {
    onDomainFormChange,
    onDomainFormSubmit,
    onDomainFormNameChange,
    onDomainFormModeChange,
    removeErrorPresent,
    onDomainFormInputChange,
    onRemoveErrorMessage
} from "./DomainForm-Action";
import TextFieldDomain from "../../../../components/shared/form/TextFieldDomainForm";
import TextField from "../../../../components/shared/form/TextFieldSingleDomain";
import CheckField from "../../../../components/shared/form/CheckFieldDomain";
import Loading from "../../../../components/shared/loaders/Loading";
import {toggleDomainFormModal} from "../list/Domain-Action";
import MultipleValueTextInput from 'react-multivalue-text-input';
import i18next from 'i18next'
import {withRouter} from "react-router";

class DomainForm extends React.Component {

    state = {
        inputs: ['input-0'],
        input: [],
        data: [],
        version: ""
    };

    domainFormSubmit = (e) => {
        e.preventDefault();
        if (this.state.version === 2) {
            store.dispatch(onDomainFormModeChange("bulk"))
            store.dispatch(onDomainFormNameChange(this.state.data))
        }
        store.dispatch(onDomainFormSubmit());

    };

    onChangeName = (allItems, lb_version) => {
        store.dispatch(onRemoveErrorMessage());

        this.setState({
            data: allItems,
        });
        this.setState({
            version: lb_version,
        });
    };

    onChange = (e) => {
        store.dispatch(onDomainFormChange(e.target.name, e.target.value,));
    };


    render() {
        let {
            name, has_www, createDomain, domainForm, lb_version, createBulkDomain, domainName
        } = this.props;
        let {errors} = domainForm.data;
        let processing = createDomain.processing || createBulkDomain.processing;

        return (
            <>
                <h2 className="text-center mb-30">{i18next.t('sites.inside-site.domain.add-domain.title', {returnObjects: true})}</h2>
                <p>{i18next.t('sites.inside-site.domain.add-domain.desc', {returnObjects: true})}<a
                    href="javascript:void(0)">{i18next.t('sites.inside-site.domain.add-domain.link', {returnObjects: true})}</a>
                </p>
                <DomainFormWorker/>
                <div className="content-form-block light-gray-bg">
                    <form className="mt-30" onSubmit={this.domainFormSubmit}>
                        {lb_version === 2 ?
                            <>
                                <p style={{color: 'red'}}> {((this.state.data).length) && domainForm?.data.message !== "" ? domainForm?.data.message : null}</p>
                                <p style={{color: 'red'}}> {!((this.state.data).length) && errors && errors.name && errors.name.length ? "The domain name field is required." : null}</p>
                            </>
                            :
                            <>
                                <p style={{color: 'red'}}> {domainForm?.data.message !== "" ? domainForm?.data.message : null}</p>
                            </>
                        }
                        {lb_version === 2 ?
                                <>
                                    <MultipleValueTextInput
                                        onItemAdded={(item, allItems) => this.onChangeName(allItems, lb_version)}
                                        onItemDeleted={(item, allItems) => this.onChangeName(allItems, lb_version)}
                                        name="item-input"
                                        placeholder="Enter Domains; separate them with COMMA or ENTER."
                                    />
                                </>
                                :
                            <>
                                <TextField name="name"
                                           className="form-control"
                                           type="text"
                                           value={name}
                                           label={i18next.t('sites.inside-site.domain.add-domain.label', {returnObjects: true})}
                                    // small={i18next.t('sites.inside-site.domain.add-domain.small', {returnObjects: true})}
                                           placeholder={i18next.t('sites.inside-site.domain.add-domain.label', {returnObjects: true})}
                                           errors={errors.name}
                                           response_error={errors.name}
                                    // response_error={domainForm?.data?.payload.domainName !== "" ? "true" : "false"}
                                           onChange={this.onChange}/>

                                <CheckField name="has_www"
                                            id="domainForm"
                                            value={has_www}
                                            item={has_www === 1 ? "has_www" : null}
                                            label={i18next.t('sites.inside-site.domain.add-domain.check-label', {returnObjects: true})}
                                            checked={has_www === 1}
                                            onChange={this.onChange}
                                            errors={errors.has_www}
                                />
                            </>}

                        <div className="button-wrapper inline-buttons text-center mt-30">
                            <button className="btn-theme btn-sm" type="reset"
                                    onClick={() => store.dispatch(toggleDomainFormModal())}>{i18next.t('sites.inside-site.domain.add-domain.cancel', {returnObjects: true})}
                            </button>
                            <button type="submit" className="btn-theme btn-sm" disabled={processing}>
                                {processing ? <>
                                        {i18next.t('sites.inside-site.domain.add-domain.add', {returnObjects: true})}
                                        <Loading className="sm-loader-position" height="15" width="15"/></>
                                    : <> {i18next.t('sites.inside-site.domain.add-domain.add', {returnObjects: true})}</>}
                            </button>
                        </div>
                    </form>
                </div>
            </>

        )
    }

}

function mapStateToProps(state) {
    let {
        domainForm
        ,
        updateDomain
        ,
        createDomain
        ,
        getSite
        ,
        createBulkDomain
    }

        = state;

    let {errors, payload} = domainForm.data;

    let {lb_version} = getSite && getSite?.success

    let {
        name, has_www, sss_type, domainName
    } = payload;

    return {
        domainForm, updateDomain, createDomain, errors,
        name, has_www, sss_type, lb_version, createBulkDomain, domainName
    };
}

export default withRouter(connect(mapStateToProps)(DomainForm));