import React from 'react';
import store from '../../../Store';
import {endOfURL, pushToPage, secondToLastItemInURL} from "../../../helpers/UrlHelper";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import NoCardRoutes from "../../../routes/NoCardRoutes";

class NoCard extends React.Component
{
    render()
    {

        const current_path = this.props.location.pathname;
        const account_paths = ['/sites','/activitylog','/account/detail', '/account/overview', '/account/billing', '/account/payment','/sites/demo/redirects','/sites/demo/ssl',
        '/sites/demo/backups','/sites/demo/domain','/sites/demo/launchpad','/sites/demo/tools','/sites/demo/logs','/sites/demo/cache','/sites/create','/account/activate-account'];
        const have_card_account = ['/account/activate-account']
        let {action, role, getLoggedInUser, sale } = this.props;
        const containActivationLink = endOfURL();
        // let billable = getLoggedInUser.success.data.data.organization
// console.log(` <|  |> `, billable)
        if( getLoggedInUser.success  ) {
            let {billable} = getLoggedInUser.success.data.data.organization
            let {billing_type} = getLoggedInUser.success.data.data

            if( !sale && (!getLoggedInUser.success.data.data.has_active_card && billable === 1 && billing_type !== "fortnox")) {
                if (secondToLastItemInURL() === "verify" && containActivationLink) {
                    // pushToPage(this.props, current_path)
                }
               else if( !account_paths.includes( current_path )  ) {
                    pushToPage(this.props, `/sites`)
                }

            }else if( !sale &&(current_path.includes( have_card_account ) && billable === 1)){
                pushToPage(this.props, `/sites`)
            }
        }

        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, loginForm, getLoggedInUser} = state;

    let sale =
        getLoggedInUser &&
        getLoggedInUser.success &&
        getLoggedInUser.success.data &&
        getLoggedInUser.success.data.data &&
        getLoggedInUser.success.data.data.sale_tracking

    let {action, routes_url} = route.data;

    let role = route && route.data.user && route.data.user.role.slug;

    let {redirectTo} = loginForm.data;

    return {route, action, loginForm, redirectTo, routes_url, getLoggedInUser, role, sale};
}

export default withRouter(connect(mapStateToProps)(NoCard));


// export default withRouter( NoCard );