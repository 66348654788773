const DefaultMigrateFormStep3State = {
    payload: {
        create_new_site: ``,
        site_name: ``,
    },
    selectedRole: ``,
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    serverValidationErrors: {},

    message: ``,
    user_mode: ``,
    action: 'loader',
};

export const MigrateFormStep3State = {data: DefaultMigrateFormStep3State}

