import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {setMigrationDataForDetails} from "./MigrationForm-Action";
import getMigration from "../../../ajax/actions/manual-migration/migration_get";

class MigrationFormWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getMigration(this.props.match.params.id))
    }

    componentDidUpdate(prev) {
        if (this.props.getMigration !== prev.getMigration) {
            let {success} = this.props.getMigration;
            if(success) {
                store.dispatch(setMigrationDataForDetails());
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {migrationDetailsForm, getMigration} = state;
    return {migrationDetailsForm, getMigration};
}

export default withRouter(connect(mapStateToProps)(MigrationFormWorker));