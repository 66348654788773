export default function getAccessLogsReducer
    (state = { error:null, success:null, processing:false }, action) {

    switch (action.type)
    {
        case 'GET_ACCESS_LOGS_SUCCESS':
            return {...state, ...{success: action.success }};

        case 'GET_ACCESS_LOGS_ERROR':
            return {...state, ...{ error: action.error }};

        case 'GET_ACCESS_LOGS_PROCESSING':
            return {...state, ...{ processing: action.processing }};

        default: return state;
    }
}