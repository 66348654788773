import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultForgotPasswordFormState = {
  payload:{
    email:``,
    password:``,
    password_confirmation:``,
    token:``,
  },
  token: ``,
  email:``,
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``
};

export const ForgotPasswordFormState = { data: DefaultForgotPasswordFormState };