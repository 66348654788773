import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import i18next from "i18next";

class SidebarMainMenu extends React.Component {
  change(option) {
    localStorage.setItem("lang", option.target.value);
    window.location.reload();
  }

  render() {
    const current_path = this.props.location.pathname;
    const account_paths = [
      "/account/detail",
      "/account/overview",
      "/account/billing",
    ];
    const lang = localStorage.getItem("lang") || "en";

    let { customer_id, role, getLoggedInUser } = this.props;
    return (
      <>
        <div className="main-menu">
          <div className="main-menu-wrapper">
            <div className="sidebar-upper-wrap">
              <span>Kepler Hosting</span>
              <ul className="upper-menu">
                <li>
                  <Link
                    className={current_path === "/sites" ? "active" : ""}
                    to={`/sites`}
                  >
                    {i18next.t("navigation-left.1", { returnObjects: true })}
                  </Link>
                </li>
                <li>
                  <Link
                    className={current_path === "/migration" ? "active" : ""}
                    to={`/migration`}
                  >
                    {i18next.t("navigation-left.6", { returnObjects: true })}
                  </Link>
                </li>
                <li>
                  <Link
                    className={current_path === "/activitylog" ? "active" : ""}
                    to={`/activitylog`}
                  >
                    {i18next.t("navigation-left.2", { returnObjects: true })}
                  </Link>
                </li>
                <li>
                  <Link
                    className={current_path === "/support" ? "active" : ""}
                    to={`/support`}
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className="sidebar-lower-wrap">
              <span>Organisation</span>
              <ul className="lower-menu">
                <li>
                  <Link
                    className={
                      account_paths.includes(current_path) ? "active" : ""
                    }
                    to={`/account/overview`}
                  >
                    {i18next.t("navigation-left.3", { returnObjects: true })}
                  </Link>
                </li>
                {getLoggedInUser.success &&
                  getLoggedInUser?.success?.data?.data?.organization
                    .billable === 1 && (
                    <li>
                      <Link
                        className={
                          current_path === "/account/payment" ? "active" : ""
                        }
                        to={`/account/payment`}
                      >
                        {i18next.t("navigation-left.4", {
                          returnObjects: true,
                        })}
                      </Link>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { route, getLoggedInUser } = state;
  let customer_id = getLoggedInUser?.success?.data.data.id;

  let role = route && route.data.user && route.data.user.role.slug;

  return { route, customer_id, getLoggedInUser, role };
}

export default withRouter(connect(mapStateToProps)(SidebarMainMenu));
