import React from 'react'
import {connect} from 'react-redux';
import ActivityWorker from './ActivityWorker';
import {toggleActivityListFilter,loadMore} from './ActivityList-Actions'
import store from '../../../Store';
import TableHead from "../../../components/shared/table/TableHead";
import {fullDateFormat} from "../../../helpers/DateTimeHelper";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import ActivityHeading from "./ActivityHeading";
import {endOfURL} from "../../../helpers/UrlHelper";
import {getCurrentDate} from "../../../helpers/DateTimeHelper";
import SiteTableCell from "../../site/list/SiteTableCell";
import i18next from 'i18next'

class Activity extends React.Component {
    state = {
        processing_sort: true,
        processing_more: false
    };
    toggleSort = (field) => {

        store.dispatch(toggleActivityListFilter({
            target: {name: `sort_by`, value: field, id: this.props.customer_id}
        }));
        this.setState({
            processing_sort: true,
            processing_more: false
        })
    };

    loadMoreInvoice(customer_id)
    {
        store.dispatch(loadMore({
            // target: {name: `sort_id`, value: field}
            target: {name: `limit`, value: 4,id:customer_id}
        }))
        this.setState({
            processing_more: true,
            processing_sort: false,
        })
    };

    render() {
        let {pages, getYourActivity, list, params,searchedParam, fields_en, fields_sv,customer_id,total,searchFrom,getLoggedInUser} = this.props;

        let {processing, error} = getYourActivity;
        let lang = localStorage.getItem('lang')

        if (error) {
            this.props.history.push('/');
        }

        return (
            <>
                    <ActivityHeading organization_id={this.props.match.params.id}/>
                {searchedParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <div className="title-block">
                                    <h2>{i18next.t('activity-log.page-heading' ,{returnObjects: true})}</h2>
                                </div>
                            </div>

                            <Page title={i18next.t('activity-log.page-heading' ,{returnObjects: true})} processing={processing}>

                                <div className="table-block table-responsive activity-log-table listing-table-alt">
                                    <table className="mb-0">
                                        <TableHead fields={
                                            lang === "en" ?
                                            fields_en

                                            :

                                            fields_sv
                                        }
                                                   params={params}
                                                   noActions={true}
                                                   onClick={this.toggleSort}/>
                                        <tbody>
                                        {
                                            (processing && (this.state && this.state.processing_sort === true)) ? <tr>
                                                    <td colSpan={5} className="text-center"><Loading/></td>
                                                </tr> :
                                                list && list.length ? list.map((l, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{fullDateFormat(l.created_at)}</td>

                                                                <td>{l.site_name || "-"}</td>
                                                                {l.payment ?
                                                                    <td>{l.event} {"("}{l.payment.amount} {l.payment.currency}{")"}</td> :
                                                                    <td>{l.event}</td>}
                                                                <td>
                                                                    {l.user_name}
                                                                </td>
                                                                <td>
                                                                    {/*<Badge type={l.status} round>*/}
                                                                    <i className={`icon-${(l.status === "failed") ? "close-circle" : ((l.status === "pending") ? 'pending' : 'checkmark')} text-${l.status === "failed" ? "danger" : l.status === "pending" ? "primary" : "success"}`}/>
                                                                    {/*</Badge>*/}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                   getLoggedInUser.success && !getLoggedInUser.success.data.data.has_active_card?
                                                       <tr>
                                                           <td>{getCurrentDate()}</td>
                                                           <td>{"Demo Site"}</td>
                                                           <td>{"Create Site"}</td>
                                                           <td>
                                                               {getLoggedInUser && getLoggedInUser?.success?.data?.data.first_name}
                                                           </td>
                                                           <td>
                                                               <i className="icon-checkmark text-success"/>
                                                           </td>
                                                       </tr>
                                                       :

                                                    <tr>
                                                        <td colSpan="5" className="text-center">No Activity Logs
                                                            Available
                                                        </td>
                                                    </tr>
                                        }
                                        {processing && (this.state && this.state.processing_more) === true ? <tr>
                                            <td colSpan={5} className="text-center"><Loading/></td>
                                        </tr> : null}
                                        </tbody>
                                    </table>
                                </div>
                                {list && list.length < total &&
                                <div className="ml-3 text-center">
                                    <button className="btn-theme mt-20"
                                            onClick={() => this.loadMoreInvoice(customer_id)}>{processing ? <><Loading/>{i18next.t('activity-log.load-more-button' ,{returnObjects: true})}</> : i18next.t('activity-log.load-more-button' ,{returnObjects: true})}</button>
                                </div>}
                                <ActivityWorker/>
                            </Page>
                        </div>
                    </>
                }
                </>
        )
    }
}

function mapStateToProps(state) {
    let {userDetail, yourActivityList, getYourActivity, getLoggedInUser,siteList} = state;
    let searchFrom = siteList?.data.params.searchFrom
    let {params:searchedParam} = siteList.data

    let {params, fields_sv, fields_en, modal} = yourActivityList.data;
    let customer_id = getLoggedInUser?.success?.data.data.id
    let {success} = getYourActivity;
    let list =
        success &&
        success.data &&
        success.data.data.data;
    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let from = success && success.data && success.data.data.from;
    let pages = {current_page, last_page, total, from};
    return {getYourActivity, list, userDetail, yourActivityList, pages, params, fields_sv, fields_en, modal, customer_id,total,searchFrom,siteList,getLoggedInUser, searchedParam};
}

export default connect(mapStateToProps)(Activity);