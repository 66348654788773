import { toggleURLQuery } from '../../../helpers/UrlHelper'
import store from "../../../Store"
import getPayments from "../../../ajax/actions/invoice/payment_get";

export function toggleAddCardFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-ADD-CARD-FORM-MODAL'});
    }
}

export function togglePaymentListFilter(e, history,) {
    return function (dispatch) {
        let {name, value} = e.target;
        dispatch({type: 'TOGGLE-PAYMENT-LIST-FILTER', name, value,history});
        store.dispatch(getPayments());
    }
}
export function loadMore(e,history) {
    return function (dispatch) {
        let { name, value } = e.target;
        dispatch({type:'LOAD-MORE', name, value, history});
        if(name === 'limit') {
            store.dispatch( getPayments());
            toggleURLQuery( 'limit', value ,history)
        }
        else {
            store.dispatch(getPayments());
        }
    }
}


