import React from 'react'
import {connect} from 'react-redux';
import Page from "../../../components/shared/pages/Page";
import SiteDashboardHeader from "../../site/SiteDashboardHeader";
import MigrationWorker from "./MigrationWorker";
import Loading from "../../../components/shared/loaders/Loading";
import MigrationTableCell from "./MigrationTableCell";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {endOfURL} from "../../../helpers/UrlHelper";
import SiteTableCell from "../../site/list/SiteTableCell";
import {loadMore} from "./Migration-Actions";
import store from "../../../Store";

class Migration extends React.Component {
    state = {
        initialLoading: false
    }

    loadMoreMigration()
    {
        store.dispatch(loadMore({
            // target: {name: `sort_id`, value: field}
            target: {name: `limit`, value: 4}
        }))
    };

    componentDidUpdate(prevProps) {
        if (this.props.getsMigration !== prevProps.getsMigration) {
            let {processing, success} = this.props.getsMigration;
            if (processing) {
                this.setState({initialLoading: true});
            }

            if (success) {
                this.setState({initialLoading: false})
            }
        }
    }

    render() {
        let {list, getsMigration, pages, searchFrom, searchParam} = this.props;
        let {processing} = getsMigration
        return (
            <>
                {/*Migration Header*/}
                <SiteDashboardHeader/>
                {searchParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>

                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <div className="title-block">
                                    <h2>Migration</h2>
                                </div>
                            </div>

                            {/*Migration Body*/}
                            <Page title="Migration">

                                {this.state.initialLoading ?
                                    <div className="listing-table-block mt-60">
                                        <div className="table-responsive listing-table">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td colSpan={5} className="text-center"><Loading/></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {list && list?.length ?
                                            <div className="listing-table-block">
                                                <div className="table-responsive listing-table ">
                                                    <div className="migration-rules">
                                                        <div className="migration-rules-text">
                                                            <h6>Initiate Migration</h6>

                                                            {/*<p>You currently do not have any made payments. Don't worry,
                                                                please navigate to
                                                                the overview
                                                                tab to see your<br/> outstanding payments once you have
                                                                created your first
                                                                site.<br/></p>*/}


                                                            <div className="migration-rules-buttons">
                                                                <p> We aim to complete Premium migrations in one
                                                                    business day. With a premium migration,
                                                                    we offer the ability to schedule the migration, or
                                                                    for it to be performed as soon as
                                                                    possible. For scheduled migrations we will be in
                                                                    touch possible, we'll send you an update
                                                                    when the migration starts. We will then keep you
                                                                    informed about its progress, and confirm
                                                                    when it's ready. Please note that we don't process
                                                                    site migrations on weekends.</p>
                                                                <div>
                                                                    <Link to='/migration/create'>
                                                                        <button className={"btn-theme"}
                                                                        >
                                                                            Request Migration
                                                                        </button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}
                                        {/*Table*/}

                                        {this.state.initialLoading ?
                                            <div className="listing-table-block mt-60">
                                                <div className="table-responsive listing-table">
                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td colSpan={5} className="text-center"><Loading/></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            :
                                            <MigrationTableCell/>
                                        }
                                       {/* {list && list?.length ? (
                                            <div className="fixed-footer ">
                                                <PaginationButtons pages={pages}
                                                                   processing={processing}
                                                                   fetchAction={toggleMigrateFilter}/>
                                            </div>
                                        ) : null}*/}
                                        {pages.total > 25 && (pages.to !== pages.total) &&
                                        <div className="load-more ml-3">
                                            <button className="btn-theme mt-10"
                                                    onClick={() => this.loadMoreMigration()}>{processing ? <>Load
                                                More <Loading className="sm-loader-position" height="15"
                                                              width="15"/></> : `Load More`}</button>
                                        </div>
                                        }
                                    </>}

                            </Page>
                            <MigrationWorker/>
                        </div>
                    </>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
        migrationList
        ,
        getsMigration,
        siteList
    }

        = state

    let {params, fields, migrate} = migrationList.data

    let {success} = getsMigration
    let list = success && success.data

    let searchFrom = siteList?.data.searchFrom
    let searchParam = siteList.data.params;

    let current_page = success && success.current_page;
    let last_page = success && success.last_page;
    let total = success && success.total;
    let to = success && success.to;
    let from = success && success.from;
    let pages = {current_page, last_page, total, from, to};
    return {migrationList, getsMigration, list, params, fields, pages, migrate, searchFrom, searchParam};
}

export default withRouter(connect(mapStateToProps)(Migration));