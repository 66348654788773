import React from 'react'
import TextField from '../../../components/shared/form/MigrationTextField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onFormChange, changeType,
    initializeMigrateFormStep3Error
} from './MigrateFormStep3-Actions'
import SelectField from "../../../components/shared/form/MigrationSelectField";
import {yesNoType} from "../../../static-data/UserData";


class MigrateForm extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(initializeMigrateFormStep3Error())
    }

    onChange = (e) => {
        e.preventDefault()
        store.dispatch(onFormChange(e));
    }
    onChangeType = (type) => {
        store.dispatch(changeType(type));
    }

    render() {
        let {
            errors, payload, submitted
        } = this.props;

        let {
            create_new_site, site_name
        } = payload

        let {step2} = submitted;


        return (
            <>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="site_name"
                                       value={site_name}
                                       errors={step2 && errors && errors.site_name}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    {/*<div className="col-md-6">
                        <div className="form-group">
                            <TextField name="create_new_site"
                                       value={create_new_site}
                                       label="Create a new site?"
                                       errors={step2 && errors && errors.create_new_site}
                                       onChange={this.onChange}/>
                        </div>
                    </div>*/}
                    <div className=" col-md-6">
                        <div className="form-group">
                            <SelectField name="create_new_site"
                                         value={create_new_site}
                                         options={yesNoType}
                                         errors={step2 && errors && errors.create_new_site}
                                         onChange={this.onChange}
                                         placeholder="Select..."
                                         label="Create a New Site?"
                                         _value="value"
                                         _label="name"/>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
         createMigration, migrationDetailsStep2,
        currentHostStep1,migrateFormStep3,migrationForm
    } = state;

    let {payload, errorMessage} = migrateFormStep3.data;

    let {errors, errorsPresent} = migrateFormStep3.data;

        let {submitted, mode} = migrationForm.data;
    return {
        errors, errorsPresent, createMigration, mode,
        migrationDetailsStep2, errorMessage, payload, submitted, currentHostStep1,migrateFormStep3,migrationForm
    };
}

export default connect(mapStateToProps)(MigrateForm);
