import React from 'react'
import {connect} from 'react-redux';
import ActivityWorker from './ActivityWorker';
import {toggleActivityListFilter,loadMore} from './ActivityList-Actions'
import store from '../../../Store';
import TableHead from "../../../components/shared/table/TableHead";
import {fullDate, fullDateFormat} from "../../../helpers/DateTimeHelper";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import SiteDashboardHeader from "../../site/SiteDashboardHeader";

class Activity extends React.Component {

    toggleSort = (field) => {
        store.dispatch(toggleActivityListFilter({
            target: {name: `sort_by`, value: field, id: this.props.customer_id}
        }))
    };
    loadMoreInvoice()
    {
        store.dispatch(loadMore({
            // target: {name: `sort_id`, value: field}
            target: {name: `limit`, value: 4}
        }))
    };

    render() {
        let {pages, getActivity, list, params, fields} = this.props;

        let {processing, error} = getActivity;
        if (error) {
            this.props.history.push('/');
        }

        return (
            <>
                    <SiteDashboardHeader/>
                    {/*<ActivityHeading organization_id={this.props.match.params.id}/>*/}

                    <div className="boxed-block">
                        <div className="heading-block mt-70">
                            <div className="title-block">
                                <h2>Activity Log</h2>
                            </div>
                        </div>
                        {/*<div className="content-menu">*/}

                        {/*    <ActivityLinks/>*/}
                        {/*</div>*/}
                        <Page title="Activity Log" processing={processing}>

                        <div className="table-block table-responsive activity-log-table">
                            <table className="mb-50">
                                <TableHead fields={fields}
                                           params={params}
                                           noActions={true}
                                           onClick={this.toggleSort}/>
                                <tbody>
                                {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                                    list && list.length ? list.map((l, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{fullDateFormat(l.created_at)}</td>

                                                    <td>{l.site_name || "-"}</td>
                                                    {l.payment ?
                                                        <td>{l.event} {"("}{l.payment.amount} {l.payment.currency}{")"}</td>:
                                                        <td>{l.event}</td>}
                                                    <td>
                                                        {l.user_name}
                                                        {/*{l.user_name && true ?*/}
                                                        {/*    <Link to={`/users/${l.user_id}/update`}>*/}
                                                        {/*        {l.user_name} </Link> : "Kepler Hosting"}*/}
                                                    </td>
                                                    <td>
                                                        {/*<Badge type={l.status} round>*/}
                                                        <i className={`icon-${l.status === "failed" ? "close-circle" : 'checkmark'} text-${l.status === "failed" ? "danger" : l.status === "pending" ? "primary" : "success"}`}/>
                                                        {/*</Badge>*/}
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="5" className="text-center">No Activity Logs Available</td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                            {pages.total > 25 && (pages.to !== pages.total) &&
                            <div className="load-more ml-3">
                                <button className="btn-theme mt-10"
                                        onClick={() => this.loadMoreInvoice()}>{processing ? <><Loading/>Load
                                    More</> : `Load More`}</button>
                            </div>
                            }
                        <ActivityWorker/>
                        </Page>
                    </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {userDetail, activityList, getActivity, getLoggedInUser} = state;

    let {params, fields, modal} = activityList.data;
    let customer_id = getLoggedInUser?.success?.data.data.id
    let {success} = getActivity;
    let list =
        success &&
        success.data &&
        success.data.data.data;
    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let to = success && success.data && success.data.data.to;
    let from = success && success.data && success.data.data.from;
    let pages = {current_page, last_page, total, from};

    return {getActivity, list, userDetail, activityList, pages, params, fields, modal, customer_id};
}

export default connect(mapStateToProps)(Activity);