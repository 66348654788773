import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AccountDashboardHeader from "../AccountDashboardHeader";
import Page from "../../../components/shared/pages/Page";
import AccountLinks from "../AccountLinks";
import PaymentWorker from "./PaymentWorker";
import Loading from "../../../components/shared/loaders/Loading";
import store from "../../../Store";
import swal from "sweetalert";
import postDefaultCard from "../../../ajax/actions/card/default_card_post";
import { scrollToError } from "../../../helpers/ScrollHelper";
import { unslufigy } from "../../../helpers/StringHelper";
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import BillingForm from "../billing/Billing";
import {
  toggleAddCardFormModal,
  loadMore,
  togglePaymentListFilter,
} from "./Payment-Actions";
import moment from "moment";
import getDownloadInvoice from "../../../ajax/actions/invoice/invoice_download_get";
import {
  justDates,
  justDate,
  fullDateFormat,
  fullDateStringNoTime,
} from "../../../helpers/DateTimeHelper";
import deleteCard from "../../../ajax/actions/card/card_delete";
import singleInvoicePay from "../../../ajax/actions/pay/single_invoice_pay_post";
import { endOfURL, pushToPage } from "../../../helpers/UrlHelper";
import SiteTableCell from "../../site/list/SiteTableCell";
import NoCardMessage from "../../../components/shared/pages/NoCardMessage";
import { ucFirst } from "../../../helpers/StringHelper";
import TableHead from "../../../components/shared/table/TableHead";
import i18next from "i18next";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.fireBillingButton = this.fireBillingButton.bind(this);
  }

  fireBillingButton(e) {
    e.preventDefault();
    /*v1*/
    // const current_path = this.props.location.pathname;
    // const account_paths = [ '/account/overview', '/account/payment'];
    // if( !account_paths.includes( current_path ) ) {
    //     pushToPage(this.props, `/account/overview`)
    // }
    const current_path = this.props.location.pathname;
    const account_paths = ["/account/activate-account"];
    if (!account_paths.includes(current_path)) {
      pushToPage(this.props, `/account/activate-account`);
    }

    // store.dispatch( toggleAddCardFormModal() );
  }

  state = {
    processing_sort: true,
    processing_more: false,
  };

  toggleSort = (field) => {
    store.dispatch(
      togglePaymentListFilter({
        target: { name: `sort_by`, value: field },
      })
    );
    this.setState({
      processing_sort: true,
      processing_more: false,
    });
  };

  download(invoice_id) {
    store.dispatch(getDownloadInvoice(invoice_id));
  }

  removeCard = (card_id) => {
    swal(
      i18next.t(
        "account.inside-account.overview.card-details.swal-remove.desc",
        { returnObjects: true }
      ),
      {
        buttons: {
          cancel: i18next.t(
            "account.inside-account.overview.card-details.swal-remove.btns.cancel",
            { returnObjects: true }
          ),
          catch: {
            text: i18next.t(
              "account.inside-account.overview.card-details.swal-remove.btns.confirm",
              { returnObjects: true }
            ),
            value: card_id,
          },
        },
      }
    ).then((value) => {
      if (value) store.dispatch(deleteCard(card_id));
    });
  };

  payNow = (invoice_id) => {
    swal("Are you sure you want to pay?", {
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Yes",
          value: true,
        },
      },
    }).then((value) => {
      if (value) store.dispatch(singleInvoicePay(invoice_id));
    });
  };

  makeDefaultConfirm = (card_id) => {
    swal(
      i18next.t(
        "account.inside-account.overview.card-details.swal-default.desc",
        { returnObjects: true }
      ),
      {
        buttons: {
          cancel: i18next.t(
            "account.inside-account.overview.card-details.swal-default.btns.cancel",
            { returnObjects: true }
          ),
          catch: {
            text: i18next.t(
              "account.inside-account.overview.card-details.swal-default.btns.confirm",
              { returnObjects: true }
            ),
            value: card_id,
          },
        },
      }
    ).then((value) => {
      if (value) store.dispatch(postDefaultCard(card_id));
    });
  };

  loadMorePayments() {
    store.dispatch(
      loadMore({
        // target: {name: `sort_id`, value: field}
        target: { name: `limit`, value: 4 },
      })
    );
    this.setState({
      processing_more: true,
      processing_sort: false,
    });
  }

  render() {
    let {
      cardDetail,
      add_card_modal,
      getPayments,
      list,
      total,
      searchFrom,
      params,
      getCard,
      paymentList,
      singleInvoicePay,
      user_name,
      getLoggedInUser,
      sale,
    } = this.props;

    let { fields_en, fields_sv } = paymentList.data;
    let paymentsParams = paymentList.data.params;

    let { processing } = getPayments;
    let cardProcessing = getCard.processing;
    let singleInvoicePayProcessing = singleInvoicePay.processing;
    let lang = localStorage.getItem("lang");

    let { billing_type } = getLoggedInUser.success.data.data;

    return (
      <>
        <AccountDashboardHeader />
        {params.q !== "" && searchFrom === endOfURL() ? (
          <>
            <div className="boxed-block">
              <div className="listing-table-block mt-60">
                <div className="table-responsive listing-table">
                  <table>
                    <tbody>
                      <SiteTableCell />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {(getLoggedInUser.success &&
              getLoggedInUser?.success?.data?.data?.has_active_card) ||
            (getLoggedInUser.success &&
              getLoggedInUser?.success?.data?.data?.organization.billable ===
                1) ? (
              <NoCardMessage />
            ) : null}

            <div className="boxed-block">
              <div className="heading-block mt-70">
                <h2>
                  {i18next.t("account.page-heading", { returnObjects: true })}
                </h2>
              </div>
              <div className="content-menu">
                <AccountLinks site_id={this.props.match.params.id} />
              </div>
              <Page
                title={i18next.t("account.account-nav.payment", {
                  returnObjects: true,
                })}
                processing={processing}
              >
                {/* {sale && (
                  <div className="payment-notice">
                    <div className="light-gray-bg">
                      <div className="notice">
                        <h6 className="ml-5">
                          {sale && sale.valid_months} Month Free Credit
                        </h6>
                      </div>
                      <div className="payment-notice-message">
                        <p>
                          Your {sale && sale.valid_months} month free credit is
                          activate and will end on{" "}
                          {sale && fullDateStringNoTime(sale.expires_on)}. Feel
                          free to create as many sites as you like and contact
                          our support if you have any questions!
                        </p>
                      </div>
                    </div>
                  </div>
                )} */}

                <div className="content-container light-gray-bg">
                  <div className="text-block mb-20">
                    <h3>
                      {i18next.t(
                        "account.inside-account.payment.history.heading",
                        { returnObjects: true }
                      )}
                    </h3>
                  </div>

                  <div className="table-block table-responsive fixed-dropdown-btn listing-table-alt">
                    <table className="table-with-action-buttons">
                      {list && list.length !== 0 ? (
                        <TableHead
                          fields={lang === "en" ? fields_en : fields_sv}
                          params={paymentsParams}
                          noActions={true}
                          onClick={this.toggleSort}
                        />
                      ) : null}
                      <tbody>
                        {processing &&
                        this.state &&
                        this.state.processing_sort === true ? (
                          <tr>
                            <td colSpan={5} className="text-center">
                              <Loading />
                            </td>
                          </tr>
                        ) : list && list.length ? (
                          list.map((invoice, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <span>
                                    <strong>
                                      {justDate(invoice.created_at)}
                                    </strong>
                                  </span>
                                </td>
                                {/*  <td>{invoice.reference_number ?
                                                                <>{i18next.t('account.inside-account.payment.history.invoice-msg' ,{returnObjects: true})} {justDates(invoice.invoice_for)}</>
                                                                : <>{i18next.t('account.inside-account.payment.history.payment-msg' ,{returnObjects: true})}
                                                                    ({ucFirst(invoice.card_type)} {invoice.card_last_four})</>}</td>*/}
                                <td>
                                  {invoice.reference_number ? (
                                    <>
                                      {i18next.t(
                                        "account.inside-account.payment.history.invoice-msg",
                                        { returnObjects: true }
                                      )}{" "}
                                      {justDates(invoice.invoice_for)}
                                    </>
                                  ) : invoice.type === `coupon_redemption` ? (
                                    unslufigy(invoice.type)
                                  ) : invoice.payment_type === "fortnox" &&
                                    invoice.type === `auto_invoice_payment` ? (
                                    unslufigy(invoice.type) + "   (Fortnox)"
                                  ) : invoice.payment_type === "stripe" &&
                                    invoice.type === `auto_invoice_payment` ? (
                                    unslufigy(invoice.type) + "   (Stripe)"
                                  ) : (
                                    <>
                                      {i18next.t(
                                        "account.inside-account.payment.history.payment-msg",
                                        { returnObjects: true }
                                      )}
                                      ({ucFirst(invoice.card_type)}{" "}
                                      {invoice.card_last_four})
                                    </>
                                  )}
                                </td>
                                <td>
                                  {invoice.reference_number ? (
                                    <>
                                      {invoice.grand_total} {invoice.currency}
                                    </>
                                  ) : (
                                    <>
                                      - {invoice.amount} {invoice.currency}
                                    </>
                                  )}
                                </td>

                                {invoice.reference_number ? (
                                  <td className="action text-right">
                                    {invoice.status === "paid" ? (
                                      <div className="icon-wrapper">
                                        <i className="icon-checkmark" />
                                      </div>
                                    ) : billing_type !== "fortnox" ? (
                                      <>
                                        <div className="action-button action-pay">
                                          {/*<div className="button-block">*/}
                                          <button
                                            className="btn-theme btn-outline"
                                            onClick={() => {
                                              this.payNow(invoice.id);
                                            }}
                                          >
                                            {singleInvoicePayProcessing ? (
                                              <>
                                                <Loading color="blue" />
                                                Pay Now
                                              </>
                                            ) : (
                                              `Pay Now`
                                            )}
                                          </button>
                                        </div>
                                        {/*</div>*/}
                                      </>
                                    ) : null}
                                    <div className="dropdown dropdown-alt text-center">
                                      <span
                                        className="icon-more"
                                        data-toggle="dropdown"
                                      ></span>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <a
                                          href="javascript:void(0);"
                                          className="dropdown-item"
                                          type="button"
                                          onClick={() =>
                                            this.download(invoice.id)
                                          }
                                        >
                                          {i18next.t(
                                            "account.inside-account.payment.history.download",
                                            { returnObjects: true }
                                          )}
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          })
                        ) : null}
                        {/*<div className="content-container light-gray-bg">
                                                <div className="content-message-block">
                                                    <h6>Your account is now setup and ready</h6>
                                                    <h2>Hi <span>{user_name}</span>,<br/>
                                                        Welcome to Kepler Hosting
                                                        Start by creating your first site</h2>
                                                    <p>Kepler Hosting is a managed WordPress hosting provider
                                                        that helps take care of all your needs
                                                        regarding your website. We run our services on
                                                        cutting-edge technology and take support to
                                                        the next level.</p>

                                                    <div className="button-wrapper inline-buttons mt-30">
                                                        <a href="" className="btn-theme btn-blue">Create a site</a>
                                                        <a href="#" className="button-wrapper">
                                                            <Link to={`sites/create`}
                                                                  className="btn-theme btn-blue">
                                                                Create Site
                                                            </Link>
                                                        </a>
                                                        <a href="https://keplerhosting.com/general/faq/"
                                                           target="_blank"
                                                           className="btn-theme btn-dashed">Knowledge base</a>
                                                    </div>
                                                </div>
                                            </div>}*/}
                        {processing &&
                        (this.state && this.state.processing_more) === true ? (
                          <tr>
                            <td colSpan={5} className="text-center">
                              <Loading />
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>

                  {list && list.length < total && (
                    <button
                      className="btn-theme mt-20"
                      onClick={() => this.loadMorePayments()}
                    >
                      {processing ? (
                        <>
                          <Loading />
                          {i18next.t(
                            "account.inside-account.payment.history.load",
                            { returnObjects: true }
                          )}
                        </>
                      ) : (
                        i18next.t(
                          "account.inside-account.payment.history.load",
                          { returnObjects: true }
                        )
                      )}
                    </button>
                  )}

                  {list && list.length === 0 ? (
                    <div className="content-container light-gray-bg">
                      <div className="content-message-block">
                        <h6>
                          {i18next.t(
                            "account.inside-account.payment.setup.start",
                            { returnObjects: true }
                          )}
                        </h6>
                        <h2>
                          {i18next.t(
                            "account.inside-account.payment.setup.hello",
                            { returnObjects: true }
                          )}
                          <span> {user_name}</span>,<br />
                          {i18next.t(
                            "account.inside-account.payment.setup.welcome",
                            { returnObjects: true }
                          )}
                          <br />
                          {i18next.t(
                            "account.inside-account.payment.setup.first",
                            { returnObjects: true }
                          )}
                        </h2>
                        <p>
                          {i18next.t(
                            "account.inside-account.payment.setup.desc",
                            { returnObjects: true }
                          )}
                        </p>

                        <div className="button-wrapper inline-buttons mt-30">
                          {/*<a href="" className="btn-theme btn-blue">Create a site</a>*/}
                          <a href="#" className="button-wrapper">
                            {(getLoggedInUser.success &&
                              getLoggedInUser?.success?.data?.data
                                ?.has_active_card) ||
                            (getLoggedInUser.success &&
                              getLoggedInUser?.success?.data?.data?.organization
                                .billable === 0) ? (
                              <Link
                                className="btn-theme btn-blue"
                                to={`/sites/create`}
                              >
                                {i18next.t(
                                  "account.inside-account.payment.setup.create",
                                  { returnObjects: true }
                                )}
                              </Link>
                            ) : (
                              <a
                                href="#"
                                className="btn-theme btn-blue"
                                onClick={this.fireBillingButton}
                              >
                                {i18next.t(
                                  "account.inside-account.payment.setup.create",
                                  { returnObjects: true }
                                )}
                              </a>
                            )}
                          </a>
                          <a
                            href="https://keplerhosting.com/general/faq/"
                            target="_blank"
                            className="btn-theme btn-dashed"
                          >
                            {i18next.t(
                              "account.inside-account.payment.setup.knowledge",
                              { returnObjects: true }
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/*// <div>You currently do not have any made payments. Please navigate to*/}
                  {/*// the overview tab to see your outstanding payments.</div>*/}
                </div>

                {billing_type === "stripe" && (
                  <div className="content-container light-gray-bg">
                    <div className="text-block mb-20 d-flex justify-content-between mx-100">
                      <h3>
                        {i18next.t(
                          "account.inside-account.overview.card-details.heading",
                          { returnObjects: true }
                        )}
                      </h3>
                      <div className="add-card">
                        <ResponsiveModalForm
                          visible={add_card_modal.mode}
                          extraClass="add-new-card-popup"
                          backdrop="static"
                          closeModal={() =>
                            store.dispatch(toggleAddCardFormModal())
                          }
                        >
                          <BillingForm />
                        </ResponsiveModalForm>
                        {/*<button className="btn-theme btn-outline blue-outline" data-toggle="modal"*/}
                        {/*        data-target="#public-path"*/}
                        {/*        onClick={() => store.dispatch(toggleAddCardFormModal())}*/}
                        {/*>Add Card*/}
                        {/*</button>*/}
                        {getLoggedInUser.success.data.data.has_active_card ? (
                          <button
                            className="btn-theme btn-outline blue-outline"
                            data-toggle="modal"
                            data-target="#public-path"
                            onClick={() =>
                              store.dispatch(toggleAddCardFormModal())
                            }
                          >
                            {i18next.t(
                              "account.inside-account.general.add-card",
                              { returnObjects: true }
                            )}
                          </button>
                        ) : (
                          <div className="button-wrapper">
                            <Link
                              className="btn-theme btn-outline blue-outline"
                              to={`/account/activate-account`}
                            >
                              {i18next.t(
                                "account.inside-account.general.add-card",
                                { returnObjects: true }
                              )}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    {cardProcessing ? (
                      <Loading />
                    ) : (
                      <div className="card-details-block">
                        {cardDetail && cardDetail.length
                          ? cardDetail.map((card, i) => {
                              return (
                                <div
                                  className="single-card-details payment-block"
                                  key={i}
                                >
                                  <div className="card-info">
                                    <h6>
                                      {card.card_brand_label}
                                      <span>XXXX-{card.card_last_four} </span>
                                    </h6>
                                    <p>
                                      {i18next.t(
                                        "account.inside-account.overview.card-details.expired",
                                        { returnObjects: true }
                                      )}{" "}
                                      {card.expiry_date} -{" "}
                                      {i18next.t(
                                        "account.inside-account.overview.card-details.added",
                                        { returnObjects: true }
                                      )}{" "}
                                      {moment(card.created_at).format(
                                        "MMM Do YYYY"
                                      )}
                                    </p>
                                  </div>
                                  {/*{card.default === 1 ? null :*/}
                                  <div className="button-block  d-flex align-items-center">
                                    {/*<a href="#"><i className="icon-more"></i></a>*/}
                                    {/*<a href="#" className="btn-theme btn-blue">Default</a>*/}
                                    <div className="dropdown dropdown-alt text-center">
                                      <span
                                        className="icon-more "
                                        data-toggle="dropdown"
                                      />
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        {/*<a href="#!"*/}
                                        {/*   className="dropdown-item"*/}
                                        {/*   type="button"*/}
                                        {/*>*/}
                                        {/*    Edit Card Details*/}
                                        {/*</a>*/}
                                        <button
                                          href="#!"
                                          className="dropdown-item"
                                          type="button"
                                          onClick={() => {
                                            this.removeCard(card.id);
                                          }}
                                          disabled={card.default}
                                        >
                                          {i18next.t(
                                            "account.inside-account.general.remove-card",
                                            { returnObjects: true }
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    <button
                                      className={
                                        card.default
                                          ? "btn-theme btn-blue ml-4"
                                          : "btn-theme ml-4"
                                      }
                                      onClick={() => {
                                        scrollToError();
                                        this.makeDefaultConfirm(card.id);
                                      }}
                                      disabled={card.default}
                                    >
                                      {card.default
                                        ? i18next.t(
                                            "account.inside-account.general.default",
                                            { returnObjects: true }
                                          )
                                        : i18next.t(
                                            "account.inside-account.general.make-default",
                                            { returnObjects: true }
                                          )}
                                    </button>
                                  </div>
                                  {/*}*/}
                                </div>
                              );
                            })
                          : i18next.t(
                              "account.inside-account.overview.card-details.not-added",
                              { returnObjects: true }
                            )}
                      </div>
                    )}
                  </div>
                )}
                <PaymentWorker />
              </Page>
            </div>
          </>
        )}
        {/*</Page>*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    route,
    getCard,
    paymentList,
    getInvoice,
    getSites,
    siteList,
    getPayments,
    singleInvoicePay,
    getLoggedInUser,
  } = state;
  let list =
    getPayments &&
    getPayments.success &&
    getPayments.success.data &&
    getPayments.success.data.data;
  let sale =
    getLoggedInUser &&
    getLoggedInUser.success &&
    getLoggedInUser.success.data &&
    getLoggedInUser.success.data.data &&
    getLoggedInUser.success.data.data.sale_tracking;

  let user_name = route && route.data.user && route.data.user.first_name;

  let searchFrom = siteList?.data.searchFrom;
  let { params, payload } = siteList.data;
  let user = route && route.data.user;
  let cardDetail = getCard && getCard.success;
  let { add_card_modal } = paymentList.data;
  let total = getPayments && getPayments.success && getPayments.success.total;

  return {
    getCard,
    user,
    cardDetail,
    paymentList,
    total,
    add_card_modal,
    getPayments,
    getInvoice,
    singleInvoicePay,
    getSites,
    list,
    searchFrom,
    params,
    user_name,
    payload,
    getLoggedInUser,
    sale,
  };
}

export default withRouter(connect(mapStateToProps)(Payment));
