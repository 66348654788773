import axios from 'axios';
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'
import store from "../../../Store"
import getCards from "./cards_get";

function _success(success) {
    return { type: 'DELETE_CARD_SUCCESS', success }
}
function _error(error) {
    return { type:'DELETE_CARD_ERROR', error }
}
function _processing(processing) {
    return { type: 'DELETE_CARD_PROCESSING', processing }
}

function deleteCard( id )
{
    return (dispatch, getState) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let config = {
            headers: { 'Authorization': 'Bearer ' + loginToken(),
                'lang': lang},
        };

         axios.delete(apiBaseUrl(`customer/cards/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch( getCards() )
            }).catch(error => {
                dispatch(_error(error));
                dispatch(_processing(false));
              handleError( error )
          });
    }
}
export default deleteCard;