export default function deleteRedirectReducer
    (state = {error: null, success: null, processing: false}, action) {

    switch (action.type) {
        case 'REDIRECT_DELETE_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'REDIRECT_DELETE_ERROR':
            return {...state, ...{error: action.error}};

        case 'REDIRECT_DELETE_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default:
            return state;
    }
}