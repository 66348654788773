import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import getYourActivityLogs from "../../../ajax/actions/activity-log/your_activity_logs_get";
import {setDefaultLimit} from "./ActivityList-Actions";
import getSites from "../../../ajax/actions/sites/sites_get";
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";


class ActivityWorker extends React.Component {
    componentDidMount() {
        let {customer_id} = this.props;
        store.dispatch(getYourActivityLogs(customer_id))
        store.dispatch(setDefaultLimit())
        store.dispatch(getSites())
        store.dispatch(getDemoSite())
    }

    componentDidUpdate(prev) {
        if(this.getYourActivityLogs !== prev.getYourActivityLogs)
        {
            store.dispatch(getYourActivityLogs(this.props.customer_id))
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {route,getYourActivityLogs} = state;

    let customer_id = route && route.data.user && route.data.user.id;

    return {route, customer_id,getYourActivityLogs}
}

export default withRouter(connect(mapStateToProps)(ActivityWorker));