import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, validationError} from '../../../helpers/ErrorHelper'
import store from "../../../Store"
import {billingFormServerValidationErrors,cancelOrganizationFormDispatch} from "../../../pages/account/billing/billing-form-card/CardBillingFormStep2-Actions"

function _success(success) {
    return {type: 'UPDATE_CARD_ADD_ORGANIZATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_CARD_ADD_ORGANIZATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_CARD_ADD_ORGANIZATION_PROCESSING', processing}
}

function updateCardAddOrganization(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'


        let {cardBillingForm} = getState();
        let payload = {...cardBillingForm.data.payload}
        if(payload.type=== 'personal'){
            delete payload.vat_id;
            delete payload.company_name;
        }
        // let organization_id = id|| getLoggedInUser?.success?.data?.data?.organization.id

        axios({
            // url: apiBaseUrl(`customer/organizations/${organization_id}`),
            url: apiBaseUrl(`customer/auth/activate`),
            method: "post",
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang,
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                // handleSuccess(res)
                store.dispatch(cancelOrganizationFormDispatch())
            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)
            store.dispatch(cancelOrganizationFormDispatch())
            if (error.response && error.response.status === 422) {
                validationError(error.response.data.message,error.response.status)
                // console.log(` <|  |> `, error.response.data.errors)
                store.dispatch(billingFormServerValidationErrors(error.response.data.errors))
            }
        });
    }
}


export default updateCardAddOrganization;
