import React from 'react';
import store from '../../../../Store';
import {setSiteIDBackup} from "./Backup-Action";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getBackup from "../../../../ajax/actions/backup/backup_get";
import getSite from "../../../../ajax/actions/sites/site_get";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getManualSiteBackup from "../../../../ajax/actions/backup/site_manual_backup_get";
import getSites from "../../../../ajax/actions/sites/sites_get";
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";
import getDemoSite from "../../../../ajax/actions/sites/demo_sites_get";

class BackupWorker extends React.Component {

    componentDidMount() {
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/backups'
        if(current_path === demo_site_path){
            store.dispatch(getDemoSite());
        }
        else {
            store.dispatch(setSiteIDBackup(this.props.match.params.id))
            store.dispatch(getBackup());
            store.dispatch(getSite(this.props.match.params.id));
            store.dispatch(getSiteDomain(this.props.match.params.id));
            store.dispatch(getManualSiteBackup(this.props.match.params.org_id));
            store.dispatch(getSiteStatus(this.props.match.params.id));
            store.dispatch(getSites())
            this.periodicFetch()
        }
    }

    componentWillUnmount() {

        clearInterval(this.timer);

    }

    periodicFetch() {
        this.timer = setInterval(() => store.dispatch(getSiteStatus(this.props.match.params.id)), 10000)
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getBackup, getSiteStatus, getManualSiteBackup, getSiteBackup, getDemoSite} = state;
    return {getBackup, getSiteStatus, getManualSiteBackup, getSiteBackup, getDemoSite};
}

export default withRouter(connect(mapStateToProps)(BackupWorker));