export function phpVersionFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PHP-VERSION-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPhpVersionFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PHP-VERSION-FORM-SUBMIT'})
    }
}

export function onPhpVersionFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PHP-VERSION-FORM-CHANGE', name, value});
    }
}
export function onPhpVersionFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-PHP-VERSION-FORM-MODE-CHANGE', mode});
    }
}

export function cancelPhpVersionFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PHP-VERSION-FORM-DISPATCH'});
    }
}

export function setDefaultPhpVersionForm() {
    return function (dispatch,getState) {
        let { getSite } = getState();
        let phpVersion = getSite && getSite.success && getSite.success.php_engine ;
        dispatch({type: 'SET-DEFAULT-PHP-VERSION-FORM',phpVersion});
    }
}


export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID',site_id});
    }
}


export function searchAndReplaceError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-PHP-VERSION-ERROR', error});
    }
}
