import React from 'react'
import {connect} from 'react-redux'
import store from '../../Store';
import {
    migrationFormChangeSteps, onMigrationFormSubmit
} from './MigrationForm-Actions'
import {ConfigMigrationFormSteps} from '../../Config'
import StepsButtons from "../../components/shared/steps-buttons/StepsButtons"
import SiteDashboardHeader from "../site/SiteDashboardHeader";
import Page from "../../components/shared/pages/Page";
import MigrationFormWorker from "./MigrationFormWorker"

class MigrationForm extends React.Component {

    render() {
        let {
            errors, mode, migrationForm, errorsPresent, createMigration, migrationValidationFirst,
            migrationValidationSecond,
        } = this.props;
        let steps = ConfigMigrationFormSteps;
        let {processing} = createMigration
        let migrationValidationFirstProcessing = migrationValidationFirst.processing
        let migrationValidationSecondProcessing = migrationValidationSecond.processing
        let {currentStep} = migrationForm.data

        return (
            <>
                <SiteDashboardHeader/>

                <div className="boxed-block">
                    <div className="heading-block mt-70">
                        <div className="title-block">
                            <h2>Migration</h2>
                        </div>
                    </div>


                    {/*Migration Body*/}
                    <Page title="Migration">
                        <div className="content-area">
                            <div className="migration-page-block light-gray-bg">
                                <div className="custom-form-block">
                                    <div className="upper-block text-center mb-75">
                                        <div className="migration-nav-menu">
                                            <ul className="migration-nav-link">
                                                <li className={`nav-item ${currentStep === 0 ? 'current' : 'completed'}`}>
                                                    <a className={`nav-link ${currentStep === 0 ? 'active' : ""}`}
                                                    >Current Host</a>
                                                </li>

                                                <li className={`nav-item ${currentStep === 1 ? 'current' : currentStep === 2 ? "completed" : ""}`}>
                                                    <a className={`nav-link ${currentStep === 1 ? 'active' : ""}`}
                                                    >Migration Details</a>
                                                </li>

                                                <li className={`nav-item ${currentStep === 2 ? 'current' : ""}`}>
                                                    <a className={`nav-link ${currentStep === 2 ? 'active' : ""}`}
                                                    >Migration</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                    <form onSubmit={e => store.dispatch(onMigrationFormSubmit(e))}>
                                        {/*<form>*/}
                                        <div className="steps-content pd-4">

                                            {steps && steps[currentStep].content}
                                        </div>

                                        <div className="button-wrapper text-right mt-20 mb-40">
                                            <StepsButtons steps={steps}
                                                          errors={errors}
                                                          changeStep={migrationFormChangeSteps}
                                                          processing={processing || migrationValidationFirstProcessing ||migrationValidationSecondProcessing}
                                                // disabled={processing || errorsPresent}
                                                // disabled={errorsPresent}
                                                          currentStep={currentStep}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Page>
                </div>
                <MigrationFormWorker/>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        migrationForm, createMigration, migrationValidationFirst,
        migrationValidationSecond,
    } = state;
    let {payload, mode, errorMessage} = migrationForm.data;
    let {errors, errorsPresent} = migrationForm.data;

    return {
        errors, errorsPresent, mode, createMigration,

        migrationForm, errorMessage, payload, migrationValidationFirst,
        migrationValidationSecond,

    };
}

export default connect(mapStateToProps)(MigrationForm);
