import {MigrationDetailsFormState} from './MigrationForm-State'

export default function MigrationFormReducer(state = MigrationDetailsFormState, action) {

    let {data} = {...state};
    let {migration} = action;

    switch (action.type) {

        case 'SET-MIGRATION-DATA-FOR-DETAIL':

            data.payload = {
                id:migration.id,
                server_ip: migration.server_ip,
                server_port:migration.server_port,
                server_username:migration.server_username,
                server_password:migration.server_password,
                type: migration.type,
                db_login_url:migration.db_login_url,
                is_multi_site: migration.is_multi_site,
                is_ecommerce: migration.is_ecommerce,
                wordpress_username: migration.wordpress_username,
                wordpress_password: migration.wordpress_password,
                wordpress_admin_url: migration.wordpress_admin_url,
                create_new_site: migration.create_new_site,
                site_name: migration.site_name,
                status:migration.status
/*                currentHost:migration.current_host,
                username:migration.username,
                hosting_login_url:migration.hosting_login_url,
                domain:migration.domain,
                isEcommerce:migration.is_ecommerce,
                wordpressUser:migration.wordpress_user,
                wordpressPassword:migration.wordpress_password,
                wordpress_admin_url:migration.wordpress_admin_url,
                isMultisite:migration.is_multisite,
                is_https:migration.is_https,
                createNewSite:migration.createNewSite,
                keplerSiteName:migration.kepler_site_name,
                status:migration.status*/
            };
            return {...state, data};

        default:
            return state
    }
}
