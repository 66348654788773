import {DomainFormState} from './DomainForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {endOfURL} from '../../../../helpers/UrlHelper'
import {isObject} from "../../../../helpers/DataHelper";
import {isValidDomain} from '../../../../helpers/ValidationHelper';


export default function domainFormReducer(state = DomainFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, domain, mode, error, inputFields, stepTrack, lb_version} = action;
    switch (action.type) {

        case 'ON-DOMAIN-FORM-CHANGE':
            if (name === 'has_www') {
                data.payload.has_www === 1 ?
                    data.payload.has_www = 0 : data.payload.has_www = 1
            } else {
                data.payload[name] = value;
            }
            if (name === "name" && value === "") {
                data.message = "";
            }

            buildErrors();
            return {...state, data};

        case 'ON-REMOVE-ERROR-MESSAGE':
            data.message = '';
            buildErrors();
            return { ...state, data };

        case 'ON-DOMAIN-FORM-NAME-CHANGE':
            data.payload['name'] = value;
            data.payload['has_www'] = 0;
            buildErrors();
            return {...state, data};

        case 'ON-DOMAIN-FORM-INPUT-CHANGE':
            data.inputs = inputFields;
            data.stepTrack=stepTrack
            buildErrors();
            return {...state, data};

        case 'ON-REMOVE-ERROR':
            data.errors = [];
            data.submitted = false;
            data.errorsPresent = false;
            buildErrors();
            return {...state, data};

        case 'ON-DOMAIN-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-DOMAIN-ERROR':
            data.message = error;
            buildErrors();
            return {...state, data};

        case 'SET-BULK-DOMAIN-ERROR':
            data.message = error;
            data.errorsPresent = true;
            data.dispatchAPI = false;
            buildErrors();
            return {...state, data};

        case 'SET-DOMAIN-DATA-FOR-UPDATE':
            data.payload = {
                name: domain.name,
                site_id: domain.site_id,
                ssl_type: domain.ssl_type,
                has_www: domain.has_www,
            };
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-DOMAIN-FORM':
            data = {
                payload: {
                    // site_id:``,
                    name:``,
                    has_www:0,
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``,
                lb_version:lb_version,
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-DOMAIN-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-DOMAIN-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'DOMAIN-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: `${validationErrors[key][0]}`}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field)
    {
        let { payload, submitted, validationErrors } = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `name`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({ step, message })
        }

        function _(field) {
            return data.payload[field]
        }
    }
}