import React from 'react';
import SiteDashboardHeader from '../SiteDashboardHeader';
import Page from '../../../components/shared/pages/Page';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import SiteDashboardLinks from '../SiteDashboardLinks';
import DisplayNameForm from '../change-display-name/form/DisplayNameForm';
import PlanWorker from './PlanWorker';
import {endOfURL, secondToLastItemInURL} from '../../../helpers/UrlHelper';
import i18next from 'i18next';
import SiteTableCell from "../list/SiteTableCell";
import Loading from "../../../components/shared/loaders/Loading";
import store from "../../../Store";
import changeDisplayName from "../../../ajax/actions/sites/site_displayname_post";
import {ucFirst} from "../../../helpers/StringHelper";

class LogsList extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
        page: "error"
    }

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.setState({edit:false});
    }

    render() {


        let {getSite,site_status, siteListParam,params, searchFrom,plan} = this.props
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/plan'

        return (
            <>
                <SiteDashboardHeader />
                <PlanWorker/>
                {siteListParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    <div className="boxed-block">
                    <div className="heading-block mt-70">
                        <div className={this.state.edit === true ? "heading-content edit-on" :"heading-content"}>

                        {this.state.edit === false ? <>
                                        <h2>{current_path === demo_site_path?"Demo Site":getSite?.success?.display_name}</h2></> :
                                    <DisplayNameForm data={current_path === demo_site_path?"Demo Site":getSite?.success?.display_name}/>}
                                {this.state.edit === false ?
                                    <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                        <i className="icon-edit"/></a> : <>
                                        <button className="btn-theme btn-black"
                                                onClick={this.updateDisplayName}
                                        disabled={current_path === demo_site_path}>{i18next.t('sites.page-heading.edit.update' ,{returnObjects: true})}
                                        </button>
                                        <button className="btn-theme btn-black"
                                                onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel' ,{returnObjects: true})}
                                        </button>
                                    </>}
                            </div>
                            <div className="breadcrumb-block">
                                <span><Link to={'/sites'}>{i18next.t('sites.page-heading.breadcrum' ,{returnObjects: true})}</Link></span>
                                <span>{current_path === demo_site_path?"Demo Site":getSite?.success?.display_name}</span>
                            </div>
                        </div>

                        <div className="content-menu">
                            <SiteDashboardLinks site_id={current_path === demo_site_path?"demo":this.props.match.params.id}/>
                        </div>

                        <Page title={i18next.t('sites.inside-site.site-nav.logs' ,{returnObjects: true})}>
                            <div className="boxed-block">
                                <div className="listing-table-block mt-60">
                                    <div className="table-responsive listing-table">
                                        <table>
                                            <tbody>
                                            <tr>
                                                {plan ?
                                                    <>
                                                <td>
                                                    <span className="title">Title</span>
                                                    <h6>{plan && plan.title}</h6>
                                                </td>
                                                    <td>
                                                    <span className="title">Site Type</span>
                                                    <h6>{plan && plan.site_type}</h6>
                                                    </td>
                                                    <td>
                                                    <span className="title">Description</span>
                                                    <h6>{plan && plan.description}</h6>
                                                    </td>
                                                    <td>
                                                    <span className="title">Price</span>
                                                    <h6>{plan && plan.price}</h6>
                                                    </td>
                                                    <td>
                                                    <span className="title">Interval</span>
                                                    <h6>{plan && ucFirst(plan.interval)}</h6>
                                                    </td>
                                                    </>:
                                                    <td>
                                                        <span className="title"></span>
                                                        <h6>No Plan</h6>
                                                    </td>
                                                }
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Page>

                    </div>

                </>}
            </>
         );
    }
}

function mapStateToProps(state) {
    let {getSite, siteDashboard,getAccessLogsReducer, logList, siteList} = state;
    let {site_status} = siteDashboard.data;
    let plan = getSite && getSite.success && getSite.success.plan
    let {params} = logList.data;
    let searchFrom = siteList?.data.searchFrom
    let siteListParam = siteList.data.params

    return {getSite, getAccessLogsReducer, logList, params, siteListParam, searchFrom, plan,site_status};
}


export default connect(mapStateToProps) (LogsList);
