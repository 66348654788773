import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Restart from "../../../assets/images/restart.svg";
import store from "../../../Store";
import { toggleAddCardFormModal } from "../overview/Overview-Actions";
import getCard from "../../../ajax/actions/card/cards_get";
import getLoggedInUser from "../../../ajax/actions/user/user_loggedin_get";
import CardBillingform from "./billing-form-card/CardBillingform";
import {
  onOrganizationFormSubmit,
  setDefaultBillingRegisterData,
  changeType,
} from "./billing-form-card/CardBillingFormStep2-Actions";
import { connect } from "react-redux";
import Loading from "../../../components/shared/loaders/Loading";

import i18next from "i18next";

toast.configure();

function CardSetupForm({
  history,
  cardBillingForm,
  updateCardAddOrganization,
  ...props
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [billingName, setBillingName] = useState("");
  const [saveLoading, setSaveLoading] = useState(0);
  const [submittingCard, setSubmittingCard] = useState(0);
  const onNameChange = (e) => {
    setBillingName(e.target.value);
  };
  let { type } = cardBillingForm.data.payload;

  const onChangeType = () => {
    store.dispatch(changeType());
  };

  const handleSubmit = async (event) => {
    // store.dispatch(getIntent( ));
    setSaveLoading(1);
    setSubmittingCard(1);

    event.preventDefault();
    if (props.mode === "activate_account") {
      window.dataLayer.push({
        event: "activateAccount",
        eventCategory: "ActivateAccount",
        eventAction: "ButtonClick",
        eventLabel: "ActivateAccountButton",
      });
      store.dispatch(onOrganizationFormSubmit());
    }
    let { error } = updateCardAddOrganization;
    if (error || cardBillingForm.data.errorsPresent) {
      setSaveLoading(0);
      setSubmittingCard(0);
    }

    if (
      props.mode === "activate_account" &&
      cardBillingForm.data.errorsPresent
    ) {
      return null;
    } else {
      // if(submittingCard === 1){
      if (!stripe || !elements) {
        return;
      }
      const result = await stripe.confirmCardSetup(props.client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            // name: 'Jenny Rosen',
            name: billingName,
          },
          // metadata: {'order_id': '6735'}
        },
      });
      if (result.error) {
        setSaveLoading(0);
        setSubmittingCard(0);
        toast.error((el) => (
          <div className="pop-up-message">
            <div className="media">
              <div className="media-img d-flex mr-3">
                <img src={Restart} />
              </div>
              <div className="media-body">
                <h6>
                  {i18next.t(
                    "account.inside-account.payment.add-new-card.add-form.error",
                    { returnObjects: true }
                  )}
                </h6>
                <p>{result.error.message}</p>
              </div>
            </div>
          </div>
        ));
      } else {
        if (result) {
          // setSaveLoading(0);
          setSubmittingCard(0);
          toast((el) => (
            <div className="pop-up-message">
              <div className="media">
                <div className="media-img d-flex mr-3">
                  <img src={Restart} />
                </div>
                <div className="media-body">
                  <h6>
                    {i18next.t(
                      "account.inside-account.payment.add-new-card.add-form.success",
                      { returnObjects: true }
                    )}
                  </h6>
                  <p>
                    {i18next.t(
                      "account.inside-account.payment.add-new-card.add-form.success-desc",
                      { returnObjects: true }
                    )}
                  </p>
                </div>
              </div>
            </div>
          ));

          setTimeout(() => store.dispatch(getCard()), 2000);
          setTimeout(() => store.dispatch(getLoggedInUser()), 2000);
          if (props.mode === "activate_account") {
            setTimeout(
              () => (window.location.href = "/account/overview"),
              2000
            );
            // window.location.href = "/account/overview";
          } else {
            setTimeout(() => store.dispatch(toggleAddCardFormModal()), 2000);
            // store.dispatch(toggleAddCardFormModal());
          }
          // store.dispatch(toggleAddCardFormModal());
          store.dispatch(setDefaultBillingRegisterData());
        }
      }
    }
    // }
  };

  return (
    <>
      {props.mode === "activate_account" ? (
        <form onSubmit={handleSubmit}>
          <div className="add-new-card">
            <div className="billing-information-section">
              {type === "personal" ? (
                <div className="company-question mt-30">
                  <p>
                    {i18next.t(
                      "account.inside-account.activate-account.company.question",
                      { returnObjects: true }
                    )}
                    <button
                      className="btn-theme btn-outline"
                      onClick={onChangeType}
                    >
                      {i18next.t(
                        "account.inside-account.activate-account.company.btn",
                        { returnObjects: true }
                      )}
                    </button>
                  </p>
                </div>
              ) : null}
              <div className="row pl-40 pr-40">
                <CardBillingform />
                <div className="col-lg-6">
                  <div className="add-card-section">
                    <div className="box-block billing-box-block add-card-modal">
                      <div className="billing-block">
                        <div className="billing-info-block"></div>
                        <div className="payment-block">
                          <div className="tab-content">
                            <div className="payment-details form-block">
                              <div className="title-block">
                                <h3>
                                  <strong>
                                    {i18next.t(
                                      "account.inside-account.payment.add-new-card.detail",
                                      { returnObjects: true }
                                    )}
                                  </strong>
                                </h3>
                              </div>
                              <div className="card-detail-container mt-30">
                                <CardSection
                                  onCardDetailChange={onNameChange}
                                />
                                <div className="form-group col-md-12 btn-holder text-center mt-30">
                                  <button
                                    className="btn-theme btn-blue btn-lg w-100"
                                    disabled={!stripe || submittingCard === 1}
                                  >
                                    {saveLoading === 1 ? (
                                      <>
                                        <Loading />{" "}
                                        {i18next.t(
                                          "account.inside-account.payment.add-new-card.save-btn",
                                          { returnObjects: true }
                                        )}
                                      </>
                                    ) : (
                                      i18next.t(
                                        "account.inside-account.payment.add-new-card.save-btn",
                                        { returnObjects: true }
                                      )
                                    )}
                                  </button>
                                </div>
                              </div>
                              {/*</form>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="add-new-card">
          <div className="heading-block pr-60">
            <div className="title-block">
              <h2>
                {i18next.t("account.inside-account.payment.add-card", {
                  returnObjects: true,
                })}
              </h2>
            </div>
          </div>

          <div className="box-block billing-box-block add-card-modal">
            <div className="billing-block">
              <div className="billing-info-block"></div>
              <div className="payment-block">
                <div className="tab-content">
                  <div className="payment-details form-block">
                    <form onSubmit={handleSubmit}>
                      <h3>
                        <strong>
                          {i18next.t(
                            "account.inside-account.payment.payment-details",
                            { returnObjects: true }
                          )}
                        </strong>
                      </h3>
                      <div className="card-detail-container mt-2">
                        <CardSection onCardDetailChange={onNameChange} />
                        <div className="form-group col-md-12 btn-holder text-center">
                          <button
                            className="btn-theme btn-blue"
                            disabled={!stripe || submittingCard === 1}
                          >
                            {saveLoading === 1 ? (
                              <>
                                <Loading /> Save
                              </>
                            ) : (
                              "Save"
                            )}
                            {/* Save */}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  let { getLoggedInUser, cardBillingForm, updateCardAddOrganization, route } =
    state;
  let { action, routes_url } = route.data;
  return {
    getLoggedInUser,
    cardBillingForm,
    updateCardAddOrganization,
    route,
    action,
    routes_url,
  };
}

export default connect(mapStateToProps)(CardSetupForm);
