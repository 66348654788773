const DefaultDisplayNameFormState = {
    payload: {
        display_name: '',
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
    organization_id: ``,
};

export const DisplayNameFormState = {data: DefaultDisplayNameFormState};