import React from 'react';
import {connect} from "react-redux";
import store from "../../../../Store";
import {withRouter} from 'react-router';
import SiteDashboardHeader from "../../SiteDashboardHeader";
import Page from "../../../../components/shared/pages/Page";
import SiteDashboardLinks from "../../SiteDashboardLinks";
import {endOfURL} from "../../../../helpers/UrlHelper";
import SiteTableCell from "../../list/SiteTableCell";
import DisplayNameForm from "../../change-display-name/form/DisplayNameForm";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import {Link} from "react-router-dom";
import RedirectsWorker from "./RedirectsWorker";
import i18next from 'i18next';
import {toggleRedirectsFormModal} from "./Redirects-Action";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import RedirectsForm from "../form/RedirectsForm";
import getRedirect from "../../../../ajax/actions/redirects/redirect_get"
import deleteRedirect from "../../../../ajax/actions/redirects/redirect_delete";
import {onRedirectsFormModeChange} from "../form/RedirectsForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";

class Redirects extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
        status: false,
    }

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.state.edit = false;
    }

    onDeleteRedirect = (id) => {
        store.dispatch(deleteRedirect(this.props.match.params.id, id));
    }

    onEditRedirect = (id) => {
        store.dispatch(getRedirect(this.props.match.params.id, id));
        store.dispatch(onRedirectsFormModeChange("update"));
        store.dispatch(toggleRedirectsFormModal());
    }

    onAddRedirect = (event) => {
        if (!event) {
            store.dispatch(onRedirectsFormModeChange("create"));
            store.dispatch(toggleRedirectsFormModal());
        }
    }


    render() {
        let {
            getSite, siteListParam, searchFrom, redirects, list,user_name, pages, event, site_status, redirect_rule_id,getLoggedInUser
        } = this.props
        let {processing} = getSite

        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/redirects'


        return (
            <>
                <SiteDashboardHeader/>
                {siteListParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="boxed-block">
                        <div className="heading-block mt-70">
                            <div className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                {this.state.edit === false ? <>
                                        <h2>{current_path === demo_site_path ? "Demo Site" : getSite?.success?.display_name}</h2></> :
                                    <DisplayNameForm
                                        data={current_path === demo_site_path ? "Demo Site" : getSite?.success?.display_name}/>}
                                {this.state.edit === false ?
                                    <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                        <i className="icon-edit"/></a> : <>
                                        <button className="btn-theme btn-black"
                                                onClick={this.updateDisplayName}
                                                disabled={current_path === demo_site_path}>{i18next.t('sites.page-heading.edit.update', {returnObjects: true})}
                                        </button>
                                        <button className="btn-theme btn-black"
                                                onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel', {returnObjects: true})}
                                        </button>
                                    </>}
                            </div>
                            <div className="breadcrumb-block">
                                <span><Link
                                    to={'/sites'}>{i18next.t('sites.page-heading.breadcrum', {returnObjects: true})}</Link></span>
                                <span>{current_path === demo_site_path ? "Demo Site" : getSite?.success?.display_name}</span>
                            </div>
                        </div>
                        <div className="content-menu">
                            <SiteDashboardLinks
                                site_id={current_path === demo_site_path ? "demo" : this.props.match.params.id}/>
                        </div>

                        <Page title={`Redirects`}>

                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table tools-table">

                                    <ResponsiveModalForm visible={redirects.mode}
                                                         redirectPopup={true}
                                                         closeModal={() => store.dispatch(toggleRedirectsFormModal())}>
                                        <RedirectsForm/>
                                    </ResponsiveModalForm>

                                    <div
                                        className="redirect-rules light-gray-bg d-flex align-items-center justify-content-between">
                                        <div className="redirect-rules-text">
                                            <h6>Redirects</h6>
                                            <p>Welcome to our redirect tool. Here you will be able to create
                                                server-level redirects for your websites.<br/>
                                                Please contact our support if you have any questions and we will be
                                                happy to help you get started.</p>
                                        </div>
                                        <div className="redirect-rules-buttons">
                                            <button className="btn-theme"
                                                    onClick={() => {
                                                        this.onAddRedirect(event && event)
                                                        // store.dispatch(toggleRedirectsFormModal())
                                                    }}
                                                    disabled={event && event || (current_path === demo_site_path) }
                                            >
                                                {event === "create_redirect_rule" ? <>Adding Redirect Rule<Loading
                                                    className="sm-loader-position" height="15"
                                                    width="15"/></> : "Add Redirect Rule"}
                                            </button>
                                        </div>
                                    </div>

                                    {/*Table*/}
                                    <div className="ignore-urls">
                                        <table className="url-table mb-50">
                                            <tbody>
                                            <tr className="url-table-col">
                                                <th className="url-table-heading">Domain</th>
                                                <th className="url-table-heading">Redirect From</th>
                                                <th className="url-table-heading">Redirect To</th>
                                                <th className="url-table-heading">Redirect Code</th>
                                                <th className="url-table-heading"
                                                    id="actions">Actions
                                                </th>
                                            </tr>
                                            {
                                                list && list.map(items => {
                                                    return (
                                                        <>
                                                            <tr className="url-table-col">
                                                                {site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ||
                                                                site_status === 'restoring'  ?
                                                                    <td className="url-table-body">
                                                                        {items.domain ? items.domain.name : "All Domains"}
                                                                    </td> :
                                                                    <td className="url-table-body onclick"
                                                                        onClick={() => this.onEditRedirect(items.id)}>{items.domain ? items.domain.name : "All Domains"}</td>
                                                                }
                                                                <td className="url-table-body">{items.regex}</td>
                                                                <td className="url-table-body">{items.destination}</td>
                                                                <td className="url-table-body">{items.redirect_code}</td>
                                                                {(event === "create_redirect_rule" || event === "delete_redirect_rule" || event === "update_redirect_rule") && redirect_rule_id === items.id ?
                                                                    <td className="url-table-body" id="actions">
                                                                        {event === "create_redirect_rule" && <>Adding<Loading/></>}
                                                                        {event === "delete_redirect_rule" && <>Deleting<Loading/></>}
                                                                        {event === "update_redirect_rule" && <>Updating<Loading/></>}
                                                                    </td>
                                                                    :
                                                                    <td className="url-table-body" id="actions">
                                                                        {site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ||
                                                                        site_status === 'restoring' ?
                                                                            <span className="icon-more"/>
                                                                            :
                                                                            <div
                                                                                className="dropdown dropdown-alt text-center">
                                                                                    <span className="icon-more"
                                                                                          data-toggle="dropdown"></span>
                                                                                <div
                                                                                    className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton">
                                                                                    <a href="javascript:void(0);"
                                                                                       className="dropdown-item"
                                                                                       type="button"
                                                                                       onClick={() => this.onEditRedirect(items.id)}> Edit
                                                                                    </a>
                                                                                    <a href="javascript:void(0);"
                                                                                       className="dropdown-item"
                                                                                       type="button"
                                                                                       onClick={() => this.onDeleteRedirect(items.id)}> Delete
                                                                                    </a>

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {/*<span className="icon-more " data-toggle="dropdown" onClick={this.onClickIconMore()}/>*/}
                                                                    </td>
                                                                }
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }


                                            </tbody>
                                        </table>

                                        {list && !list.length || current_path === demo_site_path ?
                                        // <td colSpan={5} className="text-center  pt-4">No Redirects</td>
                                        <div className="content-container light-gray-bg">
                                            <div className="content-message-block">
                                                <h2>Hi <span>{user_name}</span>,<br/>
                                                    Welcome to Redirects<br/>
                                                    </h2>
                                                <p>Welcome to kepler Hosting redirects. Here you will be able to create server site redirects.</p>

                                                <div className="button-wrapper inline-buttons mt-30">
                                                    <button className="btn-theme btn-blue"
                                                            onClick={() => {
                                                                this.onAddRedirect(event && event)
                                                                // store.dispatch(toggleRedirectsFormModal())
                                                            }}
                                                            disabled={event && event || (current_path === demo_site_path) }
                                                    >Add Redirect
                                                    </button>
                                                </div>
                                            </div>
                                        </div> : null}

                                    </div>
                                </div>
                            </div>
                        </Page>
                        <RedirectsWorker/>
                    </div>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    getSite
,
    getSites
,
    getSiteStatus
,
    siteDashboard
,
    siteList
,
    getRedirects
,
    redirectList,
        getLoggedInUser,
        route
}

= state;
    let user_name = route && route.data.user && route.data.user.first_name;


    let {redirects} = redirectList.data

let {success} = getRedirects;
let list =
    success &&
    success.data &&
    success.data.data.data;

let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
let redirect_rule_id = getSiteStatus && getSiteStatus.success && getSiteStatus.success.redirect_rule_id

let current_page = success && success.data && success.data.data.current_page;
let last_page = success && success.data && success.data.data.last_page;
let total = success && success.data && success.data.data.total;
let pages = {current_page, last_page, total};

let {site_status} = siteDashboard.data;
let searchFrom = siteList?.data.searchFrom
let {params: siteListParam, payload} = siteList.data

return {
    getSite,
    getSites,
    getSiteStatus,
    site_status,
    siteDashboard,
    searchFrom,
    siteListParam,
    payload,
    redirects,
    list, pages,
    event,
    redirect_rule_id,
    getLoggedInUser,
    user_name
};
}

export default withRouter(connect(mapStateToProps)(Redirects));