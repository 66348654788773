import {DefaultMigrateFormStep3State, MigrateFormStep3State} from './MigrateFormStep3-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'

export default function migrateFormReducer(state = MigrateFormStep3State, action) {

    let {data} = {...state};
    let {name, value, validationErrors} = action;

    switch (action.type) {

        case 'ON-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors()
            return {...state, data};

        case 'ON-TYPE-CHANGE':
            data.payload.type = true;
            buildErrors()
            return {...state, data};

        case 'MIGRATE-FORM-SERVER-VALIDATION-ERRORS':
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` ${validationErrors[key][0]}`.slice(0, -1)}]
            )
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};

        case 'INITIALIZE-MIGRATE-FORM-STEP3-ERRORS':
            buildErrors()
            return {...state, data};

        case 'SET-DEFAULT-MIGRATE-DATA':
            data = {
                payload: {
                    create_new_site: ``,
                    site_name: ``,
                },

                errors: {},
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: "submit",


                user_mode: ``,
                action: 'loader',
                message: ``

            };
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)

        data.errorsPresent = errorsPresent(errors);
    }

    function errors(field) {
        let {validationErrors, type} = data
        let errors = {};
        Object.keys(data.payload).map(key => errors[key] = []);

        if (field === `create_new_site`) {
            if (!_(field)) newError(field, 0, `The create new site field is required`)
        }

        if (field === `site_name`) {
            if (!_(field)) newError(field, 0, `The site name field is required`)
        }


        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}