const DefaultFeatureBlockState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        per_page: 25,
        page: 1,
        order: 'desc',
        sort_id: `id`,
        q: ``,
    },
    site_id: ``,
    domain_id: ``,
    modal: {
        mode: '',
    },
    public_path_modal: {
        mode: '',
    },
    php_version:{
        mode:'',
    },
    password_protection:{
        mode:'',
    },
    // force_ssl:{
    //     mode:'',
    // }
}

export const FeatureBlockState = {data: DefaultFeatureBlockState}