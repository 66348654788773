import React from 'react';
import {names, ucFirst, unslufigy} from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';
import {endOfURL} from "../../../helpers/UrlHelper";

class TextField extends React.Component {
    placeholder() {
        let {placeholder, name, label, small, message} = this.props;
        if (placeholder) return placeholder;
        if (label) return label;
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return _label;
            }
        }
    }

    small() {
        let {small} = this.props;
        if (small) {
            return (<small>{small}</small>);
        } else return null;
    }

    label() {
        let {name, label, errors, nolabel} = this.props;
        let errorsPresent = errors && errors.length;
        if (!nolabel && label) return (
            <label>
                {label}
            </label>
        );
        else {
            if (!nolabel && name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                        {_label}
                    </label>
                )
            }
        }
    }

    render() {
        let {
            name,
            size,
            value,
            onChange,
            id,
            noLabel,
            disabled,
            errors,
            required,
            small,
            message,
            forForm,
            data,
            errorName,
            errorMatchIndex,
            index,
            onDeleteTextField
        } = this.props;

        return (
            <>
                {message && message.length ?
                    <>
                        <div
                            className={names(`form-group`, `animated fadeIn faster`, `has-error`, required && `required`)}>

                            {!noLabel ? <>{this.label()}</> : null}
                            {small ? <><br/>{this.small()}</> : null}

                            <input type="text"
                                   className="form-control"
                                   size={size || `default`}
                                   name={name}
                                   disabled={disabled}
                                   id={id || name}
                                   value={value}
                                   autoComplete="nope"
                                   placeholder={this.placeholder()}
                                   onChange={onChange}
                            />
                            {message ? <>
                                <div className="error-msg">{message}</div>
                            </> : null}
                        </div>
                    </>
                    :
                    <div
                        className={names(`form-group`, `animated fadeIn faster`, errors && errors[0] && `has-error`, required && `required`)}>

                        {!noLabel ? <>{this.label()}</> : null}
                        {small ? <><br/>{this.small()}</> : null}
                        <div className="wp-password-input-wrapper">
                            <input type="text"
                                   className="form-control"
                                   size={size || `default`}
                                   name={name}
                                   disabled={disabled}
                                   id={id || name}
                                   value={value}
                                   autoComplete="nope"
                                   placeholder={this.placeholder()}
                                   onChange={onChange}
                            />
                            {index !== 0 &&
                            <a className="password-reset">
                                <button
                                    className="bg-transparent  border-0"
                                    type="button"
                                    onClick={()=>onDeleteTextField(JSON.stringify(index),JSON.stringify(data))}
                                ><span className="fas fa-trash"/></button>
                            </a>
                            }
                        </div>

                        <div className=' error-msg'>
                            {endOfURL() === "domain" && forForm === "bulkDomainForm" && !value &&
                            errors && errors.length && errors[0] && errors[0]?.message ?
                                errors[0]?.message : null
                            }
                            {value && errorMatchIndex && errorMatchIndex["name." + index] && errorMatchIndex["name." + index].length ?
                                errorMatchIndex["name." + index][0]["message"] : null}
                        </div>
                    </div>
                }
            </>
        )
    }
}

let {string, number, oneOfType} = PropTypes;
TextField.propTypes = {value: oneOfType([string, number])};
export default TextField;