export function setSiteDashboardData() {
    return function (dispatch, getState) {
        let {getSite} = getState();
        let {success} = getSite;
        if (success) {
            dispatch({type: 'SET-SITE-DASHBOARD-DATA', site: success});
        }
    }
}

export function setSiteStatus() {
    return function (dispatch, getState) {
        let {getSiteStatus} = getState();
        let {success} = getSiteStatus;
        if (success) {
            dispatch({type: 'SET-SITE-STATUS', status: success});
        }
    }
}

export function setPupUpMessage(message) {
    return function (dispatch) {
        dispatch({type: 'SET-PUP-UP-MESSAGE', message});
    }
}

export function setSearchingFlag(message) {
    return function (dispatch) {
        dispatch({type: 'SET-SEARCHING-FLAG', message});
    }
}

export function setSearchedFlag(message) {
    return function (dispatch) {
        dispatch({type: 'SET-SEARCHED-FLAG', message});
    }
}

export function setTotalSites() {
    return function (dispatch, getState) {
        let {getSites} = getState();
        let {success} = getSites;
        let total = success && success.data && success.data.data.total;
        if (success) {
            dispatch({type: 'SET-TOTAL-SITES', total});
        }
    }
}

export function setSiteListStatus() {
    return function (dispatch, getState) {
        let {getSiteListStatus} = getState();
        let {success} = getSiteListStatus;
        if (success) {
            dispatch({type: 'SET-SITE-LIST-STATUS', status: success});
        }
    }
}

export function setUserIDSite(user_id) {
    return function (dispatch) {
        dispatch({type: 'SET-USERID-SITE', user_id});
    }
}