const DefaultPaymentState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 4,
        per_page: 25,
        page: 1,
        order: 'desc',
        sort_by: `created_at`,
        q: ``,
    },
    modal: {
        mode: '',
    },
    add_card_modal: {
        mode: '',
    },

    fields_en: [
        {field: `created_at`, label: `Date`, sort: true},
        {field: `description`, label: `Description`, sort: false},//display_name
        {field: `grand_total`, label: `Amount`, sort: true},
        {field: `pay`, label: `Download`, sort: false},
    ],

    fields_sv: [
        {field: `created_at`, label: `Datum`, sort: true},
        {field: `description`, label: `Beskrivning`, sort: false},//display_name
        {field: `grand_total`, label: `Summa`, sort: true},
        {field: `pay`, label: `Ladda ner`, sort: false},
    ]
}

export const PaymentState = {data: DefaultPaymentState}