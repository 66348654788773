import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {  handleSuccess, handleError } from '../../../helpers/ErrorHelper'
import { clone } from '../../../helpers/DataHelper'
import {cancelForgotPasswordFormDispatch, forgotPasswordError, forgotPasswordFormServerValidationErrors, setDefaultForgotPasswordForm}
  from '../../../pages/forgot-password/Form/ForgotPasswordForm-Action'
import store from '../../../Store';
import { scrollToError } from '../../../helpers/ScrollHelper'
import {loginToken} from "../../../helpers/LocalStorageHelper";

function _success(success)
{
  return { type: 'POST_CHANGE_PASSWORD_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_CHANGE_PASSWORD_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_CHANGE_PASSWORD_PROCESSING', processing };
}

function resetPassword( )
{
  return ( dispatch , getState ) =>
  {
    dispatch(_processing(true));

    const lang = localStorage.getItem('lang') || 'en'

    let { forgotPasswordForm } = getState();
    let data = clone( forgotPasswordForm.data.payload );
    // let {token} = forgotPasswordForm.data.token ;

    axios({
      url: apiBaseUrl(`customer/password/reset`),
      method: "post",
      dataType: 'json',
      data,
      headers: {
        'lang': lang
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(setDefaultForgotPasswordForm())
      })
      .catch(function(error){
        dispatch(_error(error.response.data.message));
        dispatch(_processing(false));

        if( error.response && error.response && error.response.data.status !== 422 )
        store.dispatch(forgotPasswordError( error && error.response && error.response.data && error.response.data.message ))
        store.dispatch( cancelForgotPasswordFormDispatch( ));
        // store.dispatch( setDefaultForgotPasswordForm() );

        if(error.response && error.response && error.response.data.status === 422 ) {
          store.dispatch( forgotPasswordFormServerValidationErrors( error.response.data.errors ))
          scrollToError();
        }
      });

  }
}


export default resetPassword;