const DefaultIgnoreCacheFormState = {
    payload: {
        path: ''
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
};

export const ignoreCacheFormState = {data: DefaultIgnoreCacheFormState};