import {combineReducers} from 'redux'

import route from './routes/main-route/MainRoute-Reducer'
import loginForm from './pages/login/Login-Reducer'
import getUser from './ajax/reducers/user/user-get'
import loginUser from './ajax/reducers/user/user-login-post'
import logoutUser from './ajax/reducers/user/user-logout-post'
import updateUser from './ajax/reducers/user/user-put'
import getLoggedInUser from './ajax/reducers/user/user-loggedin-get'

/*verify or activate User by email verification*/
/*reSend mail*/
import resendEmailUserVerification from './ajax/reducers/user/verifyuser-email-send-get'
import userActivationFromEmail from "./ajax/reducers/user/user-activation-from-email-get";

import deleteSite from './ajax/reducers/sites/site-delete';
import getSite from './ajax/reducers/sites/site-get';
import createSite from './ajax/reducers/sites/site-post';
import getSites from './ajax/reducers/sites/sites-get';
import createSiteDisplayName from './ajax/reducers/sites/site-displayname-post';
import displayNameForm from './pages/site/change-display-name/form/DisplayNameForm-Reducer';
import getDemoSite from './ajax/reducers/sites/demo-sites-get'
//
import getDataCenters from "./ajax/reducers/data-center/data-centers-get";
import getPayments from "./ajax/reducers/invoice/payment-get";

// logs
import getAccessLogsReducer from './ajax/reducers/logs/access-logs'
import logList from './pages/site/logs/list/Logs-Reducer'

//organizations
import getOrganizations from './ajax/reducers/organization/organizations-get';
import getOrganization from './ajax/reducers/organization/organization-get';
import updateOrganization from './ajax/reducers/organization/organization-put';
import organizationForm from "./pages/oragnization/form/OrganizationForm-Reducer";


import siteList from "./pages/site/list/SiteList-Reducer";
import siteForm from "./pages/site/form/SiteForm-Reducer";
//
import userForm from "./pages/user/form/UserForm-Reducer";
//
import getActivity from './ajax/reducers/activity-log/activity-logs-get';
import getYourActivity from './ajax/reducers/activity-log/your-activity-logs-get';
import activityList from "./pages/activity-log/list/ActivityList-Reducer";
import yourActivityList from "./pages/activity-log/yourActivityList/ActivityList-Reducer";
//
import siteDashboard from "./pages/site/SiteDashboard-Reducer";
import getSiteStatus from './ajax/reducers/sites/site-status-get';
import getSiteListStatus from './ajax/reducers/sites/site-list-status-get';

import getCredentials from './ajax/reducers/sftp-database/credential-get';
import createCredentials from './ajax/reducers/sftp-database/credential-post';
import SftpList from "./pages/site/sftp-database/list/Sftp-Reducer";
import getSiteCreateDataCenters from "./ajax/reducers/data-center/site-create-data-centers-get";


//DOMAIN
import getDomain from './ajax/reducers/domain/domain-get';
import updateDomain from './ajax/reducers/domain/domain-put';
import createDomain from './ajax/reducers/domain/domain-post';
import deleteDomain from './ajax/reducers/domain/domain-delete';
import getSiteDomain from './ajax/reducers/domain/site-domain-get';
import createBulkDomain from "./ajax/reducers/domain/domain-bulk-post"
import getSiteDomainNoPagination from "./ajax/reducers/domain/site-domain-nopagination-get"


import domainList from "./pages/site/domain/list/Domain-Reducer";
import domainForm from "./pages/site/domain/form/DomainForm-Reducer";

import getBackup from "./ajax/reducers/backup/backup-get";
import getSiteBackup from "./ajax/reducers/backup/site-backup-get";
import restoreBackup from "./ajax/reducers/backup/restore-backup-post";
import deleteBackup from "./ajax/reducers/backup/backup-delete";
import createBackup from "./ajax/reducers/backup/backup-post";
import getManualSiteBackup from "./ajax/reducers/backup/site-manual-backup-get";
import manualBackupForm from "./pages/site/backup/form/ManualBackupForm-Reducer";



import backupList from "./pages/site/backup/list/Backup-Reducer";

//forgotPassword
import forgotPasswordForm from "./pages/forgot-password/Form/ForgotPasswordForm-Reducer";
import createForgotPasswordEmail from "./ajax/reducers/forgot-password/forgot-password-post";
import createChangePassword from "./ajax/reducers/forgot-password/change-password-post";

//kepler tools
import createMonitor from "./ajax/reducers/kepler-tools/monitor-post"
import createReactConfiguration from "./ajax/reducers/kepler-tools/react-configuration-post"
import createPublicPath from "./ajax/reducers/kepler-tools/public-path-post"
import debug from "./ajax/reducers/kepler-tools/debug-post"
import featureBlocklist from "./pages/site/feature-block/list/FeatureBlock-Reducer";
import publicPathForm from "./pages/site/feature-block/form/PublicPathForm-Reducer";
import restartPhp from "./ajax/reducers/kepler-tools/restart-php-post"
import search from "./ajax/reducers/kepler-tools/search-post"
import replace from "./ajax/reducers/kepler-tools/replace-post"
import searchAndReplaceForm from "./pages/site/searchandreplace/form/SearchAndReplaceForm-Reducer";
import enableCache from "./ajax/reducers/kepler-tools/enable-cache-post"
import disableCache from "./ajax/reducers/kepler-tools/disable-cache-post"
import clearCache from "./ajax/reducers/kepler-tools/clear-cache-post"
import cacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-post"
import getCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-get"
import getsCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-gets"
import deleteCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-delete"
import updateCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-put"
import ignoreCacheForm from "./pages/site/cache/ignoreCache/form/IgnoreCacheForm-Reducer";
import cacheList from "./pages/site/cache/list/Cache-Reducer"
import getPhpVersions from "./ajax/reducers/kepler-tools/php-versions-get"
import phpVersionForm from "./pages/site/phpVersion/form/PhpVersionForm-Reducer"
import createPhpVersion from "./ajax/reducers/kepler-tools/php-version-post"
import createPasswordProtection from "./ajax/reducers/kepler-tools/password-protection-post"
import passwordProtectionForm from "./pages/site/passwordProtection/form/PasswordProtectionForm-Reducer";
import disablePasswordProtection from "./ajax/reducers/kepler-tools/disable-password-protection-post";
import createForceSsl from "./ajax/reducers/kepler-tools/force-ssl-post";


import getSslCertificates from "./ajax/reducers/ssl/ssl-get"
import getSslCertificate from "./ajax/reducers/ssl/ssl-certificate-get"
import updateSSLCertificates from "./ajax/reducers/ssl/ssl-put"
import createSSLCertificates from "./ajax/reducers/ssl/ssl-post"
import deleteSSLCertificates from "./ajax/reducers/ssl/ssl-delete"
import SSLForm from "./pages/site/ssl/sslCertificate/form/SSLForm-Reducer";
import SSLList from "./pages/site/ssl/list/SSL-Reducer";
import createCustomSslCertificate from "./ajax/reducers/ssl/custom-ssl-certificate-post";
import createGenerateLetsEncrypt from "./ajax/reducers/ssl/generate-lets-encrypt-post";
import createGenerateSsl from "./ajax/reducers/ssl/generateSsl-post"
import createGenerateCustomSsl from "./ajax/reducers/ssl/generateCustomSsl-post";

import overViewList from "./pages/account/overview/Overview-Reducer"
import paymentList from "./pages/account/payment/Payment-Reducer"

//danger Zone
import dangerZoneReducer from "./pages/site/danger-zone/DangerZone-Reducer";

//fetchintent
import fetchIntent from "./ajax/reducers/fetchintent/fetch-intent-post"

//INVOICE
import getInvoice from "./ajax/reducers/invoice/invoice-get"
import getCurrentInvoice from "./ajax/reducers/invoice/invoice-current-get"
import getDownloadInvoice from "./ajax/reducers/invoice/invoice-download-get"
import getDownloadCurrentInvoice from "./ajax/reducers/invoice/invoice-current-download-get"
// import invoiceList from "./pages/billing/invoice/list/InvoiceList-Reducer";

//card
import getCard from "./ajax/reducers/card/cards-get"
import createDefaultCard from "./ajax/reducers/card/default-card-post"
import deleteCard from "./ajax/reducers/card/card-delete"

//pay
import currentPay from "./ajax/reducers/pay/current-pay-post";
import singleInvoicePay from "./ajax/reducers/pay/single-invoice-pay-post";

//register
// import registerUser from "./ajax/reducers/register/register-post";
// import registerForm from "./pages/register/RegisterForm-Reducer";
// import verifyEmail from "./ajax/reducers/register/verify-email-post";
// import BillingFormStep2 from "./pages/register/billing-form/BillingFormstep2-Reducer"
// import CustomerFormStep1 from "./pages/register/customer-form/CustomerFormstep1-Reducer"

/* account->billing->billing-form-card */
import cardBillingForm from "./pages/account/billing/billing-form-card/CardBillingFormstep2-Reducer"
import updateCardAddOrganization from "./ajax/reducers/organization/cardaddorganization-put";


//register v2
import registerUserV2 from "./ajax/reducers/register/registerv2-post"
import registerFormV2 from "./pages/register-single/RegisterForm-Reducer"



import getCountries from "./ajax/reducers/country/countries-get";

import createCoupon from "./ajax/reducers/coupon/coupon-post";
import couponForm from "./pages/account/coupon/form/CouponForm-Reducer";

//redirects
import getRedirects from "./ajax/reducers/redirects/redirects-get";
import redirectList from "./pages/site/redirects/list/Redirects-Reducer";
import redirectForm from "./pages/site/redirects/form/RedirectsForm-Reducer"
import createRedirect from "./ajax/reducers/redirects/redirect-post";
import updateRedirect from "./ajax/reducers/redirects/redirect-put";
import getRedirect from "./ajax/reducers/redirects/redirect-get";
import deleteRedirect from "./ajax/reducers/redirects/redirect-delete";

//manual-migration
import getsMigration from "./ajax/reducers/manual-migration/migration-gets";
import getMigration from "./ajax/reducers/manual-migration/migration-get";
import createMigration from "./ajax/reducers/manual-migration/migration-post";
import migrationList from "./pages/manual-migration/list/Migration-Reducer";
import migrationDetailsForm
    from "./pages/manual-migration/form/MigrationForm-Reducer";

import migrationForm from "./pages/manual-migration/MigrationForm-Reducer";
import migrationDetailsStep2 from "./pages/manual-migration/migration-details-form/MigrationDetailsFormstep2-Reducer"
import currentHostStep1 from "./pages/manual-migration/current-host-form/CurrentHostFormstep1-Reducer"
import migrateFormStep3 from "./pages/manual-migration/migration-final-form/MigrateFormstep3-Reducer"

import migrationValidationFirst from "./ajax/reducers/manual-migration/migration-validation-first-post"
import migrationValidationSecond from "./ajax/reducers/manual-migration/migration-validation-second-post"

//plans
import getPlans from "./ajax/reducers/hosting-plan/plan-get"

export default combineReducers({

    /* - - - COMPONENT REDUCERS - - -*/

    route,
    loginForm,
    getUser, loginUser, logoutUser, updateUser, getLoggedInUser,

    resendEmailUserVerification,
    userActivationFromEmail,

    getDataCenters,
    getOrganizations,
    getOrganization,
    updateOrganization,
    organizationForm,

    userForm,

    getActivity,
    getYourActivity,

    activityList,
    yourActivityList,

    deleteSite, getSite, createSite, getSites,

    siteList, siteForm,
    siteDashboard,

    getSiteStatus,

    getAccessLogsReducer, logList,

    getSiteListStatus,
    getDemoSite,
    //
    getCredentials, createCredentials, SftpList,

    getDomain, updateDomain, createDomain, deleteDomain, getSiteDomain,
    domainList, domainForm,createBulkDomain,getSiteDomainNoPagination,

    getBackup, getSiteBackup, backupList, restoreBackup, deleteBackup, createBackup, getManualSiteBackup,

    createMonitor,
    createReactConfiguration,
    forgotPasswordForm,
    createForgotPasswordEmail, createChangePassword,
    // fetchIntent,
    //
    // createPlan, cancelPlan, updatePlan,
    getPlans,
    getInvoice, getDownloadInvoice, getCurrentInvoice, getDownloadCurrentInvoice,
    // invoiceList,
    //
    createDefaultCard,
    getCard,
    deleteCard,
    createPublicPath,
    featureBlocklist,
    publicPathForm,
    restartPhp,
    debug,
    enableCache,
    disableCache,
    clearCache,
    cacheIgnore,
    getCacheIgnore,
    updateCacheIgnore,
    getsCacheIgnore,
    deleteCacheIgnore,
    cacheList,
    ignoreCacheForm,
    search,
    searchAndReplaceForm,
    replace,
    createSiteDisplayName,
    displayNameForm,
    fetchIntent,
    overViewList,
    paymentList,

    currentPay,
    singleInvoicePay,
    // registerUser,
    // registerForm,
    // BillingFormStep2, CustomerFormStep1,
    getPayments,
    // verifyEmail,
    getCountries,
    getSiteCreateDataCenters,
    getPhpVersions,
    phpVersionForm,
    createPhpVersion,
    passwordProtectionForm,
    createPasswordProtection,
    disablePasswordProtection,

    cardBillingForm,
    updateCardAddOrganization,

    registerFormV2,
    registerUserV2,

    createCoupon,
    couponForm,

    getSslCertificates,
    SSLForm,
    createSSLCertificates,
    updateSSLCertificates,
    getSslCertificate,
    deleteSSLCertificates,
    SSLList,
    createGenerateLetsEncrypt,
    createCustomSslCertificate,
    createGenerateSsl,
    createGenerateCustomSsl,
    migrationValidationFirst,
    migrationValidationSecond,

    manualBackupForm,

    dangerZoneReducer,

    createForceSsl,

    getRedirects,
    redirectList,
    redirectForm,
    createRedirect,
    updateRedirect,
    getRedirect,
    deleteRedirect,

    createMigration,
    getMigration,
    getsMigration,
    migrationList,
    migrationDetailsForm,
    /*forms*/
    currentHostStep1,migrationDetailsStep2,migrationForm,migrateFormStep3

})