import React from 'react';
import store from "../../../../Store";
import {togglePasswordProtectionFormModal} from "./FeatureBlock-Action";
import pathSvg from "../../../../assets/images/door.svg";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";
import disablePasswordProtection from "../../../../ajax/actions/kepler-tools/disable_password_protection_post"
import {withRouter} from "react-router";
import {secondToLastItemInURL} from "../../../../helpers/UrlHelper";
import i18next from 'i18next';

class PasswordProtectionPrimaryView extends React.Component {
    disableAuthPath() {
        const payload = {
            "paths": []
        }
        // console.log(` <|  |> `,this.props )
        store.dispatch(disablePasswordProtection(payload, this.props.match.params.id));
    }

    render() {
        let {event, getSiteStatus, site_status, httpAuthPaths, demoSite} = this.props

        return (
            <tr>
                <td>
                    <div className="media">
                        <div className="media-img d-flex mr-3">
                            <img src={pathSvg} alt="" className="d-light"/>
                            <img src={pathSvg} alt="" className="d-dark"/>
                        </div>
                        <div className="media-body">
                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading' ,{returnObjects: true})}</span>
                            <h4>{i18next.t('sites.inside-site.tools.site-tools.2.title' ,{returnObjects: true})}</h4>
                        </div>
                    </div>
                </td>
                <td>
                    <p>{i18next.t('sites.inside-site.tools.site-tools.2.desc' ,{returnObjects: true})}</p>
                </td>
                <td>
                    <div className="btn-wrapper">
                        {httpAuthPaths && httpAuthPaths.length === 0 ?
                            <button className="btn-theme btn-outline blue-outline" data-toggle="modal"
                                    data-target="#password-protection"
                                    onClick={() => store.dispatch(togglePasswordProtectionFormModal())}
                                    disabled={demoSite === "demo" || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                            >
                                {event === "update_auth_paths" ? <><Loading/>{i18next.t('sites.inside-site.tools.enable' ,{returnObjects: true})}</> : `${i18next.t('sites.inside-site.tools.enable' ,{returnObjects: true})}`}
                            </button> :
                            <button className="btn-theme btn-outline blue-outline"
                                    type="button"
                                    onClick={() => this.disableAuthPath()}
                                    disabled={demoSite === "demo" || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                            >
                                {event === "update_auth_paths" ? <>{i18next.t('sites.inside-site.tools.disable' ,{returnObjects: true})} <Loading/></> : `${i18next.t('sites.inside-site.tools.disable' ,{returnObjects: true})}`}
                            </button>
                        }

                    </div>
                </td>
            </tr>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;
    let httpAuthPaths = getSite && getSite.success && getSite.success.httpAuthPaths

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus, site_status, httpAuthPaths};

}

export default withRouter(connect(mapStateToProps)(PasswordProtectionPrimaryView));
