import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import {
    onPasswordProtectionFormChange,
    onPasswordProtectionFormSubmit
} from "./PasswordProtectionForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";
import {togglePasswordProtectionFormModal} from "../../feature-block/list/FeatureBlock-Action";
import TextField from "../../../../components/shared/form/TextField";
import PasswordField from "../../../../components/shared/form/PasswordField";
import PasswordProtectionFormWorker from "./PasswordProtectionFormWorker";
import {secondToLastItemInURL} from "../../../../helpers/UrlHelper";
import i18next from 'i18next';


class PasswordProtectionForm extends React.Component {
    passwordProtectionFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onPasswordProtectionFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onPasswordProtectionFormChange(e.target.name, e.target.value,));
    };


    render() {
        let {
            errors,
            http_auth_username,
            http_auth_password,
            createPasswordProtection,
            site_search_status_event,
            site_search_status_message,
            getSiteStatus,
            site_status,
            demoSite
        } = this.props;

        let {processing} = createPasswordProtection

        return (
            <>
                <PasswordProtectionFormWorker/>
                <div className="content-form-block light-gray-bg">

                    <h3 className="search-header text-center mb-20">{i18next.t('sites.inside-site.tools.site-tools.2.title' ,{returnObjects: true})}</h3>
                    <div className="search-replace-line">

                        <p>  {site_search_status_event === "update_auth_paths" ? <>
                            <p className="bg-danger"><Loading color="white"/> {site_search_status_message}</p>

                        </> : null}</p>

                        <form className="mt-30 domain-form" onSubmit={this.passwordProtectionFormSubmit}>

                            <TextField name="http_auth_username"
                                       className="form-control"
                                       type="text"
                                       value={http_auth_username}
                                       placeholder={i18next.t('sites.inside-site.tools.site-tools.2.form.username' ,{returnObjects: true})}
                                       label={i18next.t('sites.inside-site.tools.site-tools.2.form.username' ,{returnObjects: true})}
                                       errors={errors.http_auth_username}
                                       onChange={this.onChange}/>

                            <PasswordField name="http_auth_password"
                                       className="form-control"
                                       type="text"
                                       value={http_auth_password}
                                       placeholder={i18next.t('sites.inside-site.tools.site-tools.2.form.password' ,{returnObjects: true})}
                                       label={i18next.t('sites.inside-site.tools.site-tools.2.form.password' ,{returnObjects: true})}
                                       errors={errors.http_auth_password}
                                       onChange={this.onChange}/>


                            <div className="button-wrapper inline-buttons text-center">
                                <button className="btn-theme btn-outline" type="reset"
                                        onClick={() => store.dispatch(togglePasswordProtectionFormModal())}>{i18next.t('sites.inside-site.tools.cancel' ,{returnObjects: true})}
                                </button>
                                <button type="submit" className="btn-theme btn-blue"
                                        disabled={demoSite === "demo" ||site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>

                                    {/*{processing ? <Loading/> : `Submit`}*/}
                                    {processing ? <Loading/> : i18next.t('sites.inside-site.tools.submit' ,{returnObjects: true})}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {passwordProtectionForm, createPasswordProtection, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    let site_search_status_event =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.event
    let site_search_status_message =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.message

    let {errors, payload} = passwordProtectionForm.data;

    let {
        http_auth_username, http_auth_password
    } = payload;

    return {
        http_auth_username,
        http_auth_password,
        errors,
        getSiteStatus,
        site_search_status_event,
        site_search_status_message,
        site_status,
        createPasswordProtection
    };
}

export default connect(mapStateToProps)(PasswordProtectionForm);