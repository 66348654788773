import React from 'react';
import store from '../../../Store';
import {endOfURL} from "../../../helpers/UrlHelper";
import {withRouter} from "react-router";
import userEmailVerify from "../../../ajax/actions/user/user_activation_from_email_get"
import LoginSidebar from "../../layout/LoginSidebar";
import {connect} from "react-redux";
import NotVerifiedEmailMessage from "./NotVerifiedEmailMessage";
import {loggedIn, logOut} from "../../../helpers/LocalStorageHelper";
import {pushToPage} from "../../../helpers/UrlHelper";
import NotFound from "./NotFound";
import Sidebar from "../../layout/Sidebar";

class UserActivation extends React.Component {
    componentDidMount() {
            let token = endOfURL();
      /* if( loggedIn() ) {
           localStorage.removeItem('kepler_c_token');
           pushToPage(this.props, `/verify/${token}`);
       }
       else {*/
           store.dispatch(userEmailVerify(token))
       // }
    }

    onclick() {
        // pushToPage(this.props, `/`)
        window.location.href = "/"
    }

    render() {
        let {userActivationFromEmail,success,error} = this.props;
        // let {error, success}= userActivationFromEmail;
        /*if(success && success.same_user === 'false'){
            localStorage.removeItem('kepler_c_token')
        }*/
        return (
            <section className="login email-verify">
                <div className="container-fluid p-0">
                        <LoginSidebar/>
                    <div className="content-area-wrapper">
                        <div className="content-area">
                            {success &&
                                <>
                                    <div className="email-verify-message">
                                        <h2>Email Verified</h2>
                                        <div className="col-lg-12 col-md-8 col-12 text-center pd-3 pt-2 ">

                                        <span>You have successfully verified your email.
                                            You can now login to your account.</span>
                                        </div>
                                        <div className="button-wrapper text-center mt-30">
                                            <button type="reset" className="btn-theme btn-blue btn-lg"
                                                    onClick={this.onclick}>
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                error && error.response.status === 404 ?
                                <NotFound />
                                    :
                                  error &&   !error.response.status === 422 &&
                                    <NotFound />
                            }
                            {
                                error && error.message && error.response.status === 422 &&
                                <NotVerifiedEmailMessage error={error}/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    let {userActivationFromEmail} = state;
    let {success,error} = userActivationFromEmail
    return {userActivationFromEmail,success,error};
}

export default withRouter(connect(mapStateToProps)(UserActivation));