import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import {
  onSslCertificateFormModeChange,
  setSSLDataForUpdate
} from "../../../pages/site/ssl/sslCertificate/form/SSLForm-Action";
import {loginToken} from "../../../helpers/LocalStorageHelper";

function _success(success) {
  return{ type: 'GET_SSL_CERTIFICATE_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_SSL_CERTIFICATE_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_SSL_CERTIFICATE_PROCESSING', processing };
}

function getSslCertificates(site_id,id) {
  return ( dispatch ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'


    axios({
        url: apiBaseUrl(`customer/sites/${site_id}/certificates/${id}`),
        method: "get",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken(),'lang': lang
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res));
        store.dispatch(onSslCertificateFormModeChange('update'));
        store.dispatch(setSSLDataForUpdate());

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default getSslCertificates;
