import {endOfURL} from '../../helpers/UrlHelper'

const DefaultLoginState = {
    payload: {
        email: ``,
        password: ``,
        // device_type: 'web',
    },
    loginStatus: {},

    user_id: ``,
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``,
    errorMessage: ``,

};

export const LoginFormState = {data: DefaultLoginState}

