import store from "../../Store";
import {setDefaultCurrentHostFormStep1} from './current-host-form/CurrentHostFormStep1-Actions'
import {setDefaultMigrationDetailsFormStep2} from './migration-details-form/MigrationDetailsFormStep2-Actions'
import {setDefaultMigrateFormStep3} from './migration-final-form/MigrateFormStep3-Actions'
import createMigrationValidateFirst from "../../ajax/actions/manual-migration/migration_validatae_first_post";
import createMigrationValidateSecond from "../../ajax/actions/manual-migration/migration_validatae_second_post";

export function setMigrationFormMode(formMode) {
    return function (dispatch) {
        dispatch({type: 'SET-REGISTER-FORM-MODE', formMode});
    }
}

export function onMigrationFormSubmit(e) {
    e && e.preventDefault()
    return function (dispatch, getState) {
        let {migrationDetailsStep2, currentHostStep1, migrateFormStep3} = getState();
        let currentHostForm1 = currentHostStep1.data;
        let migrationDetailsForm2 = migrationDetailsStep2.data;
        let migrateForm3 = migrateFormStep3.data;
        dispatch({type: 'ON-MIGRATION-FORM-SUBMIT', currentHostForm1, migrationDetailsForm2, migrateForm3});
    }
}

export function cancelMigrationsFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-MIGRATION-FORM-DISPATCH'});
    }
}

export function stepChange(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'STEP-CHANGE',validationErrors});
    }
}

export function stepChangeSecondStep(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'STEP-CHANGE-SECOND-STEP',validationErrors});
    }
}

export function stepChangeThirdStep(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'STEP-CHANGE-THIRD-STEP',validationErrors});
    }
}

export function migrationValidateFirst() {
    return function (dispatch) {
        dispatch({type: 'FIRST-VALIDATION-SUCCESS',});
    }
}

export function migrationValidateSecond() {
    return function (dispatch) {
        dispatch({type: 'SECOND-VALIDATION-SUCCESS',});
    }
}
// export function changeType() {
//     return function (dispatch) {
//         dispatch({type: 'ON-TYPE-CHANGE'});
//     }
// }
export function migrationFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MIGRATION-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function emailverificationSuccess() {
    return function (dispatch) {
        dispatch({type: 'EMAIL-VALIDATION-SUCCESS',});
    }
}


/*Remove initialLoading to stop each step validation*/
export function migrationFormChangeSteps(mode) {
    return function (dispatch, getState) {

        let {migrationForm, migrationDetailsStep2, currentHostStep1, migrateFormStep3} = getState();
        if (mode === `next` && !currentHostStep1.data.errorsPresent && migrationForm.data.validateFirst === false && migrationForm.data.validateSecond === false) {
            store.dispatch(createMigrationValidateFirst())
        }
        if (mode === `next` && !migrationDetailsStep2.data.errorsPresent && migrationForm.data.validateFirst === true && migrationForm.data.validateSecond === false) {
            store.dispatch(createMigrationValidateSecond())
        }
        let {currentStep,initialLoading} = migrationForm.data;
        let currentHostForm1 = currentHostStep1.data;
        let migrationDetailsForm2 = migrationDetailsStep2.data;
        let migrateForm3 = migrateFormStep3.data;

        dispatch({type: 'SET-MIGRATION-FORM-STEP-ON-CHANGE', mode, currentStep,initialLoading, currentHostForm1, migrationDetailsForm2, migrateForm3});
        if (mode === 'submit') {
            dispatch({type: 'ON-MIGRATION-FORM-SUBMIT', currentHostForm1, migrationDetailsForm2,migrateForm3, initialLoading});
        }
    }
}

export function migrationFormErrors() {
    return function (dispatch, getState) {
        let {migrationForm, migrationDetailsStep2, currentHostStep1, migrateFormStep3} = getState();
        let {currentStep} = migrationForm.data;
        let currentHostForm1 = currentHostStep1.data.payload;
        let migrationDetailsForm2 = migrationDetailsStep2.data.payload;
        let migrateForm3 = migrateFormStep3.data.payload;
        dispatch({type: 'MIGRATION-FORM-ERRORS', currentStep, currentHostForm1, migrationDetailsForm2, migrateForm3});
    }
}

export function onChangeForm(){
    return function (dispatch) {
        dispatch({type: 'ON-CHANGE'});
    }
}

export function onChangeFormSecond(){
    return function (dispatch) {
        dispatch({type: 'ON-CHANGE-SECOND'});
    }
}

export function setDefaultMigrationForm() {
    return function (dispatch) {
        store.dispatch(setDefaultMigrationDetailsFormStep2());
        store.dispatch(setDefaultCurrentHostFormStep1());
        store.dispatch(setDefaultMigrateFormStep3());
        dispatch({type: 'SET-DEFAULT-MIGRATION-DATA'});
    }
}
