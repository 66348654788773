import React from 'react';
import {withRouter} from 'react-router';
import store from '../../../Store';
import {connect} from 'react-redux';
import getCards from "../../../ajax/actions/card/cards_get";
import getCurrentInvoice from "../../../ajax/actions/invoice/invoice_current_get";
import getSites from "../../../ajax/actions/sites/sites_get";
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";
import {setDefaultCouponAddMessage} from "./Overview-Actions"

class OverviewWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getCards());
        store.dispatch(getSites());
        store.dispatch(getCurrentInvoice());
        store.dispatch(getDemoSite())
        store.dispatch(setDefaultCouponAddMessage())
    }

    // componentDidUpdate(prev) {
    //
    //     if (this.props.getCard !== prev.getCard) {
    //         let {success} = this.props.getCard;
    //         if (success) {
    //             store.dispatch(getCards());
    //         }
    //     }
    // }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser,getCard} = state;
    return {getLoggedInUser,getCard}
}

export default withRouter(connect(mapStateToProps)(OverviewWorker));