import React from 'react';
import store from "../../../../Store";
import {connect} from "react-redux";
import {toggleBackupFormModal} from "./Backup-Action";
import i18next from "i18next";

class BackupPrimaryView extends React.Component {

    render() {
        let { site_status, getSite } = this.props
        return (
            <button className="btn-theme btn-blue"
                    data-toggle="modal"
                    data-target="#manual-backup"
                    onClick={() => store.dispatch(toggleBackupFormModal(getSite && getSite.success && getSite.success.id))}
                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
            >{i18next.t('sites.inside-site.backups.manual.create-backup' ,{returnObjects: true})}
            </button>

        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    return { getSite, getSiteStatus, site_status, siteDashboard};

}

export default connect(mapStateToProps)(BackupPrimaryView);