import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, validationError} from '../../../helpers/ErrorHelper'
import {loginFormServerValidationErrors, loginError, cancelLoginFormDispatch} from "../../../pages/login/Login-Actions";
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'


function _success(success) {
    return {type: 'LOGIN_USER_SUCCESS', success};
}

function _error(error) {
    return {type: 'LOGIN_USER_ERROR', error};
}

function _processing(processing) {
    return {type: 'LOGIN_USER_PROCESSING', processing};
}

function loginUser() {

    return (dispatch, getState) => {
        dispatch(_processing(true));
        const lang = localStorage.getItem('lang') || 'en'

        let {payload} = getState().loginForm.data;

        const request = axios({

            url: apiBaseUrl(`customer/login`),
            method: "post",
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)

                store.dispatch(cancelLoginFormDispatch())
                if (error.response && error.response.status === 422) {
                    validationError(error.response.data.message,error.response.status)
                    store.dispatch(loginFormServerValidationErrors(error.response.data.errors))
                    scrollToError()
                }
            });

        return {
            type: 'LOGIN_USER_SUCCESS',
            payload: request
        }
    }
}

export default loginUser;