import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultPhpVersionForm,setSiteId
} from "./PhpVersionForm-Action";
import createPhpVersion from "../../../../ajax/actions/kepler-tools/php_versions_post";
import {togglePhpVersionFormModal} from "../../feature-block/list/FeatureBlock-Action";
import getPhpVersions from "../../../../ajax/actions/kepler-tools/php_versions_get";

class PhpVersionFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultPhpVersionForm())
        store.dispatch(setSiteId(this.props.match.params.id))
        store.dispatch(getPhpVersions());

    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if (this.props.createPhpVersion !== prev.createPhpVersion) {
            let {success} = this.props.createPhpVersion;

            if (success) {
                store.dispatch(togglePhpVersionFormModal());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createPhpVersion(this.props.match.params.id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {phpVersionForm,createPhpVersion} = state;
    let {dispatchAPI, mode, payload} = phpVersionForm.data;
    return {phpVersionForm, dispatchAPI, mode, payload, createPhpVersion};
}

export default withRouter(connect(mapStateToProps)(PhpVersionFormWorker));