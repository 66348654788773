import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import updateOrganization from "../../../ajax/actions/organization/organization_put";
import {
    setOrganizationDataForUpdate,
} from "./OrganizationForm-Action";
// import getOrganization from "../../../ajax/actions/organization/organization_get";
import getLoggedInUser from "../../../ajax/actions/user/user_loggedin_get";
import getSites from "../../../ajax/actions/sites/sites_get";
import getCountries from "../../../ajax/actions/country/countries_get";
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";

class OrganizationFormWorker extends React.Component {
    // constructor(props) {
    //     super(props);
    //     store.dispatch(getOrganization(this.props.organization_id));
    //
    // }

    componentDidMount() {
        store.dispatch(getLoggedInUser());
        store.dispatch(setOrganizationDataForUpdate());
        store.dispatch(getSites())
        store.dispatch(getCountries());
        store.dispatch(getDemoSite())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;

        // if(this.props.getOrganization !== prev.getOrganization) {
        //   let {success} = this.props.getOrganization;
        //   if(success) {
        //     store.dispatch(setOrganizationDataForUpdate());
        //   }
        // }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(updateOrganization(this.props.organization_id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {organizationForm, getOrganization, getOrganizations} = state;

    let {dispatchAPI, mode, payload} = organizationForm.data;
    return {organizationForm, dispatchAPI, getOrganization, mode, payload, getOrganizations};
}

export default withRouter(connect(mapStateToProps)(OrganizationFormWorker));