const DefaultMigrateState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    order:  'desc',
    sort_by: ``,
    q: ``,
  },
  migrate: {
    mode:''
  },

  fields: [
    { field: `site_name`, label: `Site`, sort: true },//display_name
    { field: `event`, label: `Action`, sort: true },

  ]
}

export const MigrationState = {data:DefaultMigrateState}