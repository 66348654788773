export function domainFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'DOMAIN-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onDomainFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-DOMAIN-FORM-SUBMIT'})
    }
}

export function onRemoveErrorMessage( value )
{
    return function ( dispatch )
    {
        dispatch({ type:'ON-REMOVE-ERROR-MESSAGE', value} );
    }
}

export function onDomainFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-DOMAIN-FORM-CHANGE', name, value});
    }
}
export function removeErrorPresent() {
    return function (dispatch) {
        dispatch({type: 'ON-REMOVE-ERROR'});
    }
}

export function onDomainFormNameChange( value )
{
    return function ( dispatch )
    {
        dispatch({ type:'ON-DOMAIN-FORM-NAME-CHANGE', value} );
    }
}

export function onDomainFormInputChange( inputFields, stepTrack )
{
    return function ( dispatch )
    {
        dispatch({ type:'ON-DOMAIN-FORM-INPUT-CHANGE', inputFields, stepTrack} );
    }
}

export function onDomainFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-DOMAIN-FORM-MODE-CHANGE', mode});
    }
}

export function cancelDomainFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-DOMAIN-FORM-DISPATCH'});
    }
}

export function setDefaultDomainForm() {
    return function (dispatch,getState) {
        let {getSite} = getState();
        let {lb_version} = getSite && getSite.success
        dispatch({type: 'SET-DEFAULT-DOMAIN-FORM', lb_version});
    }
}

export function setDomainDataForUpdate() {
    return function (dispatch, getState) {
        let {getDomain} = getState();
        let domain = getDomain && getDomain.success;
        dispatch({type: 'SET-DOMAIN-DATA-FOR-UPDATE', domain});
    }
}

export function domainError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-DOMAIN-ERROR', error});
    }
}

export function domainBulkError(error)
{
    return function( dispatch )
    {
        dispatch({type:'SET-BULK-DOMAIN-ERROR' , error});
    }
}