import store from '../../../../Store';
import {toggleURLQuery} from '../../../../helpers/UrlHelper'
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";

export function toggleDomainListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        let toggle = true;
        if (toggle) toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-DOMAIN-LIST-FILTER', name, value});
        store.dispatch(getSiteDomain());
    }
}

export function setSiteIDDomain(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITEID-DOMAIN', site_id});
    }
}

export function toggleDomainFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-DOMAIN-FORM-MODAL'});
    }
}