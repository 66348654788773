import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getMigrationList from "../../../ajax/actions/manual-migration/migration_gets";
import getActivityLogs from "../../../ajax/actions/activity-log/activity_logs_get";

export function toggleMigrateFilter(e, history,)
{
  return function (dispatch ) {
    let { name, value  } = e.target;
    toggleURLQuery(name, value, history)
    dispatch({type:'TOGGLE-MIGRATE-FILTER', name, value});
    store.dispatch(getMigrationList());
  }
}

export function toggleMigrateFormModal() {
  return function (dispatch) {
    dispatch({type: 'TOGGLE-MIGRATE-FORM-MODAL'});
  }
}

export function loadMore(e,history) {
  return function (dispatch) {
    let { name, value ,id} = e.target;
    dispatch({type:'LOAD-MORE', name, value,id, history});
    if(name === 'limit') {
      store.dispatch( getMigrationList());
      toggleURLQuery( 'limit', value ,history)
    }
    else {
      store.dispatch(getMigrationList());
    }
  }
}