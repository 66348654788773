import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import PublicPathFormWorker from './PublicPathFormWorker'
import {onPublicPathFormChange, onPublicPathFormSubmit} from "./PublicPathForm-Action";
import TextField from "../../../../components/shared/form/TextFieldDomain";
import Loading from "../../../../components/shared/loaders/Loading";
import {togglePublicPathFormModal} from "../list/FeatureBlock-Action";
import {secondToLastItemInURL} from "../../../../helpers/UrlHelper";
import i18next from 'i18next';

class publicPathForm extends React.Component {
    publicPathFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onPublicPathFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onPublicPathFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            public_path, errors, getSite, publicPathForm,demoSite
        } = this.props;
        let {processing} = getSite.processing

        return (
            <>
                <PublicPathFormWorker/>
                <div className="content-form-block light-gray-bg">
                    <h3 className="search-header text-center mb-20">{i18next.t('sites.inside-site.tools.site-tools.4.title' ,{returnObjects: true})}</h3>
                    <form className="mt-30" onSubmit={this.publicPathFormSubmit}>

                        <TextField name="public_path"
                                   className="form-control"
                                   type="text"
                                   value={public_path}
                                   noLabel
                                   errors={errors.name}
                                   response_error={publicPathForm?.data?.payload.public_path === "" ? "true" : "false"}
                                   onChange={this.onChange}/>

                        <div className="button-wrapper inline-buttons text-center mt-30">
                            <button className="btn-theme btn-outline" type="reset"
                                    onClick={() => store.dispatch(togglePublicPathFormModal())}>{i18next.t('sites.inside-site.tools.cancel' ,{returnObjects: true})}
                            </button>
                            <button type="submit" className="btn-theme btn-blue"
                            disabled={demoSite === "demo"}>
                                {processing ? <Loading/> : i18next.t('sites.inside-site.tools.submit' ,{returnObjects: true})}
                            </button>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {publicPathForm, createPublicPath, getSite} = state;
    let {errors, payload} = publicPathForm.data;

    let {
        public_path
    } = payload;

    return {
        publicPathForm, public_path, errors, createPublicPath, getSite
    };
}

export default connect(mapStateToProps)(publicPathForm);