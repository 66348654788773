import {ActivityListState} from './ActivityList-State'

export default function activityListReducer(state = ActivityListState, action) {

    let {data} = {...state};
    let {name, value} = action;

    switch (action.type) {

        case 'TOGGLE-ACTIVITY-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            if (name === `sort_by`) {
                if (data.params.sort_by === value) {
                    data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
                }
            }
            return {...state, data};

        case 'LOAD-MORE':
            if (name === 'limit') {
                data.params.limit = data.params.limit+4;
            }
            // data.params['limit'] =  data.params.limit+4;
            return { ...state, data };

        case 'DEFAULT-LIMIT':
            if (name === 'limit') {
                data.params.limit = 10;
            }
            data.params['limit'] =  10;
            return { ...state, data };
        default:
            return state
    }
}
