import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../pages/NotFound";
import Site from "../pages/site/list/Site";
import SiteForm from "../pages/site/form/SiteForm";
import UserForm from "../pages/user/form/UserForm";
import ActivityLog from "../pages/activity-log/list/Activity";
import Backup from "../pages/site/backup/list/BackupList";
import Domain from "../pages/site/domain/list/Domain";
import DangerZone from "../pages/site/danger-zone/DangerZone";
import Info from "../pages/site/lunchpad/LunchPad";
import AccountDashboard from "../pages/account/AccountDashboard";
import Tools from "../pages/site/feature-block/list/FeatureBlock";
import Cache from "../pages/site/cache/list/Cache";
import UserActivation from "../components/shared/pages/UserActivation";
import Ssl from "../pages/site/ssl/list/SSL";
import Plan from "../pages/site/plan/Plan";
import Redirects from "../pages/site/redirects/list/Redirects";
import Logs from "../pages/site/logs/list/LogsList";
import Support from "../pages/support/Support";
import Migration from "../pages/manual-migration/list/Migration";
import MigrationForm from "../pages/manual-migration/MigrationForm";
import MigrationDetails from "../pages/manual-migration/form/MigrationForm";
class CustomerRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Site} />
        <Route exact path="/verify/:token" component={UserActivation} />
        {/*<Route exact path="/dashboard" component={Dashboard}/>*/}
        <Route exact path="/sites" component={Site} />
        <Route exact path="/sites/create" component={SiteForm} />
        <Route
          exact
          path="/users/:id([0-9]{0,9})/update"
          component={UserForm}
        />

        <Route exact path="/support" component={Support} />
        <Route exact path="/migration" component={Migration} />
        <Route exact path="/migration/create" component={MigrationForm} />
        <Route
          exact
          path="/migration/:id([0-9]{0,9})/detail"
          component={MigrationDetails}
        />

        <Route exact path="/account/detail" component={AccountDashboard} />
        <Route exact path="/activitylog" component={ActivityLog} />
        <Route exact path="/sites/:id([0-9]{0,9})/backups" component={Backup} />
        <Route exact path="/sites/:id([0-9]{0,9})/domain" component={Domain} />
        <Route
          exact
          path="/sites/:id([0-9]{0,9})/dangerzone"
          component={DangerZone}
        />
        <Route exact path="/sites/:id([0-9]{0,9})/launchpad" component={Info} />
        <Route exact path="/sites/:id([0-9]{0,9})/tools" component={Tools} />
        <Route exact path="/sites/:id([0-9]{0,9})/cache" component={Cache} />
        <Route
          exact
          path="/sites/:id([0-9]{0,9})/redirects"
          component={Redirects}
        />

        <Route exact path="/sites/:id([0-9]{0,9})/plan" component={Plan} />
        <Route exact path="/sites/:id([0-9]{0,9})/logs" component={Logs} />
        <Route exact path="/sites/:id([0-9]{0,9})/ssl" component={Ssl} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default CustomerRoutes;
