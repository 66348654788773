import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handle422errorWithOutStatusCode, handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import {
    toggleCacheIgnoreFormModal,
} from "../../../pages/site/cache/list/Cache-Action";
import {scrollToError} from "../../../helpers/ScrollHelper";
import {
    cancelIgnoreCacheFormDispatch, ignoreCacheFormServerValidationErrors,
    setDefaultIgnoreCacheForm
} from "../../../pages/site/cache/ignoreCache/form/IgnoreCacheForm-Action";

function _success(success) {
    return {type: 'CACHE_IGNORE_UPDATE_SUCCESS', success}
}

function _error(error) {
    return {type: 'CACHE_IGNORE_UPDATE_ERROR', error}
}

function _processing(processing) {
    return {type: 'CACHE_IGNORE_PUT_PROCESSING', processing}
}

function ignoreCache(site_id,id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let {ignoreCacheForm, getCacheIgnore} = getState();
        let {path} = getCacheIgnore
        && getCacheIgnore.success
        && getCacheIgnore.success.data.data

        let payload = ignoreCacheForm.data.payload
        let finalPayload = {
            "path": "/" + payload.path
        }

        let isPayloadSame = path === payload.path

        if (isPayloadSame) {
            store.dispatch(cancelIgnoreCacheFormDispatch())
            let error = {"message": "Please change data to update Ignore Cache"}
            handle422errorWithOutStatusCode(error)
        } else {
            axios({
                url: apiBaseUrl(`customer/sites/${site_id}/tools/cache/ignore/${id}`),
                method: "put",
                dataType: 'json',
                data: finalPayload,
                headers: {
                    'Authorization': 'Bearer ' + loginToken(),'lang': lang
                }
            })
                .then(function (res) {
                    dispatch(_success(res));
                    dispatch(_processing(false));
                    handleSuccess(res)
                    store.dispatch(getSiteStatus(site_id));
                    store.dispatch(getSite(site_id));
                    store.dispatch(setDefaultIgnoreCacheForm());
                    store.dispatch(toggleCacheIgnoreFormModal());

                })
                .catch(function (error) {
                    dispatch(_error(error.response.data.message));
                    dispatch(_processing(false));
                    handleError(error.response.data)
                    store.dispatch(cancelIgnoreCacheFormDispatch())
                    if (error.response && error.response.status === 422) {
                        store.dispatch(ignoreCacheFormServerValidationErrors(error.response.data.errors))
                        scrollToError();
                    }
                });
        }
    }
}

export default ignoreCache;