import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultPasswordProtectionForm,setSiteId
} from "./PasswordProtectionForm-Action";
import createPasswordProtection from "../../../../ajax/actions/kepler-tools/password_protection_post";
import {togglePasswordProtectionFormModal} from "../../feature-block/list/FeatureBlock-Action";

class PasswordProtectionFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultPasswordProtectionForm())
        store.dispatch(setSiteId(this.props.match.params.id))
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if (this.props.createPasswordProtection !== prev.createPasswordProtection) {
            let {success} = this.props.createPasswordProtection;

            if (success) {
                store.dispatch(togglePasswordProtectionFormModal());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createPasswordProtection(this.props.match.params.id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {passwordProtectionForm,createPasswordProtection} = state;
    let {dispatchAPI, mode, payload} = passwordProtectionForm.data;
    return {passwordProtectionForm, dispatchAPI, mode, payload, createPasswordProtection};
}

export default withRouter(connect(mapStateToProps)(PasswordProtectionFormWorker));