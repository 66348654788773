import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import createCoupon from "../../../../ajax/actions/coupon/coupon_post";


class couponFormWorker extends React.Component {

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createCoupon(this.props.match.params.id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {couponForm, createCoupon,} = state;
    let {dispatchAPI, mode, payload} = couponForm.data;
    return {couponForm, dispatchAPI, mode, payload, createCoupon};
}

export default withRouter(connect(mapStateToProps)(couponFormWorker));