import {DangerZoneState} from './DangerZone-State'

export default function dangerZoneListReducer(state = DangerZoneState, action) {

    let {data} = {...state};

    switch (action.type) {

        case 'TOGGLE-DANGER-ZONE-FORM-MODAL':
            if (data.modal.mode) { // if open, close
                data.modal = {mode: ``,}
            } else { // if close, open
                data.modal.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}
