import React from "react";
import store from "../../Store";
import logoutUser from "../../ajax/actions/user/user_logout_post";
import { Link } from "react-router-dom";
import { logOut, loggedIn } from "../../helpers/LocalStorageHelper";
import { connect } from "react-redux";
import SidebarMainMenu from "./SidebarMainMenu";
import { withRouter } from "react-router";
import Dlight from "../../assets/images/d-light1.svg";
import Ddark from "../../assets/images/d-dark1.svg";
import Gravatar from "react-gravatar";
import i18next from "i18next";

class Sidebar extends React.Component {
  state = {
    width: window.innerWidth,
    logoutClicked: false,
    activeLabel: ``,
    languageSelection: false,
    selectedLanguage: "current-language",
  };

  logOut = () => {
    if (!this.state.logoutClicked) {
      store.dispatch(logoutUser());
      this.setState({ logoutClicked: true }, () => logOut());
    }
  };

  change(option) {
    localStorage.setItem("lang", option.target.value);
    window.location.reload();
  }

  handleClick = (e) => {
    localStorage.setItem("lang", e.target.className);
    window.location.reload();
  };

  componentDidMount() {
    // let nav_toggle = document.querySelector('.navbar-toggle-btn');
    // let sidebar_wrapper = document.querySelector('.sidebar-wrapper');
    let theme_switch = document.querySelector(".theme-switch");
    let theme_switch_md = document.querySelector(".theme-switch-md");
    let themeMode = localStorage.getItem("themeMode");

    if (themeMode == "true") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }

    function callEve() {
      document.body.classList.toggle("dark-theme");
      let bodyClass = document.body.classList.contains("dark-theme");
      localStorage.setItem(
        "themeMode",
        JSON.stringify(bodyClass === true ? true : false)
      );
    }

    theme_switch.addEventListener("click", function () {
      callEve();
    });
    theme_switch_md.addEventListener("click", function () {
      callEve();
    });
  }

  menuCloseToggle() {
    let shadesEl = document.querySelector(".sidebar-wrapper");
    let toggleOpen = document.querySelector(".navbar-toggle-btn");
    let html = document.getElementsByTagName("html")[0];

    document.body.classList.remove("menu-active");
    html.classList.remove("menu-active");
    shadesEl.classList.remove("active");
    toggleOpen.classList.remove("open");
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.menuCloseToggle();
  }

  render() {
    let { user, getLoggedInUser } = this.props;
    let { success } = getLoggedInUser;
    const lang = localStorage.getItem("lang") || "en";

    return (
      <div className="sidebar-wrapper">
        <div className="sidebar" id="sidebar">
          <div className="sidebar-block">
            <div className="sidebar-title-block">
              <div className="sidebar-logo-block">
                <a href="/">
                  <span className="icon-hbg"></span>
                  <p>Kepler Hosting</p>
                </a>
              </div>
              <div className="theme-switch">
                <div className="theme-toggle-btn"></div>
              </div>
            </div>
            {loggedIn() ? (
              <div className="sidebar-menu-block">
                <SidebarMainMenu />
                <div className="sidebar-main-wrapper">
                  <div className="sidebar-main-wrapper">
                    {lang === "en" ? (
                      <>
                        <div className="language-selection">
                          <div className="language">
                            <a
                              className="sv"
                              id={this.state.selectedLanguage}
                              onClick={this.handleClick}
                            >
                              Svenska
                            </a>
                          </div>
                        </div>
                      </>
                    ) : lang === "sv" ? (
                      <div className="language-selection">
                        <div className="language">
                          <a
                            className="en"
                            id={this.state.selectedLanguage}
                            data-nolt="button"
                            onClick={this.handleClick}
                          >
                            English
                          </a>
                        </div>
                      </div>
                    ) : null}

                    <div className="sidebar-lower-block">
                      <div className="user-info">
                        <Link to="/account/detail">
                          <Gravatar email={user.email} />
                          <span className="ml-2">
                            {user.first_name} {user.last_name}
                          </span>
                        </Link>
                      </div>
                      <div className="log-out" onClick={this.logOut}>
                        <Link to="">
                          <i className="icon-logout"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { route, getUser, getLoggedInUser } = state;

  let { success } = getUser;

  let role = route && route.data.user && route.data.user.role.slug;

  let getFirstName = success && success.first_name;

  let { displayRightSidebar, site_url, user, dashboard_menu } = route.data;

  return {
    route,
    displayRightSidebar,
    site_url,
    user,
    dashboard_menu,
    getUser,
    getFirstName,
    role,
    getLoggedInUser,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
