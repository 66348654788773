import React from 'react';
import store from '../../../Store'
import {stepHasError} from '../../../helpers/ErrorHelper'
import Loading from "../loaders/Loading";

class StepsButtons extends React.Component {
    render() {
        let {changeStep, steps, currentStep, errors, disabled, processing, loading} = this.props;

        if (steps) {
            return (

                <div className="steps-buttons">
                    {
                        currentStep > 0 && (
                            <button onClick={() => store.dispatch(changeStep(`previous`, currentStep))}
                                    // className={stepHasError(currentStep, errors) ? `btn-theme-disabled` : "btn-theme btn-blue"}
                                // className="btn-theme btn-blue"
                                    className="btn-theme btn-black btn-rounded position-relative"
                            >
                                {/*<MyIcon chevronLeft bottom="0" />*/}
                                Previous
                            </button>
                        )}
                    {currentStep < steps.length - 1 && (
                        <button type="primary"
                            disabled={processing}
                                onClick={() => store.dispatch(changeStep(`next`, currentStep))}
                                // className={stepHasError(currentStep, errors) ? `btn-theme-disabled` : "btn-theme btn-blue"}
                            className="btn-theme btn-black ml-4 btn-rounded position-relative"
                        >
                            {processing ? <>Next<Loading className="loader-position" height="15" width="15"/></> : `Next`}
                            {/*<MyIcon chevronRight bottom="0" />*/}
                        </button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <button type="primary"
                            // disabled={disabled}
                                processing={processing ? `processing` : undefined}
                                onClick={() => store.dispatch(changeStep(`submit`, currentStep))}
                                // className="btn-theme btn-blue ml-3"
                                className="btn-theme btn-black ml-4 btn-rounded position-relative"

                            // className={ stepHasError( currentStep, errors ) ? `item-disabled btn-theme btn-blue` : "btn-theme btn-blue ml-3" }
                        >
                            {/*<MyIcon done width={11} bottom={1} right={5} /> */}
                            {processing ? <>Submit<Loading className="loader-position" height="15" width="15"/></> : `Submit`}
                        </button>
                    )}

                </div>

            )
        }
        return null
    }
}

export default StepsButtons;