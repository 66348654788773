import {FeatureBlockState} from './FeatureBlock-State'

export default function featureBlockListReducer(state = FeatureBlockState, action) {

    let {data} = {...state};

    switch (action.type) {

        case 'TOGGLE-PUBLIC-PATH-FORM-MODAL':
            if (data.public_path_modal.mode) { // if open, close
                data.public_path_modal = {mode: ``,}
            } else { // if close, open
                data.public_path_modal.mode = true;
            }
            return {...state, data};

        case 'TOGGLE-SEARCH-AND-REPLACE-FORM-MODAL':
            if (data.modal.mode === true) { // if open, close
                data.modal = {mode: ``,}
            } else { // if close, open
                data.modal.mode = true;
            }
            return {...state, data};

            case 'TOGGLE-PHP-VERSION-FORM-MODAL':
            if (data.php_version.mode === true) { // if open, close
                data.php_version = {mode: ``,}
            } else { // if close, open
                data.php_version.mode = true;
            }
            return {...state, data};

        // case 'TOGGLE-FORCE-SSL-FORM-MODAL':
        //     if (data.force_ssl.mode === true) { // if open, close
        //         data.force_ssl = {mode: ``,}
        //     } else { // if close, open
        //         data.force_ssl.mode = true;
        //     }
        //     return {...state, data};

            case 'TOGGLE-PASSWORD-PROTECTION-FORM-MODAL':
            if (data.password_protection.mode === true) { // if open, close
                data.password_protection = {mode: ``,}
            } else { // if close, open
                data.password_protection.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}
