import React from 'react';
import { names, ucFirst,  unslufigy } from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';
import {endOfURL} from "../../../helpers/UrlHelper";

class TextField extends React.Component
{
    placeholder()
    {
        let { placeholder, name, label , small , message} = this.props;
        if ( placeholder ) return placeholder;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label;
            }
        }
    }
    small()
    {
        let{small}=this.props;
        if(small)
        {
            return (<small>{small}</small>);
        }
        else return null;
    }
    label()
    {
        let { name, label, errors   } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label >
                { label }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                        { _label }
                    </label>
                )
            }
        }
    }

    render()
    {
        let {
           name, size, value, icon, onChange, id, noLabel, labelIcon, addonAfter, disabled, className, errors, required,
            placeholder, addonBefore,small,message
        } = this.props;
        let errorsPresent = errors && errors.length;

        return (
<>
        <div className={ names(`form-group`, `animated fadeIn faster`, errors && errors.length && `has-error`, required && `required`) }>

                { !noLabel ? <>{ this.label() }</> : null }
                { small ?<><br/>{this.small()}</>:null}
                <input type="text"
                       className="form-control"
                       size={size || `default`}
                       name={name}
                       disabled={disabled}
                       id={id || name}
                       value={ value }
                       autoComplete="nope"
                       placeholder={this.placeholder()}
                       onChange={ onChange }
                />
                <div className=' error-msg'>
            {
            // errorsPresent ? errors[0].message + '.' : null}</div>
            errorsPresent ? errors[0].message : null}</div>

        </div>
    </>

        )
    }
}
let { string, number, oneOfType } = PropTypes;
TextField.propTypes = { value: oneOfType([ string, number ]) };

export default TextField;