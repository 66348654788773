import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_ACCESS_LOGS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ACCESS_LOGS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ACCESS_LOGS_PROCESSING', processing};
}

function getAccessLogs() {
    return (dispatch,getState) => {
        let {logList} = getState();
        let {site_id} = logList.data
        let {params} = logList.data;
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        const request = axios({
            url: apiBaseUrl(`customer/sites/${site_id}/tools/logs`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken(),'lang': lang}
        })
            .then(function (res) {
                dispatch(_success(res?.data.data));
                dispatch(_processing(false));

            }).catch(function (error) {
                if (error && error.response &&
                    error.response.status === 401) {
                    logOut()
                } else {
                    handleError(error)
                    dispatch(_error(error));
                    dispatch(_processing(false));
                }
            });

        return {
            type: 'GET_ACCESS_LOGS_SUCCESS',
            payload: request
        }
    }
}


export default getAccessLogs;