export function manualBackupFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MANUAL-BACKUP-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onManualBackupFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-MANUAL-BACKUP-FORM-SUBMIT'})
    }
}


export function setManualBackupError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-MANUAL-BACKUP-ERROR', error});
    }
}

export function setDefaultManualBackupForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MANUAL-BACKUP-FORM'});
    }
}

export function onManualBackupFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-MANUAL-BACKUP-FORM-CHANGE', name, value});
    }
}

export function cancelManualBackupFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-MANUAL-BACKUP-FORM-DISPATCH'});
    }
}
