import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'

function _success(success) {
  return { type: 'FETCH_INTENT_SUCCESS', success }
}

function _error(error) {
  return { type: 'FETCH_INTENT_ERROR', error }
}

function _processing(processing) {
  return { type: 'FETCH_INTENT_PROCESSING', processing }
}

function createIntent() {
  return (dispatch) => {

    const lang = localStorage.getItem('lang') || 'en'

    dispatch(_processing(true))
    axios({
      url: apiBaseUrl(`subscription/fetchIntent`),
      method: 'post',
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'lang': lang
      }
    })
      .then(function(res) {
        dispatch(_success(res))
        dispatch(_processing(false))
        // handleSuccess("Intent created Successfully")
      }).catch(function(error) {
        dispatch(_error(error?.response?.data.message))
        dispatch(_processing(false))
        handleError(error)
      })
  }
}

export default createIntent
