import React from 'react'
import {Route} from 'react-router-dom'
import "../../assets/scss/main.scss";
import "../../assets/css/main.min.css"
import 'animate.css';
import MainRoute from '../../../src/routes/main-route/MainRoute';
import {connect} from "react-redux";
import Intercom from 'react-intercom';
import 'react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "react-toastify";
import {hotjar} from 'react-hotjar';
// import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import {ConfigIntercom} from "../../Config";

const tagManagerArgs = {
    // gtmId: 'GTM-KGJDD8H'
    // gtmId: 'AW-579429186'
    gtmId: 'GTM-5HVG6J4'
}
// export const GA_TRACKING_ID = 'AW-579429186'
// export const pageview = url => {
//     window.gtag('config', GA_TRACKING_ID, {
//         page_location: url
//     })
// }

const App = ({getLoggedInUser}) => {
    const user = getLoggedInUser?.success?.data?.data
    const {id, email, first_name, intercom_hash} = user || {};
// ReactIntercom will automatically handle shutdown/boot when user_id or email changes
    const intercomData = user
        ? {user_id: id, email: email, name: first_name, user_hash: intercom_hash}
        : {last_step: 'checkout'};

    hotjar.initialize(2120442, 6);

    // if(getLoggedInUser?.success)
    // {
    //     window.civchat = {
    //         apiKey: '0vrM5P',
    //         name: first_name,
    //         user_id: 8,
    //         email: email,
    //         gender: 2,
    //         status: 2,
    //         date_attr: "2017-07-25T14:14:08.612Z",   // Must be a valid ISO 8601 format
    //         phone_number: "+44754123434", // Must be a valid E.164 format
    //         score: 0,
    //         company: {
    //             name: "My Company",
    //             company_id: "idfromyourdatabase",
    //             revenue: "$239.9 billion"
    //         }
    //     };
    //
    // }
    // else{
    //     window.civchat = {
    //         apiKey: '0vrM5P',
    //     };
    // }

    // const advancedMatching = {em: email}; // optional, more lunchpad: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    // const options = {
    //     autoConfig: true, // set pixel's autoConfig. More lunchpad: https://developers.facebook.com/docs/facebook-pixel/advanced/
    //     debug: false, // enable logs
    // };

    TagManager.initialize(tagManagerArgs)


    // ReactPixel.init('4109562889071268', advancedMatching, options);
    // ReactPixel.init('4109562889071268', advancedMatching, options);
    //
    // ReactPixel.pageView(); // For tracking page view
    // ReactPixel.track("user_id", id); // For tracking default events. More lunchpad about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle(4109562889071268, "user_id", id); // For tracking default events.
    // ReactPixel.trackCustom("user_id", id); // For tracking custom events. More lunchpad about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', "user_id", id);

    return (

        <div className="App">
            <Intercom
                appID={ConfigIntercom.AppID}
                { ...intercomData }
            />
            <Route path="/" component={MainRoute}/>
            {/*<Route path="/" component={ RegisterForm } />*/}
            {/*<Alert stack={{limit: 1}} />*/}
            <ToastContainer hideProgressBar={true} draggable={false}
            />
        </div>
    )
}

function mapStateToProps(state) {
    let {getLoggedInUser} = state;
    return {getLoggedInUser};
}

export default connect(mapStateToProps)(App);


