export const filteredUserRoles = [
  { name: `customer` },
  { name: `restaurant-admin` },
  { name: `driver` },
  { name: `branch-admin` },
  { name: `branch-operator` },
  { name: `driver-captain` },
  { name: `restaurant-customer` },
];

export const allowedRole = user_role =>
{
  return (
    user_role === 'branch-admin' ||
    user_role === 'restaurant-admin' ||
    user_role === 'admin' ||
    user_role === 'super-admin'
  )
};

export const parentRole = [{name:"Restaurant Admin", value:"restaurant-admin"},{name:"Branch Admin", value:"branch-admin"}]

export const genderList = [{ id:'female', name:'Female' },{ id:'male', name:'Male' }];

export const activeStatus = [{ id:1, name:`Active` }, { id:0, name:`Inactive` }];

export const activeStatusFilter = [{name:"Active", value:1},{name:"Inactive", value:0}];

export const activeTypeFilter = [{name:"Personal", value:"personal"},{name:"Company", value:"company"}];

export const selectType = [{name:"Normal", value:"normal"},{name:"Ecommerce", value:"ecommerce"}];

export const yesNoType = [{name:"Yes", value:"1"},{name:"No", value:"0"}];

export const ftpSftp = [{name:"FTP", value:"ftp"},{name:"SFTP", value:"sftp"}];

export const organizationType = [{name:"Personal", value:"personal"},{name:"Company", value:"company"}];

export const availabilityStatus = [{name:"Available", value:1},{name:"Unavailable", value:0}];

export const customer_type = [{name:"General", value:30},{name:"VIP", value:10}];

export const xpTypeList = [{name:"Normal", value:"normal"},{name:"Deduction", value:"deduction"}];

export const targetUsers = [{name:"All", value:"all"},{name:"Logged In", value:"logged-in-users"}];

export const operatorType = [
  {value:'normal', name:'Normal'},
  {value:'in_house', name:'In House'}

]

export const sslType = [
  {value:'certbot', name:'Certbot'},
  {value:'certificate', name:'Certificate'}

]



