import React from 'react';
import store, {history} from './Store'
import {Router, Route} from 'react-router-dom'
import {Provider} from 'react-redux'
import App from './components/app/App'

class Boot extends React.Component {
    render() {
        return (

            <Provider store={store}>
                <Router history={history}>

                    <Route path="/" component={App}/>

                </Router>
            </Provider>

        )
    }
}

export default Boot;