import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import getSites from "../../../ajax/actions/sites/sites_get";
import getLoggedInUser from "../../../ajax/actions/user/user_loggedin_get";
import getSiteListStatus from "../../../ajax/actions/sites/site_list_status_get";
import { clearSearchFieldValue} from "./SiteList-Actions";

class SiteWorker extends React.Component {

    componentDidMount() {
            store.dispatch(getSiteListStatus());
            store.dispatch(getSites());
            store.dispatch(getLoggedInUser());
            store.dispatch(clearSearchFieldValue())
            this.periodicFetch();
    }

    componentDidUpdate(prevProps) {
        if (this.props.getSiteListStatus.data !== prevProps.getSiteListStatus.data) {
            store.dispatch(getSites());
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    periodicFetch() {
        this.timer = setInterval(() => !this.props.getSiteListStatus.processing && store.dispatch(getSiteListStatus()), 10000)
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser, getSiteListStatus, getSites,getDemoSite} = state;
    return {getSiteListStatus, getLoggedInUser, getSites,getDemoSite}
}

export default withRouter(connect(mapStateToProps)(SiteWorker));
