import store from '../../../../Store';
import {toggleURLQuery} from '../../../../helpers/UrlHelper'
import getSftp from "../../../../ajax/actions/sftp-database/credential_get";


export function toggleSftpListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        let toggle = true;
        if (toggle) toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-SFTP-LIST-FILTER', name, value});
        store.dispatch(getSftp());
    }
}

export function setSftpID(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SFTPID-SFTP', site_id});
    }
}

export function toggleSftpFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-SFTP-FORM-MODAL',});
    }
}

export function onSftpSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-SFTP-SUBMIT'})
    }
}
