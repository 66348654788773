import {endOfURL} from "../../helpers/UrlHelper";

const DefaultRouteState = {
  user_role: ``,
  action:'loader',
  user:``,
  dashboard_menu:``,
  user_id:'',
  site_url:endOfURL()
};

export const MainRouteState = {data: DefaultRouteState}