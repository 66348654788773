import React from 'react'
import TextField from '../../../components/shared/form/TextField'
import PasswordField from '../../../components/shared/form/PasswordField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onFormChange,
} from './CustomerFormStep1-Actions'

class CustomerFormStep1 extends React.Component {

    onChange = (e) => {
        store.dispatch(onFormChange(e));
    }

    render() {
        let {
            errors, payload, submitted, message
        } = this.props;
        let {
            first_name, last_name, password, email, password_confirmation
        } = payload
        let {step0} = submitted;


        return (
            <>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            {/*<span> {message?message:null}</span>*/}
                            <TextField required
                                       value={email}
                                       name="email"
                                       type="email"
                                       icon="mail"
                                       autoComplete="nope"
                                       errors={step0 && errors && errors.email}
                                       message={message ? message : ""}
                                       onChange={this.onChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField required
                                       value={first_name}
                                       name="first_name"
                                       icon="user"
                                       onChange={this.onChange}
                                       errors={step0 && errors && errors.first_name}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField required
                                       value={last_name}
                                       name="last_name"
                                       icon="user"
                                       onChange={this.onChange}
                                       errors={step0 && errors && errors.last_name}/>
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <PasswordField required
                                           value={password}
                                           name="password"
                                           icon="lock"
                                           defaultValue={null}
                                           autoComplete="new-password"
                                           onChange={this.onChange}
                                           errors={step0 && errors && errors.password}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <PasswordField required
                                           name="password_confirmation"
                                           value={password_confirmation}
                                           icon="lock"
                                           defaultValue={null}
                                           onChange={this.onChange}
                                           autoComplete="new-password"
                                           // errors={step0 && errors && errors.password_confirmation}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {registerForm, registerUser, CustomerFormStep1} = state;
    let {payload, mode, errorMessage, message} = CustomerFormStep1.data;
    let {submitted} = registerForm.data;
    let {errors, errorsPresent} = CustomerFormStep1.data;
    return {
        errors, errorsPresent, registerUser, mode, message,
        registerForm, errorMessage, payload, submitted, CustomerFormStep1
    };
}

export default connect(mapStateToProps)(CustomerFormStep1);
