const DefaultOverViewState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        per_page: 25,
        page: 1,
        order: 'desc',
        sort_id: `id`,
        q: ``,
    },
    modal: {
        mode: '',
    },
    add_card_modal: {
        mode: '',
    },
    couponAdded:``
}

export const OverViewState = {data: DefaultOverViewState}