import React from 'react';
import store from '../../../Store';
import {endOfURL, pushToPage, secondToLastItemInURL} from "../../../helpers/UrlHelper";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import NoCardRoutes from "../../../routes/NoCardRoutes";

class NoCard extends React.Component
{
    render()
    {

        /*if billable is disabled or 0 than all the account_paths url will be removed and user
        * will only be redirect to account/detail*/

        const current_path = this.props.location.pathname;
        const account_paths = ['/account/overview','/account/payment','/account/activate-account'];
        const containActivationLink = endOfURL();

        let { getLoggedInUser } = this.props;
        if( getLoggedInUser.success  ) {
            let {billable} = getLoggedInUser.success.data.data.organization

            if(  billable === 0 ) {
                if (secondToLastItemInURL() === "verify" && containActivationLink) {
                    // pushToPage(this.props, current_path)
                }
                else if( account_paths.includes( current_path )  ) {
                    pushToPage(this.props, `/account/detail`)
                }

            }
        }

        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, loginForm, getLoggedInUser} = state;

    let {action, routes_url} = route.data;

    let role = route && route.data.user && route.data.user.role.slug;

    let {redirectTo} = loginForm.data;

    return {route, action, loginForm, redirectTo, routes_url, getLoggedInUser, role};
}

export default withRouter(connect(mapStateToProps)(NoCard));


// export default withRouter( NoCard );