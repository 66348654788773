import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_SITE_CREATE_DATA_CENTERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SITE_CREATE_DATA_CENTERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SITE_CREATE_DATA_CENTERS_PROCESSING', processing};
}

function getSiteCreateDataCenters() {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        axios({
            url: apiBaseUrl(`customer/datacenters?filter_for_create_site=1`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`kepler_c_token`),
                'lang': lang
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));

        }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default getSiteCreateDataCenters;
