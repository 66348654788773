import axios from 'axios';
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";

function _success(success) {
  return{ type: 'GET_COUNTRIES_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_COUNTRIES_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_COUNTRIES_PROCESSING', processing };
}

function getCountries() {
  return ( dispatch ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'

    axios({
      url: apiBaseUrl(`customer/countries`),
      method: "get",
      dataType: 'json',
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'lang': lang
      }

    }).then(function(res){
      dispatch(_processing(false));
      dispatch(_success(res));

    }).catch(function(error){
      handleError(error);
      dispatch(_error(error));
      dispatch(_processing(false));
    });
  }
}

export default getCountries;
