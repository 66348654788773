export default function getVerifyUserEmailReducer
    (state = { error:null, success:null, processing:false }, action) {

    switch (action.type)
    {
        case 'VERIFY_USER_EMAIL_SEND_SUCCESS':
            return {...state, ...{success: action.success }};

        case 'VERIFY_USER_EMAIL_SEND_ERROR':
            return {...state, ...{ error: action.error }};

        case 'VERIFY_USER_EMAIL_SEND_PROCESSING':
            return {...state, ...{ processing: action.processing }};

        default: return state;
    }
}