export function siteFormServerValidationErrors(validationErrors) {
  return function (dispatch) {
    dispatch({ type: "SITE-FORM-SERVER-VALIDATION-ERRORS", validationErrors });
  };
}

export function onSiteFormSubmit() {
  return function (dispatch) {
    dispatch({ type: "ON-SITE-FORM-SUBMIT" });
  };
}

export function onSiteFormChange(name, value) {
  return function (dispatch) {
    dispatch({ type: "ON-SITE-FORM-CHANGE", name, value });
  };
}

export function onSiteFormCheckedChange(value) {
  return function (dispatch) {
    dispatch({ type: "ON-SITE-FORM-CHECKED-CHANGE", value });
  };
}

export function onSiteFormCommerceCheckedChange(value) {
  return function (dispatch) {
    dispatch({ type: "ON-SITE-FORM-COMMERCE-CHECKED-CHANGE", value });
  };
}

export function onSiteFormPasswordChange(value) {
  return function (dispatch) {
    dispatch({ type: "ON-SITE-FORM-PASSWORD-CHANGE", value });
  };
}

export function cancelSiteFormDispatch() {
  return function (dispatch) {
    dispatch({ type: "CANCEL-SITE-FORM-DISPATCH" });
  };
}

export function setDefaultSiteForm(password) {
  return function (dispatch) {
    dispatch({ type: "SET-DEFAULT-SITE-FORM", password });
  };
}

export function onSiteFormModeChange() {
  return function (dispatch) {
    dispatch({ type: "ON-SITE-FORM-MODE" });
  };
}

export function setOrganizationID(org_id) {
  return function (dispatch) {
    dispatch({ type: "SET-ORGANIZATION-ID", org_id });
  };
}

export function onDataCenterFormChange(data_center_ids) {
  return function (dispatch) {
    dispatch({ type: "ON-DATA-CENTER-SELECT-SUCCESS", data_center_ids });
  };
}

export function onOrganizationFormChange(org_id) {
  return function (dispatch) {
    dispatch({ type: "ON-ORGANIZATION-SELECT-SUCCESS", org_id });
  };
}

export function onSelectPlan(description, price) {
  return function (dispatch) {
    dispatch({ type: "ON-SELECT-PLAN", description, price });
  };
}
