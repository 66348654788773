import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import store from "../../Store";
import { Link } from "react-router-dom";
import Loading from "../../components/shared/loaders/Loading";
import { toggleSiteListFilter } from "./list/SiteList-Actions";
import debounce from "lodash.debounce";
import { setSearchingFlag, setSearchedFlag } from "./SiteDashboard-Action";
import { pushToPage } from "../../helpers/UrlHelper";
import { toggleAddCardFormModal } from "../account/overview/Overview-Actions";
import i18next from "i18next";

class InfoHeading extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.fireBillingButton = this.fireBillingButton.bind(this);
    this.emitChangeDebounced = debounce(this.toggleFilter, 800);
  }
  state = {
    name: "",
    value: "",
    searching: false,
  };

  fireBillingButton(e) {
    e.preventDefault();
    // const current_path = this.props.location.pathname;
    // const account_paths = [ '/account/overview', '/account/payment'];
    // if( !account_paths.includes( current_path ) ) {
    //     pushToPage(this.props, `/account/overview`)
    // }
    const current_path = this.props.location.pathname;
    const account_paths = ["/account/activate-account"];
    if (!account_paths.includes(current_path)) {
      pushToPage(this.props, `/account/activate-account`);
    }
    window.dataLayer.push({
      event: "createSite",
      eventCategory: "CreateSite",
      eventAction: "ButtonClick",
      eventLabel: "CreateSiteButton",
    });
    // store.dispatch( toggleAddCardFormModal() );
  }

  fireTagManager() {
    window.dataLayer.push({
      event: "createSite",
      eventCategory: "CreateSite",
      eventAction: "ButtonClick",
      eventLabel: "CreateSiteButton",
    });
  }

  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }

  handleChange(e) {
    this.emitChangeDebounced(e.target.name, e.target.value);
    this.setState({ name: e.target.name, value: e.target.value });
    this.setState({ searching: true });

    store.dispatch(setSearchingFlag());
  }

  toggleFilter() {
    // this.setState({name:name,value:value})
    store.dispatch(
      toggleSiteListFilter(
        this.state.name,
        this.state.value,
        this.props.match.params.id
      )
    );
    this.setState({ searching: false });
    store.dispatch(setSearchedFlag());
  }

  render() {
    let { site_status, site_message, params, getSite, getLoggedInUser, sale } =
      this.props;
    let { billing_type } = getLoggedInUser.success.data.data;

    return (
      <>
        {(site_status === "updating" || site_status === "restoring") && (
          <div className="notification-banner">
            <div className="single-notification process-notification">
              <div className="single-notification-wrapper">
                <div className="notification-text">
                  <p>
                    <Loading color="white" /> {site_message}
                  </p>
                </div>
                <div className="button-wrapper">
                  <a href="/activitylog" className="btn-theme">
                    {i18next.t("create-site.update", { returnObjects: true })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={
            site_status === "updating" || site_status === "restoring"
              ? "header-block mt-90"
              : "header-block"
          }
        >
          <div className="header-block-wrapper">
            <div className="search-bar">
              <input
                type="text"
                className="form-control"
                name="q"
                placeholder={i18next.t("create-site.placeholder", {
                  returnObjects: true,
                })}
                value={this.state.value}
                onChange={this.handleChange}
              />
              <span className="icon-search"> </span>
            </div>

            <div className="button-wrapper">
              {(getLoggedInUser.success &&
                getLoggedInUser?.success?.data?.data?.has_active_card) ||
              (getLoggedInUser.success &&
                getLoggedInUser?.success?.data?.data?.organization.billable ===
                  0) ||
              billing_type === "fortnox" ? (
                //  ||
                // sale
                <Link
                  id="site-dashboard-create-site-button"
                  className="btn-theme btn-blue"
                  to={`/sites/create`}
                  onClick={this.fireTagManager}
                >
                  {i18next.t("create-site.button", { returnObjects: true })}
                </Link>
              ) : (
                <a
                  href="#"
                  id="site-dashboard-create-site-button"
                  className="btn-theme btn-blue"
                  onClick={this.fireBillingButton}
                >
                  {i18next.t("create-site.button", { returnObjects: true })}
                </a>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    route,
    getLoggedInUser,
    getUser,
    getSites,
    siteDashboard,
    siteList,
    getDemoSite,
    getSite,
  } = state;
  let { site_status, site_notification, site_message } = siteDashboard.data;

  let sale =
    getLoggedInUser &&
    getLoggedInUser.success &&
    getLoggedInUser.success.data &&
    getLoggedInUser.success.data.data &&
    getLoggedInUser.success.data.data.sale_tracking;

  let { params } = siteList.data;

  return {
    route,
    getLoggedInUser,
    getUser,
    getSites,
    siteList,
    params,
    siteDashboard,
    site_status,
    site_notification,
    site_message,
    getDemoSite,
    getSite,
    sale,
  };
}

export default withRouter(connect(mapStateToProps)(InfoHeading));
