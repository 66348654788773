import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultSearchAndReplaceForm,setSiteId
} from "./SearchAndReplaceForm-Action";
import search from "../../../../ajax/actions/kepler-tools/search_post";
import {toggleSearchAndReplaceFormModal} from "../../feature-block/list/FeatureBlock-Action";
import replace from "../../../../ajax/actions/kepler-tools/replace_post";

class SearchAndReplaceFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultSearchAndReplaceForm())
        store.dispatch(setSiteId(this.props.match.params.id))
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,replace_flag} = this.props;
        if (this.props.search !== prev.search) {
            let {success, error} = this.props.search;

            if (success) {
                store.dispatch(toggleSearchAndReplaceFormModal());
            }
        }

        if (dispatchAPI && replace_flag=== 0) {
            if (!prev.dispatchAPI) {
                store.dispatch(search(this.props.match.params.id))
            }
        }
        if (dispatchAPI && replace_flag=== 1) {
                store.dispatch(replace(this.props.match.params.id))
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {searchAndReplaceForm, createReplace,} = state;
    let {dispatchAPI, mode, payload,replace_flag} = searchAndReplaceForm.data;
    return {searchAndReplaceForm, dispatchAPI, mode, payload, createReplace,replace_flag};
}

export default withRouter(connect(mapStateToProps)(SearchAndReplaceFormWorker));