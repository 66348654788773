export function SSLCertificateFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'SSL-CERTIFICATES-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onSslCertificateFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-SSL-CERTIFICATES-FORM-SUBMIT'})
    }
}

export function onSslCertificateFormChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-SSL-CERTIFICATES-FORM-CHANGE', name, value});
    }
}

export function onDeleteSslCertificateForm(value) {
    return function (dispatch) {
        dispatch({type: 'ON-DELETE-SSL-CERTIFICATES-FORM', value});
    }
}

export function onSslCertificateFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-SSL-CERTIFICATES-FORM-MODE-CHANGE', mode});
    }
}

export function cancelSslCertificateFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-SSL-CERTIFICATES-FORM-DISPATCH'});
    }
}

export function setDefaultSslCertificateForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-SSL-CERTIFICATES-FORM'});
    }
}
export function setSSLDataForUpdate() {
    return function (dispatch, getState) {
        let {getSslCertificate} = getState();
        let sslCertificate = getSslCertificate && getSslCertificate.success.data && getSslCertificate.success.data.data;
        dispatch({type: 'SET-SSL-DATA-FOR-UPDATE', sslCertificate});
    }
}
export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}

