import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";
import {
    cancelMigrationsFormDispatch,
    setDefaultMigrationForm
} from "../../../pages/manual-migration/MigrationForm-Actions";
import {
    migrateFormServerValidationErrors,
    setDefaultMigrateFormStep3
} from "../../../pages/manual-migration/migration-final-form/MigrateFormStep3-Actions";
import {
    migrationDetailsFormServerValidationErrors,
    setDefaultMigrationDetailsData
} from "../../../pages/manual-migration/migration-details-form/MigrationDetailsFormStep2-Actions";
import {
    customerValidationError,
    setDefaultCurrentHostFormStep1
} from "../../../pages/manual-migration/current-host-form/CurrentHostFormStep1-Actions";

function _success(success) {
    return {type: 'CREATE_MIGRATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_MIGRATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_MIGRATION_PROCESSING', processing}
}

function createMigration() {
    return (dispatch, getState) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        const lang = localStorage.getItem('lang') || 'en'

        let { currentHostStep1,migrationDetailsStep2,migrationForm,migrateFormStep3} = getState();

        let finalData = {
            ...migrationForm.data.payload,
            ...currentHostStep1.data.payload,
            ...migrationDetailsStep2.data.payload,
            ...migrateFormStep3.data.payload,
        }

        axios({
            url: apiBaseUrl(`customer/manual-migration`),
            method: 'post',
            dataType: 'json',
            data: JSON.parse(JSON.stringify(finalData)),
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(cancelMigrationsFormDispatch())
                store.dispatch(setDefaultMigrationDetailsData())
                store.dispatch(setDefaultCurrentHostFormStep1())
                store.dispatch(setDefaultMigrateFormStep3())
                store.dispatch(setDefaultMigrationForm())

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelMigrationsFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(migrateFormServerValidationErrors(error.response.data.errors))
                // store.dispatch(migrationDetailsFormServerValidationErrors(error.response.data.errors))
                // store.dispatch(customerValidationError(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default createMigration
