import React from "react";
import sslSvg from "../../../../assets/images/ssl.svg";
import { connect } from "react-redux";
import store from "../../../../Store";
import { withRouter } from "react-router";
import {
  toggleSSLFormModal,
  setSSLFilteredData,
  onChangeLetsEncrypt,
} from "./SSL-Action";
import SiteDashboardHeader from "../../SiteDashboardHeader";
import Page from "../../../../components/shared/pages/Page";
import SiteDashboardLinks from "../../SiteDashboardLinks";
import { endOfURL } from "../../../../helpers/UrlHelper";
import SiteTableCell from "../../list/SiteTableCell";
import DisplayNameForm from "../../change-display-name/form/DisplayNameForm";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import { Link } from "react-router-dom";
import Loading from "../../../../components/shared/loaders/Loading";
import SSLWorker from "./SSLWorker";
import i18next from "i18next";
import SSLForm from "../../ssl/sslCertificate/form/SSLForm";
import deleteSslCertificates from "../../../../ajax/actions/ssl/ssl_delete";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import getSslCertificate from "../../../../ajax/actions/ssl/ssl_certificate_get";
import createGenerateLetsEncrypt from "../../../../ajax/actions/ssl/generate_lets_encrypt_post";
import createCustomSslCertificate from "../../../../ajax/actions/ssl/custom_ssl_certificate_post";
import createGenerateSsl from "../../../../ajax/actions/ssl/generateSsl_post";
import CheckField from "../../../../components/shared/form/CheckFieldDomain";
import customSslImg from "../../../../assets/images/custom_ssl.svg";
import createGenerateCustomSsl from "../../../../ajax/actions/ssl/generateCustomSsl_post";
import { fullDateStringNoTime } from "../../../../helpers/DateTimeHelper";

class SSL extends React.Component {
  state = {
    edit: false,
    cancel: false,
    update: false,
    status: false,
    sslForm: false,
  };

  componentDidUpdate(prev) {
    if (
      this.props.getSiteDomainNoPagination !== prev.getSiteDomainNoPagination
    ) {
      let { success } = this.props.getSiteDomainNoPagination;
      if (success) {
        store.dispatch(setSSLFilteredData());
      }
    }
  }

  onChangeLetsEncrypt = (index, value, e) => {
    store.dispatch(onChangeLetsEncrypt(e));
  };

  onGenerateSSL = (e) => {
    store.dispatch(createGenerateSsl(this.props.match.params.id));
  };
  onGenerateCustomSSL = (e) => {
    store.dispatch(createGenerateCustomSsl(this.props.match.params.id));
  };

  updateDisplayName = (e) => {
    store.dispatch(changeDisplayName(this.props.match.params.id));
    this.state.edit = false;
  };

  enableDisableGenerateLetsEncrypt = (value) => {
    const payload = {
      ssl_type: value,
    };
    store.dispatch(
      createGenerateLetsEncrypt(this.props.match.params.id, payload)
    );
  };

  enableDisableCustomSslCertificate = (value) => {
    const payload = {
      ssl_type: value,
    };
    store.dispatch(
      createCustomSslCertificate(this.props.match.params.id, payload)
    );
  };

  onClickAddRule = () => {
    this.setState({ sslForm: true });
  };

  onDeleteCertificate = (id) => {
    store.dispatch(deleteSslCertificates(this.props.match.params.id, id));
  };

  onEditCertificate = (id) => {
    this.setState({ sslForm: false });
    store.dispatch(getSslCertificate(this.props.match.params.id, id));
    store.dispatch(toggleSSLFormModal());
  };

  render() {
    let {
      SSLCertificate,
      getSslCertificates,
      site_status,
      sslType,
      deleteSSLCertificates,
      ignore_ssl,
      getSiteStatus,
      getSite,
      siteListParam,
      searchFrom,
      createGenerateLetsEncrypt,
      createCustomSslCertificate,
      domainLists,
      createGenerateSsl,
      createGenerateCustomSsl,
      letsEncryptgeneratingDomainsId,
      event,
      SSLList,
    } = this.props;
    const current_path = this.props.location.pathname;
    const demo_site_path = "/sites/demo/ssl";

    let domainListsExceptKeplerCloud =
      domainLists &&
      domainLists.filter((n) => !n.name.includes(".keplerhosting.cloud"));
    let { processing } = getSslCertificates;
    let letsEncryptProcessing = createGenerateLetsEncrypt.processing;
    let customProcessing = createCustomSslCertificate.processing;
    let createGenerateSslProcessing = createGenerateSsl.processing;
    let createGenerateCustomSslProcessing = createGenerateCustomSsl.processing;
    let deleteSSLCertificatesProcessing = deleteSSLCertificates.processing;
    let getSiteProcessing = getSite.processing;
    let getSslCertificatesProcessing = getSslCertificates.processing;

    const { data, isCheckedLetsEncryptID } = SSLList.data;

    return (
      <>
        <SiteDashboardHeader />
        {siteListParam.q !== "" && searchFrom === endOfURL() ? (
          <>
            <div className="boxed-block">
              <div className="listing-table-block mt-60">
                <div className="table-responsive listing-table">
                  <table>
                    <tbody>
                      <SiteTableCell />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="boxed-block">
            <div className="heading-block mt-70">
              <div
                className={
                  this.state.edit === true
                    ? "heading-content edit-on"
                    : "heading-content"
                }
              >
                {this.state.edit === false ? (
                  <>
                    <h2>
                      {current_path === demo_site_path
                        ? "Demo Site"
                        : getSite?.success?.display_name}
                    </h2>
                  </>
                ) : (
                  <DisplayNameForm
                    data={
                      current_path === demo_site_path
                        ? "Demo Site"
                        : getSite?.success?.display_name
                    }
                  />
                )}
                {this.state.edit === false ? (
                  <a
                    href="javascript:void(0);"
                    onClick={() => this.setState({ edit: true })}
                  >
                    <i className="icon-edit" />
                  </a>
                ) : (
                  <>
                    <button
                      className="btn-theme btn-black"
                      onClick={this.updateDisplayName}
                      disabled={current_path === demo_site_path}
                    >
                      {i18next.t("sites.page-heading.edit.update", {
                        returnObjects: true,
                      })}
                    </button>
                    <button
                      className="btn-theme btn-black"
                      onClick={() => this.setState({ edit: false })}
                    >
                      {i18next.t("sites.page-heading.edit.cancel", {
                        returnObjects: true,
                      })}
                    </button>
                  </>
                )}
              </div>
              <div className="breadcrumb-block">
                <span>
                  <Link to={"/sites"}>
                    {i18next.t("sites.page-heading.breadcrum", {
                      returnObjects: true,
                    })}
                  </Link>
                </span>
                <span>
                  {current_path === demo_site_path
                    ? "Demo Site"
                    : getSite?.success?.display_name}
                </span>
              </div>
            </div>
            <div className="content-menu">
              <SiteDashboardLinks
                site_id={
                  current_path === demo_site_path
                    ? "demo"
                    : this.props.match.params.id
                }
              />
            </div>
            <Page title={`SSL`}>
              <div className="listing-table-block mt-60">
                <div className=" listing-table tools-table">
                  <div className="listing-ssl">
                    <div className="custom-ssl">
                      <div className="media">
                        <img src={sslSvg} alt="" className="d-light" />
                        <img src={sslSvg} alt="" className="d-dark" />
                      </div>
                      <div className="media-body">
                        <span>
                          {i18next.t("sites.inside-site.ssl.heading", {
                            returnObjects: true,
                          })}
                        </span>
                        <h4>
                          {i18next.t(
                            "sites.inside-site.ssl.generate-lets-encrypt.title",
                            { returnObjects: true }
                          )}
                        </h4>
                      </div>

                      <div className="btn-wrapper">
                        <a>
                          {sslType === "none" ? (
                            <button
                              className="btn-theme btn-outline blue-outline "
                              disabled={
                                sslType === "custom" ||
                                current_path === demo_site_path ||
                                site_status === "pending" ||
                                site_status === "updating" ||
                                site_status === "restoring"
                                  ? "disabled"
                                  : null
                              }
                              onClick={() =>
                                this.enableDisableGenerateLetsEncrypt(
                                  "letsencrypt"
                                )
                              }
                            >
                              {letsEncryptProcessing ||
                              (getSiteStatus?.success?.event ===
                                "change_site_ssl" &&
                                getSiteStatus?.success?.status ===
                                  "updating") ? (
                                <div>
                                  <Loading />
                                  {i18next.t(
                                    "sites.inside-site.ssl.generate-lets-encrypt.enable",
                                    { returnObjects: true }
                                  )}
                                </div>
                              ) : (
                                i18next.t(
                                  "sites.inside-site.ssl.generate-lets-encrypt.enable",
                                  { returnObjects: true }
                                )
                              )}
                            </button>
                          ) : (
                            sslType === "letsencrypt" && (
                              <button
                                className="btn-theme btn-outline blue-outline red-btn"
                                disabled={
                                  sslType === "custom" ||
                                  current_path === demo_site_path ||
                                  site_status === "pending" ||
                                  site_status === "updating" ||
                                  site_status === "restoring"
                                    ? "disabled"
                                    : null
                                }
                                onClick={() =>
                                  this.enableDisableGenerateLetsEncrypt("none")
                                }
                              >
                                {letsEncryptProcessing ||
                                (getSiteStatus?.success?.event ===
                                  "change_site_ssl" &&
                                  getSiteStatus?.success?.status ===
                                    "updating") ? (
                                  <div>
                                    <Loading />
                                    {i18next.t(
                                      "sites.inside-site.ssl.generate-lets-encrypt.disable",
                                      { returnObjects: true }
                                    )}
                                  </div>
                                ) : (
                                  i18next.t(
                                    "sites.inside-site.ssl.generate-lets-encrypt.disable",
                                    { returnObjects: true }
                                  )
                                )}
                              </button>
                            )
                          )}
                          {sslType === "custom" ||
                            (current_path === demo_site_path && (
                              <button className="btn-theme-disabled" disabled>
                                Activate
                              </button>
                            ))}
                          {!sslType && getSiteProcessing && (
                            <button className="btn-theme-disabled" disabled>
                              <Loading />
                            </button>
                          )}
                        </a>
                      </div>
                    </div>
                    <div className="custom-ssl">
                      <div className="media">
                        <img src={customSslImg} alt="" className="d-light" />
                        <img src={customSslImg} alt="" className="d-dark" />
                      </div>
                      <div className="media-body">
                        <span>
                          {i18next.t("sites.inside-site.ssl.heading", {
                            returnObjects: true,
                          })}
                        </span>
                        <h4>
                          {i18next.t(
                            "sites.inside-site.ssl.custom-ssl-certificate.title",
                            { returnObjects: true }
                          )}
                        </h4>
                      </div>

                      <div className="btn-wrapper">
                        <a>
                          {sslType === "none" ? (
                            <button
                              className="btn-theme btn-outline blue-outline "
                              disabled={
                                sslType === "letsencrypt" ||
                                current_path === demo_site_path ||
                                site_status === "pending" ||
                                site_status === "updating" ||
                                site_status === "restoring"
                                  ? "disabled"
                                  : null
                              }
                              onClick={() =>
                                this.enableDisableCustomSslCertificate("custom")
                              }
                            >
                              {/*fix this event name change*/}
                              {customProcessing ||
                              (getSiteStatus?.success?.event ===
                                "change_site_ssl" &&
                                getSiteStatus?.success?.status ===
                                  "updating") ? (
                                <div>
                                  {i18next.t(
                                    "sites.inside-site.ssl.custom-ssl-certificate.activate",
                                    { returnObjects: true }
                                  )}
                                  <Loading />
                                </div>
                              ) : (
                                i18next.t(
                                  "sites.inside-site.ssl.custom-ssl-certificate.activate",
                                  { returnObjects: true }
                                )
                              )}
                            </button>
                          ) : (
                            sslType === "custom" && (
                              <button
                                className="btn-theme btn-outline blue-outline red-btn"
                                disabled={
                                  sslType === "letsencrypt" ||
                                  current_path === demo_site_path ||
                                  site_status === "pending" ||
                                  site_status === "updating" ||
                                  site_status === "restoring"
                                    ? "disabled"
                                    : null
                                }
                                onClick={() =>
                                  this.enableDisableGenerateLetsEncrypt("none")
                                }
                              >
                                {customProcessing ||
                                (getSiteStatus?.success?.event ===
                                  "change_site_ssl" &&
                                  getSiteStatus?.success?.status ===
                                    "updating") ? (
                                  <div>
                                    {i18next.t(
                                      "sites.inside-site.ssl.custom-ssl-certificate.disable",
                                      { returnObjects: true }
                                    )}
                                    <Loading />
                                  </div>
                                ) : (
                                  i18next.t(
                                    "sites.inside-site.ssl.custom-ssl-certificate.disable",
                                    { returnObjects: true }
                                  )
                                )}
                              </button>
                            )
                          )}
                          {sslType === "letsencrypt" ||
                            (current_path === demo_site_path && (
                              <button className="btn-theme-disabled" disabled>
                                Activate
                              </button>
                            ))}
                          {!sslType && getSiteProcessing && (
                            <button className="btn-theme-disabled" disabled>
                              <Loading />
                            </button>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                  {sslType === "custom" && (
                    <div className="add-new-ssl-rule">
                      <div className="add-new-rule-text">
                        <h6>
                          {i18next.t(
                            "sites.inside-site.ssl.add-custom-ssl-certificate.title",
                            { returnObjects: true }
                          )}
                        </h6>

                        <p>
                          {i18next.t(
                            "sites.inside-site.ssl.add-custom-ssl-certificate.desc.1",
                            { returnObjects: true }
                          )}
                          <br />
                          {i18next.t(
                            "sites.inside-site.ssl.add-custom-ssl-certificate.desc.2",
                            { returnObjects: true }
                          )}
                          <br />
                          <br />
                          {i18next.t(
                            "sites.inside-site.ssl.add-custom-ssl-certificate.desc.3",
                            { returnObjects: true }
                          )}
                        </p>

                        {/* <div className="add-new-rule-buttons">
                                                <p>{i18next.t('sites.inside-site.ssl.add-custom-ssl-certificate.desc.3', {returnObjects: true})}
                                                    <a href="#">{i18next.t('sites.inside-site.cache.add-cache.desc.3', {returnObjects: true})}</a>
                                                </p>
                                                <div>
                                                    <button className="btn-theme btn-outline blue-outline"
                                                            onClick={this.onClickAddRule}>
                                                        {i18next.t('sites.inside-site.cache.add-cache.add-new-btn', {returnObjects: true})}
                                                    </button>
                                                </div>
                                            </div>*/}
                      </div>
                    </div>
                  )}

                  {getSite && getSslCertificatesProcessing ? (
                    <p className="text-center pt-3">
                      <Loading />
                    </p>
                  ) : (
                    <>
                      {sslType === "custom" && !SSLCertificate ? (
                        <SSLForm />
                      ) : null}

                      {/*no need to show this their*/}
                      {/*  {sslType === "custom" && SSLCertificate &&
                                                    <>
                                                        <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-50 mt-20">
                                                                <TextField name="name"
                                                                           className="form-control"
                                                                           value={SSLCertificate.name}
                                                                           label="Certificate Name"
                                                                           readonly
                                                                           />
                                                            </div>
                                                        </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="mb-50">
                                                                    <TextAreaField name="key_content"
                                                                                   className="form-control"
                                                                                   value={SSLCertificate.key}
                                                                                   label="Add .key contents"
                                                                                   readonly

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-50">
                                                                    <TextAreaField name="certificate"
                                                                                   className="form-control"
                                                                                   value={SSLCertificate.key}
                                                                                   label="Add .cert contents"
                                                                                   readonly
                                                                    />
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </>
                                                }*/}

                      <ResponsiveModalForm
                        visible={ignore_ssl.mode}
                        closeModal={() => store.dispatch(toggleSSLFormModal())}
                        sslPopup={true}
                      >
                        <SSLForm />
                      </ResponsiveModalForm>

                      {SSLCertificate && sslType === "custom" ? (
                        <div className="rule-urls table-responsive">
                          <table className="url-table mb-50">
                            <tbody>
                              <tr className="url-table-col">
                                <th className="url-table-heading">
                                  {i18next.t(
                                    "sites.inside-site.ssl.ssl-url.certificate-name",
                                    { returnObjects: true }
                                  )}
                                </th>
                                <th className="url-table-heading" id="actions">
                                  {i18next.t(
                                    "sites.inside-site.ssl.ssl-url.actions",
                                    { returnObjects: true }
                                  )}
                                </th>
                              </tr>
                              {processing ? (
                                <td colSpan={5} className="text-center">
                                  <Loading />
                                </td>
                              ) : (
                                sslType === "custom" &&
                                SSLCertificate && (
                                  <>
                                    <tr className="url-table-col">
                                      <td
                                        className="url-table-body onclick"
                                        onClick={() =>
                                          this.onEditCertificate(
                                            SSLCertificate.id
                                          )
                                        }
                                      >
                                        {SSLCertificate.name}
                                      </td>
                                      {deleteSSLCertificatesProcessing ? (
                                        <td
                                          className="url-table-body"
                                          id="actions"
                                        >
                                          Deleting
                                          <Loading />
                                        </td>
                                      ) : (
                                        <td
                                          className="url-table-body"
                                          id="actions"
                                        >
                                          <div className="dropdown dropdown-alt text-center">
                                            <span
                                              className="icon-more"
                                              data-toggle="dropdown"
                                            ></span>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton"
                                            >
                                              <a
                                                href="javascript:void(0);"
                                                className="dropdown-item"
                                                type="button"
                                                onClick={() =>
                                                  this.onEditCertificate(
                                                    SSLCertificate.id
                                                  )
                                                }
                                              >
                                                {" "}
                                                Edit
                                              </a>
                                              <a
                                                href="javascript:void(0);"
                                                className="dropdown-item"
                                                type="button"
                                                onClick={() =>
                                                  this.onDeleteCertificate(
                                                    SSLCertificate.id
                                                  )
                                                }
                                              >
                                                {" "}
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                          {/*<span className="icon-more " data-toggle="dropdown" onClick={this.onClickIconMore()}/>*/}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )
                              )}
                              {!SSLCertificate ? (
                                <td colSpan={3} className="text-center  pt-4">
                                  No Certificate
                                </td>
                              ) : null}
                            </tbody>
                          </table>

                          {SSLCertificate && sslType === "custom" ? (
                            <div className="button-wrapper text-right mt-30">
                              <button
                                type="submit"
                                className="btn-theme btn-sm "
                                onClick={this.onGenerateCustomSSL}
                                disabled={
                                  createGenerateCustomSslProcessing ||
                                  site_status === "pending" ||
                                  site_status === "updating" ||
                                  site_status === "restoring"
                                    ? "disabled"
                                    : null
                                }
                              >
                                {createGenerateCustomSslProcessing ||
                                (getSiteStatus?.success?.event ===
                                  "generate_custom_ssl" &&
                                  getSiteStatus?.success?.status ===
                                    "updating") ? (
                                  <>
                                    Generating{" "}
                                    <Loading
                                      className="sm-loader-position"
                                      height="15"
                                      width="15"
                                    />
                                  </>
                                ) : (
                                  "Generate"
                                )}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  )}

                  {sslType === "letsencrypt" ? (
                    <div className="rule-urls">
                      <table className="url-table mb-50">
                        <tbody>
                          <tr className="url-table-col">
                            <th className="url-table-heading">
                              {i18next.t(
                                "sites.inside-site.ssl.ssl-url.select-domain",
                                { returnObjects: true }
                              )}
                            </th>
                            <th className="url-table-heading" id="actions">
                              {i18next.t(
                                "sites.inside-site.ssl.ssl-url.select",
                                { returnObjects: true }
                              )}
                            </th>
                          </tr>
                          {processing ? (
                            <tr className="url-table-col">
                              <td colSpan={5} className="text-center">
                                <Loading />
                              </td>
                            </tr>
                          ) : (
                            sslType === "letsencrypt" &&
                            domainLists &&
                            domainLists.length &&
                            domainLists
                              .filter(
                                (n) => !n.name.includes(".keplerhosting.cloud")
                              )
                              .map((domainName, index) => (
                                <>
                                  <tr className="url-table-col">
                                    <td className="url-table-body">
                                      {domainName.name}{" "}
                                      {"[Expiry Date:" +
                                        fullDateStringNoTime(
                                          domainName.expiry_date
                                        ) +
                                        "]"}
                                      {domainName.ssl_type === "letsencrypt" ? (
                                        <span className="badge badge-primary">
                                          {domainName.ssl_type}
                                        </span>
                                      ) : null}
                                    </td>
                                    <td className="url-table-body" id="actions">
                                      {(site_status === "pending" ||
                                        site_status === "updating" ||
                                        site_status === "restoring") &&
                                      event === "generate_ssl" &&
                                      letsEncryptgeneratingDomainsId &&
                                      letsEncryptgeneratingDomainsId.includes(
                                        domainName.id
                                      ) ? (
                                        <>
                                          Generating <Loading />
                                        </>
                                      ) : (
                                        <CheckField
                                          name="name"
                                          className="form-control"
                                          value={domainName.id}
                                          id={domainName.id}
                                          // checked={[...this.state.data].includes((domainName.id).toString())}
                                          checked={
                                            [...data].includes(
                                              domainName.id.toString()
                                            ) ||
                                            isCheckedLetsEncryptID.includes(
                                              domainName.id.toString()
                                            )
                                          }
                                          noLabel
                                          onChange={(e) =>
                                            this.onChangeLetsEncrypt(
                                              index,
                                              e.target.value,
                                              e
                                            )
                                          }
                                          disabled={
                                            site_status === "pending" ||
                                            site_status === "updating" ||
                                            site_status === "restoring"
                                              ? "disabled"
                                              : null
                                          }
                                        />
                                      )}
                                      {/* <CheckField name="name"
                                                                                    className="form-control"
                                                                                    value={domainName.id}
                                                                                    id={domainName.id}
                                                                            // item={domainName.ssl_type === "letsencrypt" ? domainName.id : null}
                                                                            // checked={domainName.ssl_type === "letsencrypt" ? domainName.id : null}
                                                                                    checked={[...this.state.data].includes((domainName.id).toString())}
                                                                                    noLabel
                                                                                    onChange={(e) => this.onChange(index, e.target.value, e)}/>*/}
                                    </td>
                                  </tr>
                                </>
                              ))
                          )}

                          {domainListsExceptKeplerCloud &&
                          !domainListsExceptKeplerCloud.length &&
                          !processing ? (
                            <tr className="url-table-col">
                              <td colSpan={2} className="text-center  pt-4">
                                No Domains
                              </td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                      {domainListsExceptKeplerCloud &&
                      domainListsExceptKeplerCloud.length &&
                      sslType === "letsencrypt" ? (
                        <div className="button-wrapper text-right mt-30">
                          <button
                            type="submit"
                            className="btn-theme btn-sm "
                            onClick={this.onGenerateSSL}
                            disabled={
                              createGenerateSslProcessing ||
                              site_status === "pending" ||
                              site_status === "updating" ||
                              site_status === "restoring"
                                ? "disabled"
                                : null
                            }
                          >
                            {createGenerateSslProcessing ||
                            (getSiteStatus?.success?.event === "generate_ssl" &&
                              getSiteStatus?.success?.status === "updating") ? (
                              <>
                                Generating <Loading />
                              </>
                            ) : (
                              "Generate"
                            )}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {/* {SSLCertificate && sslType === `custom`?
                                        <div className="rule-urls">
                                            <table className="url-table mb-50">
                                                <tbody>
                                                <tr className="url-table-col">
                                                    <th className="url-table-heading">{i18next.t('sites.inside-site.ssl.ssl-url.select-domain', {returnObjects: true})}</th>
                                                    <th className="url-table-heading"
                                                        id="actions">{i18next.t('sites.inside-site.ssl.ssl-url.select', {returnObjects: true})}</th>
                                                </tr>
                                                {
                                                    processing ?
                                                        <td colSpan={5} className="text-center"><Loading/></td>
                                                        :
                                                         domainLists && domainLists.length && domainLists.filter(n => !n.name.includes('.keplerhosting.cloud')).map((domainName, index) => (
                                                            <>
                                                                <tr className="url-table-col">
                                                                    <td className="url-table-body">{domainName.name}
                                                                    {domainName.ssl_type ==="custom"?
                                                                        <span className="badge badge-primary">{domainName.ssl_type}</span>:null}</td>
                                                                    <td className="url-table-body" id="actions">
                                                                        {(site_status === 'pending' || site_status === 'updating' || site_status === 'restoring') &&
                                                                        event ===  'generate_ssl' &&    letsEncryptgeneratingDomainsId && letsEncryptgeneratingDomainsId.includes(domainName.id) ?
                                                                            <>Generating <Loading/></> :
                                                                            <CheckField name="name"
                                                                                        className="form-control"
                                                                                        value={domainName.id}
                                                                                        id={domainName.id}
                                                                                        checked={[...this.state.dataCustom].includes((domainName.id).toString())}
                                                                                        noLabel
                                                                                        onChange={(e) => this.onChangeCustom(index, e.target.value, e)}
                                                                                        disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))}

                                                {domainListsExceptKeplerCloud && !domainListsExceptKeplerCloud.length ?
                                                    <td colSpan={3} className="text-center  pt-4">No Domains</td>
                                                    : null}
                                                </tbody>
                                            </table>
                                            {(domainListsExceptKeplerCloud && domainListsExceptKeplerCloud.length)
                                            && sslType === 'custom'
                                                ?
                                                <div className="button-wrapper text-right mt-30">
                                                    <button type="submit" className="btn-theme btn-sm "
                                                            onClick={this.onGenerateCustomSSL}
                                                            disabled={createGenerateSslProcessing || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                    >
                                                        {createGenerateSslProcessing || (getSiteStatus?.success?.event === "generate_ssl" && getSiteStatus?.success?.status === "updating") ? <>
                                                            Generating <Loading/></> : "Generate"}
                                                    </button>
                                                </div>
                                                :
                                                null}
                                        </div>:null}*/}
                </div>
              </div>
              <SSLWorker sslType={sslType} />
            </Page>
          </div>
        )}
        {/*</Page>*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    getSite,
    getSites,
    getSiteStatus,
    siteDashboard,
    siteList,
    getSslCertificates,
    SSLList,
    createGenerateLetsEncrypt,
    createCustomSslCertificate,
    deleteSSLCertificates,
    getSiteDomainNoPagination,
    createGenerateSsl,
    createGenerateCustomSsl,
  } = state;
  let { success } = getSiteDomainNoPagination;

  let domainLists = success && success.data && success.data.data;

  let letsEncryptgeneratingDomainsId =
    getSiteStatus && getSiteStatus.success && getSiteStatus.success.domain_id;
  let event =
    getSiteStatus && getSiteStatus.success && getSiteStatus.success.event;

  let SSLCertificate =
    getSslCertificates &&
    getSslCertificates.success &&
    getSslCertificates.success.data &&
    getSslCertificates.success.data.data;

  let sslType = getSite && getSite.success && getSite.success.ssl_type;

  let { site_status } = siteDashboard.data;
  let searchFrom = siteList?.data.searchFrom;
  let { params: siteListParam, payload } = siteList.data;
  let { ignore_ssl } = SSLList.data;

  return {
    getSite,
    getSslCertificates,
    getSites,
    getSiteStatus,
    site_status,
    siteDashboard,
    searchFrom,
    SSLList,
    siteListParam,
    payload,
    SSLCertificate,
    ignore_ssl,
    createGenerateLetsEncrypt,
    createCustomSslCertificate,
    deleteSSLCertificates,
    sslType,
    domainLists,
    createGenerateSsl,
    createGenerateCustomSsl,
    letsEncryptgeneratingDomainsId,
    event,
  };
}

export default withRouter(connect(mapStateToProps)(SSL));
