import axios from 'axios';
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken, logOut } from '../../../helpers/LocalStorageHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success)
{
  return { type: 'GET_BACKUP_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_BACKUP_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_BACKUP_PROCESSING', processing };
}

function getBackup( )
{
  return ( dispatch , getState ) =>
  {
    dispatch(_processing(true));
      let { backupList } = getState();
      let { params, site_id } = backupList.data;
      const lang = localStorage.getItem('lang') || 'en'


      axios({
      url: apiBaseUrl(`customer/sites/${site_id}/backups?type=others`),
      method: "get",
      dataType: 'json',
          params,
      headers: { 'Authorization': 'Bearer ' +loginToken() ,
          'lang': lang}
    })
      .then(function(res){
          dispatch(_success(res.data.data));
          dispatch(_processing( false ));
      }).catch(function( error ){
           handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
      });


  }
}


export default getBackup;