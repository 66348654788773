import React from 'react';
import CardSetupForm from "./CardSetupForm";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import BillingWorker from './BillingWorker'
import {connect} from "react-redux";
import {STRIPE_KEY} from '../../../stripeConfig'

class Billing extends React.Component {
    render() {
        let {client_secret, customer_name, mode} = this.props;
        let {key} = STRIPE_KEY;
        const stripePromise = loadStripe(key);

        return (
            <>
                <BillingWorker/>
                <Elements stripe={stripePromise}>
                    <CardSetupForm client_secret={client_secret} customer_name={customer_name} mode={mode}/>
                </Elements>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {fetchIntent, getLoggedInUser, route} = state;
    let customer_name = getLoggedInUser?.success?.data?.data.first_name
    let client_secret = fetchIntent?.success?.data?.intent?.client_secret

    return {fetchIntent, client_secret, getLoggedInUser, customer_name, route};
}


export default connect(mapStateToProps)(Billing);
