import {CacheState} from './Cache-State'

export default function cacheListReducer(state = CacheState, action) {

    let {data} = {...state};

    switch (action.type) {

        case 'TOGGLE-CACHE-IGNORE-FORM-MODAL':
            if (data.ignore_cache.mode === true) { // if open, close
                data.ignore_cache = {mode: ``,}
            } else { // if close, open
                data.ignore_cache.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}
