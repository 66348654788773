import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { loggedIn } from "../../helpers/LocalStorageHelper";
import { endOfURL } from "../../helpers/UrlHelper";
import store from "../../Store";
import LoginRoutes from "../LoginRoutes";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { setUser } from "./MainRoute-Actions";
import AdminRoutes from "../AdminRoutes";
import CustomerRoutes from "../CustomerRoutes";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";
import NotFound from "../../components/shared/pages/NotFound";
import NoCard from "../../components/shared/pages/NoCard";
import DisabledBilling from "../../components/shared/pages/DisabledBilling";
import RedirectRoutes from "../RedirectRoutes";
import NotVerifiedRedirect from "../../components/shared/pages/NotVerifiedRedirect";

class MainRoute extends React.Component {
  componentDidMount() {
    const current_path = this.props.location.pathname;
    if (current_path === "/se/login") {
      localStorage.setItem("lang", "sv");
      // window.location.reload();
    }

    if (endOfURL() === "analytics" || endOfURL() === "requested") {
      document.body.classList.add("developing");
    } else {
      document.body.classList.remove("developing");
    }

    if (loggedIn()) {
      store.dispatch(getLoggedInUser());
    }
  }

  removeClassDeveloping() {
    document.body.classList.remove("developing");
  }

  componentDidUpdate(prev) {
    if (endOfURL() !== "requested" && endOfURL() !== "analytics") {
      this.removeClassDeveloping();
    }

    if (this.props.getLoggedInUser !== prev.getLoggedInUser) {
      let { success } = this.props.getLoggedInUser;
      if (success) {
        store.dispatch(setUser(success.data.data));
      }
    }
  }

  render() {
    let { action, role, getLoggedInUser } = this.props;
    let { success } = getLoggedInUser;
    if (action === "render-404") {
      return <NotFound />;
    } else {
      return loggedIn() ? (
        <>
          <NotVerifiedRedirect />
          <DisabledBilling />
          <NoCard />
          <Header />
          <section>
            <div className="container-fluid p-0">
              <Sidebar />
              <div className="content-area-wrapper">
                <div className="content-area">
                  {/* {(role === 'organization-owner') &&
                                            <Route path="/" component={AdminRoutes}/>
                                            }{(role === 'customer-role') &&
                                        <Route path="/" component={CustomerRoutes}/>
                                        } */}
                  {(role === "organization-owner" ||
                    role === "customer-role") && (
                    <Route path="/" component={AdminRoutes} />
                  )}
                </div>
                <div className="fixed-footer">
                  <p>Developed with love by - Kepler Technologies AB</p>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {/*< RedirectRoutes/>*/}
          <LoginRoutes />
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  let { route, loginForm, getLoggedInUser } = state;

  let { action, routes_url } = route.data;

  let role = route && route.data.user && route.data.user.role.slug;

  let { redirectTo } = loginForm.data;

  return {
    route,
    action,
    loginForm,
    redirectTo,
    routes_url,
    getLoggedInUser,
    role,
  };
}

export default withRouter(connect(mapStateToProps)(MainRoute));
