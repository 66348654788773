import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../../Store';
import IgnoreCacheFormWorker from './IgnoreCacheFormWorker'
import {
    onIgnoreCacheFormChange,
    onIgnoreCacheFormSubmit
} from "./IgnoreCacheForm-Action";
import TextField from "../../../../../components/shared/form/TextFieldIgnoreCacheForm";
import Loading from "../../../../../components/shared/loaders/Loading";
import {toggleCacheIgnoreFormModal} from "../../list/Cache-Action";
import {secondToLastItemInURL} from "../../../../../helpers/UrlHelper";
import i18next from 'i18next'
import {withRouter} from "react-router";

class IgnoreCacheForm extends React.Component {

    ignoreCacheFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onIgnoreCacheFormSubmit());
    };

    onChange = (e) => {
        let value = e.target.value.replace(/[!@$%^*()\[\]{};':"\\|,.<>]+/,"")
        store.dispatch(onIgnoreCacheFormChange(e.target.name, value));
    };

    render() {
        let {
            errors,
            site_status,
            path,
            domainLists,
            cacheIgnore,
            getCacheIgnore,
            mode
        } = this.props;
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/cache'
        const primaryDomain = (domainLists || []).find(d => d.is_primary === 1) || '';

        let {processing} = cacheIgnore
        let getCacheIgnoreProcessing = getCacheIgnore.processing

        return (
            <>
                <IgnoreCacheFormWorker/>
                {getCacheIgnoreProcessing ?
                    <div className="content-form-block light-gray-bg">
                        <h2>{i18next.t('sites.inside-site.cache.add-cache.add-ignore.updateTitle', {returnObjects: true})}
                            <Loading/></h2>
                    </div>
                    :
                    <div className="content-form-block light-gray-bg">
                        {mode === 'create' ?
                            <h2>{i18next.t('sites.inside-site.cache.add-cache.add-ignore.title', {returnObjects: true})}</h2> :
                            <h2>{i18next.t('sites.inside-site.cache.add-cache.add-ignore.updateTitle', {returnObjects: true})}</h2>
                        }
                        <form className="mt-30 domain-form" onSubmit={this.ignoreCacheFormSubmit}>
                            <div className="ignore-cache-primarydomain d-flex">
                                <TextField name="path"
                                           className="form-control"
                                           type="text"
                                           // value={path.replace('/','')}
                                           value={path}
                                           primaryDomainName={current_path === demo_site_path ? "keplerdemo.keplerhosting.cloud" : primaryDomain.name}
                                           placeholder={i18next.t('sites.inside-site.cache.add-cache.add-ignore.title', {returnObjects: true})}
                                           noLabel
                                           errors={errors.path}
                                           onChange={(e) => this.onChange(e)}/>
                            </div>

                            <div className="button-wrapper inline-buttons text-center">
                                <button className="btn-theme btn-outline" type="reset"
                                        onClick={() => store.dispatch(toggleCacheIgnoreFormModal())}>{i18next.t('sites.inside-site.cache.add-cache.add-ignore.cancel', {returnObjects: true})}
                                </button>
                                <button type="submit" className="btn-theme btn-blue"
                                        disabled={secondToLastItemInURL() === "demo" || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>

                                    {/*{processing ? <Loading/> : `Submit`}*/}
                                    {processing ?
                                        <>
                                            {i18next.t('sites.inside-site.cache.add-cache.add-ignore.submit', {returnObjects: true})}
                                            <Loading/> </>
                                        : i18next.t('sites.inside-site.cache.add-cache.add-ignore.submit', {returnObjects: true})}
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </>
        )
    }
}

function mapStateToProps(state)
{
    let {ignoreCacheForm, getSite, getSiteStatus, siteDashboard, getSiteDomain, cacheIgnore, getCacheIgnore} = state;

    let {site_status} = siteDashboard.data;
    let domainLists =
        getSiteDomain &&
        getSiteDomain.success &&
        getSiteDomain.success.data &&
        getSiteDomain.success.data.data &&
        getSiteDomain.success.data.data.data;
    let ignore_cache_event =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.event
    let site_search_status_message =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.message

    let {errors, payload,mode} = ignoreCacheForm.data;

    let {
        path
    } = payload;

    return {
        ignoreCacheForm,
        path,
        errors,
        ignore_cache_event,
        getSite,
        getSiteStatus,
        site_search_status_message,
        site_status,
        getSiteDomain,
        domainLists,
        cacheIgnore,
        getCacheIgnore,
        mode
    };
}

export default withRouter(connect(mapStateToProps)(IgnoreCacheForm));