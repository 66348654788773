import {LogsState} from './Logs-State'

export default function logsListReducer(state = LogsState, action) {

    let {data} = {...state};
    let {name, value, site_id} = action;

    switch (action.type) {

        case 'TOGGLE-LOGS-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            return {...state, data};

        case 'SET-SITEID-LOGS':
            data.site_id = +site_id;
            return {...state, data};
        
        case 'SET-LOGS-ERRORCLICK':
            data.params.type = "error";
            return {...state, data};

        case 'SET-LOGS-ACCESSCLICK':
            data.params.type = "access";
            return {...state, data};

        default:
            return state
    }
}

