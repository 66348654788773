import React from 'react';
import store from '../../../../Store';
import {setSftpID} from "./Sftp-Action";
import getCredentials from "../../../../ajax/actions/sftp-database/credential_get";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getSite from "../../../../ajax/actions/sites/site_get"
import getSites from "../../../../ajax/actions/sites/sites_get";
import {secondToLastItemInURL} from "../../../../helpers/UrlHelper";

class SftpWorker extends React.Component {

    constructor(props) {
        super(props);
        if(secondToLastItemInURL() === 'demo'){
        }
        else {
            store.dispatch(setSftpID(props.match.params.id))
            // store.dispatch(getCredentials(props.match.params.id));
            // store.dispatch(getSite(props.match.params.id))
            // store.dispatch(getSites())
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.getCredentials.data !== prevProps.getCredentials.data) {
            store.dispatch(getCredentials(this.props.match.params.id));
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getSite,getDemoSites,getCredentials} = state;

    return {getSite,getDemoSites,getCredentials};
}

export default withRouter(connect(mapStateToProps)(SftpWorker));