import {endOfURL} from '../../helpers/UrlHelper'

const DefaultMigrationState = {
    payload: {},
    currentStep: 0,
    message: ``,
    errors: {},
    errorsPresent: false,
    validateFirst: false,
    validateSecond: false,
    serverValidationErrors: {},
    mode: endOfURL(),
    submitted: {
        step0: false,
        step1: false,
        step2: false,
    },
    initialLoading:true
};

export const MigrationFormState = {data: DefaultMigrationState}

