import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess,validationError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";
import Alert from "react-s-alert";
import {ConfigErrorAlert} from "../../../Config";
import getCards from "./cards_get";

function _success(success) {
    return {type: 'UPDATE_DEFAULT_CARD_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_DEFAULT_CARD_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_DEFAULT_CARD_PROCESSING', processing}
}

function updateDefaultCard(card_id) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        axios({
            url: apiBaseUrl(`customer/cards/${card_id}/default`),
            method: "post",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess("Card Updated Successfully")
                store.dispatch(getCards());

            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)
            if (error.response && error.response.status === 422) {
                validationError(error.response.data.message,error.response.status)
                // Alert.error(`Error ${422} - Validation Error `, ConfigErrorAlert);
                scrollToError();
            }
        });
    }
}
export default updateDefaultCard;
