import React from 'react';
import restartSvg from "../../../../assets/images/restart-new.svg";
import wpDebugSvg from "../../../../assets/images/wp_debug.svg";
import monitorSvg from "../../../../assets/images/monitor-new.svg";
import {connect} from "react-redux";
import store from "../../../../Store";
import monitorPost from "../../../../ajax/actions/kepler-tools/monitor_post";
import {withRouter} from 'react-router';
import FeatureBlockWorker from "./FeatureBlockWorker";
import {
    togglePublicPathFormModal,
    toggleSearchAndReplaceFormModal,
    togglePhpVersionFormModal,
    togglePasswordProtectionFormModal,
    // toggleForceSslToolFormModal
} from "./FeatureBlock-Action";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import PublicPathForm from "../form/PublicPathForm";
import PublicPathPrimaryView from "./PublicPathPrimaryView";
import restartPhp from "../../../../ajax/actions/kepler-tools/restart_php_post";
import Loading from "../../../../components/shared/loaders/Loading";
import debug from "../../../../ajax/actions/kepler-tools/debug_post";
import SearchAndReplaceForm from "../../searchandreplace/form/SearchAndReplaceForm";
import SearchAndReplacePrimaryView from "./SearchAndReplacePrimaryView";
import SiteDashboardHeader from "../../SiteDashboardHeader";
import Page from "../../../../components/shared/pages/Page";
import SiteDashboardLinks from "../../SiteDashboardLinks";
import {endOfURL} from "../../../../helpers/UrlHelper";
import SiteTableCell from "../../list/SiteTableCell";
import DisplayNameForm from "../../change-display-name/form/DisplayNameForm";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import {Link} from "react-router-dom";
import PhpVersionPrimaryView from "./PhpVersionPrimaryView";
import PhpVersionForm from "../../phpVersion/form/PhpVersionForm"
import PasswordProtectionPrimaryView from "./PasswordProtectionPrimaryView";
import PasswordProtectionForm from "../../passwordProtection/form/PasswordProtectionForm";
import i18next from 'i18next';
// import ForceSslForm from "../../Enableforcinghttps/form/ForceSslForm";
// import ForceSslToolPrimaryView from "./ForceSslToolPrimaryView";
import forceSslPost from "../../../../ajax/actions/kepler-tools/force_ssl_post"
import sslSvg from "../../../../assets/images/ssl.svg";
import createReactConfiguration from "../../../../ajax/actions/kepler-tools/react_configuration_post"


class FeatureBlock extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
    }
    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.setState({edit: false});
    }

    enabledisablemonitor = (value) => {
        const payload = {
            "monitor": value
        }
        store.dispatch(monitorPost(payload, this.props.match.params.id));
    }

    enableDisableForceSsl = (value) => {
        const payload = {
            "force_ssl": value
        }
        store.dispatch(forceSslPost(payload, this.props.match.params.id));
    }

    enabledisableReactConfiguration = (value) => {
        const payload = {
            "react_flag": value
        }
        store.dispatch(createReactConfiguration(payload, this.props.match.params.id));

    }

    debug = (value) => {
        const payload = {
            "debug": value
        }
        store.dispatch(debug(payload, this.props.match.params.id));
    }

    restartPhp = () => {
        store.dispatch(restartPhp(this.props.match.params.id));
    }

    render() {
        let {
            monitor,
            reactConfiguration,
            debugMode,
            modal,
            domainList,
            restartPhp,
            debug,
            createMonitor,
            php_version,
            password_protection,
            force_ssl,
            public_path_modal,
            site_status,
            getSiteStatus,
            debug_event,
            wp_flag,
            getSite,
            siteListParam,
            searchFrom,
            event,
            createForceSsl,
            createReactConfiguration
        } = this.props
        let {processing} = getSite
        let restartPhpProcessing = restartPhp.processing
        let debugProcessing = debug.processing
        let monitorProcessing = createMonitor.processing
        let forceSSLProcessing = createForceSsl.processing
        let reactConfigurationProcessing = createReactConfiguration.processing

        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/tools'


        return (
            <>
                <SiteDashboardHeader/>
                {siteListParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    current_path !== demo_site_path ?
                        <>
                            <div className="boxed-block">

                                {/*heading block*/}
                                <div className="heading-block mt-70">
                                    <div
                                        className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                        {this.state.edit === false ? <>
                                                <h2>{getSite?.success?.display_name}</h2></> :
                                            <DisplayNameForm data={getSite?.success?.display_name}/>}
                                        {this.state.edit === false ?
                                            <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                                <i className="icon-edit"/></a> : <>
                                                <button className="btn-theme btn-black"
                                                        onClick={this.updateDisplayName}>{i18next.t('sites.page-heading.edit.update', {returnObjects: true})}
                                                </button>
                                                <button className="btn-theme btn-black"
                                                        onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel', {returnObjects: true})}
                                                </button>
                                            </>}
                                    </div>
                                    <div className="breadcrumb-block">
                                        <span><Link
                                            to={'/sites'}>{i18next.t('sites.page-heading.breadcrum', {returnObjects: true})}</Link></span>
                                        <span>{getSite?.success?.display_name}</span>
                                    </div>
                                </div>
                                <div className="content-menu">
                                    <SiteDashboardLinks site_id={this.props.match.params.id}/>
                                </div>
                                {/*End heading block*/}

                                <Page title={i18next.t('sites.inside-site.site-nav.tools', {returnObjects: true})}>

                                    <div className="listing-table-block mt-60">
                                        <div className="table-responsive listing-table tools-table">
                                            <table>
                                                <tbody>

                                                {/*Force SSL*/}
                                                <tr>
                                                    <td>
                                                        <div className="media">
                                                            <div className="media-img d-flex mr-3">
                                                                <img src={sslSvg} alt="" className="d-light"/>
                                                                <img src={sslSvg} alt="" className="d-dark"/>
                                                            </div>
                                                            <div className="media-body">
                                                                <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                                <h4>{i18next.t('sites.inside-site.tools.site-tools.8.title', {returnObjects: true})}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>{i18next.t('sites.inside-site.tools.site-tools.8.desc', {returnObjects: true})}</p>
                                                    </td>
                                                    <td>
                                                        {/*<div className="btn-wrapper">*/}
                                                        {/*    <button className="btn-theme btn-outline blue-outline" data-toggle="modal"*/}
                                                        {/*            data-target="#public-path"*/}
                                                        {/*            onClick={() => store.dispatch(toggleForceSslToolFormModal())}*/}
                                                        {/*            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}*/}
                                                        {/*    >*/}
                                                        {/*        /!*Check this event name*!/*/}
                                                        {/*        {event === "change_force_ssl" ?*/}
                                                        {/*            <>{i18next.t('sites.inside-site.tools.change' ,{returnObjects: true})} <Loading/></>*/}
                                                        {/*            :*/}
                                                        {/*            i18next.t('sites.inside-site.tools.change' ,{returnObjects: true})*/}
                                                        {/*        }*/}

                                                        {/*    </button>*/}
                                                        {/*</div>*/}
                                                        <a href="javascript:void(0)">
                                                            {force_ssl && force_ssl === 1 ?
                                                                <button className="btn-theme btn-outline blue-outline"
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.enableDisableForceSsl(0)}>
                                                                    {forceSSLProcessing || event === "force_ssl_disable" ? <>
                                                                        {i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}
                                                                        <Loading/></> : i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}</button>
                                                                :
                                                                <button className="btn-theme btn-outline blue-outline"
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.enableDisableForceSsl(1)}>
                                                                    {forceSSLProcessing || event === "force_ssl_enable" ? <>
                                                                        {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                                        <Loading/></> : `${i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}`}</button>
                                                            }

                                                        </a>
                                                    </td>
                                                </tr>
                                                {/*End Force SSL*/}

                                                {/*Monitoring*/}
                                                <tr>
                                                    <td>
                                                        <div className="media">
                                                            <div className="media-img d-flex mr-3">
                                                                <img src={monitorSvg} alt="" className="d-light"/>
                                                                <img src={monitorSvg} alt="" className="d-dark"/>
                                                            </div>
                                                            <div className="media-body">
                                                                <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                                <h4>{i18next.t('sites.inside-site.tools.site-tools.1.title', {returnObjects: true})}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>{i18next.t('sites.inside-site.tools.site-tools.1.desc', {returnObjects: true})}</p>
                                                    </td>
                                                    <td>
                                                        {/*<div className="btn-wrapper">*/}
                                                        <a href="javascript:void(0)">
                                                            {monitor && monitor === 1 ?
                                                                <button className="btn-theme btn-outline blue-outline"
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.enabledisablemonitor(0)}>
                                                                    {monitorProcessing ? <>
                                                                        {i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}
                                                                        <Loading/></> : i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}</button> :
                                                                <button className="btn-theme btn-outline blue-outline"
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.enabledisablemonitor(1)}>
                                                                    {monitorProcessing ? <>
                                                                        {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                                        <Loading/></> : `${i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}`}</button>
                                                            }

                                                        </a>
                                                    </td>
                                                </tr>
                                                {/*End Monitoring*/}

                                                {/*password Protection*/}
                                                <ResponsiveModalForm visible={password_protection.mode}
                                                                     closeModal={() => store.dispatch(togglePasswordProtectionFormModal())}>
                                                    <PasswordProtectionForm/>
                                                </ResponsiveModalForm>
                                                <PasswordProtectionPrimaryView/>
                                                {/*End password protection*/}

                                                {/*php version*/}
                                                <ResponsiveModalForm visible={php_version.mode}
                                                                     closeModal={() => store.dispatch(togglePhpVersionFormModal())}>
                                                    <PhpVersionForm/>
                                                </ResponsiveModalForm>
                                                <PhpVersionPrimaryView/>
                                                {/*end php version*/}

                                                {/*can be reused for Http Enabling*/}


                                                {/*<ResponsiveModalForm visible={force_ssl.mode}*/}
                                                {/*                     closeModal={() => store.dispatch(toggleForceSslToolFormModal())}>*/}
                                                {/*    <ForceSslForm/>*/}
                                                {/*</ResponsiveModalForm>*/}
                                                {/*<ForceSslToolPrimaryView/>*/}

                                                {/*public path*/}
                                                <ResponsiveModalForm visible={public_path_modal.mode}
                                                                     closeModal={() => store.dispatch(togglePublicPathFormModal())}>
                                                    <PublicPathForm/>
                                                </ResponsiveModalForm>
                                                <PublicPathPrimaryView/>
                                                {/*End public Path*/}

                                                {/*Restart Php*/}
                                                <tr>
                                                    <td>
                                                        <div className="media">
                                                            <div className="media-img d-flex mr-3">
                                                                <img src={restartSvg} alt="" className="d-light"/>
                                                                <img src={restartSvg} alt="" className="d-dark"/>
                                                            </div>
                                                            <div className="media-body">
                                                                <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                                <h4>{i18next.t('sites.inside-site.tools.site-tools.5.title', {returnObjects: true})}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>{i18next.t('sites.inside-site.tools.site-tools.5.desc', {returnObjects: true})}</p>
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            {/*<a href="" className="btn-theme btn-outline blue-outline">{i18next.t('sites.inside-site.tools.enable' ,{returnObjects: true})}</a>*/}
                                                            <a>
                                                                <button className="btn-theme btn-outline blue-outline "
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.restartPhp()}>
                                                                    {restartPhpProcessing || (getSiteStatus?.success?.event === "restart_php_fpm" && getSiteStatus?.success?.status) === "updating" ?
                                                                        <div>{i18next.t('sites.inside-site.tools.site-tools.5.restart', {returnObjects: true})}
                                                                            <Loading/></div> :
                                                                        `${i18next.t('sites.inside-site.tools.site-tools.5.restart', {returnObjects: true})}`}
                                                                </button>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/*End Restart pgp */}

                                                {/*search and replace*/}
                                                <ResponsiveModalForm visible={modal.mode}
                                                                     closeModal={() => store.dispatch(toggleSearchAndReplaceFormModal())}>
                                                    <SearchAndReplaceForm/>
                                                </ResponsiveModalForm>
                                                <SearchAndReplacePrimaryView/>
                                                {/*End Search and replace*/}

                                                {/*Wp flag*/}
                                                {!processing && wp_flag === 1 ?
                                                    <tr>
                                                        <td>
                                                            <div className="media">
                                                                <div className="media-img d-flex mr-3">
                                                                    <img src={wpDebugSvg} alt="" className="d-light"/>
                                                                    <img src={wpDebugSvg} alt="" className="d-dark"/>
                                                                </div>
                                                                <div className="media-body">
                                                                    <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                                    <h4>{i18next.t('sites.inside-site.tools.site-tools.7.title', {returnObjects: true})}</h4>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {i18next.t('sites.inside-site.tools.site-tools.7.desc.1', {returnObjects: true})}
                                                                <br/>
                                                                {i18next.t('sites.inside-site.tools.site-tools.7.desc.2', {returnObjects: true})}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="btn-wrapper">
                                                                <a href="javascript:void(0)">
                                                                    {debugMode && debugMode === 1 ?
                                                                        <button
                                                                            className="btn-theme btn-outline blue-outline"
                                                                            type="button"
                                                                            disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                            onClick={() => this.debug(0)}>
                                                                            {debugProcessing || debug_event === "disable_debug" ? <>
                                                                                {i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}
                                                                                <Loading/></> : `${i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}`}</button> :
                                                                        <button
                                                                            className="btn-theme btn-outline blue-outline"
                                                                            type="button"
                                                                            disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                            onClick={() => this.debug(1)}>
                                                                            {debugProcessing || debug_event === "enable_debug" ? <>
                                                                                {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                                                <Loading/></> : `${i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}`}</button>
                                                                    }

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr> : null}
                                                {/*end wpFlag*/}

                                                <tr>
                                                    <td>
                                                        <div className="media">
                                                            <div className="media-img d-flex mr-3">
                                                                <img src={monitorSvg} alt="" className="d-light"/>
                                                                <img src={monitorSvg} alt="" className="d-dark"/>
                                                            </div>
                                                            <div className="media-body">
                                                                <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                                <h4>{i18next.t('sites.inside-site.tools.site-tools.9.title', {returnObjects: true})}</h4>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p>{i18next.t('sites.inside-site.tools.site-tools.9.desc', {returnObjects: true})}</p>
                                                    </td>
                                                    <td>
                                                        {/*<div className="btn-wrapper">*/}
                                                        <a href="javascript:void(0)">
                                                            {reactConfiguration && reactConfiguration === 1 ?
                                                                <button className="btn-theme btn-outline blue-outline"
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.enabledisableReactConfiguration(0)}>
                                                                    {reactConfigurationProcessing  || event === "change_react_config_flag"? <>
                                                                        {i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}
                                                                        <Loading/></> : i18next.t('sites.inside-site.tools.disable', {returnObjects: true})}</button> :
                                                                <button className="btn-theme btn-outline blue-outline"
                                                                        disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                        onClick={() => this.enabledisableReactConfiguration(1)}>
                                                                    {reactConfigurationProcessing || event === "change_react_config_flag" ? <>
                                                                        {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                                        <Loading/></> : `${i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}`}</button>
                                                            }

                                                        </a>
                                                    </td>
                                                </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <FeatureBlockWorker/>

                                </Page>
                            </div>
                        </> :
                        /*Demo Site*/
                        current_path === demo_site_path &&
                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <div
                                    className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                    {/*<h2>{primaryDomainName ?? <Loading/>}</h2>*/}
                                    {this.state.edit === false ? <>
                                            <h2>{"Demo Site"}</h2></> :
                                        <DisplayNameForm data={"Demo Site"}/>}
                                    {this.state.edit === false ?
                                        <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                            <i className="icon-edit"/></a> : <>
                                            <button className="btn-theme btn-black"
                                                    disabled>{i18next.t('sites.page-heading.edit.update', {returnObjects: true})}
                                            </button>
                                            <button className="btn-theme btn-black"
                                                    onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel', {returnObjects: true})}
                                            </button>
                                        </>}
                                </div>
                                <div className="breadcrumb-block">
                                    <span><Link
                                        to={'/sites'}>{i18next.t('sites.page-heading.breadcrum', {returnObjects: true})}</Link></span>
                                    <span>{"Demo Site"}</span>
                                </div>
                            </div>
                            <div className="content-menu">
                                <SiteDashboardLinks site_id={"demo"}/>
                            </div>
                            <Page title={i18next.t('sites.inside-site.site-nav.tools', {returnObjects: true})}>

                                <div className="listing-table-block mt-60">
                                    <div className="table-responsive listing-table tools-table">
                                        <table>
                                            <tbody>

                                            {/*Force SSL*/}
                                            <tr>
                                                <td>
                                                    <div className="media">
                                                        <div className="media-img d-flex mr-3">
                                                            <img src={sslSvg} alt="" className="d-light"/>
                                                            <img src={sslSvg} alt="" className="d-dark"/>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                            <h4>{i18next.t('sites.inside-site.tools.site-tools.8.title', {returnObjects: true})}</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>{i18next.t('sites.inside-site.tools.site-tools.8.desc', {returnObjects: true})}</p>
                                                </td>
                                                <td>
                                                    <button className="btn-theme btn-outline blue-outline"
                                                            disabled>
                                                        {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                    </button>
                                                </td>
                                            </tr>
                                            {/*End Force SSL*/}

                                            <tr>
                                                <td>
                                                    <div className="media">
                                                        <div className="media-img d-flex mr-3">
                                                            <img src={monitorSvg} alt="" className="d-light"/>
                                                            <img src={monitorSvg} alt="" className="d-dark"/>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                            <h4>{i18next.t('sites.inside-site.tools.site-tools.1.title', {returnObjects: true})}</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>{i18next.t('sites.inside-site.tools.site-tools.1.desc', {returnObjects: true})}</p>
                                                </td>
                                                <td>
                                                    <button className="btn-theme btn-outline blue-outline"
                                                            disabled>
                                                        {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                    </button>
                                                </td>
                                            </tr>

                                            <ResponsiveModalForm visible={password_protection.mode}
                                                                 closeModal={() => store.dispatch(togglePasswordProtectionFormModal())}>
                                                <PasswordProtectionForm demoSite={'demo'}/>
                                            </ResponsiveModalForm>
                                            <PasswordProtectionPrimaryView demoSite={'demo'}/>

                                            <ResponsiveModalForm visible={php_version.mode}
                                                                 closeModal={() => store.dispatch(togglePhpVersionFormModal())}>
                                                <PhpVersionForm demoSite={'demo'}/>
                                            </ResponsiveModalForm>
                                            <PhpVersionPrimaryView/>

                                            <ResponsiveModalForm visible={public_path_modal.mode}
                                                                 closeModal={() => store.dispatch(togglePublicPathFormModal())}>
                                                <PublicPathForm demoSite={'demo'}/>
                                            </ResponsiveModalForm>
                                            <PublicPathPrimaryView/>

                                            <tr>
                                                <td>
                                                    <div className="media">
                                                        <div className="media-img d-flex mr-3">
                                                            <img src={restartSvg} alt="" className="d-light"/>
                                                            <img src={restartSvg} alt="" className="d-dark"/>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                            <h4>{i18next.t('sites.inside-site.tools.site-tools.5.title', {returnObjects: true})}</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>{i18next.t('sites.inside-site.tools.site-tools.5.desc', {returnObjects: true})}</p>
                                                </td>
                                                <td>
                                                    <div className="btn-wrapper">
                                                        {/*<a href="" className="btn-theme btn-outline blue-outline">{i18next.t('sites.inside-site.tools.enable' ,{returnObjects: true})}</a>*/}
                                                        <a>
                                                            <button className="btn-theme btn-outline blue-outline "
                                                                    disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                    onClick={() => this.restartPhp()}>
                                                                {restartPhpProcessing || (getSiteStatus?.success?.event === "restart_php_fpm" && getSiteStatus?.success?.status) === "updating" ?
                                                                    <div>
                                                                        <Loading/>{i18next.t('sites.inside-site.tools.site-tools.5.restart', {returnObjects: true})}
                                                                    </div> :
                                                                    `${i18next.t('sites.inside-site.tools.site-tools.5.restart', {returnObjects: true})}`}
                                                            </button>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <ResponsiveModalForm visible={modal.mode}
                                                                 closeModal={() => store.dispatch(toggleSearchAndReplaceFormModal())}>
                                                <SearchAndReplaceForm demoSite={'demo'}/>
                                            </ResponsiveModalForm>
                                            <SearchAndReplacePrimaryView/>

                                            <tr>
                                                <td>
                                                    <div className="media">
                                                        <div className="media-img d-flex mr-3">
                                                            <img src={wpDebugSvg} alt="" className="d-light"/>
                                                            <img src={wpDebugSvg} alt="" className="d-dark"/>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                            <h4>{i18next.t('sites.inside-site.tools.site-tools.7.title', {returnObjects: true})}</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>
                                                        {i18next.t('sites.inside-site.tools.site-tools.7.desc.1', {returnObjects: true})}
                                                        <br/>
                                                        {i18next.t('sites.inside-site.tools.site-tools.7.desc.2', {returnObjects: true})}
                                                    </p>
                                                </td>
                                                <td>
                                                    <div className="btn-wrapper">
                                                        <button
                                                            className="btn-theme btn-outline blue-outline"
                                                            type="button"
                                                            disabled>
                                                            {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="media">
                                                        <div className="media-img d-flex mr-3">
                                                            <img src={monitorSvg} alt="" className="d-light"/>
                                                            <img src={monitorSvg} alt="" className="d-dark"/>
                                                        </div>
                                                        <div className="media-body">
                                                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading', {returnObjects: true})}</span>
                                                            <h4>{i18next.t('sites.inside-site.tools.site-tools.9.title', {returnObjects: true})}</h4>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p>{i18next.t('sites.inside-site.tools.site-tools.1.desc', {returnObjects: true})}</p>
                                                </td>
                                                <td>
                                                    {/*<div className="btn-wrapper">*/}
                                                    <div className="btn-wrapper">
                                                        <button
                                                            className="btn-theme btn-outline blue-outline"
                                                            type="button"
                                                            disabled>
                                                            {i18next.t('sites.inside-site.tools.enable', {returnObjects: true})}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <FeatureBlockWorker/>

                            </Page>
                        </div>
                }
            </>

        )
    }
}

function mapStateToProps(state) {
    let {
    getSite
,
    getSites
,
    featureBlocklist
,
    getSiteStatus
,
    siteDashboard
,
    siteList
,
    restartPhp
,
    debug
,
    enableCache
,
    disableCache
,
    createMonitor
,
    getDemoSite
,
    createForceSsl
,
    clearCache
,
        createReactConfiguration
}

= state;


let {site_status} = siteDashboard.data;
let {success} = getSites;

let debug_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
let cache_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

let wp_flag = getSite && getSite.success && getSite.success.wp_flag
let monitor = getSite && getSite.success && getSite.success.monitor
let reactConfiguration = getSite && getSite.success && getSite.success.react_flag
let debugMode = getSite && getSite.success && getSite.success.debug_mode
let force_ssl = getSite && getSite.success && getSite.success.force_ssl
let cache = getSite && getSite.success && getSite.success.site_meta && getSite.success.site_meta.enable_cache
let {modal, public_path_modal, php_version, password_protection} = featureBlocklist.data;
// let domainList = getSiteDomain?.success?.data.data.data

let searchFrom = siteList?.data.searchFrom
let {params: siteListParam, payload} = siteList.data

return {
    getSite,
    cache_event,
    getSites,
    monitor,
    createReactConfiguration,
    reactConfiguration,
    debug_event,
    wp_flag,
    modal,
    featureBlocklist,
    public_path_modal,
    php_version,
    password_protection,
    getSiteStatus,
    debugMode,
    cache,
    site_status,
    siteDashboard,
    // domainList,
    searchFrom,
    siteListParam,
    payload,
    restartPhp,
    debug,
    enableCache,
    disableCache,
    clearCache,
    createMonitor,
    getDemoSite,
    force_ssl,
    event,
    createForceSsl
};
}

export default withRouter(connect(mapStateToProps)(FeatureBlock));