import axios from 'axios';
import {apiBaseUrl, endOfURL} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success)
{
  return { type: 'GET_SITE_DOMAIN_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_SITE_DOMAIN_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_SITE_DOMAIN_PROCESSING', processing };
}

function getSiteDomain( id= null)
{
  return ( dispatch, getState  ) =>
  {
    dispatch(_processing(true));
    dispatch(_error(null));
    let { domainList } = getState();
    let { params, site_id } = domainList.data;
    let s_id = id || site_id
      const lang = localStorage.getItem('lang') || 'en'

   axios({
      url: apiBaseUrl(`customer/sites/${s_id}/domains`),
      method: "get",
      dataType: 'json',
      params,
      headers: { 'Authorization': 'Bearer ' +loginToken(),
          'lang': lang}
    })
      .then(function(res){
          dispatch(_success(res));
          dispatch(_processing( false ));
          // handleSuccess( res )

      }).catch(function( error ){
       handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
      });
  }
}
export default getSiteDomain;