import React from "react";
import TextFieldDeleteSite from "../../../../components/shared/form/TextFieldDeleteSite";
import store from "../../../../Store";
import { toggleDangerZoneFormModal } from "../DangerZone-Action";
import i18next from "i18next";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import deleteSite from "../../../../ajax/actions/sites/site_delete";

class DeleteSiteForm extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  deleteConfirm = () => {
    store.dispatch(deleteSite(this.props.siteId));
    this.props.history.push(`/sites`);
  };

  render() {
    let { siteName, siteId } = this.props;
    return (
      <>
        <div className="content-form-block light-gray-bg delete-site">
          <h2>Destroy Site</h2>
          <p>Are you sure you want to permanently delete {siteName}.</p>
          <p>Confirm by entering the site name below</p>
          {/* <p>Confirm by entering the Droplet text below</p> */}
          <p className="delete-site-name">
            <h6> {siteName}</h6>
          </p>
          <div className="delete-site-form mb-20">
            <form className="mt-30" onSubmit={this.deleteConfirm}>
              <div className="form-group">
                <TextFieldDeleteSite
                  name="site_name"
                  value={this.state.site_name}
                  placeholder="Enter here"
                  label="Enter the site name"
                  siteName={siteName}
                  onChange={this.onChange}
                />
              </div>

              <div className="button-wrapper inline-buttons text-center">
                <button
                  className="btn-theme btn-outline"
                  type="reset"
                  onClick={() => store.dispatch(toggleDangerZoneFormModal())}
                >
                  {i18next.t(
                    "sites.inside-site.cache.add-cache.add-ignore.cancel",
                    { returnObjects: true }
                  )}
                </button>

                <button
                  type="submit"
                  className={
                    siteName !== this.state.site_name
                      ? "btn-theme-disabled btn-lg"
                      : "btn-theme red-btn"
                  }
                  disabled={siteName !== this.state.site_name}
                >
                  {/*onClick={() => this.deleteConfirm(this.props.match.params.id)}>*/}
                  {i18next.t("sites.inside-site.danger-zone.delete-btn-popup", {
                    returnObjects: true,
                  })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { getSite } = state;
  let siteId = getSite && getSite.success && getSite.success.id;
  let siteName = getSite && getSite.success && getSite.success.name;
  return {
    getSite,
    siteId,
    siteName,
  };
}

export default withRouter(connect(mapStateToProps)(DeleteSiteForm));
