import React from 'react'
import TextField from '../../../../components/shared/form/TextField'
import store from '../../../../Store';
import {connect} from 'react-redux'
import {
    onFormChange, changeType,
    selectCountry
} from './CardBillingFormStep2-Actions'
import SelectField from "../../../../components/shared/form/SelectField";
import getCountries from "../../../../ajax/actions/country/countries_get";
import CardBillingFormWorker from "./CardBillingFormWorker";
import i18next from 'i18next'

class CardBillingform extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(getCountries());
    }

    onChange = (e) => {
        e.preventDefault()
        store.dispatch(onFormChange(e));
    }

    onChangeType = () => {
        store.dispatch(changeType());
    }

    handleCountry = (selectedOption) => {

        let value = "";
        if (selectedOption) {
            value = selectedOption.target.value;
        }
        store.dispatch(selectCountry(value));
    }

    render() {
        let {
            errors, payload, countriesList
        } = this.props;

        let {
            address, extra_address_line, city, country_id, zip, company_name, vat_id, type
        } = payload

        let countryList = [];
        countriesList && countriesList.map(c => {
            countryList.push({value: c.nice_name, label: c.name, id: c.id})
        })


        return (
            <>
                {/*{type === 'personal' ?*/}
                {/*    <div className="company-question mt-30">*/}
                {/*        <p>Are You A company?*/}
                {/*            <button className="btn-theme btn-outline" onClick={() => this.onChangeType()}>Yes,Company*/}
                {/*                account</button></p>*/}
                {/*    </div>*/}
                {/*    : null}*/}


                    <div className="col-lg-6">
                        <div className="title-block"><h3>{i18next.t('account.inside-account.activate-account.billing-information.heading' ,{returnObjects: true})}</h3></div>
                        <div className="form-row mt-30">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-group animated fadeIn faster  ">
                                        <TextField name="address_line_1"
                                                   value={address}
                                                   label={i18next.t('account.inside-account.activate-account.billing-information.fields.address' ,{returnObjects: true})}
                                                   errors={errors && errors.address_line_1}
                                                   onChange={this.onChange}/>
                                    </div>
                                </div>
                            </div>
                            {type === 'company' ?
                                <>
                                    <div className="col-md-12"></div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="form-group animated fadeIn faster  ">
                                                <TextField required
                                                           value={company_name}
                                                           name="company_name"
                                                           label={i18next.t('account.inside-account.activate-account.billing-information.fields.company' ,{returnObjects: true})}
                                                           placeholder={i18next.t('account.inside-account.activate-account.billing-information.fields.company' ,{returnObjects: true})}
                                                           icon="user"
                                                           onChange={this.onChange}
                                                           errors={errors && errors.company_name}/>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <div className="col-md-6"></div>}


                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-group animated fadeIn faster  ">

                                        <TextField name="address_line_2"
                                                   value={extra_address_line}
                                                   label={i18next.t('account.inside-account.activate-account.billing-information.fields.second-address' ,{returnObjects: true})}
                                                   placeholder={i18next.t('account.inside-account.activate-account.billing-information.fields.second-address' ,{returnObjects: true})}
                                                   errors={errors && errors.extra_address_line}
                                                   onChange={this.onChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-group animated fadeIn faster  ">
                                        <TextField name="zip"
                                                   value={zip}
                                                   label={i18next.t('account.inside-account.activate-account.billing-information.fields.post-code' ,{returnObjects: true})}
                                                   errors={errors && errors.zip}
                                                   onChange={this.onChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-group animated fadeIn faster  ">
                                        <TextField name="city"
                                                   value={city}
                                                   label={i18next.t('account.inside-account.activate-account.billing-information.fields.city' ,{returnObjects: true})}
                                                   errors={errors && errors.city}
                                                   onChange={this.onChange}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-group animated fadeIn faster  ">
                                        <SelectField name="country_id"
                                                     options={countryList}
                                                     value={country_id}
                                                     errors={errors && errors.country_id}
                                                     onChange={this.handleCountry}
                                                     _value="id"
                                                     _label="value"
                                                     label={i18next.t('account.inside-account.activate-account.billing-information.fields.country' ,{returnObjects: true})}
                                                     />
                                                     
                                    </div>
                                </div>
                            </div>
                            {type === 'company' ?
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group animated fadeIn faster  ">
                                            <TextField name="vat_id"
                                                       value={vat_id}
                                                       label="Vat Id"
                                                       errors={errors.vat_id}
                                                       onChange={this.onChange}/>
                                        </div>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                <CardBillingFormWorker/>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {cardBillingForm, getCountries} = state;
    let {payload, errorMessage} = cardBillingForm.data;

    let {errors, errorsPresent} = cardBillingForm.data;

    let countriesList = getCountries?.success?.data.data;

    let {submitted, mode} = cardBillingForm.data;

    return {errors, errorsPresent, mode, errorMessage, payload, submitted, getCountries, countriesList};

}

export default connect(mapStateToProps)(CardBillingform);
