import {
  DefaultRegisterFormState,
  RegisterFormState,
} from "./RegisterForm-State";
import {
  errorsList,
  errorsPresent,
  mergeServerValidationErrors,
} from "../../helpers/ErrorHelper";
import { isObject } from "../../helpers/DataHelper";
import { endOfURL } from "../../helpers/UrlHelper";
import { isValidEmail } from "../../helpers/StringHelper";

export default function registerFormReducer(state = RegisterFormState, action) {
  let { data } = { ...state };
  let { name, value, validationErrors } = action;
  switch (action.type) {
    case "ON-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "SET-SPECIFIC-REGISTER-DATA":
      data.payload = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        on_sales: true,
      };
      buildErrors();
      return { ...state, data };

    case "REGISTER-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors &&
        isObject(validationErrors) &&
        Object.keys(validationErrors).map(
          (key) =>
            (data.errors[key] = [
              { message: `${validationErrors[key][0]}`.slice(0, -1) },
            ])
        );
      data.errorsPresent = true;
      data.dispatchAPI = false;
      return { ...state, data };

    case "CANCEL-REGISTER-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return { ...state, data };

    case "ON-REGISTER-FORM-SUBMIT":
      data.submitted = true;
      window.dataLayer.push({
        event: "signUp",
        eventCategory: "SignUp",
        eventAction: "Submit",
        eventLabel: "SignUpForm",
        ecommerce: {
          purchase: {
            actionField: {
              id: data.payload.email,
              affiliation: "SignUp",
              revenue: data.payload, // Replace with the actual value of the sign-up
            },
          },
        },
      });
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "SET-DEFAULT-REGISTER-FORM":
      data = {
        payload: {
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          password_confirmation: "",
          on_sales: true,
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode: endOfURL(),
        message: ``,
      };
      buildErrors();
      return { ...state, data };

    default:
      return state;
  }

  function buildErrors() {
    data.errors = errorsList(errors);

    data.errorsPresent = errorsPresent(errors);
  }

  function errors(field) {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map((key) => (errors[key] = []));
    if (submitted) {
      if (field === `first_name`) {
        if (!_(field)) newError(field, 0, `The first name field is required`);
        // else if (_(field).length <= 3) newError(field, 0, `This field must have 3 characters`);
      }
      if (field === `last_name`) {
        if (!_(field)) newError(field, 0, `The last name field is required`);
      }
      if (field === `email`) {
        if (!_(field).length) newError(field, 0, `The email field is required`);
        else if (_(field).length > 42)
          newError(field, 0, `This email is too Long`);
        else if (!isValidEmail(_(field)))
          newError(field, 0, `The email must be a valid email address`);
      }
      if (field === `password`) {
        if (!_(field)) newError(field, 0, `The password field is required`);
        else if (_(field) !== data.payload.password_confirmation)
          newError(field, 0, `The password confirmation does not match`);
      }
      // if (field === `password_confirmation`) {
      //     if (!_(field)) newError(field, 0, `The password confirmation field is required`)
      //     // else if (_(field) !== data.payload.password) newError(field, 0, `The password confirmation does not match`);
      // }
    }
    errors = mergeServerValidationErrors(errors, validationErrors);

    return field ? errors[field] : errors;

    function newError(field, step, message) {
      errors && errors[field].push({ step, message });
    }

    function _(field) {
      return data.payload[field];
    }
  }
}
