import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import {
  handleError,
  handleSuccess,
  validationError,
} from "../../../helpers/ErrorHelper";
import {
  registerFormServerValidationErrors,
  cancelRegistersFormDispatch,
  // setDefaultRegisterData,
  setDefaultRegisterForm,
} from "../../../pages/register-single/RegisterForm-Actions";
import store from "../../../Store";
import { scrollToError } from "../../../helpers/ScrollHelper";

function _success(success) {
  return { type: "REGISTER_USER_SUCCESS", success };
}

function _error(error) {
  return { type: "REGISTER_USER_ERROR", error };
}

function _processing(processing) {
  return { type: "REGISTER_USER_PROCESSING", processing };
}

function registerUser() {
  return (dispatch, getState) => {
    dispatch(_processing(true));
    const lang = localStorage.getItem("lang") || "en";

    let { registerFormV2 } = getState();

    const request = axios({
      url: apiBaseUrl(`customer/auth/register`),
      method: "post",
      dataType: "json",
      data: registerFormV2.data.payload,
      headers: {
        Authorization: "Bearer " + loginToken(),
        lang: lang,
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess(res);
        store.dispatch(cancelRegistersFormDispatch());
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);
        store.dispatch(cancelRegistersFormDispatch());
        if (error.response && error.response.status === 422) {
          store.dispatch(
            registerFormServerValidationErrors(error.response.data.data)
          );

          scrollToError();
        }
      });

    return {
      type: "REGISTER_USER_SUCCESS",
      payload: request,
    };
  };
}

export default registerUser;
