import React from 'react'
import Dlight from "../../assets/images/dd-light.svg";
import Ddark from "../../assets/images/dd-dark.svg";
import Link from "react-router-dom/Link";
import {loggedIn} from "../../helpers/LocalStorageHelper";

class LoginSidebar extends React.Component {

    componentDidMount() {
        // let nav_toggle = document.querySelector('.navbar-toggle-btn');
        // let sidebar_wrapper = document.querySelector('.sidebar-wrapper');
        let theme_switch = document.querySelector('.theme-switch');
        let themeMode = localStorage.getItem("themeMode");

        if(themeMode == 'true') {
            document.body.classList.add('dark-theme');
        }else {
            document.body.classList.remove('dark-theme');
        }

        theme_switch.addEventListener('click',function (){
            document.body.classList.toggle('dark-theme');
            let bodyClass = document.body.classList.contains('dark-theme');
            localStorage.setItem('themeMode', JSON.stringify((bodyClass === true ? true : false)))
        });
    }

    render() {

        return (
                <div className={`sidebar-wrapper ` + (loggedIn() ? '' : 'not-loggedIn')}>
                    <div className="sidebar" id="sidebar">
                        <div className="sidebar-block">
                            <div className="sidebar-title-block">
                               <Link to="/login"> <span className="icon-hbg"></span></Link>
                            </div>

                            <div className="sidebar-menu-block">
                                <div className="menu-switch-block">
                                    <div className="theme-switch text-center">
                                        <div className="theme-toggle-btn"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default LoginSidebar;