import { ForgotPasswordFormState } from './ForgotPasswordForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../helpers/ErrorHelper'
import { endOfURL } from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";
import {isValidEmail} from "../../../helpers/StringHelper";

export default function dataCenterFormReducer(state = ForgotPasswordFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, mode, token , email ,error} = action;
  switch (action.type) {

    case 'ON-FORGOT-PASSWORD-FORM-CHANGE':
      data.payload[name] = value;
      if(name === "email")
      {
        data.message="";
      }

      buildErrors();
      return { ...state, data };

    case 'ON-FORGOT-PASSWORD-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case 'ON-FORGOT-PASSWORD-FORM-MODE-CHANGE':
      data.mode = mode;
      buildErrors();
      return { ...state, data };

    case 'SET-TOKEN-FROM-URL':
      data.token = token;
      data.payload.token = token;
      buildErrors();
      return { ...state, data };

    case 'SET-EMAIL-FROM-URL':
      data.email = email;
      data.payload.email = email;
      buildErrors();
      return { ...state, data };

    case 'SET-FORGOT-PASSWORD-ERROR':
      data.message = error;
      buildErrors();
      return { ...state, data };

    case 'SET-DEFAULT-FORGOT-PASSWORD-FORM':
      data = {
        payload:{
          email:``,
          password:``,
          password_confirmation:``,
          token:``,
        },

        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ``
      };
      buildErrors();
      return { ...state, data };

    // case 'SET-CHANGE-PASSWORD-FORM':
    //   data.payload = {
    //       email:email,
    //       password:``,
    //       password_confirmation:``,
    //       token:token,
    //   };
    //   buildErrors();
    //   return { ...state, data };

    case 'CANCEL-FORGOT-PASSWORD-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};



    case 'FORGOT-PASSWORD-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && isObject( validationErrors ) &&
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: `${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
        if (field === `email`) {
          // if (!_(field)) newError(field, 0, `This is required field`)
          if (!_(field).length) newError(field, 0, `The email field is required.`);
          else if (_(field).length > 42) newError(field, 0, `This email is too Long`);
          else if (!isValidEmail(_(field))) newError(field, 0, `The email must be a valid email address`);
        }
        if(endOfURL() !== "forgotpassword"){
          if (field === `password`) {
            if (!_(field)) newError(field, 0, `This is required field`)
          }
          if (field === `password_confirmation`) {
            if (!_(field)) newError(field, 0, `This is required field`)
            else if (_(field) !== payload.password) newError(field, 0, `Password field didn't match`);

          }
        }
    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}