import React from 'react'
import Dlight from "../../assets/images/dd-light.svg";
import Ddark from "../../assets/images/dd-dark.svg";

// import logo from "../../assets/images/logo.png"

class Header extends React.Component {

    componentDidMount() {
        let html = document.getElementsByTagName('html')[0];
        let documentBody = document.getElementById('sidebar');

        function getHeight() {
            let newWindowHeight = Math.max(window.innerHeight);
            documentBody.style.height = newWindowHeight + 'px';
        }


        window.addEventListener("resize",function (){
            getHeight();
        });
        window.addEventListener('scroll resize',function (){
            getHeight();
        });
    }

    menuToggle() {
        let shadesEl = document.querySelector('.sidebar-wrapper');
        let toggleOpen = document.querySelector('.navbar-toggle-btn');
        let html = document.getElementsByTagName('html')[0];

        let documentBody = document.getElementById('sidebar');
        let newWindowHeight = Math.max(window.innerHeight);

        if (document.body.classList.contains('menu-active')) {
            document.body.classList.remove('menu-active');
            html.classList.remove('menu-active');
            shadesEl.classList.remove('active');
            toggleOpen.classList.remove('open');
            documentBody.style.height = '';
        } else {
            document.body.classList.add('menu-active');
            html.classList.add('menu-active');
            shadesEl.classList.add('active');
            toggleOpen.classList.add('open');
            documentBody.style.height = newWindowHeight + 'px';
        }
    };

    // componentDidMount() {
    //     // let nav_toggle = document.querySelector('.navbar-toggle-btn');
    //     // let sidebar_wrapper = document.querySelector('.sidebar-wrapper');
    //     let theme_switch = document.querySelector('.theme-switch');
    //     let themeMode = localStorage.getItem("themeMode");
    //
    //     if(themeMode == 'true') {
    //         document.body.classList.add('dark-theme');
    //     }else {
    //         document.body.classList.remove('dark-theme');
    //     }
    //
    //     theme_switch.addEventListener('click',function (){
    //         document.body.classList.toggle('dark-theme');
    //         let bodyClass = document.body.classList.contains('dark-theme');
    //         localStorage.setItem('themeMode', JSON.stringify((bodyClass === true ? true : false)))
    //     });
    // }

    render() {
        return (
            <header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar main-nav">
                                <div className="brandLogo">
                                    <a href="/"><span className="icon-hbg"/><p>Kepler Hosting</p></a>
                                </div>

                                <div className="theme-switch-md text-center mr-5">
                                    <div className="theme-toggle-btn"></div>
                                </div>

                                <div className={`navbar-toggle-btn`} onClick={() => this.menuToggle()}>
                                    <span/><span/> <span/>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>

            </header>

        )
    }
}

export default Header;