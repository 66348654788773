import React from "react";
import { names, ucFirst, unslufigy } from "../../../helpers/StringHelper";
import { isArray } from "../../../helpers/DataHelper";
import { endOfURL } from "../../../helpers/UrlHelper";
import i18next from "i18next";

class SelectField extends React.Component {
  placeholder() {
    let { placeholder, name, label } = this.props;
    if (placeholder) return placeholder;
    if (label) return label;
    else {
      if (name) {
        let _label = unslufigy(name);
        if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
        return _label;
      }
    }
  }

  labelText() {
    let { label, name } = this.props;
    if (label) return label;
    else {
      if (name) {
        let _label = unslufigy(name);
        if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
        return _label;
      }
    }
  }

  label() {
    let { name, label, errors } = this.props;
    let errorsPresent = errors && errors.length;
    if (label)
      return (
        <label>
          {label}
          {/*{ errorsPresent ? errors[0].message + '.' : null }*/}
        </label>
      );
    else {
      if (name) {
        let _label = unslufigy(name);
        if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
        return (
          <label>
            {_label}
            {/*{ errorsPresent ? errors[0].message + '.' : null }*/}
          </label>
        );
      }
    }
  }

  render() {
    let {
      options,
      name,
      _value,
      _label,
      onChange,
      disabled,
      value,
      noLabel,
      _customLabel,
      size,
      labelIcon,
      loading,
      allowClear,
      searchAble,
      required,
      errors,
      processing,
      formGroupStyle,
    } = this.props;
    let errorsPresent = errors && errors.length;

    let customLabel = _customLabel && _customLabel.split(",");
    let newValue =
      (typeof value === `string` || typeof value === `number`) && value !== ""
        ? String(value)
        : undefined;
    return (
      <>
        <div
          className={names(
            `form-group`,
            `animated fadeIn faster`,
            errors && errors.length && `has-error`,
            required && `required`,
            processing && `processing`
          )}
          style={formGroupStyle}
        >
          {noLabel ? null : this.label()}

          {typeof value !== undefined ? (
            <select
              name={name}
              value={newValue}
              disabled={disabled}
              className="form-control"
              size={size || `default`}
              placeholder={!noLabel ? `${this.placeholder()}` : null}
              onChange={onChange}
            >
              <option value="">
                {i18next.t("create-new-site.form.select", {
                  returnObjects: true,
                })}{" "}
                {this.labelText()}
              </option>
              {options &&
                isArray(options) &&
                options.map((o, key) => {
                  return (
                    <option value={String(o[_value || `id`])} key={key}>
                      {customLabel ? (
                        <>
                          {ucFirst(o[customLabel[0]])} ( {o[customLabel[1]]} ){" "}
                        </>
                      ) : (
                        o[_label || `name`]
                      )}
                    </option>
                  );
                })}
            </select>
          ) : (
            <select
              name={name}
              disabled={disabled}
              size={size || `default`}
              className="form-control"
              placeholder={!noLabel ? `${this.placeholder()}` : null}
              onChange={onChange}
            >
              <option value="">
                {i18next.t("create-new-site.form.select", {
                  returnObjects: true,
                })}{" "}
                {this.labelText()}
              </option>

              {options &&
                isArray(options) &&
                options.map((o, key) => {
                  return (
                    <option value={String(o[_value || `id`])} key={key}>
                      {customLabel ? (
                        <>
                          {ucFirst(o[customLabel[0]])} ( {o[customLabel[1]]} ){" "}
                        </>
                      ) : (
                        o[_label || `name`]
                      )}
                    </option>
                  );
                })}
            </select>
          )}
          {endOfURL() === "register" ? (
            <div
              className={names(
                `label-text error-msg`,
                errorsPresent && `has-error`,
                `animated fadeIn faster`
              )}
            >
              {errorsPresent ? errors[0].message + "." : null}
            </div>
          ) : null}
        </div>
        {/*<div className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>*/}
        {/*    { errorsPresent ? errors[0].message + '.' : null }*/}
        {/*</div>*/}
      </>
    );
  }
}
export default SelectField;
