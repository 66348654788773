import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {endOfURL} from "../../helpers/UrlHelper";
import i18next from 'i18next';

class AccountLinks extends React.Component {

    render() {
        let {customer_id, role, getLoggedInUser} = this.props;
        let {billing_type} = getLoggedInUser.success.data.data

        return (
            <>
                {role === "organization-owner" ?
                    <ul>
                        {getLoggedInUser.success && getLoggedInUser?.success?.data?.data?.organization.billable === 1 &&
                        <>
                        <li>
                            <Link className={endOfURL() === "overview" ? "active" : ""} to={`/account/overview`}>
                                {i18next.t('account.account-nav.overview' ,{returnObjects: true})}
                            </Link>

                            </li>

                        <li>
                            <Link className={endOfURL() === "payment" ? "active" : ""} to={`/account/payment`}>
                                {i18next.t('account.account-nav.payment' ,{returnObjects: true})}
                            </Link>
                        </li>
                            </>
                        }
                        {getLoggedInUser.success.data.data.has_active_card &&
                        <li>
                            <Link className={endOfURL() === "billing" ? "active" : ""} to={`/account/billing`}>
                                {i18next.t('account.account-nav.billing-details' ,{returnObjects: true})}
                            </Link>
                        </li>
                        }
                        <li>
                            <Link className={endOfURL() === "detail" ? "active" : ""} to={`/account/detail`}>
                                {i18next.t('account.account-nav.account-details' ,{returnObjects: true})}
                            </Link>
                        </li>
                        {/* <li>
                           <Link className={endOfURL()=== "setting"?"active":""} to={`/account/setting/organizations`}>
                               {i18next.t('account.account-nav.organization-settings' ,{returnObjects: true})}
                           </Link>
                        </li> */}
                        {getLoggedInUser.success && getLoggedInUser?.success?.data?.data?.organization.billable === 1 &&
                        !getLoggedInUser.success.data.data.has_active_card && billing_type !== "fortnox" &&
                        <li>
                            <Link className={endOfURL() === "activate-account" ? "active" : ""}
                                  to={`/account/activate-account`}>
                                {i18next.t('account.account-nav.activate-account' ,{returnObjects: true})}
                            </Link>
                        </li>
                        }
                    </ul>
                    :
                    <ul>
                        <li>
                            <Link className={endOfURL() === "detail" ? "active" : ""} to={`/account/detail`}>
                            {i18next.t('account.account-nav.account-details' ,{returnObjects: true})}
                            </Link>
                        </li>
                    </ul>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    route,
    getLoggedInUser,
    getUser,
    getSite
} = state;
let role = route && route.data.user && route.data.user.role.slug;

let customer_id = route && route.data.user && route.data.user.id;


return {route, customer_id, getLoggedInUser, getUser, getSite, role}
}

export default connect(mapStateToProps)(AccountLinks);