import React from 'react';
import ReactTooltip from 'react-tooltip'

class Tooltip extends React.Component
{
    render()
    {
        let { tooltip, tooltipPlacement, tooltipID,
        title} = this.props;
        return (
            <>
                <span data-tip={ tooltip || null }
                      data-for={ tooltipID || null }>
                    {title}
                    <ReactTooltip
                        id={ tooltipID || null }
                        class="tooltip-style"
                        place={ tooltipPlacement || 'top' }
                        type="warning"
                        effect="solid"
                        backgroundColor="blue"
                        textColor="white"
                    />
                </span>
            </>
        )
    }
}
export default Tooltip;