import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSslCertificates from "./ssl_get";
import getSite from '../../actions/sites/site_get'
import {siteFormServerValidationErrors} from "../../../pages/site/form/SiteForm-Action";
import {scrollToError} from "../../../helpers/ScrollHelper";
import getSiteStatus from "../sites/site_status_get";


function _success(success)
{
  return { type: 'POST_CUSTOM_SSL_CERTIFICATES_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_CUSTOM_SSL_CERTIFICATES_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_CUSTOM_SSL_CERTIFICATES_PROCESSING', processing };
}

function createCustomSslCertificate(id,payload)
{
  return ( dispatch ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'


    axios({
      url: apiBaseUrl(`customer/sites/${id}/ssl`),
      method: "post",
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),'lang': lang
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(getSiteStatus(id));
        store.dispatch(getSslCertificates(id));
        store.dispatch(getSite(id));
      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        if (error.response && error.response.status === 422) {
          validationError(error.response.data.errors.ssl_type[0],error.response.status)
          scrollToError()
        }
      });

  }
}


export default createCustomSslCertificate;