const DefaultLogsState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        per_page: 25,
        page: 1,
        order: 'desc',
        sort_id: `id`,
        q: ``,
        type: `error`
    },
    site_id: ``,
}

export const LogsState = {data: DefaultLogsState}