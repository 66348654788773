const DefaultPasswordProtectionFormState = {
    payload: {
        http_auth_username:``,
        http_auth_password: ``,
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
};

export const PasswordProtectionFormState = {data: DefaultPasswordProtectionFormState};