import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import getSite from "../../../../ajax/actions/sites/site_get";
import {withRouter} from "react-router";
import getSites from "../../../../ajax/actions/sites/sites_get";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getRedirects from "../../../../ajax/actions/redirects/redirects_get";
import getSiteDomainNoPagination from "../../../../ajax/actions/domain/site_domain_nopagination_get"

class RedirectsWorker extends React.Component {
    componentDidMount() {
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/redirects'
        if(current_path !== demo_site_path){
            let site_id = this.props.match.params.id;
            store.dispatch(getSite(site_id));
            store.dispatch(getRedirects(site_id));
            store.dispatch(getSiteStatus(site_id));
            store.dispatch(getSiteDomainNoPagination(site_id))
            store.dispatch(getSites());
            this.periodicFetch()
        }
    }

    componentWillUnmount() {

        clearInterval(this.timer);

    }

    periodicFetch() {
        this.timer = setInterval(() => store.dispatch(getSiteStatus(this.props.match.params.id)), 10000)
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getSite, getSites} = state;
    return {getSite, getSites};
}

export default withRouter(connect(mapStateToProps)(RedirectsWorker));