import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
  return{ type: 'GET_CARDS_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_CARDS_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_CARDS_PROCESSING', processing };
}

function getCards() {
  return ( dispatch ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    // let { route } = getstate();
    // if(!customer_id) {
    //   customer_id = route && route.data && route.data.user && route.data.user.id;
    // }
    const lang = localStorage.getItem('lang') || 'en'

    axios({
        url: apiBaseUrl(`customer/cards`),
        method: "get",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem(`kepler_c_token`),
          'lang': lang
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res?.data.data));

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default getCards;
