export function CouponFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'COUPON-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onCouponFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-FORM-SUBMIT'})
    }
}

export function onCouponFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-FORM-CHANGE', name, value});
    }
}

export function setDefaultCouponForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-COUPON-FORM'});
    }
}

export function cancelCouponFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-COUPON-FORM-DISPATCH'});
    }
}

export function couponError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-COUPON-ERROR', error});
    }
}