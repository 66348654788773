import {RedirectsFormState} from './RedirectsForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {isObject} from "../../../../helpers/DataHelper";

export default function RedirectsFormReducer(state = RedirectsFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, mode, error, site_id,redirect} = action;
    switch (action.type) {

        case 'ON-REDIRECTS-FORM-CHANGE':
           /* if(name === "redirect_code_301" && value === ""){
                data.payload["redirect_code"] = 301;
            }
            if(name === "redirect_code_302" && value === ""){
                data.payload["redirect_code"] = 302;
            }
            if((name === "redirect_code_301" && value) || (name === "redirect_code_302" && value) )
            {
                data.payload["redirect_code"] = ''
            }
            else if(name !== "redirect_code_301" || name !== "redirect_code_302" ) {
                data.payload[name] = value;
            }*/
            if(name === "redirect_code_301" && value === ""){
                data.payload["redirect_code"] = 301;
            }
            if(name === "redirect_code_301" && value === "302"){
                data.payload["redirect_code"] = 301;
            }
            if(name === "redirect_code_302" && value === ""){
                data.payload["redirect_code"] = 302;
            }
            if(name === "redirect_code_302" && value === "301"){
                data.payload["redirect_code"] = 302;
            }
            if((name === "redirect_code_302" && value === "302") || (name === "redirect_code_301" && value === "301") )
            {
                data.payload["redirect_code"] = ''
            }
            else if(name !== "redirect_code_302" || name !== "redirect_code_301" ) {
                data.payload[name] = value;
            }
            buildErrors();
            return {...state, data};

            case 'ON-REDIRECTS-FORM-DOMAIN-CHANGE':
            if(name === "domain_id" && value === true){
                data.payload["all_domains"] = value;
                data.payload[name]= null;
            }
            if(name === "domain_id" && value !== true){
                data.payload["all_domains"] = false;
                data.payload[name]=value;
            }
            buildErrors();
            return {...state, data};

        case 'ON-DELETE-REDIRECTS-FORM':
            data.payload['paths'] = value;
            buildErrors();
            return {...state, data};

        case 'ON-REDIRECTS-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-REDIRECTS-ERROR':
            data.message = error;
            buildErrors();
            return {...state, data};

        case 'SET-SITE-ID':
            data.site_id = site_id;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-REDIRECTS-FORM':
            data = {
                payload: {
                    domain_id: '',
                    all_domains: '',
                    regex: '',
                    destination:'',
                    redirect_code:''
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: `create`,
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'SET-REDIRECTS-DATA-FOR-UPDATE':

            data.payload = {
                domain_id: redirect.domain_id,
                all_domains: redirect.all_domains,
                regex: redirect.regex,
                destination:redirect.destination,
                redirect_code:redirect.redirect_code,
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-REDIRECTS-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-REDIRECTS-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'REDIRECTS-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if(!payload.domain_id && !payload.all_domains) {
                if (field === `domain_id`) {
                    if (!_(field)) newError(field, 0, `is required`)
                }
            }
            // if (field === `all_domains`) {
            //     if (!_(field)) newError(field, 0, `is required`)
            // }
            if (field === `regex`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
            if (field === `destination`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
            if (field === `redirect_code`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}
