export const generatePasswords=() =>{
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ!@%^&*)(#abcdefghijklmnopqrstuvwxyz!@%^&*)(#";
    var password = "";
    var length = 20;
    for (let i = 0; i < length; ++i) {
        password += chars[getRandomInt(0, chars.length - 1)];
    }
    return password;
}

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}