import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_PAYMENT_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_PAYMENT_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_PAYMENT_PROCESSING', processing};
}

function getPayments() {
    return (dispatch,getState) => {
        let {params} = getState().paymentList.data;
        dispatch(_processing(true));
        // dispatch(_success(null));
        dispatch(_error(null));

        const lang = localStorage.getItem('lang') || 'en'

        const request = axios({

            url: apiBaseUrl(`customer/payments`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken(),'lang': lang}
        })
            .then(function (res) {
                dispatch(_success(res.data));
                dispatch(_processing(false));
            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
            });

        return {
            type: 'GET_PAYMENT_SUCCESS',
            payload: request
        }
    }
}


export default getPayments;