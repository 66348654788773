import {migrationFormErrors, stepChange,onChangeFormSecond} from '../MigrationForm-Actions'
import store from '../../../Store'

export function onFormChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
        store.dispatch(migrationFormErrors())
        store.dispatch(onChangeFormSecond())
    }
}

// export function onCheckedRegisterChange(value) {
//     return function (dispatch,) {
//         dispatch({type: 'ON-MIGRATION-FORM-CHECKED-CHANGE', value});
//         store.dispatch(migrationFormErrors());
//     }
// }

export function initializeCurrentHostFormStep1Error() {
    return function (dispatch) {
        dispatch({type: 'INITIALIZE-CUSTOMER-HOST-FORM-STEP1-ERRORS'});
    }
}

export function customerValidationError(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'CURRENT-HOST-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
        store.dispatch(stepChange(validationErrors))
    }
}

export function setDefaultCustomerRegisterDataStep1() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATION-DATA',});
    }
}

export function emailErrorMessage(message) {
    return function (dispatch) {
        dispatch({type: 'SET-EMAIL-ERROR-MESSAGE', message});
    }
}

export function customerFormStep1ServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MIGRATION-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}


export function cancelMigrationsFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-MIGRATION-FORM-DISPATCH'});
    }
}

export function setDefaultCurrentHostFormStep1() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-CURRENT-HOSTING-DATA'});
    }
}