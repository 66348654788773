import React from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import MasterCard from "../../../assets/images/mastercard.svg";
import visa from "../../../assets/images/visa.svg";
import StripeImg from "../../../assets/images/stripe.svg";

import i18next from 'i18next';

function CardSection({onCardDetailChange}) {
    return (
        <>
            <div className="form-row align-items-end">
                <div className="form-group col-sm-12 col-12 billing-name">
                    <label htmlFor="">{i18next.t('account.inside-account.payment.add-new-card.add-form.cardholder-name' ,{returnObjects: true})}</label>
                    <input type="text" onChange={onCardDetailChange} name="cardholdername" placeholder={i18next.t('account.inside-account.payment.add-new-card.add-form.cardholder-name' ,{returnObjects: true})} className="form-control"/>
                </div>
            </div>
            <div className="row align-items-end custom-mt">
                <div className="form-group col-sm-6 col-6">
                    <div className="card-info-wrapper">
                        <label htmlFor="">{i18next.t('account.inside-account.payment.add-new-card.add-form.card-number' ,{returnObjects: true})}</label>
                        <CardNumberElement options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: (document.getElementsByTagName("body")[0].className.match('dark-theme') != null ? '#ffffff' : '#333333'),
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}/>

                    </div>
                </div>
                <div className="form-group col-sm-3 col-3">
                    <div className="card-info-wrapper">
                        <label htmlFor="">{i18next.t('account.inside-account.payment.add-new-card.add-form.expiration' ,{returnObjects: true})}</label>
                        <CardExpiryElement options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: (document.getElementsByTagName("body")[0].className.match('dark-theme') != null ? '#ffffff' : '#333333'),
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}/>
                    </div>
                </div>
                <div className="form-group col-sm-3 col-3">
                    <div className="card-info-wrapper">
                        <label htmlFor="">CVV</label>
                        <CardCvcElement options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: (document.getElementsByTagName("body")[0].className.match('dark-theme') != null ? '#ffffff' : '#333333'),
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}/>
                    </div>
                </div>

            </div>
            <span className="payment-method">Payments are processed by stripe.com  &nbsp;&nbsp;
                <span><img className="stripe-card" src={StripeImg}/></span>&nbsp;
                <span><img className="master-card" src={MasterCard}/></span>&nbsp;
                <span><img className="visa-card" src={visa}/></span></span>
        </>
    );
};

export default CardSection;