const DefaultMigrationDetailsFormState = {
    payload:{
        id:``,
        server_ip: ``,
        server_port:``,
        server_username:``,
        server_password:``,
        type: ``,
        db_login_url:``,
        is_multi_site: ``,
        is_ecommerce: ``,
        wordpress_username: ``,
        wordpress_password: ``,
        wordpress_admin_url: ``,
        create_new_site: ``,
        site_name: ``,
        status:``
        // customerName:``
        /*customerName:``,
        currentHost:``,
        username:``,
        hosting_login_url:``,
        domain:``,
        isEcommerce:``,
        wordpressUser:``,
        wordpressPassword:``,
        wordpress_admin_url:'',
        isMultisite:``,
        is_https:``,
        createNewSite:``,
        keplerSiteName:``,
        status:``*/
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: `update`,
    message: ``,
    site_id: ``,
};

export const MigrationDetailsFormState = {data: DefaultMigrationDetailsFormState};