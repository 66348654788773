import {PaymentState} from './Payment-State'

export default function paymentListReducer(state = PaymentState, action) {

    let {data} = {...state};
    let {name, value} = action;

    switch (action.type) {

        case 'TOGGLE-PAYMENT-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            if (name === `sort_by`) {
                if (data.params.sort_by === value) {
                    data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
                }
            }
            return {...state, data};

        case 'TOGGLE-ADD-CARD-FORM-MODAL':
            if (data.add_card_modal.mode) { // if open, close
                data.add_card_modal = {mode: ``,}
            } else { // if close, open
                data.add_card_modal.mode = true;
            }
            return {...state, data};

        case 'LOAD-MORE':
                if (name === 'limit') {
                    data.params.limit = data.params.limit+4;
                }
                data.params['limit'] =  data.params.limit+4;
            return { ...state, data };

        default:
            return state
    }
}