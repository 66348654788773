import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_DEMO_SITES_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_DEMO_SITES_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_DEMO_SITES_PROCESSING', processing};
}

function getDemoSites() {
    return (dispatch) => {
        dispatch(_processing(true));
        // dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        axios({
                url: apiBaseUrl(`customer/misc/dummysite`),
                method: "get",
                dataType: 'json',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(`kepler_c_token`),
                    'lang': lang
                }
            }).then(function (res) {
                dispatch(_processing(false));
                dispatch(_success(res));

            }).catch(function (error) {
                handleError(error)
                dispatch(_error(error));
                dispatch(_processing(false));
            });
        }
}

export default getDemoSites;
