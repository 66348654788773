import store from '../../../Store';
import getActivityLogs from "../../../ajax/actions/activity-log/activity_logs_get";
import {toggleURLQuery} from "../../../helpers/UrlHelper";

export function toggleActivityListFilter(e, history,) {
    return function (dispatch) {
        let {name, value, id} = e.target;
        dispatch({type: 'TOGGLE-ACTIVITY-LIST-FILTER', name, value});
        store.dispatch(getActivityLogs(id));
    }
}

export function loadMore(e,history) {
    return function (dispatch) {
        let { name, value ,id} = e.target;
        dispatch({type:'LOAD-MORE', name, value,id, history});
        if(name === 'limit') {
            store.dispatch( getActivityLogs());
            toggleURLQuery( 'limit', value ,history)
        }
        else {
            store.dispatch(getActivityLogs());
        }
    }
}

export function setDefaultLimit()
{
    return function (dispatch){
        dispatch({type:'DEFAULT-LIMIT'})
    }
}
