export function togglePublicPathFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-PUBLIC-PATH-FORM-MODAL'});
    }
}

export function toggleSearchAndReplaceFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-SEARCH-AND-REPLACE-FORM-MODAL'});
    }
}
export function togglePhpVersionFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-PHP-VERSION-FORM-MODAL'});
    }
}
export function togglePasswordProtectionFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-PASSWORD-PROTECTION-FORM-MODAL'});
    }
}
// export function toggleForceSslToolFormModal() {
//     return function (dispatch) {
//         dispatch({type: 'TOGGLE-FORCE-SSL-FORM-MODAL'});
//     }
// }
