import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultSitesListState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        sort_order: 'desc',
        sort_id: `id`,
        // sort_by: `display_name`,
        q: ``,
        searchItemName: ``,
        searchFrom: endOfURL(),
    },
    modal: {
        mode: ``
    },
    email_verify: {
        mode: ``
    },
    total_sites:``,
    organization_id: ``,
    fields: [
        {field: `display_name`, label: `Name`, sort: true},
        {field: `data_center_name`, label: `Data Center`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const SiteListState = {data: DefaultSitesListState}