import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { clone } from "../../../helpers/DataHelper";
import {
  handleError,
  handleSuccess,
  validationError,
} from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import { scrollToError } from "../../../helpers/ScrollHelper";
import {
  cancelSiteFormDispatch,
  setDefaultSiteForm,
  siteFormServerValidationErrors,
} from "../../../pages/site/form/SiteForm-Action";

function _success(success) {
  return { type: "CREATE_SITE_SUCCESS", success };
}

function _error(error) {
  return { type: "CREATE_SITE_ERROR", error };
}

function _processing(processing) {
  return { type: "CREATE_SITE_PROCESSING", processing };
}

function createSite() {
  return (dispatch, getState) => {
    let siteForm = clone(getState().siteForm);
    const lang = localStorage.getItem("lang") || "en";
    const fireGoogleTagData = {
      planeDescription: siteForm.data.payload.plan_description,
      planPrice: siteForm.data.payload.plan_price,
    };

    const siteType =
      siteForm.data.mode === "wordpress"
        ? "WordPress"
        : siteForm.data.mode === "ecommerce"
        ? "Ecommerce"
        : "BlankInstall";
    window.dataLayer.push({
      event: `siteCreate${siteType}`,
      eventCategory: "SiteCreate",
      eventAction: "Submit",
      eventLabel: "SiteCreateForm",
      siteType: siteType,
      package: fireGoogleTagData.planeDescription,
      price: fireGoogleTagData.planPrice,
    });

    delete siteForm.data.payload.plan_description;
    delete siteForm.data.payload.plan_price;

    dispatch(_success(null));
    dispatch(_error(null));
    dispatch(_processing(true));

    axios({
      url: apiBaseUrl(`customer/sites`),
      method: "post",
      dataType: "json",
      data: siteForm.data.payload,
      headers: {
        Authorization: "Bearer " + loginToken(),
        lang: lang,
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess(res);
        store.dispatch(setDefaultSiteForm());
        store.dispatch(cancelSiteFormDispatch());
      })
      .catch(function (error) {
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError(error);

        store.dispatch(cancelSiteFormDispatch());
        if (error.response && error.response.status === 422) {
          validationError(error.response.data.message, error.response.status);
          store.dispatch(
            siteFormServerValidationErrors(error.response.data.errors)
          );
          scrollToError();
        }
      });
  };
}

export default createSite;
