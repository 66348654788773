import React from 'react';
import { names, ucFirst, ucFirstAll, unslufigy } from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';
import {endOfURL} from "../../../helpers/UrlHelper";

class PasswordField extends React.Component
{
    placeholder()
    {
        let { placeholder, name, label } = this.props;
        if ( placeholder ) return placeholder;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label;
            }
        }
    }

    label()
    {
        let { name, label, errors } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label>
            { label }
            </label>
            // <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
            //     { label } { errorsPresent ? errors[0].message + '.' : null }
            // </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                    { _label }
                    </label>
                    // <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                    //     { _label } { errorsPresent ? errors[0].message + '.' : null }
                    // </label>
                )
            }
        }
    }

    render()
    {
        let {
            name, size, value, onChange, id, noLabel, disabled, errors, required
        } = this.props;
        let errorsPresent = errors && errors.length;

        return (

            <div className={ names(`form-group`, errors && errors.length && `has-error`, required && `required`) }>

                { !noLabel ? <>{ this.label() }</> : null }

                <input type="password"
                       className="form-control"
                       size={size || `default`}
                       name={name}
                       disabled={disabled}
                       id={id || name}
                       value={ value }
                       autoComplete="new-password"
                       placeholder={this.placeholder()}
                       onChange={ onChange }
                />
                <div className=' error-msg'>
                    {/*{errorsPresent ? errors[0].message + '.' : null}</div>*/}
                    {errorsPresent ? errors[0].message : null}</div>

            </div>

        )
    }
}
let { string, number, oneOfType } = PropTypes;
PasswordField.propTypes = { value: oneOfType([ string, number ]) };

export default PasswordField;