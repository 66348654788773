import React from 'react';

class MyIcon  extends React.Component
{
    render()
    {
        let { type, className, onClick, width  } = this.props;
        return (

            <i className={`icon fas fa-${type} ${className}`}
               onClick={onClick}
               style={{ fontSize: width || null }}
            />

        )
    }
}
export default MyIcon;