import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import updateCardAddOrganization from "../../../../ajax/actions/organization/cardaddorganization_put";
import getLoggedInUser from "../../../../ajax/actions/user/user_loggedin_get";
import getSites from "../../../../ajax/actions/sites/sites_get";
import getCountries from "../../../../ajax/actions/country/countries_get";
import {setDefaultBillingRegisterData} from "./CardBillingFormStep2-Actions";

class CardBillingFormWorker extends React.Component
{
    componentDidMount() {
        store.dispatch(getLoggedInUser());
        store.dispatch(getSites())
        store.dispatch(getCountries());
        store.dispatch(setDefaultBillingRegisterData())
    }

    componentDidUpdate(prev)
    {
        let {  dispatchAPI } = this.props;
        if ( dispatchAPI ) {
            if ( !prev.dispatchAPI ) {
                store.dispatch(updateCardAddOrganization( this.props.organization_id ));
            }
        }
    }

    render()
    {
        return null
    }
}

function mapStateToProps(state) {
    let { cardBillingForm  } = state;
    let { dispatchAPI, mode, payload } = cardBillingForm.data;
    return { cardBillingForm, dispatchAPI, mode, payload };
}
export default withRouter(connect( mapStateToProps )( CardBillingFormWorker ));