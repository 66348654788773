export function onFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
    }
}

export function onRegisterFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-REGISTER-FORM-SUBMIT'});
        window.gtag_report_conversion();
    }
}


export function registerFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'REGISTER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}


export function cancelRegistersFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-REGISTER-FORM-DISPATCH'});
    }
}

export function setDefaultRegisterForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-REGISTER-FORM'});
    }
}