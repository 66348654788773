import {SiteDashboardState} from './SiteDashboard-State'

export default function siteDashboardReducer(state = SiteDashboardState, action) {

    let {data} = {...state};
    let {site, status, user_id, total, message} = action;

    switch (action.type) {
        case 'SET-SITE-DASHBOARD-DATA':
            data.site_id = site.id;
            data.site_data_center_name = site.data_center_name;
            return {...state, data};

        case 'SET-SITE-STATUS':
            data.site_status = status.status;
            data.site_message = status.message;
            return {...state, data};

        case 'SET-PUP-UP-MESSAGE':
            data.pup_up_message = message;
            return {...state, data};

        case 'SET-SEARCHING-FLAG':
            data.searching = true;
            return {...state, data};

        case 'SET-SEARCHED-FLAG':
            data.searching = false;
            return {...state, data};

        case 'SET-TOTAL-SITES':
            data.total_sites = total;
            return {...state, data};

        case 'SET-SITE-LIST-STATUS':
            data.site_status = status.status;
            data.site_notification = status.notification;
            data.site_message = status.message;
            return {...state, data};

        case 'SET-USERID-SITE':
            data.user_id = user_id;
            return {...state, data};

        default:
            return state
    }

}
