import {BackupState} from './Backup-State'

export default function backupListReducer(state = BackupState, action) {

    let {data} = {...state};
    let {name, value, site_id} = action;

    switch (action.type) {

        case 'TOGGLE-BACKUP-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            return {...state, data};

        case 'LOAD-MORE':
            if (name === 'limit') {
                data.params.limit = data.params.limit+4;
            }
            // data.params['limit'] =  data.params.limit+4;
            return { ...state, data };

        case 'SET-SITEID-BACKUP':
            data.site_id = +site_id;
            return {...state, data};

        case 'TOGGLE-BACKUP-FORM-MODAL':
            if (data.backup_modal.mode === true) { // if open, close
                data.backup_modal = {mode: ``,}
            } else { // if close, open
                data.backup_modal.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}

