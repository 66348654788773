import React from 'react'

class SvgShape extends React.Component {
    render() {
        return (
            <div className="bg-img">
                <div className="right-img ml-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.22 675.38"
                         fill="#1a1f36">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path fill="#0d20dd" className="cls-1"
                                      d="M153.37,0,18.53,179a92,92,0,0,0,147,110.77L383.84,0Z"/>
                                <path fill="#1a1f36" fill-opacity="0.06" className="cls-1"
                                      d="M367.66,268.85a92,92,0,0,0,128.89-18.12L685.46,0H455L349.54,140a92,92,0,0,0,18.12,128.89Z"/>
                                <path fill="#0d20dd" className="cls-1"
                                      d="M439.22,431.49,366.54,528a92,92,0,1,0,147,110.77L669.69,431.49l63.53-84.32V41.28Z"/>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className="left-img">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641.14 978.93"
                         fill="#1a1f36">

                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <path fill="#0d20dd" className="cls-1"
                                      d="M344.48,433.54a92,92,0,0,0-128.89,18.12L0,737.8V978.93H48.8l313.81-416.5A92,92,0,0,0,344.48,433.54Z"/>
                                <path fill="#1a1f36" fill-opacity="0.06" className="cls-1"
                                      d="M356.48,18.54A92,92,0,0,0,227.59,36.66L0,338.73v305.9l374.61-497.2A92,92,0,0,0,356.48,18.54Z"/>
                                <path fill="#1a1f36" fill-opacity="0.06" className="cls-1"
                                      d="M604.48,511.54a92,92,0,0,0-128.89,18.12L137.09,978.93H367.57l255-338.5A92,92,0,0,0,604.48,511.54Z"/>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
            // <div className="bg-img">
            //     <div className="right-img ml-auto">
            //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.22 675.38" fill="#1a1f36">
            //             {/*<defs>*/}
            //             {/*    <style>.cls-1 {*/}
            //             {/*        fill: #1a1f36;*/}
            //             {/*    }</style>*/}
            //             {/*</defs>*/}
            //             <g id="Layer_2" data-name="Layer 2">
            //                 <g id="Layer_1-2" data-name="Layer 1">
            //                     <path className="cls-1"
            //                           d="M153.37,0,18.53,179a92,92,0,0,0,147,110.77L383.84,0Z"/>
            //                     <path className="cls-1"
            //                           d="M367.66,268.85a92,92,0,0,0,128.89-18.12L685.46,0H455L349.54,140a92,92,0,0,0,18.12,128.89Z"/>
            //                     <path className="cls-1"
            //                           d="M439.22,431.49,366.54,528a92,92,0,1,0,147,110.77L669.69,431.49l63.53-84.32V41.28Z"/>
            //                 </g>
            //             </g>
            //         </svg>
            //     </div>
            //
            //     <div className="left-img">
            //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641.14 978.93" fill="#1a1f36">
            //             {/*<defs>*/}
            //             {/*    <style>.cls-1 {*/}
            //             {/*        fill: #1a1f36;*/}
            //             {/*    }</style>*/}
            //             {/*</defs>*/}
            //             <g id="Layer_2" data-name="Layer 2">
            //                 <g id="Layer_1-2" data-name="Layer 1">
            //                     <path className="cls-1"
            //                           d="M344.48,433.54a92,92,0,0,0-128.89,18.12L0,737.8V978.93H48.8l313.81-416.5A92,92,0,0,0,344.48,433.54Z"/>
            //                     <path className="cls-1"
            //                           d="M356.48,18.54A92,92,0,0,0,227.59,36.66L0,338.73v305.9l374.61-497.2A92,92,0,0,0,356.48,18.54Z"/>
            //                     <path className="cls-1"
            //                           d="M604.48,511.54a92,92,0,0,0-128.89,18.12L137.09,978.93H367.57l255-338.5A92,92,0,0,0,604.48,511.54Z"/>
            //                 </g>
            //             </g>
            //         </svg>
            //     </div>
            //
            //
            // </div>
        )
    }
}

export default SvgShape;
