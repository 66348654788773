import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import PublicPathFormWorker from './SearchAndReplaceFormWorker'
import {
    onReplaceFlagChange,
    onSearchAndReplaceFormChange,
    onSearchAndReplaceFormSubmit
} from "./SearchAndReplaceForm-Action";
import TextField from "../../../../components/shared/form/TextField";
import Loading from "../../../../components/shared/loaders/Loading";
import {toggleSearchAndReplaceFormModal} from "../../feature-block/list/FeatureBlock-Action";
import CheckField from "../../../../components/shared/form/CheckFieldDomain";
import i18next from 'i18next';

class searchAndReplaceForm extends React.Component {
    searchAndReplaceFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onSearchAndReplaceFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onSearchAndReplaceFormChange(e.target.name, e.target.value,));
    };
    onReplaceFlagChange = (e) => {
        store.dispatch(onReplaceFlagChange(e.target.name));
    };


    render() {
        let {
            errors,
            getSite,
            searchAndReplaceForm,
            searchTerm,
            replaceTerm,
            site_search_status_event,
            site_search_status_message,
            getSiteStatus,
            amount_of_terms,
            replace_flag,
            search_item_name,
            site_status,
            demoSite,
            failedStatus,
            errorMessage
        } = this.props;
        let {processing} = getSite.processing

        return (
            <>
                <PublicPathFormWorker/>
                <div className="content-form-block light-gray-bg">

                    <h3 className="search-header text-center mb-20">{i18next.t('sites.inside-site.tools.site-tools.6.title' ,{returnObjects: true})}</h3>
                    <div className="search-replace-line">

                        <p>  {site_search_status_event === "search_replace_term" || site_search_status_event === "replace_searched_term" || site_search_status_event === "backup_for_replace_term" ? <>
                            {/*<div className="div-site-status sticky-top">*/}
                            {/*    <Loading color="white"/>{site_search_status_message} </div>*/}
                            <p className="bg-danger"><Loading color="white"/> {site_search_status_message}</p>

                        </> : null}</p>
                        <p>{i18next.t('sites.inside-site.tools.site-tools.6.form.desc.1' ,{returnObjects: true})}</p>
                        <form className="mt-30 domain-form" onSubmit={this.searchAndReplaceFormSubmit}>

                            <TextField name="searchTerm"
                                       className="form-control"
                                       type="text"
                                       value={searchTerm}
                                // label="Search"
                                       placeholder={i18next.t('sites.inside-site.tools.site-tools.6.form.search' ,{returnObjects: true})}
                                       noLabel
                                       errors={errors.searchTerm}
                                // response_error={searchAndReplaceForm?.data?.payload.public_path === "" ? "true" : "false"}
                                       onChange={this.onChange}/>

                            <CheckField name="replace_flag"
                                        id="replace_flag"
                                        value={replace_flag}
                                        label={i18next.t('sites.inside-site.tools.site-tools.6.form.replace' ,{returnObjects: true})}
                                        checked={replace_flag === 1}
                                        onChange={this.onReplaceFlagChange}
                            />
                            {replace_flag && replace_flag === 1 ?
                                <div className="mt-10">
                                    <TextField name="replaceTerm"
                                               className="form-control"
                                               type="text"
                                               value={replaceTerm}
                                               placeholder={i18next.t('sites.inside-site.tools.site-tools.6.form.replace-with' ,{returnObjects: true})}
                                               noLabel
                                        // label="Replace with"
                                               errors={errors.replaceTerm}
                                               onChange={this.onChange}/></div>
                                : null}
                            {/*<p className="mt-30 mb-30">*/}
                            {/*{i18next.t('sites.inside-site.tools.site-tools.6.form.desc.2' ,{returnObjects: true})} <a className="live-text">Live</a> {i18next.t('sites.inside-site.tools.site-tools.6.form.desc.3' ,{returnObjects: true})}*/}
                            {/*</p>*/}
                            {amount_of_terms ?
                                <div className="table-block table-responsive mt-20">
                                    <table className="table table-striped">
                                        <tbody>
                                        <tr>
                                            <td>
                                                <a className={`icon-tickmark`}/> {amount_of_terms} results found for
                                                "{search_item_name}"
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                : null}

                            {failedStatus && errorMessage ?
                                <p className="bg-danger">
                                    <a className={`icon-close-circle`}/> {failedStatus}-
                                    {errorMessage}</p>
                                // <div className="table-block table-responsive mt-20">
                                //     <table className="table table-striped">
                                //         <tbody>
                                //         <tr>
                                //             <td>
                                //                 <a className={`icon-close-circle`}/> {failedStatus}-
                                //                 {errorMessage}
                                //             </td>
                                //         </tr>
                                //         </tbody>
                                //     </table>
                                // </div>
                                : null}

                            <div className="button-wrapper inline-buttons text-center">
                                <button className="btn-theme btn-outline" type="reset"
                                        onClick={() => store.dispatch(toggleSearchAndReplaceFormModal())}>{i18next.t('sites.inside-site.tools.cancel' ,{returnObjects: true})}
                                </button>
                                <button type="submit" className="btn-theme btn-blue"
                                        disabled={demoSite === "demo" ||site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>

                                    {/*{processing ? <Loading/> : `Submit`}*/}
                                    {processing ? <Loading/> : i18next.t('sites.inside-site.tools.submit' ,{returnObjects: true})}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {searchAndReplaceForm, createPublicPath, getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    let site_search_status_event =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.event
    let site_search_status_message =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.message

    let {errors, payload} = searchAndReplaceForm.data;
    let replace_flag = searchAndReplaceForm && searchAndReplaceForm.data.replace_flag
    let amount_of_terms = searchAndReplaceForm && searchAndReplaceForm.data.amount_of_terms
    let search_item_name = searchAndReplaceForm && searchAndReplaceForm.data.search_item_name
    let failedStatus = searchAndReplaceForm && searchAndReplaceForm.data.failedStatus
    let errorMessage = searchAndReplaceForm && searchAndReplaceForm.data.errorMessage

    let {
        searchTerm, replaceTerm
    } = payload;
    return {
        searchAndReplaceForm,
        searchTerm,
        replaceTerm,
        errors,
        createPublicPath,
        getSite,
        getSiteStatus,
        site_search_status_event,
        site_search_status_message,
        amount_of_terms,
        replace_flag,
        search_item_name,
        site_status,
        failedStatus,
        errorMessage
    };
}

export default connect(mapStateToProps)(searchAndReplaceForm);