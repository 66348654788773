import React from 'react'
import TextField from '../../../components/shared/form/MigrationTextField'
import PasswordField from '../../../components/shared/form/MigrationPasswordField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onFormChange,
} from './CurrentHostFormStep1-Actions'
import {initializeCurrentHostFormStep1Error} from "./CurrentHostFormStep1-Actions";

class CurrentHostFormStep1 extends React.Component {

    constructor(props) {
        super(props);
        if(this.props.migrationForm.data.initialLoading) {
            store.dispatch(initializeCurrentHostFormStep1Error())
        }
    }

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onFormChange(e.target.name, e.target.value));
    }

    render() {
        let {
            errors, payload, submitted, message
        } = this.props;
        let {
            server_ip,server_port,server_username,server_password
        } = payload
        let {step0} = submitted;

        return (
            <>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            {/*<span> {message?message:null}</span>*/}
                            <TextField required
                                       value={server_ip}
                                       name="server_ip"
                                       autoComplete="nope"
                                       label="Server IP Address"
                                       errors={step0 && errors && errors.server_ip}
                                       message={message ? message : ""}
                                       onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField required
                                       value={server_port}
                                       name="server_port"
                                       label="Server Port"
                                // icon="user"
                                       onChange={this.onChange}
                                       errors={step0 && errors && errors.server_port}/>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField required
                                       value={server_username}
                                       name="server_username"
                                       label="Server Username"
                                       onChange={this.onChange}
                                       errors={step0 && errors && errors.server_username}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <PasswordField required
                                           value={server_password}
                                           name="server_password"
                                           defaultValue={null}
                                           autoComplete="new-password"
                                           onChange={this.onChange}
                                           errors={step0 && errors && errors.server_password}/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {migrationForm, createMigration, currentHostStep1} = state;
    let {payload, mode, errorMessage, message} = currentHostStep1.data;
    let {submitted} = migrationForm.data;
    let {errors, errorsPresent} = currentHostStep1.data;
    return {
        errors, errorsPresent, createMigration, mode, message,
        migrationForm, errorMessage, payload, submitted, currentHostStep1
    };
}

export default connect(mapStateToProps)(CurrentHostFormStep1);
