const DefaultBillingFormStep2State = {
    payload: {
        company_name: ``,
        country_id: ``,
        state: ``,
        city: ``,
        address_line_1: ``,
        address_line_2: ``,
        zip: ``,
        vat_id: ``,
        type: 'personal',

    },
    isHuman:false,
    selectedRole: ``,
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    serverValidationErrors: {},

    message: ``,
    user_mode: ``,
    action: 'loader',
};

export const CardBillingFormStep2State = {data: DefaultBillingFormStep2State}

