import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import DisplayNameFormWorker from './DisplayNameFormWorker'
import {onDisplayNameFormChange} from "./DisplayNameForm-Action";
import TextField from "../../../../components/shared/form/DisplayNameTextField";
import {secondToLastItemInURL} from "../../../../helpers/UrlHelper";

class displayNameForm extends React.Component {

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onDisplayNameFormChange(e.target.name, e.target.value));
    };

    render() {
        let {
            errors, getSite, displayNameForm, servers, getSiteStatus, site_status, display_name, site, payload
        } = this.props;

        let {processing} = getSite.processing

        return (
            <>
                <DisplayNameFormWorker data={secondToLastItemInURL() === "demo"?"Demo Site":site.display_name}/>
                <div className="display-text-field">
                    <div className="form-group">
                        <TextField name="display_name"
                                   value={secondToLastItemInURL() === "demo"?"Demo Site":display_name}
                                   errors={errors.display_name}
                                   onChange={this.onChange}/>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        displayNameForm, createSiteDisplayName, getSite, getSiteStatus, siteDashboard
    } = state;
    let {errors, payload} = displayNameForm.data;
    let {site_status} = siteDashboard.data;
    let site =
        getSite &&
        getSite.success;
    let {
        display_name
    } = payload;

    return {
        displayNameForm,
        site,
        display_name,
        errors,
        createSiteDisplayName,
        getSite,
        getSiteStatus,
        siteDashboard,
        site_status
    };
}

export default connect(mapStateToProps)(displayNameForm);