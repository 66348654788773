import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import { scrollToError } from '../../../helpers/ScrollHelper'
import store from "../../../Store";
import {CouponFormServerValidationErrors ,cancelCouponFormDispatch} from '../../../pages/account/coupon/form/CouponForm-Action'
import {setDefaultCouponForm} from "../../../pages/account/coupon/form/CouponForm-Action";
import {couponAddedMessage} from "../../../pages/account/overview/Overview-Actions"

function _success(success)
{
  return { type: 'POST_COUPON_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_COUPON_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_COUPON_PROCESSING', processing };
}

function createCoupon( )
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    let {couponForm} = getState()
    const lang = localStorage.getItem('lang') || 'en'

    axios({
      url: apiBaseUrl(`customer/coupons`),
      method: "post",
      dataType: 'json',
      data: couponForm.data.payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'lang': lang
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(setDefaultCouponForm());
        store.dispatch(couponAddedMessage());

      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        store.dispatch(cancelCouponFormDispatch())
        if(error.response && error.response.status === 422 ) {
          // validationError(error.response.data.message,error.response.status)
          store.dispatch(CouponFormServerValidationErrors(error.response.data.errors))
          scrollToError();
        }
      });

  }
}


export default createCoupon;