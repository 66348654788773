import React from 'react';
import SiteDashboardHeader from "../SiteDashboardHeader";
import SiteDashboardLinks from "../SiteDashboardLinks";
import {connect} from "react-redux";
import swal from "sweetalert";
import store from "../../../Store";
import deleteSite from "../../../ajax/actions/sites/site_delete";
import Page from "../../../components/shared/pages/Page";
import Loading from "../../../components/shared/loaders/Loading";
import i18next from 'i18next';
import getSite from "../../../ajax/actions/sites/site_get";
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import DeleteSiteForm from "./delete-site/DeleteSiteForm";
import {toggleDangerZoneFormModal} from "./DangerZone-Action"
import {withRouter} from "react-router";

class DangerZone extends React.Component {

    componentDidMount() {
        store.dispatch(getSite(this.props.match.params.id));
    }

    deleteConfirm = (id) => {
        swal(i18next.t('sites.inside-site.danger-zone.swal.desc' ,{returnObjects: true}), {
            buttons: {
                cancel: i18next.t('sites.inside-site.danger-zone.swal.btns.cancel' ,{returnObjects: true}),
                catch: {
                    text: i18next.t('sites.inside-site.danger-zone.swal.btns.delete' ,{returnObjects: true}),
                    value: id,
                },
            },
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteSite(id));
        this.props.history.push(`/sites`);
    }

    render() {
        let {getSite, site_status, modal,dangerZoneReducer} = this.props
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/dangerzone'
        let {processing}=getSite

        return (
            <>
                <SiteDashboardHeader/>
                <Page title={i18next.t('sites.inside-site.site-nav.danger' ,{returnObjects: true})}>
                    <div className="boxed-block">
                        <div className="heading-block mt-70">
                            <h2>{current_path === demo_site_path ? "Demo Site" : processing ? <Loading/>:getSite?.success?.display_name}</h2>
                        </div>
                        <div className="content-menu">
                            <SiteDashboardLinks
                                site_id={current_path === demo_site_path ? "demo" : this.props.match.params.id}/>
                        </div>
                        <div className="content-container light-gray-bg">
                            <h3>{i18next.t('sites.inside-site.danger-zone.title' ,{returnObjects: true})}</h3>
                            <p>
                            {i18next.t('sites.inside-site.danger-zone.desc.1' ,{returnObjects: true})}
                                <br/>
                                <br/>
                                {i18next.t('sites.inside-site.danger-zone.desc.2' ,{returnObjects: true})}<br/><br/>
                                {i18next.t('sites.inside-site.danger-zone.desc.3' ,{returnObjects: true})}<br/><br/>
                                {i18next.t('sites.inside-site.danger-zone.desc.4' ,{returnObjects: true})}
                            </p>

                            <div className="btn-wrapper inline-buttons mt-30">
                                <ResponsiveModalForm visible={modal.mode}
                                                     closeModal={() => store.dispatch(toggleDangerZoneFormModal())}>
                                    <DeleteSiteForm/>
                                </ResponsiveModalForm>
                                <button className="btn-theme red-btn"
                                        disabled={current_path === demo_site_path || site_status === `pending` || site_status === 'updating' || site_status === 'restoring'}
                                        // onClick={() => this.deleteConfirm(this.props.match.params.id)}>{i18next.t('sites.inside-site.danger-zone.delete-btn' ,{returnObjects: true})}
                                        onClick={() => {store.dispatch(toggleDangerZoneFormModal())}}>{i18next.t('sites.inside-site.danger-zone.delete-btn' ,{returnObjects: true})}
                                </button>
                                <a href="mailto:support@keplerhosting.com" action="" className="btn-theme btn-blue">{i18next.t('sites.inside-site.danger-zone.contact-sprt' ,{returnObjects: true})}</a>
                            </div>
                        </div>
                    </div>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, siteDashboard, getSiteStatus,dangerZoneReducer} = state;
    let {site_status} = siteDashboard.data;
    let {modal} = dangerZoneReducer.data
    return {getSite, siteDashboard, site_status, getSiteStatus, modal,dangerZoneReducer};
}

export default withRouter(connect(mapStateToProps)(DangerZone));