import React from 'react';
import MyIcon from "../icons/MyIcon";
import {Link} from "react-router-dom";
import cross from "../../../assets/images/cross.png"

class NotVerifiedEmail extends React.Component
{
  render()
  {
    let {error, loggedinUser} = this.props
    const failedEmailVerificationError = error.response.data.errors

    return (

        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12 text-center pd-3 pt-5 ">
            {/*<i className="icon-close-circle text-danger cross-icon"/>*/}
            <MyIcon type="tools" width={80} />
              <img className="error-cross-image" src={cross} alt="error"/>
            <h4 className="mt-5">
              {/*Your email address could not be verified.</h4>*/}
              {failedEmailVerificationError && failedEmailVerificationError.message[0]}</h4>

            <div className="mt-20">
              <div className="button-wrapper text-center mt-30">
                <Link to={ '/' } className="btn-theme btn-blue btn-lg" >
                  {loggedinUser ? "Go Back to Home" :  "Go Back to Login Page"}
                </Link>
              </div>

            </div>

          </div>
        </div>

    )
  }
}

export default  NotVerifiedEmail ;