import {
    DefaultCurrentHostFormState,
    CurrentHostFormState,
} from './CurrentHostFormStep1-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'

export default function registerFormReducer(state = CurrentHostFormState, action) {

    let {data} = {...state};
    let {name, value, validationErrors} = action;
    switch (action.type) {

        case 'ON-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors()
            return {...state, data};

        case 'SET-SPECIFIC-CURRENT-HOSTING-DATA':
            data.payload = {
               /* current_host: '',
                username: '',
                password: '',
                hosting_login_url: '',*/
                server_ip: ``,
                server_port:``,
                server_username:``,
                server_password:``
            };
            buildErrors()
            return {...state, data};

        case 'CUSTOMER-FORM-SERVER-VALIDATION-ERRORS':
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: `${validationErrors[key][0]}`}]
            )
            data.errorsPresent = true;
            data.submitted = false;
            data.dispatchAPI = false;
            return {...state, data};

        case 'CANCEL-CURRENT-HOST-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors()
            return {...state, data};

        case 'INITIALIZE-CUSTOMER-HOST-FORM-STEP1-ERRORS':
            buildErrors()
            return {...state, data};

        case 'SET-DEFAULT-CURRENT-HOSTING-DATA':
            data = {
                payload: {
                  /*  current_host: '',
                    username: '',
                    password: '',
                    hosting_login_url: '',*/
                    server_ip: ``,
                    server_port:``,
                    server_username:``,
                    server_password:``
                },
                errors: {},
                errorsPresent: false,
                serverValidationErrors: {},
                submitted: false,
            };
            buildErrors();
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)

        data.errorsPresent = errorsPresent(errors);
    }

    function errors(field) {
        let errors = {};
        Object.keys(data.payload).map(key => errors[key] = []);
        if (field === `server_ip`) {
            if (!_(field)) newError(field, 0, `The server Ip Address field is required`)
        }
        if (field === `server_port`) {
            if (!_(field)) newError(field, 0, `The server port field is required`)
        }
        if (field === `server_username`) {
            if (!_(field)) newError(field, 0, `The server username field is required`)
        }
        if (field === `server_password`) {
            if (!_(field)) newError(field, 0, `The server password field is required`)
        }
        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}