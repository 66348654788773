import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import getSite from "../../../../ajax/actions/sites/site_get";
import {withRouter} from "react-router";
import getSites from "../../../../ajax/actions/sites/sites_get";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getSslCertificates from "../../../../ajax/actions/ssl/ssl_get";
import getSiteDomainAll from "../../../../ajax/actions/domain/site_domain_nopagination_get";

class CacheWorker extends React.Component {
    componentDidMount() {
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/ssl'
        if (current_path !== demo_site_path) {
            store.dispatch(getSite(this.props.match.params.id));
            store.dispatch(getSiteStatus(this.props.match.params.id));
            store.dispatch(getSites());
            store.dispatch(getSslCertificates(this.props.match.params.id));
            store.dispatch(getSiteDomainAll(this.props.match.params.id));
            this.periodicFetch()
        }
    }

    componentDidUpdate(prev) {
        if (this.props.getSslCertificates !== prev.getSslCertificates) {
            let {success} = this.props.getSslCertificates;
            if (success) {
                store.dispatch(getSslCertificates(this.props.match.params.id));
            }
        }
    }

    componentWillUnmount() {

        clearInterval(this.timer);

    }

    periodicFetch() {
        this.timer = setInterval(() => store.dispatch(getSiteStatus(this.props.match.params.id)), 10000)
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getSite, getSites} = state;
    return {getSite, getSites};
}

export default withRouter(connect(mapStateToProps)(CacheWorker));