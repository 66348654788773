const DefaultBackupState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        // per_page: 2,
        page: 1,
        order: 'desc',
        sort_id: `id`,
        q: ``,
        limit:10
    },
    site_id: ``,
    backup_modal: {
        mode: '',
    },
    fields_en: [
        {field: `type`, label: `Backup Type`, sort: true},
        {field: `created_at`, label: `Created At`, sort: true},
        {field: `expiry_date`, label: `Expiry Date`, sort: true},
        {field: `remarks`, label: `Description`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ],
    fields_sv: [
        {field: `type`, label: `Backup-typ`, sort: true},
        {field: `created_at`, label: `Skapad`, sort: true},
        {field: `expiry_date`, label: `Utgångsdatum`, sort: true},
        {field: `remarks`, label: `Beskrivning`, sort: true},
        {field: `action`, label: `Handling`, sort: false},
    ],
    manualFields_en: [
        {field: `name`, label: `Name`, sort: true},
        {field: `created_at`, label: `Created At`, sort: true},
        {field: `expiry_date`, label: `Expiry Date`, sort: true},
        {field: `remarks`, label: `Description`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ],
    manualFields_sv: [
        {field: `name`, label: `Name`, sort: true},
        {field: `created_at`, label: `Skapad`, sort: true},
        {field: `expiry_date`, label: `Utgångsdatum`, sort: true},
        {field: `remarks`, label: `Beskrivning`, sort: true},
        {field: `action`, label: `Handling`, sort: false},
    ],
}

export const BackupState = {data: DefaultBackupState}