import React from 'react';
import BackupWorker from "./BackupWorker";
import {connect} from 'react-redux';
import TableHead from "../../../../components/shared/table/TableHeadWithAction";
import Moment from "moment";
import SiteDashboardLinks from "../../SiteDashboardLinks";
import SiteDashboardHeader from "../../SiteDashboardHeader";
import Loading from "../../../../components/shared/loaders/Loading";
import {unslufigy} from "../../../../helpers/StringHelper";
import swal from "sweetalert";
import store from "../../../../Store";
import restoreBackup from "../../../../ajax/actions/backup/restore_backup_post";
import deleteBackup from "../../../../ajax/actions/backup/backup_delete";
import postBackup from "../../../../ajax/actions/backup/backup_post";
import Page from "../../../../components/shared/pages/Page";
import {endOfURL} from "../../../../helpers/UrlHelper";
import SiteTableCell from "../../list/SiteTableCell";
import DisplayNameForm from "../../change-display-name/form/DisplayNameForm";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import getSiteBackup from "../../../../ajax/actions/backup/backup_get";
import getManualSiteBackup from "../../../../ajax/actions/backup/site_manual_backup_get";
import {Link} from "react-router-dom";
import i18next from 'i18next';
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import ManualBackupForm from "../form/ManualBackupForm";
import {
    loadMoreMaualBackups,
    loadMore,
    toggleBackupFormModal,
    toggleBackupListFilter,
    toggleManualBackupListFilter
} from "./Backup-Action";
import BackupPrimaryView from "./backupPrimaryView"
import {withRouter} from "react-router";
import PaginationButtons from "../../../../components/shared/buttons/PaginationButtons";

class BackupList extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
    }

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.setState({edit : false});
    }

    createBackup = (e) => {
        swal(`${i18next.t('sites.inside-site.backups.manual.swal.question' ,{returnObjects: true})}`, {
            buttons: {
                cancel: `${i18next.t('sites.inside-site.backups.manual.swal.cancel' ,{returnObjects: true})}`,
                catch: {
                    text: `${i18next.t('sites.inside-site.backups.manual.swal.accept' ,{returnObjects: true})}`,
                    value: "yes"
                }
            }
        }).then((value) => {
            if (value) this.createManualBackup()
        })
    };

    deleteConfirm = (id) => {
        swal(`${i18next.t('sites.inside-site.backups.swal-delete.delete-backup-question' ,{returnObjects: true})}`, {
            buttons: {
                cancel: `${i18next.t('sites.inside-site.backups.swal-delete.cancel' ,{returnObjects: true})}`,
                catch: {
                    text: `${i18next.t('sites.inside-site.backups.swal-delete.delete' ,{returnObjects: true})}`,
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    restoreBackup = (id) => {
        swal(`${i18next.t('sites.inside-site.backups.swal-restore.restore-backup-question' ,{returnObjects: true})}`, {
            buttons: {
                cancel: `${i18next.t('sites.inside-site.backups.swal-restore.cancel' ,{returnObjects: true})}`,
                catch: {
                    text: `${i18next.t('sites.inside-site.backups.swal-restore.restore' ,{returnObjects: true})}`,
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.restore(value)
        })
    };

    restore(id) {
        store.dispatch(restoreBackup(id));
    }

    delete(id) {
        store.dispatch(deleteBackup(id));
    }

    createManualBackup() {
        store.dispatch(postBackup());
    }

    onClickDaily = () => {
        store.dispatch(getSiteBackup())
    }

    onClickManual = () => {
        store.dispatch(getManualSiteBackup())
    }

    loadMoreBackup(id)
    {
        store.dispatch(loadMore({
            // target: {name: `sort_id`, value: field}
            target: {name: `limit`, value: 4 , id : id}
        }))
    };

    loadMoreManualBackup()
    {
        store.dispatch(loadMoreMaualBackups({
            // target: {name: `sort_id`, value: field}
            target: {name: `limit`, value: 4}
        }))
    };

    render() {
        let {
            getSite,
            domainLists,
            manualFields_en,
            manualFields_sv,
            getBackup,
            siteBackups,
            manualSiteBackups,
            params,
            fields_en,
            fields_sv,
            site_status,
            getSiteStatus,
            searchFrom,
            siteListParam,
            getManualSiteBackup,
            backup_modal,
            pages,
            manualBackupPages,
        } = this.props;
        let site_id = this.props.match.params.id
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/backups'

        let lang = localStorage.getItem('lang')
        let {processing, error} = getBackup;
        let manualBackupProcessing = getManualSiteBackup.processing;
        fields_en = fields_en.filter(function (obj) {
            return obj.field !== 'remarks';
        });
        fields_sv = fields_sv.filter(function (obj) {
            return obj.field !== 'remarks';
        });

        manualFields_en = manualFields_en.filter(function (obj) {
            return obj.field !== 'remarks';
        });
        manualFields_sv = manualFields_sv.filter(function (obj) {
            return obj.field !== 'remarks';
        });

        return (
            <>
                <SiteDashboardHeader/>
                <BackupWorker/>
                {siteListParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                     current_path !== demo_site_path ?
                        <>
                            <div className="boxed-block">
                                <div className="heading-block mt-70">
                                    <div
                                        className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                        {this.state.edit === false ? <>
                                                <h2>{getSite?.success?.display_name}</h2></> :
                                            <DisplayNameForm data={getSite?.success?.display_name}/>}
                                        {this.state.edit === false ?
                                            <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                                <i className="icon-edit"/></a> : <>
                                                <button className="btn-theme btn-black"
                                                        onClick={this.updateDisplayName}>{i18next.t('sites.page-heading.edit.update' ,{returnObjects: true})}
                                                </button>
                                                <button className="btn-theme btn-black"
                                                        onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel' ,{returnObjects: true})}
                                                </button>
                                            </>}
                                    </div>
                                    <div className="breadcrumb-block">
                                        <span><Link to={'/sites'}>{i18next.t('sites.page-heading.breadcrum' ,{returnObjects: true})}</Link></span>
                                        <span>{getSite?.success?.display_name}</span>
                                    </div>
                                </div>
                                <div className="content-menu">
                                    <SiteDashboardLinks site_id={this.props.match.params.id}/>
                                </div>
                                <Page title={i18next.t('sites.inside-site.site-nav.backups' ,{returnObjects: true})}>

                                    {/*<BackupWorker/>*/}

                                    <div className="content-container light-gray-bg">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-daily-tab" data-toggle="pill"
                                                   href="#pills-daily" role="tab" aria-controls="pills-daily"
                                                   aria-selected="true" onClick={this.onClickDaily}>{i18next.t('sites.inside-site.backups.daily.daily-btn' ,{returnObjects: true})}</a>
                                            </li>

                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-manual-tab" data-toggle="pill"
                                                   href="#pills-manual" role="tab" aria-controls="pills-manual"
                                                   aria-selected="false" onClick={this.onClickManual}>{i18next.t('sites.inside-site.backups.manual.manual-btn' ,{returnObjects: true})}</a>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="pills-daily" role="tabpanel"
                                                 aria-labelledby="pills-daily-tab">
                                                <div className="table-block table-responsive listing-table-alt">
                                                    <table className="mb-50 table-with-action-buttons">

                                                        <TableHead
                                                            fields={
                                                                lang === "en" ? 
                                                                fields_en

                                                                :

                                                                fields_sv
                                                            }
                                                            params={params}
                                                            noActions={true}
                                                            onClick={this.toggleSort}/>

                                                        <tbody>
                                                        {processing ?
                                                            <td colSpan={5} className="text-center"><Loading/>
                                                            </td> :
                                                            siteBackups && siteBackups.length ? siteBackups.map((siteBackup, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            {unslufigy(siteBackup.type)}
                                                                        </td>
                                                                        {/*<td><span>Backup / www.hbgdesignlab.com / STAGING Environment</span></td>*/}
                                                                        <td>{Moment(siteBackup.created_at).format('YYYY-MM-DD h:mm A')}</td>
                                                                        <td>{Moment(siteBackup.expiry_date).format('YYYY-MM-DD h:mm A')}</td>
                                                                        {/*<td>{siteBackup.remarks !== " " ? siteBackup.remarks : "N/A"}</td>*/}
                                                                        {(site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring') ?
                                                                            <>
                                                                                <td className="action">
                                                                                    <div
                                                                                        className="dropdown dropdown-alt text-center">
                                                                                                <span
                                                                                                    className="icon-more"/>
                                                                                    </div>
                                                                                </td>
                                                                            </> :
                                                                            <>
                                                                                <td className="action">
                                                                                    <div
                                                                                        className="dropdown dropdown-alt text-center">
                                                                                        {/*<span*/}
                                                                                        {/*    className="dropdown-toggle"*/}
                                                                                        {/*    data-toggle="dropdown">*/}
                                                                                        <span className="icon-more"
                                                                                              data-toggle="dropdown"/>
                                                                                        {/*<span*/}
                                                                                        {/*    className="icon-more "*/}
                                                                                        {/*    data-toggle="dropdown"/>*/}
                                                                                        {/*</span>*/}
                                                                                        <div
                                                                                            className="dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuButton">
                                                                                            <a href="#!"
                                                                                               className="dropdown-item"
                                                                                               type="button"
                                                                                               onClick={() => this.restoreBackup(siteBackup.id)}>
                                                                                                {i18next.t('sites.inside-site.backups.daily.action.restore' ,{returnObjects: true})}
                                                                                            </a>
                                                                                            <a href="javascript:void(0);"
                                                                                               className="dropdown-item"
                                                                                               onClick={() => this.deleteConfirm(siteBackup.id)}>
                                                                                                {/*<a  onClick={() => this.deleteConfirm( organization.id )} className={`link-delete`}>*/}
                                                                                                {i18next.t('sites.inside-site.backups.daily.action.delete' ,{returnObjects: true})}

                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </>}
                                                                    </tr>

                                                                )
                                                            }) : <tr>
                                                                <td colSpan={4}>{i18next.t('sites.inside-site.backups.no-backup' ,{returnObjects: true})}</td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                    {pages.total > 10 && (pages.to !== pages.total) &&
                                                    <div className="load-more ml-3">
                                                        <button className="btn-theme mt-10"
                                                                onClick={() => this.loadMoreBackup(site_id)}>{processing ? <>Load
                                                            More <Loading className="sm-loader-position" height="15"
                                                                          width="15"/></> : `Load More`}</button>
                                                    </div>
                                                    }

                                                    {/*{ siteBackups && siteBackups.length ? (
                                                        <div className="fixed-footer backup-footer pr-60" id="inside-organization-pagination">
                                                            <PaginationButtons pages={pages}
                                                                               processing={processing}
                                                                               fetchAction={toggleBackupListFilter}/>
                                                        </div>
                                                    ) : null}*/}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-manual" role="tabpanel"
                                                 aria-labelledby="pills-manual-tab">
                                                <div className="padded-block">
                                                    <div className="tab-info-wrapper">
                                                        <div className="tab-info-block">
                                                            <p>{i18next.t('sites.inside-site.backups.manual.desc.1' ,{returnObjects: true})}<br/>
                                                            {i18next.t('sites.inside-site.backups.manual.desc.2' ,{returnObjects: true})}
                                                            </p>
                                                            <p>{i18next.t('sites.inside-site.backups.manual.desc.3' ,{returnObjects: true})}
                                                            </p>
                                                        </div>
                                                        <div className="tab-backup-button">
                                                            <ResponsiveModalForm visible={backup_modal.mode}
                                                                                 closeModal={() => store.dispatch(toggleBackupFormModal(getSite && getSite.success && getSite.success.id))}>
                                                                <ManualBackupForm/>
                                                            </ResponsiveModalForm>
                                                            <BackupPrimaryView/>

                                                            {/*<button className="btn-theme btn-blue"*/}
                                                            {/*        data-toggle="modal"*/}
                                                            {/*        data-target="#manual-backup"*/}
                                                            {/*        onClick={() => this.createBackup()}*/}
                                                            {/*        disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}*/}
                                                            {/*>{i18next.t('sites.inside-site.backups.manual.create-backup' ,{returnObjects: true})}*/}
                                                            {/*</button>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-block table-responsive mt-50 listing-table-alt">
                                                    <table className="mb-50 table-with-action-buttons">

                                                        <TableHead fields={
                                                                    lang === "en" ? 
                                                                    manualFields_en

                                                                    :

                                                                    manualFields_sv
                                                                }
                                                                   params={params}
                                                                   noActions={true}
                                                                   onClick={this.toggleSort}/>

                                                        <tbody>
                                                        {manualBackupProcessing ?
                                                            <td colSpan={5} className="text-center"><Loading/>
                                                            </td> :
                                                            manualSiteBackups && manualSiteBackups.length ? manualSiteBackups.map((siteBackup, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        {/*<td>*/}
                                                                        {/*    {unslufigy(ucFirst(siteBackup.type))}*/}
                                                                        {/*</td>*/}
                                                                        <td>{siteBackup.name}</td>
                                                                        <td>{Moment(siteBackup.created_at).format('YYYY-MM-DD h:mm A')}</td>
                                                                        <td>{Moment(siteBackup.expiry_date).format('YYYY-MM-DD h:mm A')}</td>
                                                                        {/*<td>{siteBackup.remarks !== " " ? siteBackup.remarks : "N/A"}</td>*/}
                                                                        {(site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring') ?
                                                                            <>
                                                                                <td className="action">
                                                                                    <div
                                                                                        className="dropdown dropdown-alt text-center">
                                                                                        <i className="icon-more"
                                                                                           // data-toggle="dropdown"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </> :
                                                                            <>

                                                                                <td className="action">
                                                                                    <div
                                                                                        className="dropdown dropdown-alt text-center">
                                                                                        <i className="icon-more"
                                                                                           data-toggle="dropdown"
                                                                                        />
                                                                                        <div
                                                                                            className="dropdown-menu"
                                                                                            aria-labelledby="dropdownMenuButton">
                                                                                            <a href="#!"
                                                                                               className="dropdown-item"
                                                                                               type="button"
                                                                                               onClick={() => this.restoreBackup(siteBackup.id)}>
                                                                                                {i18next.t('sites.inside-site.backups.manual.action.restore' ,{returnObjects: true})}

                                                                                            </a>
                                                                                            <a href="javascript:void(0);"
                                                                                               className="dropdown-item"
                                                                                               onClick={() => this.deleteConfirm(siteBackup.id)}
                                                                                            >
                                                                                                {i18next.t('sites.inside-site.backups.manual.action.delete' ,{returnObjects: true})}

                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </>}
                                                                    </tr>

                                                                )
                                                            }) : <tr>
                                                                <td colSpan={5}>{i18next.t('sites.inside-site.backups.no-backup' ,{returnObjects: true})}</td>
                                                            </tr>
                                                        }
                                                        </tbody>
                                                    </table>
                                                    {manualBackupPages.total > 10 && (manualBackupPages.to !== pages.total) &&
                                                    <div className="load-more ml-3">
                                                        <button className="btn-theme mt-10"
                                                                onClick={() => this.loadMoreManualBackup()}>{processing ? <>Load
                                                            More <Loading className="sm-loader-position" height="15"
                                                                          width="15"/></> : `Load More`}</button>
                                                    </div>
                                                    }
                                                    {/*{ manualSiteBackups && manualSiteBackups.length ? (
                                                        <div className="fixed-footer backup-footer pr-60" id="inside-organization-pagination">
                                                            <PaginationButtons pages={manualBackupPages}
                                                                               processing={processing}
                                                                               fetchAction={toggleManualBackupListFilter}/>
                                                        </div>
                                                    ) : null}*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Page>
                            </div>
                        </> :
                        current_path === demo_site_path
                        &&
                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <div
                                    className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                    {this.state.edit === false ? <>
                                            <h2>{"Demo Site"}</h2></> :
                                        <DisplayNameForm data={"Demo Site"}/>}
                                    {this.state.edit === false ?
                                        <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                            <i className="icon-edit"/></a> : <>
                                            <button className="btn-theme btn-black"
                                                    disabled>{i18next.t('sites.page-heading.edit.update' ,{returnObjects: true})}
                                            </button>
                                            <button className="btn-theme btn-black"
                                                    onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel' ,{returnObjects: true})}
                                            </button>
                                        </>}
                                </div>
                                <div className="breadcrumb-block">
                                    <span><Link to={'/sites'}>{i18next.t('sites.page-heading.breadcrum' ,{returnObjects: true})}</Link></span>
                                    <span>{"Demo Site"}</span>
                                </div>
                                {/*<span>Total {total && total < 10 ? '0' + total : total}</span>*/}
                            </div>
                            <div className="content-menu">
                                <SiteDashboardLinks site_id={"demo"}/>
                            </div>
                            <Page title={i18next.t('sites.inside-site.site-nav.backups' ,{returnObjects: true})}>

                                {/*<BackupWorker/>*/}

                                <div className="content-container light-gray-bg">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="pills-daily-tab" data-toggle="pill"
                                               href="#pills-daily" role="tab" aria-controls="pills-daily"
                                               aria-selected="true">{i18next.t('sites.inside-site.backups.daily.daily-btn' ,{returnObjects: true})}</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="pills-manual-tab" data-toggle="pill"
                                               href="#pills-manual" role="tab" aria-controls="pills-manual"
                                               aria-selected="false">{i18next.t('sites.inside-site.backups.manual.manual-btn' ,{returnObjects: true})}</a>
                                        </li>

                                        {/*<li className="nav-item">*/}
                                        {/*    <a className="nav-link" id="pills-download-tab" data-toggle="pill"*/}
                                        {/*       href="#pills-download" role="tab" aria-controls="pills-download"*/}
                                        {/*       aria-selected="false">Download</a>*/}
                                        {/*</li>*/}
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="pills-daily" role="tabpanel"
                                             aria-labelledby="pills-daily-tab">
                                            <div className="table-block table-responsive listing-table-alt">
                                                <table className="mb-50 table-with-action-buttons">

                                                    <TableHead
                                                        fields={
                                                        lang === "en" ? 
                                                        fields_en

                                                        :

                                                        fields_sv
                                                    }
                                                        params={params}
                                                        noActions={true}
                                                        onClick={this.toggleSort}/>

                                                    <tbody>
                                                    <tr>
                                                        <td colSpan={4}>{i18next.t('sites.inside-site.backups.no-backup' ,{returnObjects: true})}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="pills-manual" role="tabpanel"
                                             aria-labelledby="pills-manual-tab">
                                            <div className="padded-block">
                                                <div className="tab-info-wrapper">
                                                    <div className="tab-info-block">
                                                        <p>{i18next.t('sites.inside-site.backups.manual.desc.1' ,{returnObjects: true})}<br/>
                                                        {i18next.t('sites.inside-site.backups.manual.desc.2' ,{returnObjects: true})}
                                                        </p>
                                                        <p>{i18next.t('sites.inside-site.backups.manual.desc.3' ,{returnObjects: true})}
                                                        </p>
                                                    </div>
                                                    <div className="tab-backup-button">
                                                        <button className="btn-theme btn-blue"
                                                                data-toggle="modal"
                                                                data-target="#manual-backup"
                                                                onClick={() => this.createBackup()}
                                                                disabled
                                                        >{i18next.t('sites.inside-site.backups.manual.create-backup' ,{returnObjects: true})}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-block table-responsive mt-50 listing-table-alt">
                                                <table className="mb-50 table-with-action-buttons">

                                                    <TableHead fields={
                                                                lang === "en" ? 
                                                                manualFields_en

                                                                :

                                                                manualFields_sv
                                                            }
                                                               params={params}
                                                               noActions={true}
                                                               onClick={this.toggleSort}/>

                                                    <tbody>
                                                    <tr>
                                                        <td colSpan={5}>{i18next.t('sites.inside-site.backups.no-backup' ,{returnObjects: true})}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Page>
                        </div>
                }
                {/*</Page>*/}
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getSite,
        getManualSiteBackup,
        getSiteDomain,
        getDemoSite,
        getBackup,
        backupList,
        siteDashboard,
        getSiteStatus,
        getSites,
        siteList
    } = state;
    let {params, fields_en, fields_sv, manualFields_en, manualFields_sv,backup_modal} = backupList.data;
    // console.log(` <|backup_modal  |> `,backup_modal )

    let {success} = backupList;
    let {site_status} = siteDashboard.data;

    // let total = getSites && getSites.success && getSites.success.data && getSites.success.data.data && getSites.success.data.data.total;

    let demoBackups = getDemoSite && getDemoSite.success && getDemoSite.success.data && getDemoSite.success.data.data && getDemoSite.success.data.data.backups && getDemoSite.success.data.data.backups.data

    let backupLists =
        success &&
        success.data &&
        success.data.data.data;

    let siteBackups =
        getBackup &&
        getBackup.success &&
        getBackup.success.data

    let manualSiteBackups =
        getManualSiteBackup &&
        getManualSiteBackup.success &&
        getManualSiteBackup.success.data &&
        getManualSiteBackup.success.data.data.data;
    let searchFrom = siteList?.data.searchFrom
    let { payload} = siteList.data
    let siteListParam = siteList.data.params
    /*Pagination for SiteBackups*/
    let current_page = getBackup && getBackup.success &&   getBackup.success &&  getBackup.success.current_page;
    let last_page = getBackup && getBackup.success &&  getBackup.success &&   getBackup.success.last_page;
    let total = getBackup && getBackup.success &&  getBackup.success &&  getBackup.success.total;
    let from = getBackup && getBackup.success &&  getBackup.success &&  getBackup.success.from;
    let pages = {current_page, last_page, total, from};

    /*Pagination for manual siteBackup*/
    let currentPage =  getManualSiteBackup &&  getManualSiteBackup.success &&    getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data && getManualSiteBackup.success.data.data.current_page;
    let lastPage =  getManualSiteBackup &&  getManualSiteBackup.success &&   getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data && getManualSiteBackup.success.data.data.last_page;
    let total_page =  getManualSiteBackup &&  getManualSiteBackup.success &&   getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data && getManualSiteBackup.success.data.data.total;
    let fr_om =  getManualSiteBackup &&  getManualSiteBackup.success &&   getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data && getManualSiteBackup.success.data.data.from;
    let manualBackupPages = {current_page : currentPage, last_page : lastPage, total : total_page, from: fr_om};

    return {
        pages,
        manualBackupPages,
        backupLists,
        getSite,
        getBackup,
        params,
        manualSiteBackups,
        fields_en,
        fields_sv,
        manualFields_en,
        manualFields_sv,
        siteBackups,
        site_status,
        getSiteStatus,
        // total,
        searchFrom,
        siteListParam,
        payload,
        getDemoSite,
        demoBackups,
        getManualSiteBackup,
        backup_modal
    };
}

export default withRouter(connect(mapStateToProps)(BackupList));