import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultOrganizationFormState = {
  payload:{
    // name: '',
    company_name: '',
    country_id: '',
    state: '',
    city: '',
    address_line_1: '',
    address_line_2: '',
    zip: '',
    vat_id: '',

  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``
};

export const OrganizationFormState = { data: DefaultOrganizationFormState };