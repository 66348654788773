import React from 'react';
import Loading from "../loaders/Loading";


class Button extends React.Component
{
    render()
    {
        let {
            processing, text, size, block, disabled, type, noPointerEvents, onClick,
            className, background, border, id, tooltip, tooltipPlacement, tooltipID, title,
            iconButton
        } = this.props;
        return (

            <>
                <button
                    title={ title }
                    className={
                        `btn btn-${
                        type || `primary`} ${ block ? `btn-block` : `` } ${
                        size || ''} ${ disabled ? `item-disabled` : '' } ${
                        className || `` }`
                    }
                    type="submit"
                    id={ id || null }
                    data-tip={ tooltip || null }
                    data-for={ tooltipID || null }
                    style={{
                        pointerEvents: noPointerEvents || disabled ? `none` : processing ? `none` : `all`,
                        background: iconButton ? `transparent` : background || null,
                        border: iconButton ? `none` : null,
                        padding: iconButton ? 0 : null,
                        boxShadow: iconButton ? 0 : null,
                        fontSize: iconButton ? 22 : null,
                        cursor: !disabled ? `pointer` : null,
                        color: iconButton ?
                            type === `primary` ? `#BF9F60` :
                            type === `danger` ? `#EB4C3E` :
                            type === `success` ? `#4CC552` :
                            type === `warning` ? `#f6c163` : null : null,
                        marginRight: 3,
                    }}
                    onClick={e => onClick ? onClick(e) : null} >
                    { processing ? <Loading xs color="white" /> : text }
                </button>

                {/*{if_(tooltip,*/}
                {/*    <ReactTooltip*/}
                {/*        id={ tooltipID || null }*/}
                {/*        class="tooltip-style"*/}
                {/*        place={ tooltipPlacement || 'top' }*/}
                {/*        type="warning"*/}
                {/*        effect="solid"*/}
                {/*    />*/}
                {/*)}*/}
            </>


        )
    }
}


export default Button;