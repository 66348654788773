import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import {setTotalSites} from "../../../pages/site/list/SiteList-Actions";

function _success(success) {
    return {type: 'GET_SITES_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SITES_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SITES_PROCESSING', processing};
}

function getSites() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        // dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let {siteList} = getState();
        let {params} = siteList.data;

        axios({
            url: apiBaseUrl(`customer/sites`),
            method: "get",
            dataType: 'json',
            params,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`kepler_c_token`),
                'lang': lang
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));
            store.dispatch(setTotalSites());

        }).catch(function (error) {
            handleError(error)
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default getSites;
