import {OverViewState} from './Overview-State'

export default function overViewListReducer(state = OverViewState, action) {

    let {data} = {...state};

    switch (action.type) {

        case 'TOGGLE-ADD-CARD-FORM-MODAL':
            if (data.add_card_modal.mode) { // if open, close
                data.add_card_modal = {mode: ``,}
            } else { // if close, open
                data.add_card_modal.mode = true;
            }
            return {...state, data};

        case 'ON-COUPON-CREATE':
            data.couponAdded = "Coupon Added Successfully";
            return {...state, data};

        case 'DEFAULT-COUPON-MESSAGE':
            data.couponAdded = "";
            return {...state, data};

        default:
            return state
    }
}