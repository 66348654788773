import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import { scrollToError } from '../../../helpers/ScrollHelper'
import {cancelDomainFormDispatch, domainFormServerValidationErrors} from "../../../pages/site/domain/form/DomainForm-Action";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
  return { type: 'UPDATE_DOMAIN_SUCCESS', success }
}

function _error(error) {
  return { type: 'UPDATE_DOMAIN_ERROR', error }
}

function _processing(processing) {
  return { type: 'UPDATE_DOMAIN_PROCESSING', processing }
}

function updateDomain(id )
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'

    let { domainList } = getState();
    let { site_id } = domainList.data;

    let data = {
      is_primary : 1
    };

    axios({
      url: apiBaseUrl(`customer/sites/${site_id}/domains/${id}`),
      method: "put",
      dataType: 'json',
       data,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
        'lang': lang
      }
    })
        .then(function(res){
          dispatch(_success(res));
          dispatch(_processing(false));
          handleSuccess( res )
          store.dispatch( cancelDomainFormDispatch( ))
          store.dispatch( getSiteStatus( site_id ) );
          // store.dispatch( getSiteDomain() )

        }).catch(function(error){
      dispatch(_error(error));
      dispatch(_processing(false));
      handleError( error )
      store.dispatch( cancelDomainFormDispatch( ))
      if(error.response && error.response.status === 422 ) {
        validationError(error.response.data.message,error.response.status)
        store.dispatch( domainFormServerValidationErrors( error.response.data.message ))
        scrollToError();
      }
    });
  }
}


export default updateDomain;
