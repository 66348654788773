import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AccountDashboardHeader from "../AccountDashboardHeader";
import Page from "../../../components/shared/pages/Page";
import AccountLinks from "../AccountLinks";
import OverviewWorker from "./OverviewWorker";
import Loading from "../../../components/shared/loaders/Loading";
import store from "../../../Store";
import { toggleAddCardFormModal } from "./Overview-Actions";
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import BillingForm from "../billing/Billing";
import swal from "sweetalert";
import postDefaultCard from "../../../ajax/actions/card/default_card_post";
import deleteCard from "../../../ajax/actions/card/card_delete";
import { scrollToError } from "../../../helpers/ScrollHelper";
import getDownloadCurrentInvoice from "../../../ajax/actions/invoice/invoice_current_download_get";
import currentPayPost from "../../../ajax/actions/pay/current_pay_post";
import {
  fullDateFormat,
  fullDateStringNoTime,
  justDate,
} from "../../../helpers/DateTimeHelper";
import moment from "moment";
import { endOfURL, pushToPage } from "../../../helpers/UrlHelper";
import SiteTableCell from "../../site/list/SiteTableCell";
import NoCardMessage from "../../../components/shared/pages/NoCardMessage";
import i18next from "i18next";
import { onCouponFormSubmit } from "../coupon/form/CouponForm-Action";
import CouponForm from "../coupon/form/CouponForm";

class Overview extends React.Component {
  state = {
    discount: true,
    activate: false,
    coupon: ``,
  };

  onClickDiscount = () => {
    this.setState({ discount: false });
    this.setState({ activate: true });
  };

  download() {
    store.dispatch(getDownloadCurrentInvoice());
  }

  CouponFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onCouponFormSubmit());
  };

  currentPay = () => {
    swal(
      i18next.t("account.inside-account.overview.card-details.swal-pay.desc", {
        returnObjects: true,
      }),
      {
        buttons: {
          cancel: i18next.t(
            "account.inside-account.overview.card-details.swal-pay.btns.cancel",
            { returnObjects: true }
          ),
          catch: {
            text: i18next.t(
              "account.inside-account.overview.card-details.swal-pay.btns.confirm",
              { returnObjects: true }
            ),
            value: true,
          },
        },
      }
    ).then((value) => {
      if (value) store.dispatch(currentPayPost());
    });
  };

  makeDefaultConfirm = (card_id) => {
    swal(
      i18next.t(
        "account.inside-account.overview.card-details.swal-default.desc",
        { returnObjects: true }
      ),
      {
        buttons: {
          cancel: i18next.t(
            "account.inside-account.overview.card-details.swal-default.btns.cancel",
            { returnObjects: true }
          ),
          catch: {
            text: i18next.t(
              "account.inside-account.overview.card-details.swal-default.btns.confirm",
              { returnObjects: true }
            ),
            value: card_id,
          },
        },
      }
    ).then((value) => {
      if (value) store.dispatch(postDefaultCard(card_id));
    });
  };

  removeCard = (card_id) => {
    swal(
      i18next.t(
        "account.inside-account.overview.card-details.swal-remove.desc",
        { returnObjects: true }
      ),
      {
        buttons: {
          cancel: i18next.t(
            "account.inside-account.overview.card-details.swal-remove.btns.cancel",
            { returnObjects: true }
          ),
          catch: {
            text: i18next.t(
              "account.inside-account.overview.card-details.swal-remove.btns.confirm",
              { returnObjects: true }
            ),
            value: card_id,
          },
        },
      }
    ).then((value) => {
      if (value) store.dispatch(deleteCard(card_id));
    });
  };

  render() {
    let {
      cardDetail,
      getCard,
      add_card_modal,
      getCurrentInvoice,
      currentPay,
      searchFrom,
      params,
      list,
      getLoggedInUser,
      couponAdded,
      sale,
    } = this.props;

    let { processing } = getCard;
    let statusData = (getCurrentInvoice && getCurrentInvoice.success) || "";
    let currentPayProcessing = currentPay.processing;
    let { billing_type } = getLoggedInUser.success.data.data;

    return (
      <>
        <AccountDashboardHeader />
        {params.q !== "" && searchFrom === endOfURL() ? (
          <>
            <div className="boxed-block">
              <div className="listing-table-block mt-60">
                <div className="table-responsive listing-table">
                  <table>
                    <tbody>
                      <SiteTableCell />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <NoCardMessage />

            <div className="boxed-block">
              <div className="heading-block mt-70">
                <h2>
                  {i18next.t("account.page-heading", { returnObjects: true })}
                </h2>
                {/*<span>Total 02</span>*/}
              </div>
              <div className="content-menu">
                <AccountLinks site_id={this.props.match.params.id} />
              </div>
              <Page
                title={i18next.t("account.account-nav.overview", {
                  returnObjects: true,
                })}
                processing={processing}
              >
                {/* {sale && (
                  <div className="payment-notice">
                    <div className="light-gray-bg">
                      <div className="notice">
                        <h6 className="ml-5">
                          {sale && sale.valid_months} Month Free Credit
                        </h6>
                      </div>
                      <div className="payment-notice-message">
                        <p>
                          Your {sale && sale.valid_months} month free credit is
                          activate and will end on{" "}
                          {sale && fullDateStringNoTime(sale.expires_on)}. Feel
                          free to create as many sites as you like and contact
                          our support if you have any questions!
                        </p>
                      </div>
                    </div>
                  </div>
                )} */}

                {((getLoggedInUser.success &&
                  getLoggedInUser.success.data.data.has_active_card) ||
                  billing_type === "fortnox") && (
                  <>
                    <div className="content-container light-gray-bg">
                      <div className="text-block mb-20">
                        <h3>Status</h3>
                      </div>
                      {getCurrentInvoice.success ? (
                        <>
                          <div className="overview-block">
                            <div className="single-overview">
                              <p>
                                {i18next.t(
                                  "account.inside-account.overview.status.current-balance",
                                  { returnObjects: true }
                                )}
                              </p>
                              <h3>
                                {getLoggedInUser.success &&
                                getLoggedInUser?.success?.data?.data
                                  ?.organization.billable === 1 ? (
                                  // <>{statusData.current_amount} {statusData.currency}</> : "Contract"} </h3>
                                  // {Math.sign(statusData.current_amount)}
                                  <>
                                    {statusData.current_amount % 1 === 0
                                      ? statusData.current_amount
                                      : statusData.current_amount.toFixed(
                                          2
                                        )}{" "}
                                    {statusData.currency}
                                  </>
                                ) : (
                                  "Contract"
                                )}{" "}
                              </h3>
                            </div>

                            <div className="single-overview">
                              <p>
                                {i18next.t(
                                  "account.inside-account.overview.status.number-of",
                                  { returnObjects: true }
                                )}
                              </p>
                              <h3>
                                {statusData.number_of_sites}{" "}
                                {i18next.t(
                                  "account.inside-account.overview.status.sites",
                                  { returnObjects: true }
                                )}{" "}
                              </h3>
                            </div>
                            {list && list.length !== 0 ? (
                              <div className="single-overview">
                                <p>
                                  {i18next.t(
                                    "account.inside-account.overview.status.next-billing",
                                    { returnObjects: true }
                                  )}
                                </p>
                                <h3>
                                  {justDate(statusData.next_billing_date)}
                                </h3>
                              </div>
                            ) : null}

                            {/*<div className="single-overview">*/}
                            {/*    <p>Invoice </p>*/}
                            {/*    <h3><a href="#!" type="button" onClick={() => this.download()}>Download</a></h3>*/}
                            {/*</div>*/}
                            {statusData.current_amount > 0 &&
                            billing_type === "stripe" ? (
                              <div className="pay-now-btn">
                                <button
                                  className="btn-theme btn-sm"
                                  onClick={() => {
                                    this.currentPay();
                                  }}
                                  disabled={statusData.current_amount === 0}
                                >
                                  {currentPayProcessing ? (
                                    <>
                                      <Loading />
                                      {i18next.t(
                                        "account.inside-account.general.pay-now",
                                        { returnObjects: true }
                                      )}
                                    </>
                                  ) : (
                                    i18next.t(
                                      "account.inside-account.general.pay-now",
                                      { returnObjects: true }
                                    )
                                  )}
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </>
                )}
                {/*<div className="content-container light-gray-bg">*/}
                {/*    <div className="text-block mb-20 d-flex justify-content-between mx-100">*/}
                {/*        <h3>Card Details</h3>*/}
                {/*        <div className="add-card">*/}
                {/*            <ResponsiveModalForm visible={add_card_modal.mode}*/}
                {/*                                 extraClass="add-new-card-popup" backdrop="static"*/}
                {/*                                 closeModal={() => store.dispatch(toggleAddCardFormModal())}>*/}
                {/*                <BillingForm/>*/}
                {/*            </ResponsiveModalForm>*/}
                {/*            {getLoggedInUser.success && getLoggedInUser.success.data.data.has_active_card ?*/}
                {/*                <button className="btn-theme btn-outline blue-outline"*/}
                {/*                        data-toggle="modal"*/}
                {/*                        data-target="#public-path"*/}
                {/*                        onClick={() => store.dispatch(toggleAddCardFormModal())}*/}
                {/*                >Add Card*/}
                {/*                </button> :*/}
                {/*                <div className="button-wrapper">*/}
                {/*                    <Link className="btn-theme btn-outline blue-outline"*/}
                {/*                          to={`/account/activate-account`}>*/}
                {/*                        Add Card*/}
                {/*                    </Link>*/}
                {/*                </div>*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {processing ?*/}
                {/*        <Loading/>*/}
                {/*        :*/}

                {/*        <div className="card-details-block">*/}
                {/*            {cardDetail && cardDetail.length ? cardDetail.map((card, i) => {*/}
                {/*                return (*/}
                {/*                    <div className="single-card-details" key={i}>*/}
                {/*                        <div className="card-info">*/}
                {/*                            <h6>{card.card_brand_label}*/}
                {/*                                <span>XXXX-{card.card_last_four} </span></h6>*/}
                {/*                            <p>Expired {card.expiry_date} - Added*/}
                {/*                                on {moment(card.created_at).format("MMM Do YYYY")}</p>*/}
                {/*                        </div>*/}
                {/*                        /!*{card.default === 1 ? null :*!/*/}
                {/*                        <div className="button-block d-flex align-items-center">*/}
                {/*                            <div*/}
                {/*                                className="dropdown dropdown-alt text-center">*/}
                {/*                            <span className="icon-more "*/}
                {/*                                  data-toggle="dropdown"/>*/}
                {/*                                <div*/}
                {/*                                    className="dropdown-menu"*/}
                {/*                                    aria-labelledby="dropdownMenuButton">*/}
                {/*                                    /!*<a href="#!"*!/*/}
                {/*                                    /!*   className="dropdown-item"*!/*/}
                {/*                                    /!*   type="button"*!/*/}
                {/*                                    /!*>*!/*/}
                {/*                                    /!*    Edit Card Details*!/*/}
                {/*                                    /!*</a>*!/*/}
                {/*                                    <button*/}
                {/*                                        className="dropdown-item"*/}
                {/*                                        type="button"*/}
                {/*                                        onClick={() => {*/}
                {/*                                            this.removeCard(card.id)*/}
                {/*                                        }}*/}
                {/*                                        disabled={card.default}*/}
                {/*                                    >*/}
                {/*                                        Remove Card*/}
                {/*                                    </button>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <button*/}
                {/*                                className={card.default ? "btn-theme btn-blue ml-4" : "btn-theme ml-4"}*/}
                {/*                                onClick={() => {*/}
                {/*                                    scrollToError();*/}
                {/*                                    this.makeDefaultConfirm(card.id)*/}
                {/*                                }}*/}
                {/*                                disabled={card.default}>{card.default ? "Default" : "Make Default"}*/}
                {/*                            </button>*/}
                {/*                            /!*<a href="#"><i className="icon-more"></i></a>*!/*/}
                {/*                            /!*<a href="#" className="btn-theme btn-blue">Default</a>*!/*/}

                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                )*/}
                {/*            }) : "Please add your credit/debit card to get started. You will not be charged anything yet!"}*/}
                {/*        </div>}*/}

                {billing_type !== "fortnox" && (
                  <div className="content-container light-gray-bg">
                    <div className="text-block mb-20 d-flex justify-content-between mx-100">
                      <h3>
                        {i18next.t(
                          "account.inside-account.overview.card-details.heading",
                          { returnObjects: true }
                        )}
                      </h3>
                      <div className="add-card">
                        <ResponsiveModalForm
                          visible={add_card_modal.mode}
                          extraClass="add-new-card-popup"
                          backdrop="static"
                          closeModal={() =>
                            store.dispatch(toggleAddCardFormModal())
                          }
                        >
                          <BillingForm />
                        </ResponsiveModalForm>
                        {getLoggedInUser.success &&
                        getLoggedInUser.success.data.data.has_active_card ? (
                          <button
                            className="btn-theme btn-outline blue-outline"
                            data-toggle="modal"
                            data-target="#public-path"
                            onClick={() =>
                              store.dispatch(toggleAddCardFormModal())
                            }
                          >
                            {i18next.t(
                              "account.inside-account.general.add-card",
                              { returnObjects: true }
                            )}
                          </button>
                        ) : (
                          <div className="button-wrapper">
                            <Link
                              className="btn-theme btn-outline blue-outline"
                              to={`/account/activate-account`}
                            >
                              {i18next.t(
                                "account.inside-account.general.add-card",
                                { returnObjects: true }
                              )}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>

                    {/*        <button className="btn-theme btn-outline blue-outline" data-toggle="modal"*/}
                    {/*                data-target="#public-path"*/}
                    {/*                onClick={() => store.dispatch(toggleAddCardFormModal())}*/}
                    {/*        >{i18next.t('account.inside-account.general.add-card', {returnObjects: true})}*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {processing ? (
                      <Loading />
                    ) : (
                      <div className="card-details-block">
                        {cardDetail && cardDetail.length
                          ? cardDetail.map((card, i) => {
                              return (
                                <div className="single-card-details" key={i}>
                                  <div className="card-info">
                                    <h6>
                                      {card.card_brand_label}
                                      <span>XXXX-{card.card_last_four} </span>
                                    </h6>
                                    <p>
                                      {i18next.t(
                                        "account.inside-account.overview.card-details.expired",
                                        { returnObjects: true }
                                      )}{" "}
                                      {card.expiry_date} -{" "}
                                      {i18next.t(
                                        "account.inside-account.overview.card-details.added",
                                        { returnObjects: true }
                                      )}{" "}
                                      {moment(card.created_at).format(
                                        "MMM Do YYYY"
                                      )}
                                    </p>
                                  </div>
                                  <div className="button-block d-flex align-items-center">
                                    <div className="dropdown dropdown-alt text-center">
                                      <span
                                        className="icon-more "
                                        data-toggle="dropdown"
                                      />
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        {/*<a href="#!"*/}
                                        {/*   className="dropdown-item"*/}
                                        {/*   type="button"*/}
                                        {/*>*/}
                                        {/*    Edit Card Details*/}
                                        {/*</a>*/}
                                        <button
                                          className="dropdown-item"
                                          type="button"
                                          onClick={() => {
                                            this.removeCard(card.id);
                                          }}
                                          disabled={card.default}
                                        >
                                          {i18next.t(
                                            "account.inside-account.general.remove-card",
                                            { returnObjects: true }
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    <button
                                      className={
                                        card.default
                                          ? "btn-theme btn-blue ml-4"
                                          : "btn-theme ml-4"
                                      }
                                      onClick={() => {
                                        scrollToError();
                                        this.makeDefaultConfirm(card.id);
                                      }}
                                      disabled={card.default}
                                    >
                                      {card.default
                                        ? i18next.t(
                                            "account.inside-account.general.default",
                                            { returnObjects: true }
                                          )
                                        : i18next.t(
                                            "account.inside-account.general.make-default",
                                            { returnObjects: true }
                                          )}
                                    </button>
                                    {/*<a href="#"><i className="icon-more"></i></a>*/}
                                    {/*<a href="#" className="btn-theme btn-blue">Default</a>*/}
                                  </div>
                                </div>
                              );
                            })
                          : i18next.t(
                              "account.inside-account.overview.card-details.not-added",
                              {
                                returnObjects: true,
                              }
                            )}
                      </div>
                    )}
                  </div>
                )}
                <OverviewWorker />

                <div className="content-container light-gray-bg">
                  <div className="text-block mb-20 d-flex justify-content-between mx-100">
                    <h3>
                      {i18next.t(
                        "account.inside-account.overview.discount-code.heading",
                        { returnObjects: true }
                      )}
                    </h3>
                  </div>

                  <div className="card-details-block">
                    <div className="single-card-details">
                      <div className="card-info">
                        <p className="mb-0">
                          {i18next.t(
                            "account.inside-account.overview.discount-code.desc",
                            { returnObjects: true }
                          )}
                        </p>
                      </div>
                      {!couponAdded ? (
                        <div className="button-block d-flex align-items-center">
                          <form _lpchecked="1" onSubmit={this.CouponFormSubmit}>
                            <div className="button-block d-flex align-items-end">
                              {this.state.activate === true &&
                                this.state.discount === false && (
                                  <>
                                    <CouponForm />
                                  </>
                                )}
                              {this.state.discount === true && (
                                <button
                                  className="btn-theme btn-blue ml-4"
                                  onClick={this.onClickDiscount}
                                >
                                  {i18next.t(
                                    "account.inside-account.overview.discount-code.btn.enter",
                                    { returnObjects: true }
                                  )}
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      ) : (
                        <span className="coupon-success-message ml-5">
                          <h6> {couponAdded ? couponAdded : null}</h6>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/*</div>*/}
              </Page>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    route,
    getCard,
    overViewList,
    getCurrentInvoice,
    getSites,
    siteList,
    getLoggedInUser,
    currentPay,
  } = state;
  let searchFrom = siteList?.data.searchFrom;
  let { params, payload } = siteList.data;
  let { success } = getSites;
  let list = success?.data.data.data;
  let sale =
    getLoggedInUser &&
    getLoggedInUser.success &&
    getLoggedInUser.success.data &&
    getLoggedInUser.success.data.data &&
    getLoggedInUser.success.data.data.sale_tracking;
  let user = route && route.data.user;
  let cardDetail = getCard && getCard.success;
  let { add_card_modal, couponAdded } = overViewList.data;
  return {
    getCard,
    user,
    cardDetail,
    overViewList,
    add_card_modal,
    getCurrentInvoice,
    getSites,
    siteList,
    currentPay,
    searchFrom,
    params,
    list,
    getLoggedInUser,
    payload,
    couponAdded,
    sale,
  };
}

export default withRouter(connect(mapStateToProps)(Overview));
