import React from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom';
import AccountDashboardHeader from "../AccountDashboardHeader";
import {endOfURL} from "../../../helpers/UrlHelper";
import SiteTableCell from "../../site/list/SiteTableCell";
import Billing from "../billing/Billing";
import NoCardMessage from "../../../components/shared/pages/NoCardMessage";
import AccountLinks from "../AccountLinks";
import Page from "../../../components/shared/pages/Page";
import i18next from 'i18next'

class ActivateAccount extends React.Component {

    render() {
        let {
            searchFrom,
            params, getLoggedInUser
        } = this.props;

        return (
            <>
                <AccountDashboardHeader/>
                {params.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {/*{(getLoggedInUser.success && getLoggedInUser?.success?.data?.data?.has_active_card) ||*/}
                        {/*(getLoggedInUser.success && getLoggedInUser?.success?.data?.data?.organization.billable === 1) ?*/}
                        {/*    <NoCardMessage/> : null*/}
                        {/*}*/}
                        <div className="boxed-block">

                            <div className="heading-block mt-70">
                                <h2>{i18next.t('account.page-heading' ,{returnObjects: true})}</h2>
                            </div>
                            <div className="content-menu">
                                <AccountLinks site_id={this.props.match.params.id}/>
                            </div>
                            <Page title={i18next.t('account.account-nav.activate-account', {returnObjects: true})}>
                                <div className="content-container light-gray-bg">
                                    <div className="upper-block text-center mb-75">
                                        <div className="title-block">
                                            <h3 className="mt-30">{i18next.t('account.inside-account.activate-account.heading.1' ,{returnObjects: true})}</h3>
                                            <span>{i18next.t('account.inside-account.activate-account.heading.2' ,{returnObjects: true})}</span>
                                        </div>
                                    </div>
                                    <Billing mode={"activate_account"}/>
                                </div>
                            </Page>
                        </div>
                    </>
                }
            </>
        );
    }

}

function mapStateToProps(state) {
    let {
    siteList,
    getLoggedInUser
} = state;

let searchFrom = siteList?.data.searchFrom
let {params} = siteList.data

return {
    siteList,
    searchFrom,
    params,
    getLoggedInUser
}
}
export default withRouter(connect(mapStateToProps)(ActivateAccount));