import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import getManualSiteBackup from "./site_manual_backup_get";
import {toggleBackupFormModal} from "../../../pages/site/backup/list/Backup-Action";
import {setManualBackupError} from "../../../pages/site/backup/form/ManualBackupForm-Action";

function _success(success) {
  return { type: 'CREATE_BACKUP_SUCCESS', success }
}

function _error(error) {
  return { type: 'CREATE_BACKUP_ERROR', error }
}

function _processing(processing) {
  return { type: 'CREATE_BACKUP_PROCESSING', processing }
}

function createBackup() {
  return (dispatch, getState) => {
      const lang = localStorage.getItem('lang') || 'en'

      let {backupList, manualBackupForm} = getState();

        let {site_id} = backupList.data;
        let {payload} = manualBackupForm.data
        if (payload.name === "") {
            delete payload.name
        }


    dispatch(_success(null))
    dispatch(_error(null))
    dispatch(_processing(true))

    axios({
      url: apiBaseUrl(`customer/sites/${site_id}/backups`),
      method: 'post',
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
          'lang': lang
      }
    })
      .then(function(res) {
        dispatch(_success(res))
        dispatch(_processing(false))
        handleSuccess(res)
        store.dispatch(toggleBackupFormModal());
        store.dispatch(getSiteStatus(site_id));
          store.dispatch(getManualSiteBackup())

      }).catch(function(error) {
        dispatch(_error(error))
        dispatch(_processing(false))
        handleError(error)
        store.dispatch(setManualBackupError(error.response.data))
    })
  }
}

export default createBackup
