import i18next from "i18next";
import React from "react";
import Page from "../../components/shared/pages/Page";
// import ActivityHeading from "./ActivityHeading";

class Support extends React.Component {
  render() {
    return (
      <>
        <div className="boxed-block support-page">
          <div className="heading-block mt-20">
            <div className="title-block">
              <h2>
                {i18next.t("support.page-heading", {
                  returnObjects: true,
                })}
              </h2>
            </div>
          </div>
          <Page
            title={i18next.t("account.account-nav.payment", {
              returnObjects: true,
            })}
          >
            <div className="content-container light-gray-bg">
              <div className="content-details">
                <div className="content-details-wrapper">
                  <div className="row">
                    <div className="col-xl-10">
                      <div className="text-block mb-20">
                        <h3>
                          {i18next.t("support.sub-heading", {
                            returnObjects: true,
                          })}
                        </h3>
                        <p>
                          {i18next.t("support.desc.1", {
                            returnObjects: true,
                          })}
                        </p>
                      </div>
                      <div className="text-block mb-20 mt-3">
                        <h6>
                          {i18next.t("support.title.1", {
                            returnObjects: true,
                          })}
                        </h6>
                      </div>
                      <div className="text-block mb-20 mt-3">
                        <h6 className="mb-0">
                          {i18next.t("support.title.2", {
                            returnObjects: true,
                          })}
                        </h6>
                        <p>
                          {i18next.t("support.desc.2", {
                            returnObjects: true,
                          })}
                        </p>
                      </div>
                      <div className="text-block mb-20 mt-3">
                        <h6 className="mb-0">
                          {i18next.t("support.title.3", {
                            returnObjects: true,
                          })}
                        </h6>
                        <p>
                          {i18next.t("support.desc.3", {
                            returnObjects: true,
                          })}
                        </p>
                        <p className="text-underline">
                          {i18next.t("support.desc.4", {
                            returnObjects: true,
                          })}
                        </p>
                      </div>
                      <div className="text-block mb-20 mt-4">
                        <h6>
                          {i18next.t("support.title.4", {
                            returnObjects: true,
                          })}
                        </h6>
                      </div>
                      <p className="mt-0 mb-0">
                        {i18next.t("support.desc.5", {
                          returnObjects: true,
                        })}
                        <br />
                        {i18next.t("support.desc.6", {
                          returnObjects: true,
                        })}
                        <br />
                        {i18next.t("support.desc.7", {
                          returnObjects: true,
                        })}
                        <b>
                          {i18next.t("support.desc.8", {
                            returnObjects: true,
                          })}
                        </b>
                      </p>
                      <p>
                        {i18next.t("support.desc.9", {
                          returnObjects: true,
                        })}
                      </p>
                      <p>
                        {i18next.t("support.desc.10", {
                          returnObjects: true,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Page>
        </div>
      </>
    );
  }
}

export default Support;
