import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router';
import Browsers from "../../../assets/images/browsers.svg"
import BrowsersYellow from "../../../assets/images/browsers-yellow.svg"
import Dbrowsers from "../../../assets/images/d-browsers.svg"
import DbrowsersYellow from "../../../assets/images/d-browsers-yellow.svg"
import i18next from "i18next";
import {ucFirst} from "../../../helpers/StringHelper";
import getMigration from "../../../ajax/actions/manual-migration/migration_get";
import store from "../../../Store"

class MigrationTableCell extends React.Component {

    onclick(id) {
        store.dispatch(getMigration(id))
        this.props.history.push(`/migration/${id}/detail`)
    }

    render() {
        let {list, getsMigration, first_name} = this.props;
        let {processing} = getsMigration

        return (
            <>
                <div className="listing-table-block mt-60">
                    <div className="table-responsive listing-table">
                        <table>
                            <tbody>
                            {list?.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                            <span
                                                                className="title">Site Name</span>
                                                <div className="media">
                                                    <div className="media-img d-flex mr-3">
                                                        <img
                                                            src={(l.status === "completed" || l.status === "migrating"
                                                            || l.status === "pending" ? Browsers : BrowsersYellow)}
                                                            // className="d-light"
                                                            alt="#"/>
                                                        {/*<img*/}
                                                        {/*    src={(l.status === "failed"  ||*/}
                                                        {/*    l.status === "rejected" || l.status === "cancelled" || l.status === "pending"*/}
                                                        {/*        ? DbrowsersYellow : Dbrowsers)}*/}
                                                        {/*    className="d-dark" alt="#"/>*/}
                                                    </div>
                                                    <div className="media-body">
                                                        <>
                                                            <h4><Link to="#"> {l.site_name} </Link>
                                                            </h4>
                                                        </>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="text-right">
                                                <span className="title">Status</span>
                                                <h6>{ucFirst(l.status)}</h6>
                                            </td>

                                            <td className="table-view-block">
                                                <div className="button-wrapper mt-30">

                                                    <button type="reset" className="btn-theme btn-lg"
                                                            onClick={() => this.onclick(l.id)}
                                                    >
                                                        View
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                    )
                                }
                            ) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                {!processing && list && list.length === 0 ?
                    <div className="content-container light-gray-bg">
                        <div className="content-message-block">
                            <h2>Hi <span>{first_name}</span>,<br/></h2>
                            <p> Welcome to the Kepler Migration tool. Here you will be able to request a
                                site migration from any host for free. All you need to do is click "Request a Migration"
                                and
                                provide the necessary details and we will contact you to schedule a time for your site
                                migration.</p>

                            {/*<p>You currently do not have any made payments.Don't worry,please navigate
                                to the overview tab to see your outstanding payments once you have created
                                your first site.</p>*/}
                            <p>You currently do not have any migration requests</p>

                            <div className="button-wrapper inline-buttons mt-30">
                                <a href="#" className="button-wrapper">
                                    <Link to='/migration/create'>
                                        <button type="reset" className="btn-theme btn-blue"
                                        >Request a Migration
                                        </button>
                                    </Link>
                                </a>
                                {/*                                <a href="https://keplerhosting.com/general/faq/" target="_blank"
                                   className="btn-theme btn-dashed">{i18next.t('sites.no-site.knowledge', {returnObjects: true})}</a>*/}
                            </div>
                        </div>
                    </div>
                    : null
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
    getsMigration,
    getLoggedInUser
} = state;

let {first_name} = getLoggedInUser?.success.data.data
let {success} = getsMigration
let list = success && success.data
return {getsMigration, list, first_name};
}

export default withRouter(connect(mapStateToProps)(MigrationTableCell));
