export default function getsMigrationReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'GETS_MIGRATION_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'GETS_MIGRATION_ERROR':
            return {...state, ...{error: action.error}};

        case 'GETS_MIGRATION_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default: return state;
    }
}