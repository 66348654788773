import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import Alert from "react-s-alert";
import {ConfigErrorAlert} from "../../../Config";
import {scrollToError} from "../../../helpers/ScrollHelper";
import getCurrentInvoice from "../../actions/invoice/invoice_current_get"

function _success(success) {
    return {type: 'POST_CURRENT_PAY_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_CURRENT_PAY_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_CURRENT_PAY_PROCESSING', processing};
}

function currentPay() {
    return ( dispatch ) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'


        axios({
            url: apiBaseUrl(`customer/invoices/current/pay`),
            method: "post",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess("Paid Successfully")
                store.dispatch(getCurrentInvoice())
            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error.response.data)
            if (error.response && error.response.status === 422) {
                validationError(error.response.data.message,error.response.status)
                // Alert.error(`Error ${422} - Validation Error `, ConfigErrorAlert);
                scrollToError();
            }
        });
    }
}
export default currentPay;