import React from 'react';
import {connect} from 'react-redux';
import SftpWorker from "./SftpWorker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import store from "../../../../Store";
import credentialPost from '../../../../ajax/actions/sftp-database/credential_post';
import Loading from "../../../../components/shared/loaders/Loading";
import Tooltip from "../../../../components/shared/tooltip/Tooltip";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getCredentials from "../../../../ajax/actions/sftp-database/credential_get";
import i18next from 'i18next';
import copy from 'copy-to-clipboard';

class SftpList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load_balancer_public_ip: false,
            sftp_host: false,
            sftp_username: false,
            sftp_password: false,
            sftp_port: false,
            db_name: false,
            db_username: false,
            db_password: false,
            php_myadmin_url: false,
            ssh_terminal_command: false,
            data_center_name: false,
            copy_all_sftp_info:false,
            copy_all_db_info:false,
        };
    }

    componentDidUpdate(prev, prevState) {
        this.state.load_balancer_public_ip = false
        this.state.ssh_terminal_command = false
        this.state.sftp_host = false
        this.state.sftp_username = false
        this.state.sftp_password = false
        this.state.sftp_port = false
        this.state.db_name = false
        this.state.db_username = false
        this.state.db_password = false
        this.state.php_myadmin_url = false
        this.state.ssh_terminal_command = false
        this.state.data_center_name = false
        this.state.copy_all_sftp_info = false
        this.state.copy_all_db_info = false


        if (this.props.createCredentials !== prev.createCredentials) {
            let {credentials, createCredentials} = this.props;
            let {success} = createCredentials;
            if (success) {
                credentials && store.dispatch(getSiteStatus(credentials.site_id))
                this.setState({processing_sftp_password: false, processing_db_password: false})
            }
        }

        if (this.props.getSiteStatus !== prev.getSiteStatus) {
            let {getSiteStatus} = this.props;
            let data = getSiteStatus?.success?.notifications
            data && data.length && data.map((i, keys) => {
                if (i.event_name === "change_site_ssh_password" ||
                    i.event_name === "change_site_db_password") {
                    store.dispatch(getCredentials());
                }
            })
            if (getSiteStatus?.success?.status === "updating") {
                return null
            }
        }
    }

    generateDatabasePassword = () => {
        const payload = {
            "target": "db"
        }
        store.dispatch(credentialPost(payload));
    }

    generateSftpPassword = () => {
        const payload = {
            "target": "ssh"
        }
        store.dispatch(credentialPost(payload));
    }

    handleSftpCopy = (e,sftpAllInfoCopy) => {
        e.preventDefault();
        this.setState({copy_all_sftp_info: true})
        copy(sftpAllInfoCopy, {
            format : "text/plain"
        });
    }

    handleDbCopy = (e,dbAllInfoCopy) => {
        e.preventDefault();
        this.setState({copy_all_db_info: true})
        copy(dbAllInfoCopy, {
            format : "text/plain"
        });
    }

    render() {
        let {
            credential,
            site_status,
            getCredentials,
            getSiteStatus,
            getSite,
            site,
            type,
            demoSiteCredential,
            demoSite
        } = this.props;
        let {processing} = getCredentials.processing;

        let credentials = (type === 'demo' ? demoSiteCredential : credential);

        let sftpAllInfoCopy=""
        let dbAllInfoCopy=""

        if(credentials){
            sftpAllInfoCopy=
                "=== SFTP Details ====" +
                "\nSFTP HOST : " + credentials.sftp_host +
                "\nSFTP Username : " + credentials.sftp_username +
                "\nSFTP Password : " + credentials.sftp_password +
                "\nSFTP Port : " + credentials.sftp_port

            dbAllInfoCopy=
                "=== Database Details ====" +
                "\nphpMyAdmin URL : " + credentials.php_myadmin_url +
                "\nDatabase Name : " + credentials.db_name +
                "\nDatabase Username : " + credentials.db_username +
                "\nDatabase Password : " + credentials.db_password
        }

        return (
            <>
                <SftpWorker/>
                {processing ? <div className="text-center"><Loading/></div> :

                    <>
                        {credentials &&
                        <>
                            <div className="row align-items-end">
                                <div className="col-xl-3 col-lg-3 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.ip', {returnObjects: true})}</div>

                                    <div className="content-info">
                                        <span>  {type === 'demo' ? demoSite.load_balancer_public_ip : getSite?.success?.load_balancer_public_ip}</span>

                                        <div className="text-center action-btn-copy">

                                            <CopyToClipboard
                                                text={type === 'demo' ? demoSite.load_balancer_public_ip : getSite?.success?.load_balancer_public_ip}
                                                onCopy={() => this.setState({load_balancer_public_ip: true})}>
                                                <button className="btn-sm" onClick={this.bikash}>
                                                    {!this.state.load_balancer_public_ip ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.load_balancer_public_ip ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.load_balancer_public_ip ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark`}/>}
                                                                         tooltip={this.state.load_balancer_public_ip ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-sm-6">
                                    <div className="content-label">{i18next.t('sites.inside-site.sftp.datacenter', {returnObjects: true})}</div>

                                    <div className="content-info content-info-wrapper">
                                        <span>  {type === 'demo' ? demoSite.data_center_name : getSite?.success?.data_center_name}</span>

                                        <div className="text-center mt-2 action-btn-copy">

                                            <CopyToClipboard
                                                text={type === 'demo' ? demoSite.data_center_name :getSite?.success?.data_center_name}
                                                onCopy={() => this.setState({data_center_name: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.data_center_name ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.data_center_name ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.data_center_name ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark`}/>}
                                                                         tooltip={this.state.data_center_name ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                    {/*<CopyToClipboard text={sftpAllInfoCopy}*/}
                                    {/*                 onCopy={() => this.setState({copy_all_sftp_info: true})}>*/}
                                        <div className="btn-text-wrapper">
                                            <button
                                                onClick={(e)=>this.handleSftpCopy(e,sftpAllInfoCopy)}
                                                className={this.state.copy_all_sftp_info?"btn-theme btn-text all-copied":"btn-theme btn-text"}>
                                                {!this.state.copy_all_sftp_info ?
                                                    "Copy All SFTP Info"
                                                    :
                                                    this.state.copy_all_sftp_info ?
                                                            "Copied"
                                                        : null}

                                            </button>
                                        </div>
                                    {/*</CopyToClipboard>*/}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.host', {returnObjects: true})}</div>

                                    <div className="content-info content-info">
                                        <span>  {credentials.sftp_host}</span>

                                        <div className="text-center  action-btn-copy">

                                            <CopyToClipboard
                                                text={credentials.sftp_host}
                                                onCopy={() => this.setState({sftp_host: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.sftp_host ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.sftp_host ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.sftp_host ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.sftp_host ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.username', {returnObjects: true})}</div>
                                    <div className="content-info content-info">
                                        <span>{credentials.sftp_username}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard
                                                text={credentials.sftp_username}
                                                onCopy={() => this.setState({sftp_username: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.sftp_username ?
                                                        <Tooltip title={<span
                                                            className={` icon-copy`}/>}
                                                                 tooltip={this.state.sftp_username ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.sftp_username ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.sftp_username ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.password', {returnObjects: true})}</div>
                                    <div className="content-info content">
                                        <span>{credentials.sftp_password}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard text={credentials.sftp_password}
                                                             onCopy={() => this.setState({sftp_password: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.sftp_password ?
                                                        <Tooltip title={<span
                                                            className={` icon-copy`}/>}
                                                                 tooltip={this.state.sftp_password ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.sftp_password ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.sftp_password ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-3 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.port', {returnObjects: true})}</div>
                                    <div className="content-info content-info">
                                        <span>{credentials.sftp_port}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard text={credentials.sftp_port}
                                                             onCopy={() => this.setState({sftp_port: true})}>
                                                <button className="btn-sm">


                                                    {!this.state.sftp_port ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.sftp_port ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.sftp_port ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.sftp_port ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}

                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.command', {returnObjects: true})}</div>
                                    <div className="content-info content-info">
                                    <span>
                                    ssh
                                    -p {credentials.sftp_port} {credentials.sftp_username}@{credentials.sftp_host}</span>
                                        <div className="text-center  action-btn-copy">

                                            <CopyToClipboard
                                                text={"ssh -p" + " " + credentials.sftp_port + " " + credentials.sftp_username + "@" + credentials.sftp_host}
                                                onCopy={() => this.setState({ssh_terminal_command: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.ssh_terminal_command ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.ssh_terminal_command ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.ssh_terminal_command ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.ssh_terminal_command ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="btn-wrapper text-right mb-mt-35">
                                        <button className={`btn-theme btn-lg`}
                                                disabled={type === 'demo' || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                onClick={this.generateSftpPassword}>
                                            {getSiteStatus?.success?.event === "change_site_ssh_password" ? <>
                                                    {i18next.t('sites.inside-site.sftp.generate-sftp-password', {returnObjects: true})}<Loading
                                                    xs/></>
                                                : `${i18next.t('sites.inside-site.sftp.generate-sftp-password', {returnObjects: true})}`}</button>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="row align-items-end">
                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.database-name', {returnObjects: true})}</div>
                                    <div className="content-info content-info">
                                        <span>{credentials.db_name}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard text={credentials.db_name}
                                                             onCopy={() => this.setState({db_name: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.db_name ?
                                                        <Tooltip title={<span
                                                            className={` icon-copy`}/>}
                                                                 tooltip={this.state.db_name ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.db_name ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.db_name ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.database-username', {returnObjects: true})}</div>
                                    <div className="content-info content-info">
                                        <span>{credentials.db_username}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard text={credentials.db_username}
                                                             onCopy={() => this.setState({db_username: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.db_username ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.db_username ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.db_username ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.db_username ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                    <div
                                        className="content-label">{i18next.t('sites.inside-site.sftp.database-password', {returnObjects: true})}</div>
                                    <div className="content-info content-info">
                                        <span> {credentials.db_password}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard text={credentials.db_password}
                                                             onCopy={() => this.setState({db_password: true})}>
                                                <button className="btn-sm">
                                                    {!this.state.db_password ?
                                                        <Tooltip title={<span
                                                            className={`icon-copy`}/>}
                                                                 tooltip={this.state.db_password ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                        :
                                                        this.state.db_password ?
                                                            <div>
                                                                <Tooltip title={<span
                                                                    className={`icon-checkmark `}/>}
                                                                         tooltip={this.state.db_password ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                            </div>
                                                            : null}
                                                </button>
                                            </CopyToClipboard>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-6 col-sm-6">
                                    {/* Copy all sftp info */}
                                    {/*<CopyToClipboard text={dbAllInfoCopy}*/}
                                    {/*                 onCopy={() => this.setState({copy_all_db_info: true})}>*/}
                                        <div className="btn-text-wrapper">
                                            <button
                                                onClick={(e)=>this.handleDbCopy(e,dbAllInfoCopy)}
                                                className={this.state.copy_all_db_info?"btn-theme btn-text all-copied":"btn-theme btn-text"}>
                                                {!this.state.copy_all_db_info ?
                                                    "Copy All DB Info"
                                                    :
                                                    this.state.copy_all_db_info ?
                                                        "Copied"
                                                        : null}

                                            </button>
                                        </div>
                                    {/*</CopyToClipboard>*/}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content-label">phpMyAdmin URL</div>
                                    <div className="content-info">
                                      <span>
                                        {credentials.php_myadmin_url}</span>
                                        <div className="text-center  action-btn-copy">
                                            <CopyToClipboard text={credentials.php_myadmin_url}
                                                             onCopy={() => this.setState({php_myadmin_url: true})}>
                                                <button className="btn-sm">
                                                <span>
                                                {!this.state.php_myadmin_url ?
                                                    <Tooltip title={<span
                                                        className={`icon-copy`}/>}
                                                             tooltip={this.state.php_myadmin_url ? null : `${i18next.t('sites.inside-site.sftp.copy', {returnObjects: true})}`}/>
                                                    :
                                                    this.state.php_myadmin_url ?
                                                        <div>
                                                            <Tooltip title={<span
                                                                className={`icon-checkmark `}/>}
                                                                     tooltip={this.state.php_myadmin_url ? `${i18next.t('sites.inside-site.sftp.copied', {returnObjects: true})}` : null}/>
                                                        </div>
                                                        : null}
                                                </span>
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div
                                        className="btn-wrapper inline-buttons d-flex flex-wrap align-items-center justify-content-between mb-mt-35">
                                        {site_status !== `pending` && (
                                            type === 'demo' ?
                                                <a href={`#`} target="_blank"
                                                   className="open-admin">{i18next.t('sites.inside-site.sftp.open-php', {returnObjects: true})} phpMyAdmin <span
                                                    className="icon-share"/></a> :
                                                <a href={`https://${credentials.php_myadmin_url}`} target="_blank"
                                                   className="open-admin">{i18next.t('sites.inside-site.sftp.open-php', {returnObjects: true})} phpMyAdmin <span
                                                    className="icon-share"/></a>
                                        )}
                                        <a>
                                            <button className="btn-theme btn-outline"
                                                    data-toggle="modal" data-target="#new-database"
                                                    disabled={type === 'demo' || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                    onClick={this.generateDatabasePassword}>
                                                {getSiteStatus?.success?.event === "change_site_db_password" ? <>
                                                        {i18next.t('sites.inside-site.sftp.generate-db-password', {returnObjects: true})}<Loading
                                                        xs/></> :
                                                    `${i18next.t('sites.inside-site.sftp.generate-db-password', {returnObjects: true})}`}</button>
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    getSite
,
    getCredentials
,
    SftpList
,
    siteDashboard
,
    createCredentials
,
    getSiteStatus
,

    getDemoSite
}

= state;

let {params, fields} = SftpList.data;

let {success} = SftpList;

let {site_status} = siteDashboard.data;

let demoSiteCredential = getDemoSite && getDemoSite.success && getDemoSite.success.data && getDemoSite.success.data.data && getDemoSite.success.data.data.credentials
let demoSite = getDemoSite && getDemoSite.success && getDemoSite.success.data && getDemoSite.success.data.data && getDemoSite.success.data.data.site

let site = getSite && getSite.success
let SftpLists =
    success &&
    success.data &&
    success.data.data.data;

let credential =
    getCredentials &&
    getCredentials.success;

return {
    SftpLists,
    getSite,
    getCredentials,
    params,
    site,
    fields,
    credential,
    siteDashboard,
    site_status,
    createCredentials,
    getSiteStatus, getDemoSite, demoSiteCredential, demoSite
};
}

export default connect(mapStateToProps)(SftpList);