import {migrationFormErrors, stepChange} from '../../manual-migration/MigrationForm-Actions'
import store from '../../../Store'

export function onFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
        store.dispatch(migrationFormErrors())
    }
}

export function onCheckedRegisterChange(value) {
    return function (dispatch,) {
        dispatch({type: 'ON-REGISTER-FORM-CHECKED-CHANGE', value});
        store.dispatch(migrationFormErrors());
    }
}

export function customerValidationError(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'CUSTOMER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
        store.dispatch(stepChange())
    }
}

export function setDefaultCustomerRegisterDataStep1() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-REGISTER-DATA',});
    }
}

export function emailErrorMessage(message) {
    return function (dispatch) {
        dispatch({type: 'SET-EMAIL-ERROR-MESSAGE', message});
    }
}

export function customerFormStep1ServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'REGISTER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}


export function cancelRegistersFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-REGISTER-FORM-DISPATCH'});
    }
}

export function setDefaultCustomerFormStep1() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-CUSTOMER-FORM-STEP1'});
    }
}