const DefaultRegisterFormState = {
    payload: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        on_sales:true
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    message: ``
};

export const RegisterFormState = {data: DefaultRegisterFormState}

