import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Login from '../pages/login/Login'
import RedirectTo from "../pages/RedirectTo";
import {urlTrail} from "../helpers/UrlHelper";
// import RegisterForm from "../pages/register/RegisterForm";
import RegisterForm from "../pages/register-single/RegisterForm"
import ForgotPassword from "../pages/forgot-password/Form/ForgotPasswordForm";
import Redirect from "../pages/redirected-from-other/Redirect";
import UserActivation from "../components/shared/pages/UserActivation";
import UserActivated from "../components/shared/pages/UserActivated";

class LoginRoutes extends React.Component {

    render() {
        return (

            <Switch>
                <Route exact path="/" component={Login}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/se/login" component={Login}/>
                <Route exact path="/register" component={RegisterForm}/>
                <Route exact path="/se/register" component={RegisterForm}/>
                <Route exact path="/forgotpassword" component={ForgotPassword}/>
                <Route exact path="/se/forgotpassword" component={ForgotPassword}/>
                <Route exact path="/customer/password/reset/:token/:email" component={ForgotPassword}/>
                <Route exact path="/verify/:token" component={UserActivation}/>
                <Route exact path="/verify/:token/success" component={UserActivated}/>
                <Route exact path="/redirect" component={Redirect}/>

                {/*<Route exact path="/:text" component={Login}/>*/}
                {/*<Route  path="/user/create" component={ UserForm }/>*/}
                {/*<Route path="*" render={() => <RedirectTo path={ urlTrail() } />} />*/}
                <Route path="*" render={() => <RedirectTo path={urlTrail()}/>}/>
            </Switch>
        )
    }
}

export default LoginRoutes;