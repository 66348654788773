import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
// import getDemoSites from "../sites/demo_sites_get";
import store from "../../../Store"
import getUser from "./user_get";
import getOrganization from "../organization/organization_get";

function _success(success) {
    return {type: 'GET_LOGGEDIN_USER_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_LOGGEDIN_USER_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_LOGGEDIN_USER_PROCESSING', processing};
}

function getLoggedInUser(customer_token) {
    return (dispatch) => {
        dispatch(_processing(true));
        // dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let token = customer_token? customer_token : loginToken()
        axios({
            url: apiBaseUrl(`customer/loggedin`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + token,'lang':lang}
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                // store.dispatch(getDemoSites())

                // console.log(` <|  |> `,res.data.data )
                // store.dispatch(getOrganization(res.data.data.organization.id))
                // store.dispatch(getUser(res.data.data.id))
            }).catch(function (error) {

            if (error && error.response &&
                error.response.status === 401) {
                logOut()
            } else {
                handleError(error)
                dispatch(_error(error));
                dispatch(_processing(false));
            }
        });
    }
}


export default getLoggedInUser;