import React from 'react';
import store from '../../Store'
import {connect} from 'react-redux';
import {loggedInUser} from "../../helpers/LocalStorageHelper";
import loginUser from "../../ajax/actions/user/user_login_post";
import "../../routes/AdminRoutes";
import {
    cancelLoginFormDispatch,
    loginFormServerValidationErrors,
} from "./Login-Actions";

import {setUser} from "../../routes/main-route/MainRoute-Actions";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";
import {withRouter} from 'react-router';


class LoginWorker extends React.Component {

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;

        if (this.props.loginUser !== prev.loginUser) {
            let {success, error} = this.props.loginUser;
            if (success) {
                loggedInUser(success.data.data.token);
                store.dispatch(getLoggedInUser())
                // this.props.history.push('/sites')
                window.location.href = '/sites';
            }

            if (error) {
                store.dispatch(cancelLoginFormDispatch());
                if (error.response && error.response.status === 422) {
                    store.dispatch(loginFormServerValidationErrors(error.response.data && error.response.data.errors))
                }
            }
        }

        if (this.props.getLoggedInUser !== prev.getLoggedInUser) {
            let {success} = this.props.getLoggedInUser;
            if (success) {
                store.dispatch(setUser(success))
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(loginUser());
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {loginForm, loginUser, getLoggedInUser} = state;
    let {dispatchAPI, redirectTo} = loginForm.data;
    return {loginForm, dispatchAPI, loginUser, redirectTo, getLoggedInUser};
}

export default withRouter(connect(mapStateToProps)(LoginWorker));