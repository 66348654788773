import store from "../../../Store";
import {stepChangeThirdStep} from "../MigrationForm-Actions";

export function onFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
    }
}

export function changeType(types) {
    return function (dispatch) {
        dispatch({type: 'ON-TYPE-CHANGE', types});
    }
}

export function initializeMigrateFormStep3Error() {
    return function (dispatch) {
        dispatch({type: 'INITIALIZE-MIGRATE-FORM-STEP3-ERRORS'});
    }
}

export function migrateFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MIGRATE-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
        store.dispatch(stepChangeThirdStep(validationErrors))
    }
}

export function setDefaultMigrateData() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATE-DATA',});
    }
}

export function setDefaultMigrateFormStep3() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATE-DATA'});
    }
}