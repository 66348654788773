import {RedirectsState} from './Redirects-State'

export default function redirectsListReducer(state = RedirectsState, action) {

    let {data} = {...state};

    switch (action.type) {

        case 'TOGGLE-REDIRECTS-FORM-MODAL':
            if (data.redirects.mode === true) { // if open, close
                data.redirects = {mode: ``,}
            } else { // if close, open
                data.redirects.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}
