import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import store from '../../../Store';
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSiteListStatus from "../sites/site_list_status_get";
import getSites from "./sites_get";
import {toggleDangerZoneFormModal} from "../../../pages/site/danger-zone/DangerZone-Action";

function _success(success) {
    return {type: 'DELETE_SITE_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_SITE_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_SITE_PROCESSING', processing}
}

function deleteSite(id) {
    return (dispatch) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'


        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken(),'lang': lang},
        };

        axios.delete(apiBaseUrl(`customer/sites/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(toggleDangerZoneFormModal())
                store.dispatch(getSiteListStatus())
                store.dispatch(getSites())
            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteSite;