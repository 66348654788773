import React from 'react';
import SiteDashboardHeader from '../../SiteDashboardHeader';
import Page from '../../../../components/shared/pages/Page';
import {connect} from "react-redux";
import store from "../../../../Store";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import {Link} from "react-router-dom";
import SiteDashboardLinks from '../../SiteDashboardLinks';
import DisplayNameForm from '../../change-display-name/form/DisplayNameForm';
import LogsWorker from './LogsWorker';
import {onClickError, onClickAccess} from './Logs-Action';
import Loading from '../../../../components/shared/loaders/Loading';
import Refresh from '../../../../assets/images/redo-light.svg';
import {endOfURL, secondToLastItemInURL} from '../../../../helpers/UrlHelper';
import i18next from 'i18next';
import SiteTableCell from "../../list/SiteTableCell";

class LogsList extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
        page: "error"
    }

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.setState({edit:false});
    }

    onClickError = () => {
        store.dispatch(onClickError())
        this.state.page = "error"
    }

    onClickAccess = () => {
        store.dispatch(onClickAccess())
        this.state.page = "access"
    }

    onClickRefresh = () => {
        if (this.state.page === "error") {
            store.dispatch(onClickError())
        } else if (this.state.page === "access") {
            store.dispatch(onClickAccess())
        }
    }

    render() {


        let {getSite, site_status, getAccessLogsReducer, params, siteListParam, searchFrom} = this.props
        let {processing} = getAccessLogsReducer;
        let getLogsList = getAccessLogsReducer && getAccessLogsReducer.success && getAccessLogsReducer.success.data

        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/logs'

        return (
            <>
                <SiteDashboardHeader />
                <LogsWorker/>
                {siteListParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    <div className="boxed-block">
                    <div className="heading-block mt-70">
                        <div className={this.state.edit === true ? "heading-content edit-on" :"heading-content"}>

                        {this.state.edit === false ? <>
                                        <h2>{current_path === demo_site_path?"Demo Site":getSite?.success?.display_name}</h2></> :
                                    <DisplayNameForm data={current_path === demo_site_path?"Demo Site":getSite?.success?.display_name}/>}
                                {this.state.edit === false ?
                                    <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                        <i className="icon-edit"/></a> : <>
                                        <button className="btn-theme btn-black"
                                                onClick={this.updateDisplayName}
                                        disabled={current_path === demo_site_path}>{i18next.t('sites.page-heading.edit.update' ,{returnObjects: true})}
                                        </button>
                                        <button className="btn-theme btn-black"
                                                onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel' ,{returnObjects: true})}
                                        </button>
                                    </>}
                            </div>
                            <div className="breadcrumb-block">
                                <span><Link to={'/sites'}>{i18next.t('sites.page-heading.breadcrum' ,{returnObjects: true})}</Link></span>
                                <span>{current_path === demo_site_path?"Demo Site":getSite?.success?.display_name}</span>
                            </div>
                        </div>

                        <div className="content-menu">
                            <SiteDashboardLinks site_id={current_path === demo_site_path?"demo":this.props.match.params.id}/>
                        </div>

                        <Page title={i18next.t('sites.inside-site.site-nav.logs' ,{returnObjects: true})}>
                            <div className="content-container light-gray-bg">
                                <div className="nav-content-header">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="pills-error-tab" data-toggle="pill"
                                            href="#pills-error" role="tab" aria-controls="pills-error"
                                            aria-selected="false" onClick={current_path === demo_site_path? "" :this.onClickError}>{i18next.t('sites.inside-site.logs.error-btn' ,{returnObjects: true})}</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="pills-access-tab" data-toggle="pill"
                                            href="#pills-access" role="tab" aria-controls="pills-access"
                                            aria-selected="false" onClick={current_path === demo_site_path? "": this.onClickAccess}>{i18next.t('sites.inside-site.logs.access-btn' ,{returnObjects: true})}</a>
                                        </li>
                                    </ul>
                                    <div className="nav-content-header-right">
                                        <div className="nav-content-header-search">
                                            <input placeholder={i18next.t('sites.inside-site.logs.search' ,{returnObjects: true})} type="search"></input>
                                            <span className="icon-search"> </span>
                                        </div>
                                        <div className="nav-content-header-refresh" onClick={secondToLastItemInURL() === 'demo'?null:this.onClickRefresh}>
                                            <p>{i18next.t('sites.inside-site.logs.refresh' ,{returnObjects: true})}</p>
                                            <span id="refresh" className="icon-redo"></span>
                                            {/*<img id="refresh" src={Refresh}/>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="pills-error" role="tabpanel"
                                     aria-labelledby="pills-error-tab">
                                     {
                                         secondToLastItemInURL() === 'demo' ?
                                             <>
                                             <div className="cache-content">
                                                 {"94.237.120.204 2021-01-07T05:40:08.000Z /wp-admin/ GET WordPress/5.6,https://keplerdemo.keplerhosting.cloud"}
                                             </div>
                                                 <div className="cache-content">
                                                 {"94.237.120.204 2021-01-07T05:40:08.000Z /wp-login.php?redirect_to=https%3A%2F%2Fkeplerdemo.keplerhosting.cloud%2Fwp-admin%2F&reauth=1 GET WordPress/5.6"}
                                             </div>
                                             </>
                                             :
                                        getLogsList?.length ? getLogsList.map(log => {
                                            return (
                                                <div className="cache-content">
                                                    {log}
                                                </div>
                                            )
                                        }):
                                                <>
                                                    <div className="cache-content">
                                                        {processing? <Loading/>:
                                                            i18next.t('sites.inside-site.logs.no-error-logs' ,{returnObjects: true})}
                                                    </div>
                                                </>
                                    }

                                    </div>


                                    <div className="tab-pane fade" id="pills-access" role="tabpanel"
                                     aria-labelledby="pills-access-tab">
                                        {
                                            secondToLastItemInURL() === 'demo' ?
                                                <>
                                                    <div className="cache-content">
                                                        {"94.237.120.204 2021-01-07T05:40:08.000Z ,https://keplerdemo.keplerhosting.cloud"}
                                                    </div>
                                                    <div className="cache-content">
                                                        {"94.237.120.204 2021-01-07T05:40:08.000Z ?redirect_to=https%3A%2F%2Fkeplerdemo.keplerhosting.cloud%2Fwp-admin%2F&reauth=1 GET WordPress/5.6"}
                                                    </div>
                                                </>
                                                :
                                            getLogsList?.length ? getLogsList.map(log => {
                                                return (
                                                    <div className="cache-content">
                                                        {log}
                                                    </div>
                                                )
                                            }):
                                                    <>
                                                        <div className="cache-content">
                                                            {processing? <Loading/>:
                                                                i18next.t('sites.inside-site.logs.no-access-logs' ,{returnObjects: true})}
                                                        </div>
                                                    </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Page>

                    </div>

                </>}
            </>
         );
    }
}

function mapStateToProps(state) {
    let {getSite, siteDashboard, getSiteStatus, getAccessLogsReducer, logList, siteList} = state;
    let {site_status} = siteDashboard.data;
    let {params} = logList.data;
    let searchFrom = siteList?.data.searchFrom
    let siteListParam = siteList.data.params

    return {getSite, siteDashboard, site_status, getSiteStatus, getAccessLogsReducer, logList, params, siteListParam, searchFrom};
}

 
export default connect(mapStateToProps) (LogsList);
