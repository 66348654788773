import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultManualBackupForm
} from "./ManualBackupForm-Action";
import createBackup from "../../../../ajax/actions/backup/backup_post";

class ManualBackupFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultManualBackupForm())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createBackup())
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {manualBackupForm,createBackup} = state;
    let {dispatchAPI, mode, payload} = manualBackupForm.data;
    return {manualBackupForm, dispatchAPI, mode, payload, createBackup};
}

export default withRouter(connect(mapStateToProps)(ManualBackupFormWorker));