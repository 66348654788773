const DefaultSiteDashboardState = {
    site_id: ``,
    site_name: ``,
    org_flag: false,
    site_status: ``,
    site_notification: ``,
    site_message: ``,
    total_sites: ``,
    pup_up_message: ``,
    searching:``

}

export const SiteDashboardState = {data: DefaultSiteDashboardState}