import {SiteListState} from './SiteList-State'
import {endOfURL} from "../../../helpers/UrlHelper";

export default function siteListReducer(state = SiteListState, action) {

    let {data} = {...state};
    let {name, value, id, user_id,total} = action;
    switch (action.type) {

        case 'TOGGLE-SITE-LIST-FILTER':
            data.params[name] = value;
            data.searchItemName = value;
            data.searchFrom = endOfURL();
            if (name === `sort_by`) {
                if (data.params.sort_by === value) {
                    data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
                }
            }
            return {...state, data};

        case 'LOAD-MORE':
            if (name === 'limit') {
                data.params.limit = data.params.limit+4;
            }
            // data.params['limit'] =  data.params.limit+4;
            return { ...state, data };

        case 'SET-ORGANIZATIONID-SITE':
            data.organization_id = id;
            return {...state, data};

        case 'SET-TOTAL-SITE':
            data.total_sites = total;
            return {...state, data};

        case 'CLEAR-SEARCH-FIELD-VALUE':
            data.params['q'] = ``;
            return {...state, data};


        case 'TOGGLE-SITE-FORM-MODAL':
            if (data.modal.mode) { // if open, close
                data.modal = {mode: ``,}

            } else { // if close, open
                data.modal.mode = true;
            }
            return {...state, data};

        case 'TOGGLE-UNVERIFIED-USER-MODAL':
            if (data.email_verify.mode === true) { // if open, close
                data.email_verify = {mode: ``,}
            } else { // if close, open
                data.email_verify.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}
