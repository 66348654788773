import {endOfURL} from "../../../../helpers/UrlHelper";

const DefaultDomainFormState = {
    payload: {
        name:"",
        has_www:0,
    },
    errors: [],
    inputs: [],
    stepTrack: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``,
    lb_version: ``
};

export const DomainFormState = {data: DefaultDomainFormState};