import React from "react";

export const STRIPE_KEY = {
  //staging stripe key
  //   key: 'pk_test_51HroD1I2MEau8eFSleHmvma5aHysKVHcsPkZik8WsIyvcNpr6g3h9JlGHrQ87PqknsAliB0GzNZYdO4N5WYk9ZPH00QcUbTIt4' //test credential
  // key: "pk_test_51GxBBbAGik3k5zbVjU86yiums8U2vhSVoJWPvC2uCFTexRcWcc7EQK7PcysisQjfMRxX93KcTdYZOWZSx1NgMZYm00PruODe6A", //test credential

  //live stripe Key
  key: "pk_live_51GxBBbAGik3k5zbV1lkAANQzoH27iy7dGwmEz6K8NPywREhNaH0M6huitx1u4yz5dywAkZ0v4rgtu7eKUB93IRmW00GPePRkF8", //live credential
};
