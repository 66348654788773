import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccountWorker from "./AccountWorker";
import UserForm from "../user/form/UserForm";
import AccountDashboardHeader from "./AccountDashboardHeader";
import Page from "../../components/shared/pages/Page";
import AccountLinks from "./AccountLinks";
import { endOfURL } from "../../helpers/UrlHelper";
import SiteTableCell from "../site/list/SiteTableCell";
import NoCardMessage from "../../components/shared/pages/NoCardMessage";
import i18next from "i18next";
import {
  fullDateFormat,
  fullDateStringNoTime,
} from "../../helpers/DateTimeHelper";

class SiteDashboard extends React.Component {
  render() {
    let { match, userId, params, searchFrom, getLoggedInUser, sale } =
      this.props;
    let { url } = match;

    return (
      <>
        <AccountDashboardHeader />
        {params.q !== "" && searchFrom === endOfURL() ? (
          <>
            <div className="boxed-block">
              <div className="listing-table-block mt-60">
                <div className="table-responsive listing-table">
                  <table>
                    <tbody>
                      <SiteTableCell />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {(getLoggedInUser.success &&
              getLoggedInUser?.success?.data?.data?.has_active_card) ||
            (getLoggedInUser.success &&
              getLoggedInUser?.success?.data?.data?.organization.billable ===
                1) ? (
              <NoCardMessage />
            ) : null}

            <div className="boxed-block">
              <div className="heading-block mt-70">
                <h2>
                  {i18next.t("account.page-heading", { returnObjects: true })}
                </h2>
                {/*<span>Total 02</span>*/}
              </div>
              <div className="content-menu">
                <AccountLinks site_id={this.props.match.params.id} />
              </div>
              <Page
                title={i18next.t("account.account-nav.account-details", {
                  returnObjects: true,
                })}
              >
                {/* {sale && (
                  <div className="payment-notice">
                    <div className="light-gray-bg">
                      <div className="notice">
                        <h6 className="ml-5">
                          {sale && sale.valid_months} Month Free Credit
                        </h6>
                      </div>
                      <div className="payment-notice-message">
                        <p>
                          Your {sale && sale.valid_months} month free credit is
                          activate and will end on{" "}
                          {sale && fullDateStringNoTime(sale.expires_on)}. Feel
                          free to create as many sites as you like and contact
                          our support if you have any questions!
                        </p>
                      </div>
                    </div>
                  </div>
                )} */}

                <UserForm user_id={userId} />
                <AccountWorker />
              </Page>
            </div>
          </>
        )}
        {/*</Page>*/}
      </>
    );
  }
}

function mapStateToProps(state) {
  let { userDetail, route, getSites, siteList, getLoggedInUser } = state;
  let searchFrom = siteList?.data.searchFrom;
  let { params } = siteList.data;
  let sale =
    getLoggedInUser &&
    getLoggedInUser.success &&
    getLoggedInUser.success.data &&
    getLoggedInUser.success.data.data &&
    getLoggedInUser.success.data.data.sale_tracking;
  let userId = route && route.data && route.data.user && route.data.user.id;
  return {
    userDetail,
    userId,
    getSites,
    siteList,
    searchFrom,
    params,
    getLoggedInUser,
    sale,
  };
}

export default withRouter(connect(mapStateToProps)(SiteDashboard));
