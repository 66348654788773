import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import OrganizationFormWorker from './OrganizationFormWorker'
import {
    onOrganizationFormChange,
    onOrganizationFormSubmit, onOrganizationFormTypeChange,
    selectCountry
} from "./OrganizationForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import AccountDashboardHeader from "../../account/AccountDashboardHeader";
import AccountLinks from "../../account/AccountLinks";
import SelectField from "../../../components/shared/form/SelectField";
import {endOfURL} from "../../../helpers/UrlHelper";
import SiteTableCell from "../../site/list/SiteTableCell";
import NoCardMessage from "../../../components/shared/pages/NoCardMessage";
import i18next from 'i18next';
import {organizationType} from "../../../static-data/UserData";



class OrganizationForm extends React.Component {

    organizationFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onOrganizationFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onOrganizationFormChange(e.target.name, e.target.value,));
    };

    onChangeType = (data) => {
        store.dispatch(onOrganizationFormTypeChange(data));
    };

    handleCountry = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.target.value;
        }
        store.dispatch(selectCountry(value));
    }

    render() {
        let {
            payload,
            getOrganization,
            updateOrganization,
            countriesList,
            organizationForm,
            organization_id,
            searchFrom,
            params,
            getLoggedInUser
        } = this.props;
        let {errors} = organizationForm.data;
        let processing = updateOrganization.processing

        const current_path = this.props.location.pathname;
        const billing_path = "/account/billing";

        let countryList = [];
        countriesList && countriesList.map(c => {
            countryList.push({value: c.nice_name, label: c.name, id: c.id})
        })
        return (
            <>
                <AccountDashboardHeader/>
                {params.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>

                        { (getLoggedInUser.success  && getLoggedInUser?.success?.data?.data?.has_active_card) ||
                        (getLoggedInUser.success && getLoggedInUser?.success?.data?.data?.organization.billable === 1)?
                            <NoCardMessage/> : null
                        }

                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <h2>{i18next.t('account.page-heading' ,{returnObjects: true})}</h2>
                            </div>
                            <div className="content-menu">
                                <AccountLinks site_id={this.props.match.params.id}/>
                            </div>
                            <Page title={i18next.t('account.account-nav.billing-details' ,{returnObjects: true})} processing={processing}>

                                <div className="content-container light-gray-bg">
                                    <div className="text-block mb-40">
                                        <h3>{i18next.t('account.inside-account.billing-detail.details.heading' ,{returnObjects: true})}</h3>
                                    </div>

                                    <div className="content-form-block">
                                        <form onSubmit={this.organizationFormSubmit}>
                                            <div className="form-row">
                                                {/*<div className="col-md-6">*/}
                                                {/*    <div className="form-group">*/}
                                                {/*        <TextField name="name"*/}
                                                {/*                   value={payload.name}*/}
                                                {/*                   label="Name"*/}
                                                {/*                   errors={errors.name}*/}
                                                {/*                   onChange={this.onChange}/>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {current_path === billing_path ?
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <SelectField
                                                                    name="type"
                                                                     options={organizationType}
                                                                     errors={errors && errors.type}
                                                                     value={payload.type}
                                                                    label="Account Type"
                                                                     onChange={this.onChange}
                                                                    _value="value"
                                                                    _label="name"/>
                                                    </div>
                                                </div> : null
                                                }
                                                {payload && payload.type === "company" ?
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField name="company_name"
                                                                       value={payload.company_name}
                                                                       label="Company Name"
                                                                       placeholder="Company Name"
                                                                       errors={errors.company_name}
                                                                       onChange={this.onChange}/>
                                                        </div>
                                                    </div> : null}

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <TextField name="address_line_1"
                                                                   value={payload.address_line_1}
                                                                   label={i18next.t('account.inside-account.billing-detail.details.address' ,{returnObjects: true})}
                                                                   errors={errors.address_line_1}
                                                                   onChange={this.onChange}/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <TextField name="address_line_2"
                                                                   value={payload.address_line_2}
                                                                   label={i18next.t('account.inside-account.billing-detail.details.second-address' ,{returnObjects: true})}
                                                                   placeholder={i18next.t('account.inside-account.billing-detail.details.second-placeholder' ,{returnObjects: true})}
                                                                   errors={errors.address_line_2}
                                                                   onChange={this.onChange}/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <SelectField name={i18next.t            ('account.inside-account.billing-detail.details.country' ,{returnObjects: true})}
                                                                     options={countryList}
                                                                     errors={errors && errors.country_id}
                                                                     value={payload.country_id}
                                                                     onChange={this.handleCountry}
                                                                     _value="id"
                                                                     _label="value"/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <TextField name="state"
                                                                   value={payload.state}
                                                                   label={i18next.t('account.inside-account.billing-detail.details.state' ,{returnObjects: true})}
                                                                   errors={errors.state}
                                                                   onChange={this.onChange}/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <TextField name="city"
                                                                   value={payload.city}
                                                                   label={i18next.t('account.inside-account.billing-detail.details.city' ,{returnObjects: true})}
                                                                   errors={errors.city}
                                                                   onChange={this.onChange}/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <TextField name="zip"
                                                                   value={payload.zip}
                                                                   label={i18next.t('account.inside-account.billing-detail.details.post-code' ,{returnObjects: true})}
                                                                   errors={errors.zip}
                                                                   onChange={this.onChange}/>
                                                    </div>
                                                </div>
                                                {payload && payload.company_name && payload.type === "company" ?
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField name="vat_id"
                                                                       value={payload.vat_id}
                                                                       label="Vat Id"
                                                                       errors={errors.vat_id}
                                                                       onChange={this.onChange}/>
                                                        </div>
                                                    </div> : null}
                                            </div>

                                            <div className="button-wrapper text-right">
                                                <button type="submit"
                                                        className="btn-theme btn-black btn-lg" disabled={processing}>
                                                    {
                                                        processing ? <><Loading xs/> {i18next.t('account.inside-account.general.submit' ,{returnObjects: true})}</> : i18next.t('account.inside-account.general.submit' ,{returnObjects: true})
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <OrganizationFormWorker organization_id={organization_id}/>

                            </Page>
                        </div>
                    </>}
                {/*</Page>*/}
            </>

        );
    }
}

function mapStateToProps(state) {
    let {organizationForm, updateOrganization, getOrganization, getLoggedInUser,getSites,siteList,getCountries} = state;
    let searchFrom = siteList?.data.searchFrom
    let {params} = siteList.data
    let organization_id = getLoggedInUser?.success?.data?.data?.organization?.id
    let {errors, payload, mode, errorsPresent} = organizationForm.data;
    let countriesList = getCountries?.success?.data.data


    return {
        organizationForm, updateOrganization, errors, mode,
        payload, getOrganization,countriesList, errorsPresent, getLoggedInUser,organization_id,getSites,siteList,params,searchFrom,getCountries
    };
}

export default connect(mapStateToProps)(OrganizationForm);
