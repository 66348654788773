export function onLoginFormChange(name, value) {

    return function (dispatch) {
        dispatch({type: 'ON-LOGIN-FORM-CHANGE', name, value});
    }
}

export function setMessage(msgType, message, title) {

    return function (dispatch) {
        dispatch({type: 'SET-LOGIN-MESSAGE', msgType, message, title});
    }
}

export function onLoginFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-LOGIN-FORM-SUBMIT'});
    }
}

export function cancelLoginFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-LOGIN-FORM-DISPATCH'});
    }
}

export function loginFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'LOGIN-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function setRedirectToPageAfterLogin(page) {
    return function (dispatch) {
        dispatch({type: 'SET-REDIRECT-TO-PAGE-AFTER-LOGIN', page});
    }
}

export function setLoggedInUserID(id) {
    return function (dispatch) {
        dispatch({type: 'SET-LOGGEDIN-USERID', id});
    }
}

export function loginError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-LOGIN-ERROR', error});
    }
}