import React from 'react'
import loader from '../../../assets/images/loader.svg'
import whiteLoader from '../../../assets/images/loader-white.svg'

export default class Loading extends React.Component
{
    render()
    {
        let {
           color, height, width,
            xs, sm, md, lg, xl,className
        } = this.props;

        let setHeight = height || xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;
        let setWidth  = width  || xs ? 15 : sm ? 25 : md ? 50 : lg ? 80 : xl ? 200 : 25;

        return (

            <>
                <img src={ color === `white` ? whiteLoader : loader }
                     alt="loader"
                     className={className}
                     height={ setHeight }
                     width={ setWidth }
                />
            </>
        );
    }
}