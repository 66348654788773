import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelPublicPathFormDispatch, publicPathFormServerValidationErrors,
    setDefaultPublicPathForm
} from "../../../pages/site/feature-block/form/PublicPathForm-Action";
import {togglePublicPathFormModal} from "../../../pages/site/feature-block/list/FeatureBlock-Action";
import getSiteStatus from "../sites/site_status_get";
import {scrollToError} from "../../../helpers/ScrollHelper";


function _success(success) {
    return {type: 'CREATE_PUBLIC_PATH_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_PUBLIC_PATH_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_PUBLIC_PATH_PROCESSING', processing}
}

function createPublicPath(id=null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let {publicPathForm} = getState();
        let payload = publicPathForm.data.payload
        let site_id = id || publicPathForm?.data?.payload.site_id

        axios({
            url: apiBaseUrl(`customer/sites/${site_id}/public_path`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch( cancelPublicPathFormDispatch( ))
                store.dispatch( setDefaultPublicPathForm() )
                store.dispatch(togglePublicPathFormModal());
                store.dispatch( getSiteStatus(id ) );

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch( cancelPublicPathFormDispatch( ))
            if(error.response && error.response.status === 422 ) {
                validationError(error.response.data.message,error.response.status)
                store.dispatch( publicPathFormServerValidationErrors( error.response.data.errors ))
                scrollToError();
            }
        });
    }
}

export default createPublicPath;
