export function DisplayNameFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'DISPLAY-NAME-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onDisplayNameFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-DISPLAY-NAME-FORM-SUBMIT'})
    }
}

export function onDisplayNameFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-DISPLAY-NAME-FORM-CHANGE', name, value});
    }
}

export function setSiteName(display_name) {
    return function (dispatch) {
        dispatch({type: 'INITIAL-SITE-NAME', display_name});
    }
}

export function onDisplayNameFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-DISPLAY-NAME-FORM-MODE-CHANGE', mode});
    }
}

export function cancelDisplayNameFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-DISPLAY-NAME-FORM-DISPATCH'});
    }
}

export function setDefaultDisplayNameForm() {
    return function (dispatch, getState) {
        let {getSite} = getState();
        let path = getSite && getSite.success && getSite.success.path;
        dispatch({type: 'SET-DEFAULT-DISPLAY-NAME-FORM', path});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}

export function setOrganizationId(org_id) {
    return function (dispatch) {
        dispatch({type: 'SET-ORGANIZATION-ID', org_id});
    }
}


export function displayNameError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-DISPLAY-NAME-ERROR', error});
    }
}