import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultDomainForm,
    setDomainDataForUpdate
} from "./DomainForm-Action";
import createDomain from "../../../../ajax/actions/domain/domain_post";
import {toggleDomainFormModal} from "../list/Domain-Action";
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";
import createBulkDomain from "../../../../ajax/actions/domain/domain_bulk_post";

class DomainFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultDomainForm())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode, payload, errors} = this.props;

        if (this.props.getDomain !== prev.getDomain) {
            let {success} = this.props.getDomain;
            if (success) {
                store.dispatch(setDomainDataForUpdate());
            }
        }

        if (this.props.createDomain !== prev.createDomain) {
            let {success} = this.props.createDomain;

            if (success) {
                store.dispatch(getSiteDomain(this.props.match.params.id))
                store.dispatch(toggleDomainFormModal());
            }
        }

        if (this.props.createBulkDomain !== prev.createBulkDomain) {
            let {success} = this.props.createDomain;

            if (success) {
                store.dispatch(getSiteDomain(this.props.match.params.id))
                store.dispatch(toggleDomainFormModal());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === "bulk") {
                    store.dispatch(createBulkDomain(this.props.match.params.id))
                } else if (mode === "domain"){
                    store.dispatch(createDomain(this.props.match.params.id))
                }
            }
        }
        // if (dispatchAPI) {
        //     if (!prev.dispatchAPI) {
        //         store.dispatch(createDomain(this.props.match.params.id))
        //     }
        // }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {domainForm, getDomain, createDomain,createBulkDomain} = state;
    let {dispatchAPI, mode, payload, errors} = domainForm.data;
    return {domainForm, dispatchAPI, getDomain, mode, payload, createDomain,createBulkDomain, errors};
}

export default withRouter(connect(mapStateToProps)(DomainFormWorker));