import store from '../../../../Store';
import {toggleURLQuery} from '../../../../helpers/UrlHelper'
import getAccessLogs from '../../../../ajax/actions/logs/access_logs';

export function toggleLogsListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        let toggle = true;
        if (toggle) toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-LOGS-LIST-FILTER', name, value});
        store.dispatch(getAccessLogs());
    }
}

export function setSiteIDLogs(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITEID-LOGS', site_id});
    }
}

export function onClickError() {
    return function (dispatch) {
        dispatch({type: 'SET-LOGS-ERRORCLICK'});
        store.dispatch(getAccessLogs());
    }
}
export function onClickAccess() {
    return function (dispatch) {
        dispatch({type: 'SET-LOGS-ACCESSCLICK'});
        store.dispatch(getAccessLogs());
    }
}