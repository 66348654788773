const DefaultSslState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        per_page: 25,
        page: 1,
        order: 'desc',
        sort_id: `id`,
        q: ``,
    },
    data:[],
    dataCustom:[],
    isCheckedLetsEncryptID:[],
    isCheckedCustomID:[],
    site_id: ``,
    ignore_ssl:{
        mode:'',
    }
}

export const sslState = {data: DefaultSslState}