import React from 'react';
import store from '../../../Store'
import {
    setCustomerDataForUpdate,
    setDefaultUserData,
} from './UserForm-Actions'
import {endOfURL, urlTrailWithoutLastItem} from '../../../helpers/UrlHelper'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import getUser from "../../../ajax/actions/user/user_get";
import updateUser from "../../../ajax/actions/user/user_put";
import getCountries from "../../../ajax/actions/country/countries_get";
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";

class UserFormWorker extends React.Component {
    constructor(props) {
        super(props);
        if (props.userId) {
            // store.dispatch(getUser(props.userId));
            store.dispatch(getCountries());
        }
    }

    componentDidMount() {
        store.dispatch(setCustomerDataForUpdate())
        store.dispatch(getDemoSite())
        // if (endOfURL() === 'create') {
        //     store.dispatch(setDefaultUserData())
        // }
    }

    componentDidUpdate(lastProps) {
        let {dispatchAPI} = this.props;
        if (dispatchAPI) {

            if (!lastProps.dispatchAPI) {
                // if (endOfURL() === `create`) {
                // } else {
                    store.dispatch(updateUser(this.props.userId));
                // }

            }
        }

        // if (this.props.getUser !== lastProps.getUser) {
        //     let {success} = this.props.getUser;
        //     if (success) {
        //         store.dispatch(setCustomerDataForUpdate())
        //     }
        // }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getUser, updateUser, userForm, getRoles} = state;

    let {dispatchAPI} = userForm.data
    return {getUser, updateUser, userForm, dispatchAPI, getRoles};
}

export default withRouter(connect(mapStateToProps)(UserFormWorker));