import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import { clone } from '../../../helpers/DataHelper'
import {cancelDomainFormDispatch, domainError, domainFormServerValidationErrors, setDefaultDomainForm}
  from '../../../pages/site/domain/form/DomainForm-Action'
import store from '../../../Store';
import { scrollToError } from '../../../helpers/ScrollHelper'
import {toggleDomainFormModal} from "../../../pages/site/domain/list/Domain-Action";
import getSiteStatus from "../sites/site_status_get";
import getSiteDomain from "./site_domain_get";

function _success(success)
{
  return { type: 'POST_DOMAIN_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_DOMAIN_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_DOMAIN_PROCESSING', processing };
}

function createDomain(id )
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
      const lang = localStorage.getItem('lang') || 'en'

    let { domainForm } = getState();
    let data = clone( domainForm.data.payload );

    axios({
      url: apiBaseUrl(`customer/sites/${id}/domains`),
      method: "post",
      dataType: 'json',
      data,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),
          'lang': lang
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(getSiteDomain(id));
        store.dispatch( cancelDomainFormDispatch( ))
        store.dispatch( setDefaultDomainForm() )
        store.dispatch(toggleDomainFormModal());
        store.dispatch( getSiteStatus(id ) );

      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
          if(error.response.status === 403 && error.response.data.data ){
            validationError(error.response.data.data,error.response.status)
        }else {
              handleError(error)
          }
        store.dispatch(domainError( error.response.data.data ))
        store.dispatch( cancelDomainFormDispatch( ))

        if(error.response && error.response.status === 422 ) {
          // validationError(error.response.data.message,error.response.status)
          store.dispatch( domainFormServerValidationErrors( error.response.data.errors ))
          scrollToError();
        }


      });

  }
}


export default createDomain;