import React from 'react';
import {withRouter} from 'react-router';
import store from '../../Store';
import {connect} from 'react-redux';
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";
import getUser from "../../ajax/actions/user/user_get";
import getSites from "../../ajax/actions/sites/sites_get";
import getDemoSite from "../../ajax/actions/sites/demo_sites_get";

class SiteDashboardWorker extends React.Component {
    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getUser(props.match.params.id));
            store.dispatch(getSites())
            store.dispatch(getDemoSite())

        }
    }

    componentDidMount() {
        store.dispatch(getLoggedInUser());
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser} = state;
    return {getLoggedInUser}
}

export default withRouter(connect(mapStateToProps)(SiteDashboardWorker));