import React from 'react';
import {loggedInUser} from "../../helpers/LocalStorageHelper";
import store from "../../Store";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";
import {connect} from "react-redux";
import {setUser} from "../../routes/main-route/MainRoute-Actions";
// import {withRouter} from "react-router"

class Redirects extends React.Component {

    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search)
        const customer_token = query.get('ct')

        // loggedInUser(customer_token);
        // window.location.href = '/';
        store.dispatch(getLoggedInUser(customer_token))
    }

    componentDidUpdate(lastProps) {
        const query = new URLSearchParams(this.props.location.search)
        const customer_token = query.get('ct')
        if (this.props.getLoggedInUser !== lastProps.getLoggedInUser) {
            let {success} = this.props.getLoggedInUser;
            if (success) {
                store.dispatch(setUser(success))
                loggedInUser(customer_token);
                store.dispatch(getLoggedInUser(customer_token));
                this.props.history.push('/sites')
                // window.location.href = '/';
            } else {
                // window.location.href = '/';
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser} = state;
    return {getLoggedInUser};
}

// export default withRouter(connect(mapStateToProps)(Redirects));
export default connect(mapStateToProps)(Redirects);