import React from 'react';
import {connect} from 'react-redux'
import PrimaryDomainView from "./PrimaryDomainView";
import DomainWorker from "./DomainWorker";
import Loading from "../../../../components/shared/loaders/Loading";
import swal from "sweetalert";
import store from "../../../../Store";
import deleteDomain from "../../../../ajax/actions/domain/domain_delete";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import DomainForm from "../form/DomainForm";
import {toggleDomainFormModal} from "./Domain-Action";
import updateDomain from "../../../../ajax/actions/domain/domain_put";
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";
import {scrollToError} from '../../../../helpers/ScrollHelper'
import SiteDashboardLinks from "../../SiteDashboardLinks";
import SiteDashboardHeader from "../../SiteDashboardHeader";
import Page from "../../../../components/shared/pages/Page";
import {endOfURL} from "../../../../helpers/UrlHelper";
import SiteTableCell from "../../list/SiteTableCell";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import DisplayNameForm from "../../../site/change-display-name/form/DisplayNameForm";
import {Link} from "react-router-dom";
import i18next from 'i18next';

class Domain extends React.Component {
    state = {
        edit: false,
        cancel: false,
        update: false,
    }

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.setState({edit: false});
    }

    deleteConfirm = (id) => {
        swal(`${i18next.t('sites.inside-site.domain.swal-domain-delete.make-primary-question', {returnObjects: true})}`, {
            buttons: {
                cancel: `${i18next.t('sites.inside-site.domain.swal-domain-delete.cancel', {returnObjects: true})}`,
                catch: {
                    text: `${i18next.t('sites.inside-site.domain.swal-domain-delete.delete', {returnObjects: true})}`,
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    primaryConfirm = (id) => {
        swal(`${i18next.t('sites.inside-site.domain.swal-make-primary.make-primary-question', {returnObjects: true})}`, {
            buttons: {
                cancel: `${i18next.t('sites.inside-site.domain.swal-make-primary.cancel', {returnObjects: true})}`,
                catch: {
                    text: `${i18next.t('sites.inside-site.domain.swal-make-primary.yes', {returnObjects: true})}`,
                    value: id
                }
            }
        }).then((value) => {
            if (value) store.dispatch(updateDomain(id))
        })
    };

    delete(id) {
        store.dispatch(deleteDomain(id));
    }

    componentDidUpdate(prevProps) {
        if (this.props.getSiteStatus.data !== prevProps.getSiteStatus.data) {
            let {getSiteStatus} = this.props;
            let {success} = getSiteStatus;
            if (getSiteStatus?.success?.notification?.event_name === "delete_domain" ||
                getSiteStatus?.success?.notification?.event_name === "update_domain"
            ) {
                store.dispatch(getSiteDomain());
            }
            if (getSiteStatus?.success?.status === "updating" || getSiteStatus?.success?.status === "deleting") {
                return null;
            }
            store.dispatch(getSiteDomain());
        }
    }

    render() {
        let {
            demoDomain,
            domainLists,
            getSiteDomain,
            modal,
            site_status,
            getSiteStatus,
            demoSite,
            getSite,
            params,
            searchFrom,
            getDemoSite,
            creatingDomainList,
            event_name,
        } = this.props;
        const primaryDomain = (domainLists || []).find(d => d.is_primary === 1) || '';
        let {processing} = getDemoSite;
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/domain'

        return (
            <>
                <SiteDashboardHeader/>
                <DomainWorker/>

                {params.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    current_path !== demo_site_path ?
                        <>
                            <div className="boxed-block">
                                <div className="heading-block mt-70">
                                    <div
                                        className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                        {this.state.edit === false ? <>
                                                <h2>{getSite?.success?.display_name}</h2></> :
                                            <DisplayNameForm data={getSite?.success?.display_name}/>}
                                        {this.state.edit === false ?
                                            <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                                <i className="icon-edit"/></a> : <>
                                                <button className="btn-theme btn-black"
                                                        onClick={this.updateDisplayName}
                                                >{i18next.t('sites.page-heading.edit.update', {returnObjects: true})}
                                                </button>
                                                <button className="btn-theme btn-black"
                                                        onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel', {returnObjects: true})}
                                                </button>
                                            </>}
                                    </div>
                                    <div className="breadcrumb-block">
                                        <span><Link
                                            to={'/sites'}>{i18next.t('sites.page-heading.breadcrum', {returnObjects: true})}</Link></span>
                                        <span>{getSite?.success?.display_name}</span>
                                    </div>
                                    {/*<span>Total {total && total < 10 ? '0' + total : total}</span>*/}
                                </div>

                                <div className="content-menu">

                                    <SiteDashboardLinks site_id={this.props.match.params.id}/>
                                </div>
                                <Page title={i18next.t('sites.inside-site.site-nav.domain', {returnObjects: true})}>

                                    <DomainWorker/>
                                    <div className="content-container light-gray-bg">
                                        <div className="content-details">
                                            {primaryDomain ? <PrimaryDomainView/> : <Loading/>}
                                        </div>
                                    </div>


                                    <div className="listing-table-block mt-30">
                                        <div className="table-responsive listing-table">
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td className="xl-pl-60">
                                                        <span
                                                            className="title">{i18next.t('sites.inside-site.domain.primary-domain-name', {returnObjects: true})}</span>
                                                        <h4>{primaryDomain.name}</h4>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className="title">{i18next.t('sites.inside-site.domain.pointed-ip', {returnObjects: true})}</span>
                                                        <span
                                                            className={primaryDomain.domain_ip_address ?"big-span big-span-blue" : "big-span"}>IP: {primaryDomain.domain_ip_address?primaryDomain.domain_ip_address:"Not pointed to any DNS"}</span>
                                                    </td>
                                                    <td className="buttons-block close-button">
                                                        {/*<span className="icon-minus"*/}
                                                        {/*      aria-disabled/>*/}
                                                        <span/>
                                                    </td>
                                                    <td className="text-right custom-button-td">
                                                        <button
                                                            disabled
                                                            className="btn-theme btn-outline">
                                                            {i18next.t('sites.inside-site.domain.primary-btn', {returnObjects: true})}
                                                        </button>
                                                    </td>

                                                </tr>
                                                {processing ? <div className="text-center"><Loading/></div> :
                                                    domainLists && domainLists.length ?
                                                        domainLists.map((d, key) => {
                                                            return (
                                                                d.is_primary !== 1 ?
                                                                    <tr key={key}>
                                                                        <td className="xl-pl-60">
                                                                            <span
                                                                                className="title">{i18next.t('sites.inside-site.domain.domain-name', {returnObjects: true})}
                                                                            </span>
                                                                            <h4>{d.name}</h4></td>
                                                                        <td>
                                                                            <span
                                                                                className="title">{i18next.t('sites.inside-site.domain.pointed-ip', {returnObjects: true})}</span>
                                                                            <span
                                                                                className={d.domain_ip_address ?"big-span big-span-blue" : "big-span"}>IP: {d.domain_ip_address?d.domain_ip_address :"Not pointed to any DNS"}</span>
                                                                        </td>
                                                                        {event_name === "create_domain" && creatingDomainList && creatingDomainList.length && creatingDomainList.includes(d.id) ?
                                                                            <>
                                                                                <td className="loading-domain">
                                                                                    <Loading/>
                                                                                    Adding
                                                                                </td>
                                                                                <td>
                                                                                </td>
                                                                            </>
                                                                            :
                                                                            event_name === 'delete_domain' && creatingDomainList && creatingDomainList === d.id ?
                                                                                <>
                                                                                    <td className="loading-domain">
                                                                                        <Loading/>
                                                                                        Deleting
                                                                                    </td>
                                                                                    <td>
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                            <>
                                                                                <td className="buttons-block close-button onclick">
                                                                                    {getSiteStatus?.success?.domain_id !== d.id ?

                                                                                        d.name.match(/.keplerhosting.cloud/) === null && site_status !== `pending` && d.is_primary !== 1 ?
                                                                                            <span
                                                                                                onClick={() => site_status !== 'updating' ? this.deleteConfirm(d.id) : null}>
                                                              <span className="icon-minus"
                                                                    aria-disabled={site_status === 'updating'}/>
                                                          </span> : null : null
                                                                                    }
                                                                                </td>

                                                                                <td className="text-right custom-button-td">
                                                                                    {d.is_primary !== 1 && (
                                                                                        <button onClick={() => {
                                                                                            scrollToError();
                                                                                            this.primaryConfirm(d.id)
                                                                                        }}
                                                                                                disabled={site_status === `pending` || site_status === 'updating'}
                                                                                                className="btn-theme btn-outline">{getSiteStatus?.success?.event === "update_domain" && getSiteStatus?.success?.domain_id === d.id ?
                                                                                            <div>
                                                                                                <Loading/>{i18next.t('sites.inside-site.domain.make-primary-btn', {returnObjects: true})}
                                                                                            </div> : <>{i18next.t('sites.inside-site.domain.make-primary-btn', {returnObjects: true})}</>}</button>
                                                                                    )}
                                                                                </td>



                                                                            </>}
                                                                    </tr> : null)
                                                        }) : null
                                                }
                                                </tbody>
                                            </table>

                                            <ResponsiveModalForm visible={modal.mode}
                                                                 closeModal={() => store.dispatch(toggleDomainFormModal())}
                                                                 domainPopup={true}>
                                                <DomainForm/>
                                            </ResponsiveModalForm>
                                        </div>
                                    </div>
                                </Page>
                            </div>
                        </>
                        :
                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <div
                                    className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                    {this.state.edit === false ? <>
                                            <h2>{"Demo Site"}</h2></> :
                                        <DisplayNameForm data={"Demo Site"}/>}
                                    {this.state.edit === false ?
                                        <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                            <i className="icon-edit"/></a> : <>
                                            <button className="btn-theme btn-black"
                                                    disabled>{i18next.t('sites.page-heading.edit.update', {returnObjects: true})}
                                            </button>
                                            <button className="btn-theme btn-black"
                                                    onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel', {returnObjects: true})}
                                            </button>
                                        </>}
                                </div>
                                <div className="breadcrumb-block">
                                    <span><Link
                                        to={'/sites'}>{i18next.t('sites.page-heading.breadcrum', {returnObjects: true})}</Link></span>
                                    <span>{"Demo Site"}</span>
                                </div>
                                {/*<span>Total {total && total < 10 ? '0' + total : total}</span>*/}
                            </div>
                            <div className="content-menu">

                                <SiteDashboardLinks site_id={'demo'}/>
                            </div>
                            <Page title={i18next.t('sites.inside-site.site-nav.domain', {returnObjects: true})}>

                                {demoSite ?

                                    <>
                                        <div className="content-container light-gray-bg">
                                            <div className="content-details">
                                                <PrimaryDomainView type={'demo'}/>
                                            </div>
                                        </div>


                                        <div className="listing-table-block mt-30">
                                            <div className="table-responsive listing-table">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="xl-pl-60">
                                                            <span
                                                                className="title">{i18next.t('sites.inside-site.domain.primary-domain-name', {returnObjects: true})}</span>
                                                            <h4>{"keplerdemo.keplerhosting.cloud"}</h4>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="title">{i18next.t('sites.inside-site.domain.pointed-ip', {returnObjects: true})}</span>
                                                            <span
                                                                className="big-span">IP: {demoDomain.dns_ip_address}</span>
                                                        </td>
                                                        <td className="buttons-block close-button">
                                                   {/* <span className="icon-minus"
                                                          aria-disabled/>*/}
                                                        </td>
                                                        <td className="text-right custom-button-td">
                                                            <button
                                                                disabled
                                                                className="btn-theme btn-outline">
                                                                {i18next.t('sites.inside-site.domain.primary-btn', {returnObjects: true})}
                                                            </button>
                                                        </td>

                                                    </tr>

                                                    </tbody>
                                                </table>

                                                {/*<ResponsiveModalForm visible={modal.mode}*/}
                                                {/*                     closeModal={() => store.dispatch(toggleDomainFormModal())}>*/}
                                                {/*    <DomainForm/>*/}
                                                {/*</ResponsiveModalForm>*/}
                                            </div>
                                        </div>
                                    </> : <td colSpan={4}><Loading/></td>}
                            </Page>
                        </div>

                }
                {/*</Page>*/}
            </>
        );
    }

}

function mapStateToProps(state) {
    let {
        getSite
        ,
        getSiteDomain
        ,
        domainList
        ,
        siteDashboard
        ,
        getSiteStatus
        ,
        getSites
        ,
        siteList
        ,
        getDemoSite
    }

        = state;
    let {site_status} = siteDashboard.data;
    let creatingDomainList = getSiteStatus && getSiteStatus.success && getSiteStatus.success.domain_id
    let event_name = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let {success} = getSiteDomain;
    let {modal} = domainList.data;
    let total = getSites && getSites.success && getSites.success.data && getSites.success.data.data && getSites.success.data.data.total;
    let demoSite = getDemoSite && getDemoSite.success && getDemoSite.success.data && getDemoSite.success.data.data && getDemoSite.success.data.data.site
    let demoDomain = getDemoSite && getDemoSite.success && getDemoSite.success.data && getDemoSite.success.data.data && getDemoSite.success.data.data.domains && getDemoSite.success.data.data.domains.data
    let domainLists = success &&
        success.data &&
        success.data.data &&
        success.data.data.data;
    let searchFrom = siteList?.data.searchFrom
    let {params, payload} = siteList.data
    return {
        getSite,
        getSiteDomain,
        getDemoSite,
        domainLists,
        demoDomain,
        domainList,
        modal,
        siteDashboard,
        site_status,
        getSiteStatus,
        total,
        searchFrom,
        params,
        payload,
        demoSite,
        creatingDomainList,
        event_name
    };
}

export default connect(mapStateToProps)(Domain);