import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import userVerificationEmail from "../../../ajax/actions/user/verifyuser _email_send_get"
import store from "../../../Store"
import Loading from "../loaders/Loading";
import i18next from 'i18next';
import {logOut} from "../../../helpers/LocalStorageHelper";

class VerifyEmail extends React.Component
{
    sendVerificationEmail(){
        store.dispatch(userVerificationEmail())
    }
    render()
    {


        let {action, role, getLoggedInUser, first_name, resendEmailUserVerification } = this.props;
        let {processing} = resendEmailUserVerification
        if( getLoggedInUser.success ) {
            if( !getLoggedInUser.success.data.data.verified) {
                return (
                                        <div className="content-container light-gray-bg">
                        <div className="content-message-block">
                            <h2>Hi <span>{first_name}</span>,<br/>
                                You are one step away!<br/>
                                Please confirm your email</h2>
                            <p>Please confirm your email by clicking on the link in the email sent from our system.
                                The link will automatically confirm your identity and give you access to your Kepler Hosting account</p>
                            <br/>
                            <p>If you did not get an email, please click resend.</p>

                            <p className="text-success">{resendEmailUserVerification.success ? resendEmailUserVerification.success.data.message:null}</p>

                            <div className="button-wrapper inline-buttons mt-30">
                                <div className="button-wrapper">
                                    <button className="btn-theme btn-blue"
                                            onClick={()=>this.sendVerificationEmail()}
                                    >
                                        {processing ? <>Resend Email<Loading/></>:"Resend Email"}
                                    </button>
                                    <button className="btn-theme btn-blue" type="reset"
                                            onClick={()=>logOut()}
                                    >
                                        Logout
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, loginForm, getLoggedInUser, resendEmailUserVerification} = state;

    let {action, routes_url} = route.data;

    let role = route && route.data.user && route.data.user.role.slug;

    let {redirectTo} = loginForm.data;
    let {first_name} = route && route.data.user

    return {route, action, loginForm, redirectTo, routes_url, getLoggedInUser, role, first_name, resendEmailUserVerification};
}

export default withRouter(connect(mapStateToProps)(VerifyEmail));


// import React from 'react';
// import {withRouter} from "react-router";
// import {connect} from "react-redux";
// import i18next from 'i18next';
// import {Link} from "react-router-dom";
//
// class VerifyEmail extends React.Component
// {
//     render()
//     {
//         let {action, role, getLoggedInUser, first_name} = this.props;
//         if( getLoggedInUser.success ) {
//             // if( !getLoggedInUser.success.data.data.verified) {
//             return (
//                 <>
//                     <div className="content-container light-gray-bg">
//                         <div className="content-message-block">
//                             <h2>Hi <span>{first_name}</span>,<br/>
//                                 You are one step away!<br/>
//                                 Please confirm your email</h2>
//                             <p>Please confirm your email by clicking on the link in the email send from our system.
//                                 The link will automatically confirm your identity and give you access
//                                 to your Kepler Hosting account</p>
//                             <br/><br/>
//                             <p>if you did not get a email click resend.</p>
//
//                             <div className="button-wrapper inline-buttons mt-30">
//                                 <a href="#" className="button-wrapper">
//                                     <Link className="btn-theme btn-blue" >
//                                         Resend Email
//                                     </Link>
//
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </>
//             )
//             // }
//         }
//
//         return (
//
//             null
//
//         )
//     }
// }
//
// function mapStateToProps(state) {
//     let {route, getLoggedInUser} = state;
//
//     let {action, routes_url} = route.data;
//
//     let role = route && route.data.user && route.data.user.role.slug;
//
//     let {first_name} = route && route.data.user
//
//     return {route, action, routes_url, getLoggedInUser, role, first_name};
// }
//
// export default withRouter(connect(mapStateToProps)(VerifyEmail));
