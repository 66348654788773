export function ignoreCacheFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'IGNORE-CACHE-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onIgnoreCacheFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-IGNORE-CACHE-FORM-SUBMIT'})
    }
}

export function onIgnoreCacheFormChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-IGNORE-CACHE-FORM-CHANGE',name, value});
    }
}

export function onIgnoreCacheFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-IGNORE-CACHE-FORM-MODE-CHANGE', mode});
    }
}

export function cancelIgnoreCacheFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-IGNORE-CACHE-FORM-DISPATCH'});
    }
}

export function setDefaultIgnoreCacheForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-IGNORE-CACHE-FORM'});
    }
}

export function setCacheIgnoreDataForUpdate() {
    return function (dispatch, getState) {
        let {getCacheIgnore} = getState();
        let path = getCacheIgnore && getCacheIgnore.success.data  && getCacheIgnore.success.data.data ;
        dispatch({type: 'SET-IGNORE-CACHE-DATA-FOR-UPDATE', path});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}


export function ignoreCacheError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-IGNORE-CACHE-ERROR', error});
    }
}
