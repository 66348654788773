import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultRedirectsForm,
     setSiteId, setRedirectDataForUpdate
} from "./RedirectsForm-Action";
import createRedirect from "../../../../ajax/actions/redirects/redirect_post";
import updateRedirect from "../../../../ajax/actions/redirects/redirect_put";

class RedirectsFormWorker extends React.Component {

    constructor(props) {
        super(props);
        // store.dispatch(setDefaultRedirectsForm())
        store.dispatch(setSiteId(this.props.match.params.id))
        if (this.props.mode === 'create') {
            store.dispatch(setDefaultRedirectsForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode} = this.props;
        if (this.props.getRedirect !== prev.getRedirect) {
            let {success, error} = this.props.getRedirect;
            if (success) {
                store.dispatch(setRedirectDataForUpdate());
            }

            if (error) {
                //
            }
        }
        if (dispatchAPI) {
            if (mode === 'create') {
                store.dispatch(createRedirect(this.props.match.params.id))
            } else {
                let {id} = this.props.getRedirect
                && this.props.getRedirect.success
                && this.props.getRedirect.success.data.data
                store.dispatch(updateRedirect(this.props.match.params.id, id))
            }
        }
    }


    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {redirectForm, getRedirect} = state;
    let {dispatchAPI, mode, payload,} = redirectForm.data;
    return {redirectForm, dispatchAPI, mode, payload, getRedirect};
}

export default withRouter(connect(mapStateToProps)(RedirectsFormWorker));