import React from 'react'
import { names, ucFirst, unslufigy } from '../../../helpers/StringHelper'

class TextAreaField extends React.Component
{
  placeholder()
  {
    let { placeholder, name, label } = this.props;
    if ( placeholder ) return placeholder
    if ( label ) return label;
    else {
      if ( name ) {
        let _label = unslufigy( name );
        if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
        return _label;
      }
    }
  }

  label()
  {
    let { name, label, errors } = this.props;
    let errorsPresent = errors && errors.length;
    if ( label ) return (
      <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
        { label }
        {/*{ errorsPresent ? errors[0].message + '.' : null }*/}
      </label>
    );
    else {
      if ( name ) {
        let _label = unslufigy( name );
        if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
        return (
          <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
            { _label }
            {/*{ errorsPresent ? errors[0].message + '.' : null }*/}
          </label>
        )
      }
    }
  }


 /* onKeyPress(e)
  {
    let { onEnterKeyPress } = this.props;
    if ( e.key === 'Enter' ) {
      if ( onEnterKeyPress ) onEnterKeyPress()
    }
  }*/

  render()
  {
    let {
      name, value, onChange, id, noLabel, disabled, errors,readonly,
      required , maxRows
    } = this.props;

    let errorsPresent = errors && errors.length;

    return (
        <div className={ names(`form-group`, errors && errors.length && `has-error`, required && `required`) }>

          { !noLabel ? <>{ this.label() }</> : null }
        <textarea rows={maxRows || 4}
                  type="text"
                  name={name}
                  className="form-control"
                  id={id || name}
                  value={ value }
                  disabled={disabled}
                  readonly={readonly}
                  placeholder={this.placeholder()}
                  // onKeyPress={(e) => this.onKeyPress(e)}
                  onChange={ !readonly ? e => onChange({ target: { name, value: e.target.value, id } }):null} />
          <div className=' error-msg'>
            {
              errorsPresent ? errors[0].message + '.' : null}</div>
      </div>

    )
  }
}

export default TextAreaField;