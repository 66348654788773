import React from 'react'
import TextField from '../../../components/shared/form/TextField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onFormChange, changeType,
    selectCountry, initializeBillingFormStep2Error,setRecapticha
} from './BillingFormStep2-Actions'
import SelectField from "../../../components/shared/form/SelectField";
import getCountries from "../../../ajax/actions/country/countries_get";
import {ReCAPTCHA_SiteKey} from "../../../Config"
import ReCAPTCHA from "react-google-recaptcha";


class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(initializeBillingFormStep2Error())
        store.dispatch(getCountries());

    }

    onRecaptchaChange(value) {
        // console.log("Captcha value:", value)
        store.dispatch(setRecapticha(value));
    }

    onChange = (e) => {
        e.preventDefault()
        store.dispatch(onFormChange(e));
    }
    onChangeType = (type) => {
        store.dispatch(changeType(type));
    }

    handleCountry = (selectedOption) => {

        let value = "";
        if (selectedOption) {
            value = selectedOption.target.value;
        }
        store.dispatch(selectCountry(value));
    }

    render() {
        let {
            errors, payload, submitted,countriesList
        } = this.props;
        let {siteKey} = ReCAPTCHA_SiteKey

        let {
            address, extra_address_line, city, country_id, post_code, state, company_name, vat_id, type
        } = payload

        let {step1} = submitted;
        let countryList = [];
        countriesList && countriesList.map(c => {
            countryList.push({value: c.nice_name, label: c.name, id: c.id})
        })


        return (
            <>
                {type === false ?
                    <div className="company-question">
                        <p>Are You A company?
                            <button className="btn-theme btn-outline" onClick={() => this.onChangeType()}>Yes,Company account</button></p>
                    </div>
                    : null}

                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="address"
                                       value={address}
                                       label="Address"
                                       errors={step1 && errors && errors.address}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    {type ?
                        <div className="col-md-6">
                            <div className="form-group">
                                <TextField required
                                           value={company_name}
                                           name="company_name"
                                           label="Company Name"
                                           placeholder="Company Name"
                                           icon="user"
                                           onChange={this.onChange}
                                           errors={step1 && errors && errors.company_name}/>
                            </div>
                        </div> : <div className="col-md-6"></div>}
                    <div className="col-md-6">
                        <div className="form-group">

                            <TextField name="extra_address_line"
                                       value={extra_address_line}
                                       label="Second Address Line"
                                       placeholder="Second Address Line"
                                       errors={step1 && errors && errors.extra_address_line}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="post_code"
                                       value={post_code}
                                       label="Post Code"
                                       errors={step1 && errors && errors.post_code}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="city"
                                       value={city}
                                       label="City"
                                       errors={step1 && errors && errors.city}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            {/*<NewSearchableSelect*/}
                            {/*    name="country"*/}
                            {/*    isClearable={clearable}*/}
                            {/*    onChange={this.handleOrganizationCountry}*/}
                            {/*    placeholder="Belgium"*/}
                            {/*    options={countryList}*/}
                            {/*    errors={errors.country}*/}
                            {/*    Searchable*/}
                            {/*/>*/}
                            <SelectField name="country_id"
                                         options={countryList}
                                         value={country_id}
                                         errors={step1 && errors && errors.country_id}
                                         onChange={this.handleCountry}
                                         _value="id"
                                         _label="value"/>
                        </div>
                    </div>

                    {/*<div className="col-md-6">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <TextField required*/}
                    {/*                   value={state}*/}
                    {/*                   name="state"*/}
                    {/*                   icon="user"*/}
                    {/*                   onChange={this.onChange}*/}
                    {/*                   errors={step1 && errors && errors.state}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {type ?
                        <div className="col-md-6">
                            <div className="form-group">
                                <TextField name="vat_id"
                                           value={vat_id}
                                           label="Vat Id"
                                           errors={step1 && errors.vat_id}
                                           onChange={this.onChange}/>
                            </div>
                        </div> : null}
                </div>
                <div className="recaptcha" >
                    <ReCAPTCHA sitekey={siteKey} onChange={this.onRecaptchaChange} />
                    <div className=' error-msg'>
                        { errors.recaptcha.length ?step1 && errors && errors.recaptcha && errors.recaptcha[0].message : null}
                    </div>
                </div>

            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        registerForm, registerUser, BillingFormStep2,getCountries,
        CustomerFormStep1
    } = state;

    let {payload, errorMessage} = BillingFormStep2.data;

    let {errors, errorsPresent} = BillingFormStep2.data;

    let countriesList = getCountries?.success?.data.data


    let {submitted, mode} = registerForm.data;
    return {
        errors, errorsPresent, registerUser, mode,
        BillingFormStep2, errorMessage, payload, registerForm, submitted, CustomerFormStep1,getCountries, countriesList
    };
}

export default connect(mapStateToProps)(RegisterForm);
