import axios from 'axios';
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
  return{ type: 'GET_ACTIVITY_LOGS_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_ACTIVITY_LOGS_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_ACTIVITY_LOGS_PROCESSING', processing };
}

function getActivityLogs(customer_id=null) {
  return ( dispatch, getState ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'
    let {activityList , route} = getState();
    let {params} = activityList.data;
    if(!customer_id) {
      customer_id = route && route.data && route.data.user && route.data.user.id;
    }
    axios({
      url: apiBaseUrl(`customer/activity_logs`),
      method: "get",
      dataType: 'json',
      params,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(`kepler_c_token`),
        'lang': lang
      }
    }).then(function(res){
      dispatch(_processing(false));
      dispatch(_success(res));

    }).catch(function(error){
      handleError(error);
      dispatch(_error(error));
      dispatch(_processing(false));
    });
  }
}

export default getActivityLogs;
