const DefaultActivityListState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 10,
        page: 1,
        order: 'desc',
        // sort_id: `id`,
        sort_by: `event`,
        q: ``,
        site_id: ``,
        show_log_of_all_users:1,
    },

    fields: [
        {field: `created_at`, label: `Date`, sort: true},
        {field: `site_name`, label: `Site`, sort: true},//display_name
        {field: `event`, label: `Action`, sort: true},
        {field: `user_name`, label: `User`, sort: false},
        {field: `status`, label: `Status`, sort: true},
    ]
}

export const ActivityListState = {data: DefaultActivityListState}