import {LoginFormState} from './Login-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../helpers/ErrorHelper'


export default function loginFormReducer(state = LoginFormState, action) {
    let {data} = {...state};
    let {payload} = data;
    let {name, value, msgType, title, message, id, validationErrors, error} = action;

    switch (action.type) {

        case 'ON-LOGIN-FORM-CHANGE':
            payload[name] = value;
            buildErrors();
            return {...state, payload};

        case 'ON-LOGIN-FORM-SUBMIT':
            data.submitted = true;
            buildErrors()
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'LOGIN-FORM-SERVER-VALIDATION-ERRORS':
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            )
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};

        case 'CANCEL-LOGIN-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors()
            return {...state, data};


        case 'SET-LOGIN-MESSAGE':
            data.message = {msgType, message, title}
            buildErrors()
            return {...state, data}

        case 'SET-LOGIN-ERROR':
            data.errorMessage = error;
            buildErrors();
            return {...state, data};

        case 'SET-LOGGEDIN-USERID':
            data.user_id = id
            buildErrors()
            return {...state, data}

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, serverValidationErrors} = data
        let errors = {}
        Object.keys(payload).map(key => {
            errors[key] = []
        })

        if (submitted) {
            if (field === `email`) {
                if (!_(field)) newError(field, 1, `is required`)
            }
            if (field === `password`) {
                if (!_(field)) newError(field, 1, `is required`)
            }
        }

        errors = mergeServerValidationErrors(errors, serverValidationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}