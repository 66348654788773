import {DefaultMigrationDetailsFormStep2State, MigrationDetailsFormStep2State} from './MigrationDetailsFormStep2-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'

export default function migrationDetailsFormReducer(state = MigrationDetailsFormStep2State, action) {

    let {data} = {...state};
    let {name, value, validationErrors, types, recaptcha} = action;

    switch (action.type) {

        case 'ON-FORM-CHANGE':
            data.payload[name] = value;
            if (name === "company_name" && value === "") {
                data.payload.vat_id = "";
            }
            buildErrors()
            return {...state, data};

        case 'ON-TYPE-CHANGE':
            data.payload.type = true;
            buildErrors()
            return {...state, data};

        case 'SET-RECAPTCHA':
            data.payload.recaptcha = recaptcha;
            buildErrors()
            return {...state, data};

        case 'MIGRATION-DETAILS-FORM-SERVER-VALIDATION-ERRORS':
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` ${validationErrors[key][0]}`.slice(0, -1)}]
            )
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};

        case 'INITIALIZE-MIGRATION-DETAILS-FORM-STEP2-ERRORS':
            buildErrors()
            return {...state, data};

        case 'SET-DEFAULT-MIGRATION-DETAILS-DATA':
            data = {
                payload: {
                   /* domain: ``,
                    is_ecommerce: ``,
                    wordpress_user: ``,
                    wordpress_password: ``,
                    wordpress_admin_url: ``,
                    is_multisite: ``,
                    is_https: ``,*/
                    type: ``,
                    db_login_url:``,
                    is_multi_site: ``,
                    is_ecommerce: ``,
                    wordpress_username: ``,
                    wordpress_password: ``,
                    wordpress_admin_url: ``,
                },

                /*errors: {},
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: "submit",


                user_mode: ``,
                action: 'loader',
                message: ``*/
                errors: {},
                errorsPresent: false,
                serverValidationErrors: {},
                submitted: false,

            };
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)

        data.errorsPresent = errorsPresent(errors);
    }

    function errors(field) {
        let {validationErrors, type} = data
        let errors = {};
        Object.keys(data.payload).map(key => errors[key] = []);

        if (field === `type`) {
            if (!_(field)) newError(field, 0, `The type field is required`)
        }
        if (field === `db_login_url`) {
            if (!_(field)) newError(field, 0, `The db login url field is required`)
        }
        if (field === `is_multi_site`) {
            if (!_(field)) newError(field, 0, `The multisite field is required`)
        }
        if (field === `is_ecommerce`) {
            if (!_(field)) newError(field, 0, `The is ecommerce field is required`)
        }
        if (field === `wordpress_username`) {
            if (!_(field)) newError(field, 0, `The wordpress username field is required`)
        }
        if (field === `wordpress_password`) {
            if (!_(field)) newError(field, 0, `The wordpress password field is required`)
        }
        if (field === `wordpress_admin_url`) {
            if (!_(field)) newError(field, 0, `The wordpress admin url field is required`)
        }

        // errors = mergeServerValidationErrors(errors, validationErrors);

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}