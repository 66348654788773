import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import store from "../../Store";
import getSites from "../../ajax/actions/sites/sites_get";
import { toggleSiteListFilter } from "../site/list/SiteList-Actions";
import debounce from "lodash.debounce";
import { pushToPage } from "../../helpers/UrlHelper";
import { toggleAddCardFormModal } from "./overview/Overview-Actions";
import i18next from "i18next";

class AccountHeading extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.fireBillingButton = this.fireBillingButton.bind(this);
    this.emitChangeDebounced = debounce(this.toggleFilter, 800);
  }
  state = {
    name: "",
    value: "",
    searching: false,
  };
  componentWillUnmount() {
    this.emitChangeDebounced.cancel();
  }
  fireBillingButton(e) {
    e.preventDefault();
    // const current_path = this.props.location.pathname;
    // const account_paths = [ '/account/overview', '/account/payment'];
    // if( !account_paths.includes( current_path ) ) {
    //     pushToPage(this.props, `/account/overview`)
    // }
    const current_path = this.props.location.pathname;
    const account_paths = ["/account/activate-account"];
    if (!account_paths.includes(current_path)) {
      pushToPage(this.props, `/account/activate-account`);
    }
    window.dataLayer.push({
      event: "createSite",
      eventCategory: "CreateSite",
      eventAction: "ButtonClick",
      eventLabel: "CreateSiteButton",
    });

    // store.dispatch( toggleAddCardFormModal() );
  }

  componentDidMount() {
    store.dispatch(getSites());
  }

  handleChange(e) {
    this.emitChangeDebounced(e.target.name, e.target.value);
    this.setState({ name: e.target.name, value: e.target.value });
    this.setState({ searching: true });
  }

  toggleFilter() {
    // this.setState({name:name,value:value})
    store.dispatch(toggleSiteListFilter(this.state.name, this.state.value));
    this.setState({ searching: false });
  }

  render() {
    let { params, getLoggedInUser, sale } = this.props;
    return (
      <>
        <div className="header-block">
          <div className="header-block-wrapper">
            <div className="search-bar">
              {/*<input type="text" className="form-control" value="" placeholder="Search Kepler"/>*/}
              {/*<span className="icon-search"> </span>*/}
              <input
                type="text"
                className="form-control"
                name="q"
                placeholder={i18next.t("create-site.placeholder", {
                  returnObjects: true,
                })}
                value={this.state.value}
                onChange={this.handleChange}
              />
              {/*<span></span>*/}
              <span className="icon-search"></span>
            </div>

            <div className="button-wrapper">
              {(getLoggedInUser.success &&
                getLoggedInUser?.success?.data?.data?.has_active_card) ||
              (getLoggedInUser.success &&
                getLoggedInUser?.success?.data?.data?.organization.billable ===
                  0) ? (
                //      ||
                // sale
                <Link
                  id="account-dashboard-create-site-button"
                  to={`/sites/create`}
                  className="btn-theme btn-blue"
                >
                  {i18next.t("create-site.button", { returnObjects: true })}
                </Link>
              ) : (
                <a
                  href="#"
                  id="account-dashboard-create-site-button"
                  className="btn-theme btn-blue"
                  onClick={this.fireBillingButton}
                >
                  {i18next.t("create-site.button", { returnObjects: true })}
                </a>
              )}

              {/*<a href="/sites/create" className="btn-theme btn-blue">Create Sites</a>*/}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { route, getSite, getLoggedInUser, getUser, siteDashboard, siteList } =
    state;
  let { site_status } = siteDashboard.data;

  let sale =
    getLoggedInUser &&
    getLoggedInUser.success &&
    getLoggedInUser.success.data &&
    getLoggedInUser.success.data.data &&
    getLoggedInUser.success.data.data.sale_tracking;

  let { params } = siteList.data;

  return {
    route,
    getSite,
    getLoggedInUser,
    getUser,
    siteList,
    params,
    siteDashboard,
    site_status,
    sale,
  };
}

export default withRouter(connect(mapStateToProps)(AccountHeading));
