export function setUserRole(role) {

  return function (dispatch) {
    dispatch({type:'SET-USER-ROLE', role});
  }
}

export function setUser( users ) {

  return function (dispatch) {
    dispatch({type:'SET-USER', users});
  }
}

export function setUserId( user_id ) {
  return function (dispatch) {
    dispatch({type:'SET-USER-ID', user_id});
  }
}

export function logOutUser() {

  return function (dispatch) {
    dispatch({type:'LOG-OUT-USER'});
  }
}

// export function setDashboardMenu( menu ) {
//
//   return function (dispatch) {
//     dispatch({type:'SET-DASHBOARD-MENU', menu });
//   }
// }
//
// export function setOrganizationSiteUrl( url ) {
//
//   return function (dispatch) {
//     dispatch({type:'SET-ORAGANIZATION-SITE-URL', url });
//   }
// }


