import React from 'react'
import TextField from '../../../components/shared/form/MigrationTextField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onFormChange, changeType,
    initializeMigrationDetailsFormStep2Error
} from './MigrationDetailsFormStep2-Actions'
import PasswordField from "../../../components/shared/form/MigrationPasswordField";
import SelectField from "../../../components/shared/form/MigrationSelectField";
import {ftpSftp, yesNoType} from "../../../static-data/UserData";
import {onChangeFormSecond} from "../MigrationForm-Actions";

class MigrationDetailsForm extends React.Component {

    // constructor(props) {
    //     super(props);
    //     store.dispatch(initializeMigrationDetailsFormStep2Error())
    // }

    onChange = (e) => {
        e.preventDefault()
        store.dispatch(onFormChange(e));
        store.dispatch(onChangeFormSecond())
    }
    onChangeType = (type) => {
        store.dispatch(changeType(type));
    }

    render() {
        let {
            errors, payload, submitted
        } = this.props;

        let {
            type,
            db_login_url,
            is_ecommerce,
            wordpress_username,
            wordpress_password,
            wordpress_admin_url,
            is_multi_site,
        } = payload

        let {step1} = submitted;


        return (
            <>
                <div className="form-row">
                    <div className=" col-md-6">
                        <div className="form-group">
                            <SelectField name="type"
                                         value={type}
                                         options={ftpSftp}
                                         errors={step1 && errors && errors.type}
                                         onChange={this.onChange}
                                         placeholder="Select..."
                                         label="Type"
                                         _value="value"
                                         _label="name"/>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="db_login_url"
                                       value={db_login_url}
                                       label="Database Login URL"
                                       errors={step1 && errors && errors.db_login_url}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    {/*<div className="col-md-6">
                        <div className="form-group">
                            <TextField name="is_ecommerce"
                                       value={is_ecommerce}
                                       label="Is it an ecommerce, Community, or membership site?"
                                       errors={step1 && errors && errors.is_ecommerce}
                                       onChange={this.onChange}/>
                        </div>
                    </div>*/}
                    <div className=" col-md-6">
                        <div className="form-group">
                            <SelectField name="is_ecommerce"
                                         value={is_ecommerce}
                                         options={yesNoType}
                                         errors={step1 && errors && errors.is_ecommerce}
                                         onChange={this.onChange}
                                         placeholder="Select..."
                                         label="Is it an eCommerce, Community or Membership site?"
                                         _value="value"
                                         _label="name"/>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="wordpress_username"
                                       value={wordpress_username}
                                       label="WordPress Username"
                                       errors={step1 && errors && errors.wordpress_username}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <PasswordField required
                                           value={wordpress_password}
                                           name="wordpress_password"
                                           defaultValue={null}
                                           label="WordPress Password"
                                           autoComplete="new-password"
                                           onChange={this.onChange}
                                           errors={step1 && errors && errors.wordpress_password}/>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <TextField name="wordpress_admin_url"
                                       value={wordpress_admin_url}
                                       label="WordPress Admin URL"
                                       errors={step1 && errors && errors.wordpress_admin_url}
                                       onChange={this.onChange}/>
                        </div>
                    </div>

                    {/*<div className="col-md-6">*/}
                    {/*    <div className="form-group">*/}
                    {/*        <TextField name="is_multisite"*/}
                    {/*                   value={is_multisite}*/}
                    {/*                   label="Is it a multiplesite installation?"*/}
                    {/*                   errors={step1 && errors && errors.is_multisite}*/}
                    {/*                   onChange={this.onChange}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className=" col-md-6">
                        <div className="form-group">
                            <SelectField name="is_multi_site"
                                         value={is_multi_site}
                                         options={yesNoType}
                                         placeholder="Select..."
                                         errors={step1 && errors && errors.is_multi_site}
                                         onChange={this.onChange}
                                         label="Is it a Multisite Installation?"
                                         _value="value"
                                         _label="name"/>

                        </div>
                    </div>

                    {/*  <div className=" col-md-6">
                        <div className="form-group">
                            <SelectField name="is_https"
                                         value={is_https}
                                         options={yesNoType}
                                         errors={step1 && errors && errors.is_https}
                                         onChange={this.onChange}
                                         placeholder="Select..."
                                         label="Does your site use HTTPS?"
                                         _value="value"
                                         _label="name"/>

                        </div>
                    </div>*/}

                    {/*<div className="col-md-6">
                        <div className="form-group">
                            <TextField name="is_https"
                                       value={is_https}
                                       label="Does your site use HTTPS?"
                                       errors={step1 && errors && errors.is_https}
                                       onChange={this.onChange}/>
                        </div>
                    </div>*/}
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        migrationForm, createMigration, migrationDetailsStep2,
        currentHostStep1
    } = state;

    let {payload, errorMessage} = migrationDetailsStep2.data;

    let {errors, errorsPresent} = migrationDetailsStep2.data;


    let {submitted, mode} = migrationForm.data;
    return {
        errors, errorsPresent, createMigration, mode,
        migrationDetailsStep2, errorMessage, payload, migrationForm, submitted, currentHostStep1
    };
}

export default connect(mapStateToProps)(MigrationDetailsForm);
