import store from '../../../Store';
import {toggleURLQuery} from '../../../helpers/UrlHelper'
import getSites from '../../../ajax/actions/sites/sites_get'
import getSiteListStatus from '../../../ajax/actions/sites/site_list_status_get'
import getSiteStatus from "../../../ajax/actions/sites/site_status_get";
import getMigrationList from "../../../ajax/actions/manual-migration/migration_gets";


export function toggleSiteListFilter(name,value,site_id=null) {
    return function (dispatch) {
        // let {name, value} = e.target;
        dispatch({type: 'TOGGLE-SITE-LIST-FILTER', name, value});
        store.dispatch(getSites());
        store.dispatch(getSiteListStatus());
        if(site_id) {
            store.dispatch(getSiteStatus(site_id));
        }
    }
}

export function loadMore(e,history) {
    return function (dispatch) {
        let { name, value ,id} = e.target;
        dispatch({type:'LOAD-MORE', name, value,id, history});
        if(name === 'limit') {
            store.dispatch( getSites());
            store.dispatch(getSiteListStatus());
            toggleURLQuery( 'limit', value ,history)
        }
        else {
            store.dispatch(getSites());
            store.dispatch(getSiteListStatus());
        }
    }
}

export function clearSearchFieldValue() {
    return function (dispatch) {
        dispatch({type: 'CLEAR-SEARCH-FIELD-VALUE' });
    }
}

export function setTotalSites() {
    return function (dispatch,getState) {
        let {getSites} = getState();
        let {success} = getSites;
        let total = success && success.data && success.data.data.total;
        dispatch({type: 'SET-TOTAL-SITE',total });
    }
}

export function setDefaultSiteFiltersToURL(history) {
    return (dispatch, getState) => {
        let {data} = getState().siteList;
        let {params} = data;
        Object.keys(params).map(key => {
            if (params[key] !== `` && params[key] !== null && params[key] !== undefined) {
                toggleURLQuery(key, params[key], history)
            }
        })
    }
}

export function toggleSiteFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-SITE-FORM-MODAL',});
    }
}

export function setOrganizationIDSite(id) {
    return function (dispatch) {
        dispatch({type: 'SET-ORGANIZATIONID-SITE', id});
    }
}

export function toggleUnverifiedUserFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-UNVERIFIED-USER-MODAL'});
    }
}

