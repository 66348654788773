import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError, handleNotificationError, handleSuccess} from "../../../helpers/ErrorHelper";
import {setSiteListStatus} from "../../../pages/site/SiteDashboard-Action";
import store from '../../../Store';
import getSites from "./sites_get";
import getDemoSite from "./demo_sites_get";

function _success(success) {
    return {type: 'GET_SITE_LIST_STATUS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SITE_LIST_STATUS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SITE_LIST_STATUS_PROCESSING', processing};
}

function getSiteStatus() {
    return (dispatch) => {
        dispatch(_processing(true));
        const lang = localStorage.getItem('lang') || 'en'

        axios({
            url: apiBaseUrl(`customer/sites/status`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken(),'lang': lang}
        })
            .then(function (res) {
                dispatch(_success(res.data.data));
                if (res.data.data) {
                    store.dispatch(setSiteListStatus());
                    if (res.data.data.notifications !== null) {
                        let notify = res.data.data.notifications

                        // notify && notify.length && notify.map((l, key) => {
                        //     if (l.status === 'successful') {
                        //         handleSuccess(l.message)
                        //     }
                        // })

                        //this is added to show the notification if the site is deleted
                        //as well as to fetch necessary information  taking consideration of ,if the site is empty
                        notify && notify.length && notify.map((l, key) => {
                            if (l.status === 'successful') {
                                handleSuccess(l.message)
                            }
                        })
                        notify && notify.length && notify.map((l, key) => {
                            l.status === 'successful' ?

                                handleSuccess(l.message) : l.status === 'failed' &&
                                handleNotificationError(l.message);
                            if (l.event_name === "deleting") {
                                store.dispatch(getDemoSite());
                                store.dispatch(getSites());
                            }
                        })
                    }
                }
                dispatch(_processing(false));

            }).catch(function (error) {
            if (error && error.response &&
                error.response.status === 401) {
                logOut()
            } else {
                handleError(error)
                dispatch(_error(error));
                dispatch(_processing(false));
            }
        });
    }
}

export default getSiteStatus;