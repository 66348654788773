import React from "react";
import TextField from "../../components/shared/form/TextField";
import PasswordField from "../../components/shared/form/PasswordField";
import store from "../../Store";
import { onLoginFormChange, onLoginFormSubmit } from "./Login-Actions";
import LoginFormWorker from "./LoginWorker";
import { connect } from "react-redux";
import Button from "../../components/shared/buttons/Button";
import { withRouter } from "react-router";
import Link from "react-router-dom/Link";
import loginImg from "../../assets/images/login-bg-img.png";
import loginLogo from "../../assets/images/loginlogo.png";

class Login extends React.Component {
  onChange = (e) => {
    let { name, value } = e.target;
    store.dispatch(onLoginFormChange(name, value));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onLoginFormSubmit());
  };

  render() {
    let { payload, errors } = this.props;

    const current_path = this.props.location.pathname;
    const account_paths = ["/se/login"];
    const login_en_path = ["/login", "/"];
    if (account_paths.includes(current_path)) {
      localStorage.setItem("lang", "sv");
    }
    if (login_en_path.includes(current_path)) {
      localStorage.setItem("lang", "en");
    }
    const lang = localStorage.getItem("lang");

    return (
      <>
        <section className="login newLogin">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-5 col-lg-6 col-xl-7">
                <div className="img-block">
                  <img src={loginImg} alt="no-img" />
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="login-page-block page_login">
                  <div className="form-block">
                    <div className="upper-block text-center">
                      <a href="https://keplerhosting.com/" target="_blank">
                        <img src={loginLogo} alt="no-img" />
                      </a>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <TextField
                          value={payload.email}
                          errors={errors.email}
                          icon="user"
                          name="email"
                          label={lang === "en" ? "Email" : "Din epost"}
                          placeholder="Your email"
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="form-group">
                        <PasswordField
                          value={payload.password}
                          errors={errors.password}
                          icon="key"
                          label={
                            lang === "en"
                              ? "Password"
                              : "Skriv in ditt lösenord här"
                          }
                          name="password"
                          placeholder="Enter your password"
                          onChange={this.onChange}
                        />
                      </div>

                      <div className="d-flex button-group button-wrapper inline-buttons  justify-content-center  mt-120">
                        <Button
                          className="btn-theme btn-blue "
                          icon="done"
                          type="primary"
                          htmlType="submit"
                          processing={this.props.loginUser.processing}
                          iconWidth={11}
                          iconRight={3}
                          text={lang === "en" ? `Login` : "Logga in"}
                        ></Button>
                        <Link
                          to={lang === "en" ? "/register" : "/se/register"}
                          className="btn-theme btn-blue"
                        >
                          {lang === "en" ? "Create Account" : "Skapa konto"}
                        </Link>
                      </div>
                    </form>

                    <div className="form-group text-center">
                      <Link
                        to={
                          lang === "en"
                            ? `/forgotpassword`
                            : "/se/forgotpassword"
                        }
                        className="forgot-password"
                      >
                        {lang === "en"
                          ? "Forgot password? Reset it here"
                          : "Glömt ditt lösenord?"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LoginFormWorker />
      </>
    );
  }
}

function mapStateToProps(state) {
  let { loginForm, loginUser } = state;
  let { payload, errors } = loginForm.data;
  return { loginForm, loginUser, payload, errors };
}

export default withRouter(connect(mapStateToProps)(Login));
