export function onFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
    }
}

export function changeType(types) {
    return function (dispatch) {
        dispatch({type: 'ON-TYPE-CHANGE', types});
    }
}
export function setRecapticha(recaptcha) {
    return function (dispatch) {
        dispatch({type: 'SET-RECAPTCHA', recaptcha});
    }
}

export function selectCountry(value) {
    let name = "country_id"
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
    }
}

export function initializeBillingFormStep2Error() {
    return function (dispatch) {
        dispatch({type: 'INITIALIZE-BILLING-FORM-STEP2-ERRORS'});
    }
}

export function billingFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'BILLING-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function setDefaultBillingRegisterData() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-REGISTER-DATA',});
    }
}

export function setDefaultBillingFormStep2() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-BILLING-FORM-STEP1'});
    }
}