import {DomainState} from './Domain-State'

export default function domainListReducer(state = DomainState, action) {

    let {data} = {...state};
    let {name, value, site_id} = action;

    switch (action.type) {

        case 'TOGGLE-DOMAIN-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            return {...state, data};

        case 'SET-SITEID-DOMAIN':
            data.site_id = +site_id;
            return {...state, data};

        case 'TOGGLE-DOMAIN-FORM-MODAL':
            if (data.modal.mode) { // if open, close
                data.modal = {mode: ``,}
            } else { // if close, open
                data.modal.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}
