import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {setDefaultSiteForm, setCustomerOrganizationID, setOrganizationID} from "./SiteForm-Action";
import createSite from "../../../ajax/actions/sites/site_post";
import {endOfURL} from "../../../helpers/UrlHelper";
import getDataCenters from "../../../ajax/actions/data-center/data_centers_get";
import getOrganizations from "../../../ajax/actions/organization/organizations_get";
// import generatePassword from 'generate-password';
import getSites from "../../../ajax/actions/sites/sites_get";
import getSiteCreateDataCenters from "../../../ajax/actions/data-center/site_create_data_centers_get";
import {generatePasswords} from "../../../helpers/passwordGenerateHelper";
import getPlans from "../../../ajax/actions/hosting-plan/plan_get";

class SiteFormWorker extends React.Component {

    componentDidMount() {
        // const password = generatePassword.generate({
        //     length: 20,
        //     numbers: true,
        //     symbols: true,
        //     lowercase: true,
        //     uppercase: true,
        // });
        let password = generatePasswords();

        if (endOfURL() === 'create') {
            store.dispatch(setDefaultSiteForm(password));
        }
        store.dispatch(getOrganizations());
        store.dispatch(setOrganizationID(this.props.organization_id));
        // store.dispatch(getDataCenters());
        store.dispatch( getSiteCreateDataCenters() );
        store.dispatch(getSites());
        store.dispatch(getPlans())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI,} = this.props;

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createSite());

            }
        }

        if (this.props.createSite !== prev.createSite) {
            let {success} = this.props.createSite;
            if (success) {
                this.props.history.push(`/sites`);
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {siteForm, createSite} = state;

    let {dispatchAPI, payload, mode} = siteForm.data;
    return {siteForm, dispatchAPI, payload, mode, createSite};
}

export default withRouter(connect(mapStateToProps)(SiteFormWorker));