import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handle422error, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import {cancelPhpVersionFormDispatch,setDefaultPhpVersionForm,phpVersionFormServerValidationErrors} from "../../../pages/site/phpVersion/form/PhpVersionForm-Action";
import {togglePhpVersionFormModal} from "../../../pages/site/feature-block/list/FeatureBlock-Action";

function _success(success) {
    return {type: 'CREATE_PHP_VERSION_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_PHP_VERSION_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_PHP_VERSION_PROCESSING', processing}
}

function createPhpVersion(id=null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        // dispatch(_success(null));
        // dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'


        let {  phpVersionForm} = getState();
        let  site_id =  id ||phpVersionForm.site_id;
        let payload = phpVersionForm.data.payload

        axios({
            url: apiBaseUrl(`customer/sites/${site_id}/tools/php_version`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(cancelPhpVersionFormDispatch())
                store.dispatch(getSite(site_id))
                store.dispatch(getSiteStatus(site_id))
                store.dispatch(setDefaultPhpVersionForm())
                store.dispatch(togglePhpVersionFormModal());
            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelPhpVersionFormDispatch())
            if (error.response && error.response.status === 422) {
                // handle422error( error.response.data.errors )
                // validationError(error.response.data.message,error.response.status)
                store.dispatch(phpVersionFormServerValidationErrors(error.response.data))
            }
        })
    }
}

export default createPhpVersion;
