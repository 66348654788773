import React from 'react'
import {Route, Switch} from 'react-router-dom'
import NotFound from "../pages/NotFound";
import Site from "../pages/site/list/Site";
import AccountDashboard from "../pages/account/AccountDashboard";
import OverView from "../pages/account/overview/Overview";
import Payment from "../pages/account/payment/Payment";
import OrganizationForm from "../pages/oragnization/form/OrganizationForm";



class NoCardRoutes extends React.Component
{
    render()
    {
        return (
            <Switch>
                <Route exact path="/" component={Site}/>
                <Route exact path="/sites" component={Site}/>
                <Route exact path="/account/overview" component={OverView}/>
                <Route exact path="/account/detail" component={AccountDashboard}/>
                <Route exact path="/account/billing" component={OrganizationForm}/>
                <Route exact path="/account/overview" component={OverView}/>
                <Route exact path="/account/payment" component={Payment}/>
                <Route component={ OverView } />
            </Switch>
        )
    }
}

export default NoCardRoutes ;