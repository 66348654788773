import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import {
    cancelSearchAndReplaceFormDispatch
} from "../../../pages/site/searchandreplace/form/SearchAndReplaceForm-Action";

function _success(success) {
    return {type: 'POST_SEARCH_SUCCESS', success}
}

function _error(error) {
    return {type: 'POST_SEARCH_ERROR', error}
}

function _processing(processing) {
    return {type: 'POST_SEARCH_PROCESSING', processing}
}

function search(id = null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {  searchAndReplaceForm} = getState();
        let  site_id =  id ||searchAndReplaceForm.site_id;
        let payload = searchAndReplaceForm.data.payload
        const lang = localStorage.getItem('lang') || 'en'

        axios({
            url: apiBaseUrl(`customer/sites/${site_id}/tools/search`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(cancelSearchAndReplaceFormDispatch())
                store.dispatch(getSite(site_id))
                store.dispatch(getSiteStatus(site_id))

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default search;
