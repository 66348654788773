import React from 'react'
import {render} from 'react-dom'
import Boot from './Boot'
import "./i18n";
import i18next from 'i18next'

const lang = localStorage.getItem('lang' || 'en')

i18next.changeLanguage(lang)

const Program = <Boot/>
const InBrowser = document.getElementById('root')

render(Program, InBrowser)
