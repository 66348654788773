import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";
import {
    cancelMigrationsFormDispatch, migrationFormServerValidationErrors, migrationValidateFirst,
} from "../../../pages/manual-migration/MigrationForm-Actions";
import {customerValidationError} from "../../../pages/register/customer-form/CustomerFormStep1-Actions";

function _success(success) {
    return {type: 'CREATE_MIGRATION_VALIDATE_FIRST_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_MIGRATION_VALIDATE_FIRST_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_MIGRATION_VALIDATE_FIRST_PROCESSING', processing}
}

function createMigrationValidateFirst() {
    return (dispatch, getState) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        const lang = localStorage.getItem('lang') || 'en'

        let { currentHostStep1} = getState();
        let {payload} = currentHostStep1.data;

        axios({
            url: apiBaseUrl(`customer/manual-migration/validate-first `),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                // handleSuccess(res)
                store.dispatch(migrationValidateFirst())

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelMigrationsFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(customerValidationError(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default createMigrationValidateFirst
