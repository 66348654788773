import {emptyObject, isString} from './DataHelper'
import React from 'react';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Restart from "../assets/images/restart.svg";
toast.configure();
export const handleError = error =>
{
  if ( error ) {
    if ( !error.response ) {
      if ( error.message ) {
          toast.error( el=>
                      <div className="pop-up-message">
                          <div className="media">
                              <div className="media-img d-flex mr-3">
                                  <img src={Restart}/>
                              </div>
                              <div className="media-body">
                                  <h6>Error 500</h6>
                                  <p>{error.message}</p>
                              </div>
                          </div>
                      </div>
          );
        // Alert.error(`Error ${ 500 } - ${ error.message }`, ConfigErrorAlert );
      }
    } else { /* if response */
      let { response } = error;
      let { status, data } = response;
      if ( status && data ) {

        if ( data[0] === `<` ) /* HTML error */ {
          // Alert.error(`Error ${ 500 } - Server Error`, ConfigErrorAlert );
            toast.error( el=>
                <div className="pop-up-message">
                    <div className="media">
                        <div className="media-img d-flex mr-3">
                            <img src={Restart}/>
                        </div>
                        <div className="media-body">
                            <h6>Error 500</h6>
                            <p>Server Error</p>
                        </div>
                    </div>
                </div>
            );
        }
        else if ( status === 400 ) {
          if (isString(data.message)) {
            // Alert.error(`Error ${ status } - ${ data.message }`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>{data.message}</p>
                          </div>
                      </div>
                  </div>
              );
          }
          else if ( data.payload.message ) {
            // Alert.error(`Error ${ status } - ${ data.payload.message }`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>{data.payload.message}</p>
                          </div>
                      </div>
                  </div>
              );
          }
        }
        else if ( status === 401 ) {
          if (data.message && isString(data.message)) {
            // Alert.error(`Error ${ status } - ${ data.message }`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>{data.message}</p>
                          </div>
                      </div>
                  </div>
              );
          } else if ( data.payload.message && isString( data.payload.message ) ) {
            // Alert.error(`Error ${ status } - ${ data.payload.message }`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>{data.payload.message}</p>
                          </div>
                      </div>
                  </div>
              );

          }
        }
        else if ( status === 403 ) {
            if (isString(data.message)) {
                // Alert.error(`Error ${ status } - ${ data.message }`, ConfigErrorAlert );
                toast.error(el =>
                    <div className="pop-up-message">
                        <div className="media">
                            <div className="media-img d-flex mr-3">
                                <img src={Restart}/>
                            </div>
                            <div className="media-body">
                                <h6>Error {status}</h6>
                                <p>{data.message}</p>
                            </div>
                        </div>
                    </div>
                );
            }
        }
          else if ( status === 403 ) {
              if (isString( data.data )) {
                  console.log(` <| data |> `,data )
                  // Alert.error(`Error ${ status } - ${ data.message }`, ConfigErrorAlert );
                  toast.error( el=>
                      <div className="pop-up-message">
                          <div className="media">
                              <div className="media-img d-flex mr-3">
                                  <img src={Restart}/>
                              </div>
                              <div className="media-body">
                                  <h6>Error {status}</h6>
                                  <p>{data.data}</p>
                              </div>
                          </div>
                      </div>
                  );
              }
          else {
            // Alert.error(`Error ${ status } - Forbidden action.`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>Forbidden action.</p>
                          </div>
                      </div>
                  </div>
              );
          }
        }
        else if ( status === 404 ) {
          if (isString(data.message.detail)) {

              // Alert.error(`Error ${ status } - ${ data.message }`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>{ data.message.detail }</p>
                          </div>
                      </div>
                  </div>
              );
          }
        }
        else if ( status === 409 ) {
          if (isString( data.message )) {
            // Alert.error(`Error ${ status } - ${ data.message }`, ConfigErrorAlert );
              toast.error( el=>
                  <div className="pop-up-message">
                      <div className="media">
                          <div className="media-img d-flex mr-3">
                              <img src={Restart}/>
                          </div>
                          <div className="media-body">
                              <h6>Error {status}</h6>
                              <p>{ data.message }</p>
                          </div>
                      </div>
                  </div>
              );
          }
        }
        else if ( status === 500 ) {
          if ( data.error ) {
            if (isString( data.error )) {
              // Alert.error(`Error ${ status } - ${ data.error }`, ConfigErrorAlert );
                toast.error( el=>
                    <div className="pop-up-message">
                        <div className="media">
                            <div className="media-img d-flex mr-3">
                                <img src={Restart}/>
                            </div>
                            <div className="media-body">
                                <h6>Error {status}</h6>
                                <p>{ data.error }</p>
                            </div>
                        </div>
                    </div>
                );
            }
          }
              if (isString(data.message)) {
                  toast.error( el=>
                      <div className="pop-up-message">
                          <div className="media">
                              <div className="media-img d-flex mr-3">
                                  <img src={Restart}/>
                              </div>
                              <div className="media-body">
                                  <h6>Error {status}</h6>
                                  <p>{data.message}</p>
                              </div>
                          </div>
                      </div>
                  );
              }
        }
      }
    }
  }
};

export const handleSuccess = success =>
{

  if(success) {
    if ( isString( success )) {
        return (
            <>
                {toast( el=>
                    <div className="pop-up-message">
                        <div className="media">
                            <div className="media-img d-flex mr-3">
                                <img src={Restart}/>
                            </div>
                            <div className="media-body">
                                <h6>Success</h6>
                                <p>{success}</p>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
      // Alert.success(`Success - ${ success }`, ConfigSuccessAlert );
    } else {
      if ( success.data ) {
        // Alert.success(`Success - ${ success.data.message }`, ConfigSuccessAlert );
        return (
            <>
              {toast( el=>
                  <div className="pop-up-message">
                    <div className="media">
                      <div className="media-img d-flex mr-3">
                        <img src={Restart}/>
                      </div>
                      <div className="media-body">
                        <h6>Success</h6>
                        <p>{success.data.message}</p>
                      </div>
                    </div>
                  </div>
              )}
            </>
        );
      }  else {
        // Alert.success(`Success - ${ success.message }`, ConfigSuccessAlert );
          return (
              <>
                  {toast( el=>

                      <div className="pop-up-message">
                          <div className="media">
                              <div className="media-img d-flex mr-3">
                                  <img src={Restart}/>
                              </div>
                              <div className="media-body">
                                  <h6>Success</h6>
                                  <p>{success.message}</p>
                              </div>
                          </div>
                      </div>
                  )}
              </>
          );
      }
    }

  }
};


export const errorsList = (errors, field = null) =>
{
    let _errors = {};
    if ( errors && errors() ) {
        Object.keys(errors()).map(item => _errors[item] = errors(item));
        return field ? _errors[field] : _errors;
    }
};

export const errorsPresent = (errors) =>
{
  let verified = false;
  for (let key in errorsList(errors)) {
    if (errorsList(errors, key) &&
        errorsList(errors, key).length) {
      verified = true;
      break;
    }
  }
  return verified;
};

export const mergeServerValidationErrors = (errors, serverValidationErrors) =>
{
  if ( errors && serverValidationErrors )
    if (!emptyObject(serverValidationErrors))
      Object.keys(serverValidationErrors).map(field => {
        if (serverValidationErrors[field].length)
          serverValidationErrors[field].map(err =>
              errors[field] && errors[field].push({ step: 0, message: err })
          );
      });
  return errors;
};

export const stepHasError = ( step, errors ) =>
{
  let hasError = false;
  if ( String( step ) && errors ) {
    Object.keys( errors ).map( key => {
      if ( errors[key].length ) {
        errors[key].map( err => {
          if ( err.step === step ) hasError = true;
        })
      }
    })
  }
  return hasError;
};

export const handle422error = errors =>
{
    if(errors) {
        Object.keys(errors).map(key =>
            // console.log(` <|  |> `,key ))
            toast.error( el=>
                <div className="pop-up-message">
                    <div className="media">
                        <div className="media-img d-flex mr-3">
                            <img src={Restart}/>
                        </div>
                        <div className="media-body">
                            <h6>Error {422}</h6>
                            <p>{errors[key][0]}</p>
                        </div>
                    </div>
                </div>
            ))
            // Alert.error(`Error ${ 422 } - ${ errors[key][0] }`.slice(0, -1), ConfigErrorAlert ));
    }
};

export const handle422errorWithOutStatusCode = errors =>
{
    if(errors) {
        if(errors.message){
        Object.keys(errors).map(key =>
            // console.log(` <|  |> `,key ))
            toast.error( el=>
                <div className="pop-up-message">
                    <div className="media">
                        <div className="media-img d-flex mr-3">
                            <img src={Restart}/>
                        </div>
                        <div className="media-body">
                            {/*<h6>Error </h6>*/}
                            <p>{errors.message}</p>
                        </div>
                    </div>
                </div>
            ))
            // Alert.error(`Error ${ 422 } - ${ errors[key][0] }`.slice(0, -1), ConfigErrorAlert ));
    }
        else if(errors.length && errors[0][0]){
            Object.keys(errors).map(key =>
                // console.log(` <|  |> `,key ))
                toast.error( el=>
                    <div className="pop-up-message">
                        <div className="media">
                            <div className="media-img d-flex mr-3">
                                <img src={Restart}/>
                            </div>
                            <div className="media-body">
                                {/*<h6>Error </h6>*/}
                                <p>{errors[0][0]}</p>
                            </div>
                        </div>
                    </div>
                ))
        }
    }
};

export const validationError = ( error, code ) =>{
    toast.error( el=>
        <div className="pop-up-message">
            <div className="media">
                <div className="media-img d-flex mr-3">
                    <img src={Restart}/>
                </div>
                <div className="media-body">
                    <h6>Error {code}</h6>
                    <p>{error}</p>
                </div>
            </div>
        </div>
    );
}


export const handleNotificationError = error =>
{
            if ( error ) {
                toast.error( el=>
                    <div className="pop-up-message">
                        <div className="media">
                            <div className="media-img d-flex mr-3">
                                <img src={Restart}/>
                            </div>
                            <div className="media-body">
                                <h6>Error</h6>
                                <p>{error}</p>
                            </div>
                        </div>
                    </div>
                );
        }
        }