import {CardBillingFormStep2State} from './CardBillingFormStep2-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {isObject} from "../../../../helpers/DataHelper";

export default function billingFormReducer(state = CardBillingFormStep2State, action) {

    let {data} = {...state};
    let {name, value, validationErrors} = action;

    switch (action.type) {

        case 'ON-FORM-CHANGE':
            data.payload[name] = value;
            if (name === "company_name" && value === "") {
                data.payload.vat_id = "";
            }
            buildErrors()
            return {...state, data};

        case 'ON-TYPE-CHANGE':
            data.payload.type = 'company';
            data.errorsPresent = false;
            buildErrors()
            return {...state, data};

        case 'ON-ORGANIZATION-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'INITIALIZE-BILLING-FORM-STEP2-ERRORS':
            buildErrors()
            return {...state, data};

        case 'CANCEL-ORGANIZATION-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-REGISTER-DATA':
            data = {
                payload: {
                    company_name: ``,
                    country_id: ``,
                    city: ``,
                    address_line_1: ``,
                    address_line_2: ``,
                    zip: ``,
                    vat_id: ``,
                    type: `personal`,
                },
                errors: {},
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: "submit",

                user_mode: ``,
                action: 'loader',
                message: ``

            };
            return {...state, data};

        case 'BILLING-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = "error"
            )
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};
        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)

        data.errorsPresent = errorsPresent(errors);
    }

    function errors(field) {
        let {validationErrors, submitted} = data
        let errors = {};
        Object.keys(data.payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `address_line_1`) {
                if (!_(field)) newError(field, 0, `The address field is required`)
            }
            if (data.payload.type === 'company') {
                if (field === `company_name`) {
                    if (!_(field)) newError(field, 0, `The company name field is required`)
                }
            }

            if (field === `country_id`) {
                if (!_(field)) newError(field, 0, `The country field is required`)
            }
            if (field === `city`) {
                if (!_(field)) newError(field, 0, `The city field is required`)
            }
            if (field === `zip`) {
                if (!_(field)) newError(field, 0, `The zip field is required`)
                else if (_(field).length >= 10) newError(field, 0, `This field must have 10 characters`);
                else if (_(field).length <= 3) newError(field, 0, `This field must have 3 characters`);
            }

            if (data.payload.type === 'company') {
                if (field === `vat_id`) {
                    if (!_(field)) newError(field, 0, `The vat id field is required`)
                }
            }
        }
        errors = mergeServerValidationErrors(errors, validationErrors);

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}