import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Loading from "../../../components/shared/loaders/Loading";
import {ucFirst} from "../../../helpers/StringHelper";
import swal from "sweetalert";
import deleteSite from "../../../ajax/actions/sites/site_delete";
import {fromRecord, inRecord} from "../../../helpers/DataHelper";
import i18next from 'i18next'

class SiteActionCell extends React.Component {

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this site? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteSite(id));
    }

    render() {
        let {site_id, site_status, getSiteListStatus} = this.props;
        let {processing} = getSiteListStatus.processing
        let s_status = '';
        if (inRecord(site_status, 'id', '==', site_id)) {
            s_status = fromRecord(site_status, 'id', '==', site_id).status;
        }
        return (
            <>
                {
                    s_status === `updating` || s_status === `idle` ?
                        <>
                            <span className="title">Status</span>
                            <h6>{i18next.t('sites.active', {returnObjects: true})}</h6>
                            {/*<span>98% Uptime</span>*/}
                        </>
                        :
                        <>
                            {/*<div className="text-right">*/}
                            <span className="title"/>
                            <h6>{ucFirst(s_status)} <Loading/></h6>
                            {/*</div>*/}
                        </>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    getSites
,
    getSiteListStatus
}

= state;

let {success} = getSites;

let site_status =
    getSiteListStatus?.success;

let list =
    success &&
    success.data &&
    success.data.data.data;

return {getSites, list, getSiteListStatus, site_status};
}

export default connect(mapStateToProps)(SiteActionCell);
