import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import CouponFormWorker from './CouponFormWorker'
import {onCouponFormChange} from "./CouponForm-Action";
import TextField from "../../../../components/shared/form/TextFieldCoupon";
import i18next from 'i18next';
import Loading from "../../../../components/shared/loaders/Loading";

class CouponForm extends React.Component {

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onCouponFormChange(e.target.name, e.target.value));
    };

    // CouponFormSubmit = (e) => {
    //     e.preventDefault();
    //     store.dispatch(onCouponFormSubmit());
    // };

    render() {
        let {
            errors, coupon, createCoupon
        } = this.props;
        let {processing} = createCoupon

        return (
            <>
                <CouponFormWorker/>
                {/*<form onSubmit={this.CouponFormSubmit}>*/}

                <div className="form-group animated fadeIn faster mb-0">
                    <TextField name="coupon"
                               className="form-control"
                               value={coupon}
                               noLabel
                               placeholder={i18next.t('account.inside-account.overview.discount-code.placeholder', {returnObjects: true})}
                               errors={errors.coupon}
                               message={errors.coupon}
                               onChange={this.onChange}/>
                </div>
                <button className="btn-theme btn-black ml-4 btn-rounded position-relative" type="submit">{processing ?
                    <>
                        {i18next.t('account.inside-account.overview.discount-code.btn.activate', {returnObjects: true})}
                        <Loading className="loader-position" height="15" width="15"/></> :
                    <>{i18next.t('account.inside-account.overview.discount-code.btn.activate', {returnObjects: true})}</>}
                </button>
                {/*<button className="btn-theme btn-black ml-4 btn-rounded position-relative" type="submit">
                      Activate<Loading className="loader-position" height="15" width="15"/>
                </button>*/}
                {/*</div>*/}
                {/*</form>*/}
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        couponForm, createCoupon
    } = state;
    let {errors, payload} = couponForm.data;
    let {
        coupon
    } = payload;

    return {
        couponForm, errors, createCoupon, coupon
    };
}

export default connect(mapStateToProps)(CouponForm);