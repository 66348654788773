export function RedirectsFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'REDIRECTS-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onRedirectsFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-REDIRECTS-FORM-SUBMIT'})
    }
}

export function onRedirectsFormChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-REDIRECTS-FORM-CHANGE', name, value});
    }
}

export function onRedirectsFormDomainChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-REDIRECTS-FORM-DOMAIN-CHANGE', name, value});
    }
}

export function onDeleteRedirectsForm(value) {
    return function (dispatch) {
        dispatch({type: 'ON-DELETE-REDIRECTS-FORM', value});
    }
}

export function onRedirectsFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-REDIRECTS-FORM-MODE-CHANGE', mode});
    }
}

export function cancelRedirectsFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-REDIRECTS-FORM-DISPATCH'});
    }
}

export function setDefaultRedirectsForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-REDIRECTS-FORM'});
    }
}
export function setRedirectDataForUpdate() {
    return function (dispatch, getState) {
        let {getRedirect} = getState();
        let redirect = getRedirect && getRedirect.success.data && getRedirect.success.data.data;
        redirect.all_domains = redirect.domain_id === null;
        dispatch({type: 'SET-REDIRECTS-DATA-FOR-UPDATE', redirect});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}

