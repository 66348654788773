import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SiteActionCell from "./SiteActionCell";
// import {ucFirst} from "../../../helpers/StringHelper";
import { withRouter } from "react-router";
import Browsers from "../../../assets/images/browsers.svg";
import BrowsersYellow from "../../../assets/images/browsers-yellow.svg";
import Dbrowsers from "../../../assets/images/d-browsers.svg";
import DbrowsersYellow from "../../../assets/images/d-browsers-yellow.svg";
import { endOfURL, pushToPage } from "../../../helpers/UrlHelper";
import store from "../../../Store";
import { clearSearchFieldValue } from "./SiteList-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import getDemoSites from "../../../ajax/actions/sites/demo_sites_get";
import { toggleAddCardFormModal } from "../../account/payment/Payment-Actions";
import i18next from "i18next";

class SiteTableCell extends React.Component {
  constructor(props) {
    super(props);
    store.dispatch(getDemoSites());
    this.fireBillingButton = this.fireBillingButton.bind(this);
  }

  fireBillingButton(e) {
    e.preventDefault();
    // const current_path = this.props.location.pathname;
    // const account_paths = [ '/account/overview', '/account/payment'];
    // if( !account_paths.includes( current_path ) ) {
    //     pushToPage(this.props, `/account/overview`)
    // }
    const current_path = this.props.location.pathname;
    const account_paths = ["/account/activate-account"];
    if (!account_paths.includes(current_path)) {
      pushToPage(this.props, `/account/activate-account`);
    }
    window.dataLayer.push({
      event: "createSite",
      eventCategory: "CreateSite",
      eventAction: "ButtonClick",
      eventLabel: "CreateSiteButton",
    });
    // store.dispatch( toggleAddCardFormModal() );
  }

  state = {
    clear: false,
  };

  onclick(site_id) {
    this.props.history.push(`/sites/${site_id}/launchpad`);
  }

  onDemoClick(demo) {
    this.props.history.push(`/sites/${demo}/launchpad`);
  }

  clearSearch() {
    store.dispatch(clearSearchFieldValue());
  }

  render() {
    let {
      list,
      site_status,
      user_name,
      params,
      getSites,
      getDemoSite,
      siteDashboard,
      getLoggedInUser,
    } = this.props;
    // let {processing} = getSites
    let { processing } = getSites;
    let noActiveCard = !getLoggedInUser.success.data.data.has_active_card;
    let billable =
      getLoggedInUser.success &&
      getLoggedInUser?.success?.data?.data?.organization.billable;

    return (
      <>
        {noActiveCard &&
        billable === 1 &&
        params.q !== "" &&
        list &&
        list.length === 0 ? (
          <div className="content-container light-gray-bg">
            <div className="content-message-block">
              <h6>
                {i18next.t("sites.no-site.setup", { returnObjects: true })}
              </h6>
              <h2>
                {i18next.t("sites.no-site.heading.1", { returnObjects: true })}{" "}
                <span>{user_name}</span>,<br />
                {i18next.t("sites.no-site.heading.2", { returnObjects: true })}
                <br />
                {i18next.t("sites.no-site.heading.3", { returnObjects: true })}
              </h2>
              <p>{i18next.t("sites.no-site.desc", { returnObjects: true })}</p>

              <div className="button-wrapper inline-buttons mt-30">
                <a href="#" className="button-wrapper">
                  {getLoggedInUser.success &&
                  getLoggedInUser?.success?.data?.data?.has_active_card ? (
                    <Link className="btn-theme btn-blue" to={`/sites/create`}>
                      {i18next.t("sites.no-site.create", {
                        returnObjects: true,
                      })}
                    </Link>
                  ) : (
                    <a
                      href="#"
                      className="btn-theme btn-blue"
                      id="site-dashboard-create-site-button"
                      onClick={this.fireBillingButton}
                    >
                      {i18next.t("sites.no-site.create", {
                        returnObjects: true,
                      })}
                    </a>
                  )}
                </a>
                <a
                  href="https://keplerhosting.com/general/faq/"
                  target="_blank"
                  className="btn-theme btn-dashed"
                >
                  {i18next.t("sites.no-site.knowledge", {
                    returnObjects: true,
                  })}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="listing-table-block mt-60">
            <div className="table-responsive listing-table">
              <table>
                <tbody>
                  {list?.length ? (
                    list.map((l, key) => {
                      return (
                        site_status &&
                        site_status.length &&
                        site_status.map((a, key) => {
                          return a.id === l.id ? (
                            <tr key={key}>
                              <td
                                onClick={() =>
                                  (l.created === true &&
                                    a.status === "updating") ||
                                  a.status === "idle" ||
                                  a.status === "restoring"
                                    ? this.onclick(l.id)
                                    : "#"
                                }
                              >
                                <span className="title">
                                  {i18next.t("sites.site-name", {
                                    returnObjects: true,
                                  })}
                                </span>
                                <div className="media">
                                  <div className="media-img d-flex mr-3">
                                    <img
                                      src={
                                        a.status === "creating" ||
                                        a.status === "deleting"
                                          ? BrowsersYellow
                                          : Browsers
                                      }
                                      // className="d-light"
                                      alt="#"
                                    />
                                    {/*<img*/}
                                    {/*    src={(l.status === "creating" ? DbrowsersYellow : Dbrowsers)}*/}
                                    {/*    className="d-dark" alt="#"/>*/}
                                  </div>
                                  <div className="media-body">
                                    {(l.created === true &&
                                      a.status === "updating") ||
                                    a.status === "updating" ||
                                    a.status === "idle" ||
                                    a.status === "restoring" ? (
                                      <>
                                        {/*{endOfURL() === 'launchpad'?  <h4><a*/}
                                        {/*     href="#" onClick={this.clearSearch}> {l.display_name} </a></h4>:*/}
                                        {/*    <h4> <Link*/}
                                        {/*     to={`/sites/${l.id}/launchpad`}> {l.display_name} </Link></h4>}*/}
                                        {endOfURL() === "launchpad" ? (
                                          <h4>
                                            <Link
                                              to={`/sites/${l.id}/launchpad`}
                                              onClick={this.clearSearch}
                                            >
                                              {" "}
                                              {l.display_name}{" "}
                                            </Link>
                                          </h4>
                                        ) : (
                                          <h4>
                                            <Link
                                              to={`/sites/${l.id}/launchpad`}
                                              onClick={this.clearSearch}
                                            >
                                              {" "}
                                              {l.display_name}{" "}
                                            </Link>
                                          </h4>
                                        )}

                                        {/*<>*/}
                                        {/*<h4> {l.display_name}</h4>*/}

                                        <span>
                                          {i18next.t("sites.in-org", {
                                            returnObjects: true,
                                          })}{" "}
                                          / {l.organization_name}
                                        </span>
                                      </>
                                    ) : l.status === "deleting" ||
                                      l.status === "creating" ? (
                                      <>
                                        <h4> {l.display_name}</h4>
                                        <span>
                                          {i18next.t("sites.in-org", {
                                            returnObjects: true,
                                          })}{" "}
                                          / {l.organization_name}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <h4>{l.display_name}</h4>
                                        <span>
                                          {i18next.t("sites.in-org", {
                                            returnObjects: true,
                                          })}{" "}
                                          / {l.organization_name}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td
                                className={
                                  l.status === "deleting" ||
                                  l.status === "creating"
                                    ? "deactive"
                                    : "onclick"
                                }
                                onClick={() =>
                                  (l.created === true &&
                                    a.status === "updating") ||
                                  a.status === "idle" ||
                                  a.status === "restoring"
                                    ? this.onclick(l.id)
                                    : "#"
                                }
                              >
                                <span className="title">Datacenter</span>
                                <h6>{l.data_center_name}</h6>
                                <span>IP: {l.server_public_ip}</span>
                              </td>
                              {/*{l.plan &&*/}
                              <td
                                className={
                                  l.status === "deleting" ||
                                  l.status === "creating"
                                    ? "deactive"
                                    : "onclick"
                                }
                                onClick={() =>
                                  (l.created === true &&
                                    a.status === "updating") ||
                                  a.status === "idle" ||
                                  a.status === "restoring"
                                    ? this.onclick(l.id)
                                    : "#"
                                }
                              >
                                <span className="title">
                                  {l.plan ? "Plan" : ""}
                                </span>
                                <h6>
                                  {l.plan && l.plan.description
                                    ? l.plan.description
                                    : ""}
                                </h6>
                                <span>
                                  {l.plan && l.plan.interval ? "Interval:" : ""}{" "}
                                  {l.plan && l.plan.interval
                                    ? l.plan.interval
                                    : ""}
                                </span>
                              </td>
                              {/*}*/}

                              <td className="text-center">
                                <SiteActionCell
                                  site_id={l.id}
                                  status={l.status}
                                />
                              </td>
                              {/*<td>*/}
                              {/*    <span className="title">Status</span>*/}
                              {/*    <h6>Active</h6>*/}
                              {/*    <span>98% Uptime</span>*/}
                              {/*</td>*/}
                            </tr>
                          ) : null;
                        })
                      );
                    })
                  ) : !processing &&
                    params.q === "" &&
                    list &&
                    list.length === 0 ? (
                    <>
                      <tr>
                        <td onClick={() => this.onDemoClick("demo")}>
                          <span className="title">
                            {i18next.t("sites.demo-site.my-demo", {
                              returnObjects: true,
                            })}
                          </span>
                          <div className="media">
                            <div className="media-img d-flex mr-3">
                              <img src={Browsers} className="d-light" alt="#" />
                              <img src={Dbrowsers} className="d-dark" alt="#" />
                            </div>
                            <div className="media-body">
                              <h4>
                                <Link
                                  to={`/sites/demo/launchpad`}
                                  onClick={this.clearSearch}
                                >
                                  {" "}
                                  {"Demo Site"}{" "}
                                </Link>
                              </h4>
                              <span>
                                {i18next.t("sites.demo-site.in-org", {
                                  returnObjects: true,
                                })}{" "}
                                /{" "}
                                {i18next.t("sites.demo-site.in-org-demo", {
                                  returnObjects: true,
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className={"onclick"} onClick={() => "#"}>
                          <span className="title">Datacenter</span>
                          <h6>{"Demo datacenter"}</h6>
                          <span>IP: {"123.123.123.123"}</span>
                        </td>

                        <td className="text-center">
                          <span className="title">Status</span>
                          <h6>
                            {i18next.t("sites.demo-site.active", {
                              returnObjects: true,
                            })}
                          </h6>
                        </td>
                      </tr>

                      {/*<div className="content-container light-gray-bg">*/}
                      {/*    <div className="content-message-block">*/}
                      {/*        <h6>Your account is now setup and ready</h6>*/}
                      {/*        <h2>Hi <span>{user_name}</span><br/>*/}
                      {/*            Welcome to Kepler Hosting<br/>*/}
                      {/*            Start by creating your first site. </h2>*/}
                      {/*        <p>Kepler Hosting is a managed WordPress hosting provider that helps take care of all your*/}
                      {/*            needs regarding your website. We run our services on cutting-edge technology and take*/}
                      {/*            support to the next level.</p>*/}

                      {/*        <div className="button-wrapper inline-buttons mt-30">*/}
                      {/*            /!*<a href="" className="btn-theme btn-blue">Create a site</a>*!/*/}
                      {/*            <a href="#" className="button-wrapper">*/}
                      {/*                { getLoggedInUser.success  && getLoggedInUser?.success?.data?.data?.has_active_card ?*/}

                      {/*                    <Link className="btn-theme btn-blue" to={`/sites/create`}>*/}
                      {/*                        Create Site*/}
                      {/*                    </Link>:*/}
                      {/*                    <a href="#" className="btn-theme btn-blue" onClick={ this.fireBillingButton }>Create Site</a>}*/}

                      {/*            </a>*/}
                      {/*            <a href="https://keplerhosting.com/general/faq/" target="_blank"*/}
                      {/*               className="btn-theme btn-dashed">Knowledge base</a>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*</div> */}
                    </>
                  ) : !processing &&
                    params.q !== "" &&
                    !siteDashboard.data.searching ? (
                    <>
                      <tr>
                        <td colSpan={4} className="text-center">
                          No Sites Available
                        </td>
                      </tr>
                    </>
                  ) : (
                    // processing ||(!noActiveCard && siteDashboard.data.searching) &&
                    <>
                      <tr>
                        <td colSpan={5} className="text-center">
                          <Loading />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!processing && params.q === "" && list && list.length === 0 ? (
          <div className="content-container light-gray-bg">
            <div className="content-message-block">
              <h6>
                {i18next.t("sites.no-site.setup", { returnObjects: true })}
              </h6>
              <h2>
                {i18next.t("sites.no-site.heading.1", { returnObjects: true })}{" "}
                <span>{user_name}</span>,<br />
                {i18next.t("sites.no-site.heading.2", { returnObjects: true })}
                <br />
                {i18next.t("sites.no-site.heading.3", { returnObjects: true })}
              </h2>
              <div className="wistia_responsive_padding">
                <div className="wistia_responsive_wrapper">
                  <iframe
                    src="https://fast.wistia.net/embed/iframe/fdjg0muoj8?videoFoam=true"
                    title="644_KeplerHosting_MoveAwayFromBadHosting_final_h264 Video"
                    allow="autoplay; fullscreen"
                    allowTransparency="true"
                    frameBorder="0"
                    scrolling="no"
                    className="wistia_embed"
                    name="wistia_embed"
                    allowFullScreen
                    msallowfullscreen
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              </div>
              <p>{i18next.t("sites.no-site.desc", { returnObjects: true })}</p>

              <div className="button-wrapper inline-buttons mt-30">
                {/*<a href="" className="btn-theme btn-blue">Create a site</a>*/}
                <a href="#" className="button-wrapper">
                  {getLoggedInUser.success &&
                  getLoggedInUser?.success?.data?.data?.has_active_card ? (
                    <Link className="btn-theme btn-blue" to={`/sites/create`}>
                      {i18next.t("sites.no-site.create", {
                        returnObjects: true,
                      })}
                    </Link>
                  ) : (
                    <a
                      href="#"
                      className="btn-theme btn-blue"
                      id="site-dashboard-create-site-button"
                      onClick={this.fireBillingButton}
                    >
                      {i18next.t("sites.no-site.create", {
                        returnObjects: true,
                      })}
                    </a>
                  )}
                </a>
                <a
                  href="https://keplerhosting.com/general/faq/"
                  target="_blank"
                  className="btn-theme btn-dashed"
                >
                  {i18next.t("sites.no-site.knowledge", {
                    returnObjects: true,
                  })}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    getSites,
    getSiteListStatus,
    route,
    siteList,
    siteDashboard,
    getLoggedInUser,
  } = state;
  let { success } = getSites;
  let user_name = route && route.data.user && route.data.user.first_name;
  // let demoSite = getDemoSite && getDemoSite.success && getDemoSite.success.data&& getDemoSite.success.data.data&& getDemoSite.success.data.data.site
  let { params } = siteList.data;
  let site_status = getSiteListStatus?.success;

  let list = success?.data.data.data;

  return {
    getSites,
    getLoggedInUser,
    list,
    getSiteListStatus,
    site_status,
    user_name,
    params,
    siteDashboard,
  };
}

export default withRouter(connect(mapStateToProps)(SiteTableCell));

/*old one without no site search design when account is not active*/

// <>
//     <div className="listing-table-block mt-60">
//         <div className="table-responsive listing-table">
//             <table>
//                 <tbody>
//                 {list?.length ? list.map((l, key) => {
//                         return (
//                             site_status && site_status.length && site_status.map((a, key) => {
//                                 return (
//                                     a.id === l.id ?
//                                         <tr key={key}>
//                                             <td
//                                                 onClick={() => (l.created === true && a.status === "updating") || a.status === "idle" || a.status === "restoring" ? this.onclick(l.id) : "#"}>
//                                                 <span className="title">{i18next.t('sites.site-name' ,{returnObjects: true})}</span>
//                                                 <div className="media">
//                                                     <div className="media-img d-flex mr-3">
//                                                         <img src={(l.status === "creating" ? BrowsersYellow : Browsers)}
//                                                              className="d-light" alt="#"/>
//                                                         <img src={(l.status === "creating" ? DbrowsersYellow : Dbrowsers)}
//                                                              className="d-dark" alt="#"/>
//                                                     </div>
//                                                     <div className="media-body">
//                                                         {(l.created === true && a.status === "updating") || a.status === "updating" || a.status === "idle" || a.status === "restoring" ?
//                                                             <>
//                                                                 {/*{endOfURL() === 'launchpad'?  <h4><a*/}
//                                                                 {/*     href="#" onClick={this.clearSearch}> {l.display_name} </a></h4>:*/}
//                                                                 {/*    <h4> <Link*/}
//                                                                 {/*     to={`/sites/${l.id}/launchpad`}> {l.display_name} </Link></h4>}*/}
//                                                                 {endOfURL() === 'launchpad' ?
//                                                                     <h4><Link
//                                                                         to={`/sites/${l.id}/launchpad`}
//                                                                         onClick={this.clearSearch}> {l.display_name} </Link></h4> :
//                                                                     <h4><Link
//                                                                         to={`/sites/${l.id}/launchpad`} onClick={this.clearSearch}
//                                                                     > {l.display_name} </Link></h4>
//                                                                 }
//
//                                                                 {/*<>*/}
//                                                                 {/*<h4> {l.display_name}</h4>*/}
//
//                                                                 <span>{i18next.t('sites.in-org' ,{returnObjects: true})} / {l.organization_name}</span>
//                                                             </>
//
//                                                             : l.status === "deleting" || l.status === "creating" ? <>
//                                                                     <h4> {l.display_name}</h4>
//                                                                     <span>{i18next.t('sites.in-org' ,{returnObjects: true})} / {l.organization_name}</span></>
//                                                                 : <> <h4>{l.display_name}</h4>
//                                                                     <span>{i18next.t('sites.in-org' ,{returnObjects: true})} / {l.organization_name}</span></>
//                                                         }
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             <td className={l.status === "deleting" || l.status === "creating" ? "deactive" : "onclick"}
//                                                 onClick={() => (l.created === true && a.status === "updating") || a.status === "idle" || a.status === "restoring"
//                                                     ? this.onclick(l.id) : "#"}>
//                                                 <span className="title">Datacenter</span>
//                                                 <h6>{l.data_center_name}</h6>
//                                                 <span>IP: {l.server_public_ip}</span>
//                                             </td>
//
//                                             <td className="text-center">
//                                                 <SiteActionCell
//                                                     site_id={l.id}
//                                                     status={l.status}
//                                                 />
//                                             </td>
//                                             {/*<td>*/}
//                                             {/*    <span className="title">Status</span>*/}
//                                             {/*    <h6>Active</h6>*/}
//                                             {/*    <span>98% Uptime</span>*/}
//                                             {/*</td>*/}
//                                         </tr>
//                                         :
//                                         null
//                                 )
//                             }
//                             ))
//                     })
//                     :
//                     !processing &&  params.q === '' && list && list.length === 0  ?
//                         <>
//                             <tr>
//                                 <td
//                                     onClick={()=> this.onDemoClick('demo')}>
//                                     <span className="title">{i18next.t('sites.demo-site.my-demo' ,{returnObjects: true})}</span>
//                                     <div className="media">
//                                         <div className="media-img d-flex mr-3">
//                                             <img src={ Browsers}
//                                                  className="d-light" alt="#"/>
//                                             <img src={ Dbrowsers}
//                                                  className="d-dark" alt="#"/>
//                                         </div>
//                                         <div className="media-body">
//                                             <h4><Link
//                                                 to={`/sites/demo/launchpad`}
//                                                 onClick={this.clearSearch}> {"Demo Site"} </Link></h4>
//                                             <span>{i18next.t('sites.demo-site.in-org' ,{returnObjects: true})} / {i18next.t('sites.demo-site.in-org-demo' ,{returnObjects: true})}</span>
//                                         </div>
//                                     </div>
//                                 </td>
//                                 <td className={"onclick"}
//                                     onClick={() => "#"}>
//                                     <span className="title">Datacenter</span>
//                                     <h6>{"Demo datacenter"}</h6>
//                                     <span>IP: {"123.123.123.123"}</span>
//                                 </td>
//
//                                 <td className="text-center">
//                                     <span className="title">Status</span>
//                                     <h6>{i18next.t('sites.demo-site.active' ,{returnObjects: true})}</h6>
//                                 </td>
//                             </tr>
//
//                             {/*<div className="content-container light-gray-bg">*/}
//                             {/*    <div className="content-message-block">*/}
//                             {/*        <h6>Your account is now setup and ready</h6>*/}
//                             {/*        <h2>Hi <span>{user_name}</span><br/>*/}
//                             {/*            Welcome to Kepler Hosting<br/>*/}
//                             {/*            Start by creating your first site. </h2>*/}
//                             {/*        <p>Kepler Hosting is a managed WordPress hosting provider that helps take care of all your*/}
//                             {/*            needs regarding your website. We run our services on cutting-edge technology and take*/}
//                             {/*            support to the next level.</p>*/}
//
//                             {/*        <div className="button-wrapper inline-buttons mt-30">*/}
//                             {/*            /!*<a href="" className="btn-theme btn-blue">Create a site</a>*!/*/}
//                             {/*            <a href="#" className="button-wrapper">*/}
//                             {/*                { getLoggedInUser.success  && getLoggedInUser?.success?.data?.data?.has_active_card ?*/}
//
//                             {/*                    <Link className="btn-theme btn-blue" to={`/sites/create`}>*/}
//                             {/*                        Create Site*/}
//                             {/*                    </Link>:*/}
//                             {/*                    <a href="#" className="btn-theme btn-blue" onClick={ this.fireBillingButton }>Create Site</a>}*/}
//
//                             {/*            </a>*/}
//                             {/*            <a href="https://keplerhosting.com/general/faq/" target="_blank"*/}
//                             {/*               className="btn-theme btn-dashed">Knowledge base</a>*/}
//                             {/*        </div>*/}
//                             {/*    </div>*/}
//                             {/*</div> */}
//                         </>:
//                         params.q !== '' && !siteDashboard.data.searching? <>
//                                 <tr>
//                                     <td colSpan={4} className="text-center">No Sites Available</td>
//                                 </tr>
//                             </>:
//                             siteDashboard.data.searching &&
//                             <>
//                                 <tr><td colSpan={5} className="text-center"><Loading/></td></tr>
//                             </>}
//                 </tbody>
//             </table>
//         </div>
//     </div>
//     {!processing &&  params.q === '' && list && list.length === 0  ?
//         <div className="content-container light-gray-bg">
//             <div className="content-message-block">
//                 <h6>{i18next.t('sites.no-site.setup' ,{returnObjects: true})}</h6>
//                 <h2>{i18next.t('sites.no-site.heading.1' ,{returnObjects: true})} <span>{user_name}</span>,<br/>
//                     {i18next.t('sites.no-site.heading.2' ,{returnObjects: true})}<br/>
//                     {i18next.t('sites.no-site.heading.3' ,{returnObjects: true})}</h2>
//                 <p>{i18next.t('sites.no-site.desc' ,{returnObjects: true})}</p>
//
//                 <div className="button-wrapper inline-buttons mt-30">
//                     {/*<a href="" className="btn-theme btn-blue">Create a site</a>*/}
//                     <a href="#" className="button-wrapper">
//                         { getLoggedInUser.success  && getLoggedInUser?.success?.data?.data?.has_active_card ?
//
//                             <Link className="btn-theme btn-blue" to={`/sites/create`}>
//                                 {i18next.t('sites.no-site.create' ,{returnObjects: true})}
//                             </Link>:
//                             <a href="#" className="btn-theme btn-blue" onClick={ this.fireBillingButton }>{i18next.t('sites.no-site.create' ,{returnObjects: true})}</a>}
//
//                     </a>
//                     <a href="https://keplerhosting.com/general/faq/" target="_blank"
//                        className="btn-theme btn-dashed">{i18next.t('sites.no-site.knowledge' ,{returnObjects: true})}</a>
//                 </div>
//             </div>
//         </div>:null
{
  /*    }*/
}
{
  /*</>*/
}
