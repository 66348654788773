import React from 'react';
import {names, ucFirst, unslufigy} from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tooltip from "../tooltip/Tooltip";
// import generatePassword from 'generate-password';
import {onSiteFormPasswordChange} from "../../../pages/site/form/SiteForm-Action"
import store from "../../../Store"
import {generatePasswords} from "../../../helpers/passwordGenerateHelper"
import i18next from 'i18next'

class CustomPasswordField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false,
        };

    }
    // state = {
    //     copied: false,
    // }
    onPasswordChange = (e) => {
        e.preventDefault();
        store.dispatch(onSiteFormPasswordChange(e.target.value));
    }

    generatePwd() {
        this.setState({
            copied: false
        });
        // this.state.copied = false;
        // console.log(` <| generatePasswords |> `,generatePasswords() )
        // let password = generatePassword.generate({
        //     length: 20,
        //     numbers: true,
        //     symbols: true,
        //     lowercase: true,
        //     uppercase: true,
        //     exclude: "$"
        // });
        let password = generatePasswords()
        store.dispatch(onSiteFormPasswordChange(password));
    }

    placeholder() {
        let {placeholder, name, label} = this.props;
        if (placeholder) return placeholder;
        if (label) return label;
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return _label;
            }
        }
    }

    label() {
        let {name, label, errors} = this.props;
        let errorsPresent = errors && errors.length;
        if (label) return (
            <label>
                {label}
            </label>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                        {_label}
                    </label>
                )
            }
        }
    }

    render() {
        let {
            name, size, value, onChange, id, noLabel, disabled, errors, required,
        } = this.props;
        let errorsPresent = errors && errors.length;


        return (
            <>
                <div className={names(`form-group`, errors && errors.length && `has-error`, required && `required`)}>

                    {!noLabel ? <>{this.label()}</> : null}

                    <div className="wp-password-input-wrapper">
                        <input type="text"
                               className="form-control"
                               size={size || `default`}
                               name={name}
                               disabled={disabled}
                               id={id || name}
                               value={value}
                               placeholder={this.placeholder()}
                               onChange={onChange}
                        />

                        <a className="password-reset">
                            <button
                                className="bg-transparent  border-0"
                                type="button"
                                onClick={() => {
                                    this.generatePwd()
                                }}
                            ><span className="fas fa-undo"/></button>
                        </a>
                        <a className="copy">
                            <CopyToClipboard
                                text={value}
                                onCopy={() => this.setState({copied: true})}>
                                <button className="bg-transparent border-0" type="reset">
                                    {!this.state.copied ?
                                        <Tooltip title={<span
                                            className={`icon-copy`}/>}
                                                 tooltip={this.state.copied ? null : i18next.t('create-new-site.form.copy' ,{returnObjects: true})}/>
                                        :
                                        this.state.copied ?
                                            <div style={{"color": "blue"}}>
                                                <Tooltip title={<span
                                                    className={`icon-checkmark `}/>}
                                                         tooltip={this.state.copied ? i18next.t('create-new-site.form.copied' ,{returnObjects: true}) : null}/>
                                            </div>
                                            // <div style={{"color": "blue"}}>
                                            //     <span className="icon-checkmark"/>
                                            // </div>
                                            : null}
                                </button>
                            </CopyToClipboard></a>
                    </div>
                </div>
            </>

        )
    }
}

let {string, number, oneOfType} = PropTypes;
CustomPasswordField.propTypes = {value: oneOfType([string, number])};

export default CustomPasswordField;