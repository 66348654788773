import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {endOfURL, secondToLastItemInURL} from "../../helpers/UrlHelper";
import i18next from 'i18next';

class SiteDashboardLinks extends React.Component {

    render() {
        let {site_id, lbVersion, success} = this.props;
        let isLbVersion2OrDemo = lbVersion === 2 || secondToLastItemInURL() === "demo"
        return (
            <>
                <ul>
                    <li>
                        <Link className={endOfURL() === "launchpad" ? "active" : ""} to={`/sites/${site_id}/launchpad`}>
                            {i18next.t('sites.inside-site.site-nav.launch-pad' ,{returnObjects: true})}
                        </Link>
                    </li>
                    <li>
                        <Link className={endOfURL() === "domain" ? "active" : ""} to={`/sites/${site_id}/domain`}>
                            {i18next.t('sites.inside-site.site-nav.domain' ,{returnObjects: true})}
                        </Link>
                    </li>
                    {/*{success &&*/}
                    {isLbVersion2OrDemo &&
                    <li>
                        <Link className={endOfURL() === "redirects" ? "active" : ""} to={`/sites/${site_id}/redirects`}>
                            {i18next.t('sites.inside-site.site-nav.redirects', {returnObjects: true})}
                        </Link>
                    </li>
                    }
                    {/*}*/}

                        {isLbVersion2OrDemo  &&
                        <li>
                            <Link className={endOfURL() === "ssl" ? "active" : ""} to={`/sites/${site_id}/ssl`}>
                                SSL
                            </Link>
                        </li>
                        }
                    <li>
                        <Link className={endOfURL() === "backups" ? "active" : ""} to={`/sites/${site_id}/backups`}>
                            {i18next.t('sites.inside-site.site-nav.backups' ,{returnObjects: true})}
                        </Link>
                    </li>
                    <li>
                        <Link className={endOfURL() === "tools" ? "active" : ""} to={`/sites/${site_id}/tools`}>
                            {i18next.t('sites.inside-site.site-nav.tools' ,{returnObjects: true})}
                        </Link>
                    </li>
                    <li>
                        <Link className={endOfURL() === "cache" ? "active" : ""} to={`/sites/${site_id}/cache`}>
                            Cache
                        </Link>
                    </li>
                    <li>
                        <Link className={endOfURL() === "logs" ? "active" : ""} to={`/sites/${site_id}/logs`}>
                            {i18next.t('sites.inside-site.site-nav.logs' ,{returnObjects: true})}
                        </Link>
                    </li>

                    <li>
                        <Link className={endOfURL() === "plan" ? "active" : ""} to={`/sites/${site_id}/plan`}>
                            {i18next.t('sites.inside-site.site-nav.plan' ,{returnObjects: true})}
                        </Link>
                    </li>


                    {/*<li><a href="">Backups</a></li>*/}
                    {/*<li><a href="">Redirects</a></li>*/}
                    {/*<li><a href="">Plugins</a></li>*/}
                    {/*<li><a href="">Kepler CDN</a></li>*/}
                    {/*<li><a href="">Logs</a></li>*/}
                    {/*<li><a href="">Danger Zone</a></li>*/}
                </ul>
                <div className="danger-zone">
                    <Link className={endOfURL() === "dangerzone" ? "active" : ""} to={`/sites/${site_id}/dangerzone`}>
                        {i18next.t('sites.inside-site.site-nav.danger' ,{returnObjects: true})}
                    </Link>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {route, getLoggedInUser, getUser, getSite} = state;

    let {success} = getSite
    let customer_id = getLoggedInUser?.success?.data.data.id
    let lbVersion = getSite.success?.lb_version
    return {route, customer_id, getLoggedInUser, getUser, getSite, lbVersion, success}
}

export default connect(mapStateToProps)(SiteDashboardLinks);