import React from 'react';
import store from '../../Store'
import {
    setDefaultRegisterForm,
} from './RegisterForm-Actions'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import registerUser from "../../ajax/actions/register/registerv2_post";
import {loggedInUser} from "../../helpers/LocalStorageHelper";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";

class RegisterFormWorker extends React.Component {

    componentDidMount() {
        store.dispatch(setDefaultRegisterForm())
    }

    componentDidUpdate(lastProps) {
        let {dispatchAPI} = this.props;
        if (this.props.registerUserV2 !== lastProps.registerUserV2) {
            let {success} = this.props.registerUserV2;
            if (success) {
                loggedInUser(success.data.data.token);
                // store.dispatch(getLoggedInUser(success.data.data))
                store.dispatch(getLoggedInUser())

                // window.location.href = '/';
                this.props.history.push('/sites');
            }
        }

        if (dispatchAPI) {
            if (!lastProps.dispatchAPI) {
                // store.dispatch(verifyEmail())
                store.dispatch(registerUser());
            }
        }

        if (this.props.registerUserV2 !== lastProps.registerUserV2) {
            let {success} = this.props.registerUserV2;
            if (success) {
                this.props.history.push('/sites');
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {registerUserV2, registerFormV2} = state;
    let {dispatchAPI} = registerFormV2.data
    return {registerUserV2, registerFormV2, dispatchAPI};
}

export default withRouter(connect(mapStateToProps)(RegisterFormWorker));