import React from 'react';
import store from '../../Store'
import {
    setDefaultMigrationDetailsData,
} from './migration-details-form/MigrationDetailsFormStep2-Actions'
import {
    setDefaultCurrentHostFormStep1,
} from './current-host-form/CurrentHostFormStep1-Actions'
import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {setDefaultMigrateFormStep3} from "./migration-final-form/MigrateFormStep3-Actions";
import createMigration from "../../ajax/actions/manual-migration/migration_post";
import {setDefaultMigrationForm} from "./MigrationForm-Actions";

class MigrationFormWorker extends React.Component {

    componentDidMount() {
        store.dispatch(setDefaultMigrationDetailsData())
        store.dispatch(setDefaultCurrentHostFormStep1())
        store.dispatch(setDefaultMigrateFormStep3())
        store.dispatch(setDefaultMigrationForm())
    }

    componentDidUpdate(lastProps) {
        let {dispatchAPI, currentStep} = this.props;

        if (this.props.createMigration !== lastProps.createMigration) {
            let {success} = this.props.createMigration;
            if (success) {
                store.dispatch(setDefaultMigrationDetailsData())
                store.dispatch(setDefaultCurrentHostFormStep1())
                store.dispatch(setDefaultMigrateFormStep3())
                store.dispatch(setDefaultMigrationForm())
                this.props.history.push('/migration');
            }
        }

        if (dispatchAPI) {
            if (!lastProps.dispatchAPI) {
                store.dispatch(createMigration());
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {createMigration, migrationForm} = state;
    let {dispatchAPI, currentStep} = migrationForm.data
    return {createMigration, migrationForm, dispatchAPI, currentStep};
}

export default withRouter(connect(mapStateToProps)(MigrationFormWorker));