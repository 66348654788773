export const shortMonthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];

export const fullDate = (string) =>
{
    if ( string && string !== '0000-00-00 00:00:00' ) {
      let date = new Date(string);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = date.getHours() >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;

      return (shortMonthsList[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear() + " " + strTime);
    }
};

export const fullDateStringNoTime = _date =>
{
    let date = new Date(_date);
    let year = date.getFullYear().toString();
    let hours = date.getHours().toString();
    let months = (date.getMonth() + 1).toString();
    let days = date.getDate().toString();

    if (+hours < 10) hours = '0' + hours;
    if (+months < 10) months = '0' + months;
    if (+days < 10) days = '0' + days;
    return year + '-' + months + '-' + days;
}

export const fullDate12Hrs = (string) =>
{
  var dateOut = string.split("-").join("/");
  var date = new Date(dateOut);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? '0' + hours : hours; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  seconds = seconds < 10 ? '0'+seconds : seconds;
  let strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
  return `${ shortMonthsList[date.getMonth() ]} ${ date.getDate() }, ${ date.getFullYear() } ${ strTime }`;
};

export const fullDateFormat = (string) =>
{
    let date = new Date(string)
    let month = date.getMonth();
    month = month <= 9 ? `0${month}` : month;
    let day = date.getDate();
    day = day <=9 ? `0${day}` : day;
    return `${date.getFullYear() + "-" + month + "-" + day} `
}

export const justDate = (string) =>
{
    let date = new Date(string.replace(/\s/, 'T'));
    return (shortMonthsList[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear());
};

export const justDates = (string) =>
{
    let date = new Date(string.replace(/\s/, 'T'));
    return (longMonthsList[date.getMonth()] + " " + date.getFullYear());
};

export const beginningOfThisMonth = () =>
{
    let date = new Date();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    month = month <= 9 ? `0${month}` : month;
    return `${ year }-${month}-01`;
};

export const getCurrentDate = () =>
{
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth()+1;
    let year = date.getFullYear();
    day = day <= 9 ? `0${ day }` : day;
    month = month <= 9 ? `0${ month }` : month;
    return `${ year }-${ month }-${ day }`;
};

export const currentYearAndMonth = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    month = month < 10 ? `0${ month }` : month;
    return `${ year }-${ month }`;
};

export const today = new Date();

export const fullDateString = _date =>
{
    let date = new Date(_date);
    let year = date.getFullYear().toString();
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();
    let months = (date.getMonth() + 1).toString();
    let days = date.getDate().toString();

    if (+hours < 10) hours = '0' + hours;
    if (+months < 10) months = '0' + months;
    if (+days < 10) days = '0' + days;
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    return year + '-' + months + '-' + days + 'T' + hours + ':' + minutes;
}

export const yesterday = new Date( new Date().setDate( new Date().getDate() - 1) );

export const daysListSoFarInCurrentMonth = () =>
{
    let array = [];
    for (let i = 1; i <= today.getDate(); i++) {
        array.push( i );
    }
    return array;
};

export const waitAnd = ( func, delay ) =>
{
    setTimeout(() => func, delay || 200);
};

export const longMonthsList = ["January", "February", "March", "April", "May", "Jun", "July", "August", "September", "October", "November", "December" ];

export const monthsWithValues =
  [
      { value: 1, name: 'January' },
      { value: 2, name: 'February' },
      { value: 3, name: 'March' },
      { value: 4, name: 'April' },
      { value: 5, name: 'May' },
      { value: 6, name: 'June' },
      { value: 7, name: 'July' },
      { value: 8, name: 'August' },
      { value: 9, name: 'September' },
      { value: 10, name: 'October' },
      { value: 11, name: 'November' },
      { value: 12, name: 'December' },
  ];

//get the lastday of the month
export const lastday = (y,m) => {
  return  new Date(y, m +1, 0).getDate();
}

export const previousMonth = () =>
{
  let date = new Date();
  let prevMonth = date.getMonth();
  prevMonth = prevMonth <= 9 ? `0${prevMonth}` : prevMonth;

  let year = date.getFullYear();
  if(prevMonth === 12) {
    year = date.getFullYear() - 1;
  }

  let day = lastday(year, prevMonth-1);
  day = day <= 9 ? `0${ day }` : day;
  let endDate = `${ year }-${prevMonth}-${day}`;
  let startDate = `${ year }-${prevMonth}-01`;
  return [startDate, endDate];
}

export const previousMonthSeparateElement = () =>
{
  let date = new Date();
  let prevMonth = date.getMonth();
  prevMonth = prevMonth <= 9 ? `0${prevMonth}` : prevMonth;

  let year = date.getFullYear();
  if(prevMonth === 12) {
    year = date.getFullYear() - 1;
  }

  let day = lastday(year, prevMonth-1);
  day = day <= 9 ? `0${ day }` : day;
  return [year, prevMonth, day];
}