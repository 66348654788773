import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSiteDomain from "../domain/site_domain_get";
import getSiteStatus from "../sites/site_status_get";
import {handle422error} from "../../../helpers/ErrorHelper"

function _success(success)
{
  return { type: 'POST_GENERATE_SSL_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_GENERATE_SSL_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_GENERATE_SSL_PROCESSING', processing };
}

function createGenerateSsl(id)
{
  return ( dispatch , getState) =>
  {
    dispatch(_processing(true));
    // dispatch(_success(null));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'

    let {SSLList} = getState();
    let {data} = SSLList.data
    const payload = {
      "domains": data
    }

    axios({
      url: apiBaseUrl(`customer/sites/${id}/generateSsl`),
      method: "post",
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken(),'lang': lang
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        // store.dispatch(getSiteDomain(id));
        store.dispatch(getSiteStatus(id))
      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        if (error.response && error.response.status === 422) {
          // store.dispatch(siteFormServerValidationErrors(error.response.data.errors))
          handle422error( error.response.data.errors )
          // scrollToError()
        }
      });

  }
}


export default createGenerateSsl;