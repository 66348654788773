import React from 'react'
import {connect} from 'react-redux';
import SiteWorker from './SiteWorker';
import {toggleSiteListFilter, loadMore} from './SiteList-Actions';
import store from '../../../Store';
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import SiteTableCell from "./SiteTableCell";
import SiteDashboardHeader from "../SiteDashboardHeader";
import NoCardMessage from "../../../components/shared/pages/NoCardMessage";
import i18next from 'i18next';
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import VerifyEmail from "../../../components/shared/pages/VerifyEmail";
import {fullDateFormat, fullDateStringNoTime} from "../../../helpers/DateTimeHelper";

class Site extends React.Component {

    state = {
        initialLoading: false
    }

    loadMoreSites()
    {
        store.dispatch(loadMore({
            // target: {name: `sort_id`, value: field}
            target: {name: `limit`, value: 4}
        }))
    };

    componentDidUpdate(prevProps) {
        if (this.props.getSites !== prevProps.getSites) {
            let {processing, success} = this.props.getSites;
            if (processing) {
                this.setState({initialLoading: true});
            }

            if (success) {
                this.setState({initialLoading: false})
            }
        }
    }

    toggleSort = (field) => {
        store.dispatch(toggleSiteListFilter({
            // target: {name: `sort_id`, value: field}
            target: {name: `sort_by`, value: field}
        }, this.props.match.params.id))
    };

    render() {
        let {pages, getSites, list, params, fields, site_status, total,sale, total_sites,email_verify, emailVerification} = this.props;
        let {processing, error, success} = getSites;
        if (error) {
            this.props.history.push('/');
        }
        // let emailVerification = false

        return (
            <>
                <SiteDashboardHeader/>

                {!sale ? <NoCardMessage/>:null}
                {/* {!sale ? <NoCardMessage/> : sale &&
                    <>
                    <div className="payment-notice">
                        <div className="light-gray-bg">
                            <div className="notice">
                                <h6 className="ml-5">{sale && sale.valid_months} Month Free Credit
                                </h6>
                            </div>
                            <div className="payment-notice-message">
                                <p>Your {sale && sale.valid_months} month free credit is activate and will
                                    end on {sale && fullDateStringNoTime(sale.expires_on)}. Feel
                                    free to create as many sites as you like and contact our support if you
                                    have
                                    any questions!</p>
                            </div>
                        </div>
                    </div>
                    </>
                } */}

                <ResponsiveModalForm visible={!emailVerification}
                                     closeIcon={false}
                                     closeOnEsc={false}
                                     closeOnOverlayClick={false}
                                     emailVerifyPopup={true}

                                     // closeModal={() => store.dispatch(toggleUnverifiedUserFormModal())}>
                                     // closeModal={emailVerification}
                >
                    <VerifyEmail/>
                </ResponsiveModalForm>
                <Page title={i18next.t('sites.page-heading.heading', {returnObjects: true})}>
                    <div className="boxed-block">
                        <div className="heading-block mt-70">
                            {/*<h2>{success && (list && list.length === 0)? "My Demo Sites" : `Sites`}</h2>*/}
                            <h2>{i18next.t('sites.page-heading.heading', {returnObjects: true})}</h2>
                            {/*<span>Total sites installed {total && total < 10 ? '0' + total : total}</span>*/}
                            <span>{i18next.t('sites.page-heading.total-sites', {returnObjects: true})} {total_sites && total_sites < 10 ? '0' + total_sites : total_sites}</span>
                        </div>
                        {this.state.initialLoading ?
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td colSpan={5} className="text-center"><Loading/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> :
                            <SiteTableCell/>
                        }
                        {/*{list && list.length ? (*/}
                        {/*    <div className="fixed-footer pr-60">*/}
                        {/*        <PaginationButtons pages={pages}*/}
                        {/*                           processing={processing}*/}
                        {/*                           fetchAction={toggleSiteListFilter}/>*/}
                        {/*    </div>*/}
                        {/*) : null}*/}
                        {pages.total > 25 && (pages.to !== pages.total) &&
                        <div className="load-more ml-3">
                            <button className="btn-theme mt-10"
                                    onClick={() => this.loadMoreSites()}>{processing ? <>Load
                                More <Loading className="sm-loader-position" height="15"
                                              width="15"/></> : `Load More`}</button>
                        </div>
                            }


                        <SiteWorker/>
                    </div>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {userDetail, siteList, getSites, getLoggedInUser, getSiteListStatus, getDemoSite} = state;

    let {params, fields, modal, total_sites,email_verify} = siteList.data;

    let site_status = getSiteListStatus &&
        getSiteListStatus.success;

    let sale =
        getLoggedInUser &&
        getLoggedInUser.success &&
        getLoggedInUser.success.data &&
        getLoggedInUser.success.data.data &&
        getLoggedInUser.success.data.data.sale_tracking

    let role =
        getLoggedInUser &&
        getLoggedInUser.success &&
        getLoggedInUser.success.data &&
        getLoggedInUser.success.data.data &&
        getLoggedInUser.success.data.data.role &&
        getLoggedInUser.success.data.data.role.slug

    let emailVerification =
        getLoggedInUser &&
        getLoggedInUser.success &&
        getLoggedInUser.success.data &&
        getLoggedInUser.success.data.data &&
        getLoggedInUser.success.data.data.verified

    let {success} = getSites;

    let list =
        success &&
        success.data &&
        success.data.data.data;

    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let to = success && success.data && success.data.data.to;
    let pages = {current_page, last_page, total,to};

    return {
        getSites,
        getDemoSite,
        list,
        userDetail,
        siteList,
        params,
        fields,
        modal,
        getLoggedInUser,
        pages,
        role,
        getSiteListStatus,
        site_status,
        total, total_sites,
        email_verify,
        emailVerification,
        sale
    };

}

export default connect(mapStateToProps)(Site);