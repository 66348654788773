import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import RedirectsFormWorker from './RedirectsFormWorker';
import TextField from "../../../../components/shared/form/TextField";
import {withRouter} from "react-router";
import Loading from "../../../../components/shared/loaders/Loading";
import {secondToLastItemInURL} from "../../../../helpers/UrlHelper";
import {
    onRedirectsFormChange,
    onRedirectsFormSubmit,
    onRedirectsFormDomainChange
} from "./RedirectsForm-Action";
import CheckField from "../../../../components/shared/form/CheckFieldDomain";
import CustomReactSelect from "../../../../components/shared/form/CustomReactSelect";

class RedirectsForm extends React.Component {

    redirectFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onRedirectsFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onRedirectsFormChange(e.target.name, e.target.value));
    };

    onChangeDomain = (selectedOption) => {

        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onRedirectsFormDomainChange("domain_id", value));
    }


    render() {
        let {
            errors, createRedirect, updateRedirect, getRedirect,
            getRedirects, mode, payload, site_status, domainLists, submitted
        } = this.props;
        let {processing} = createRedirect;
        let updateRedirectProcessing = updateRedirect.processing;
        let getRedirectProcessing = getRedirect && getRedirect.processing
        let getRedirectsProcessing = getRedirect && getRedirects.processing

        let domainList = [];
        let clearable = true;
        domainList.push({value: true, label: "All Domains"})
        domainLists && domainLists.map(c => {
            domainList.push({value: c.id, label: c.name})
        })
        let themeMode = localStorage.getItem("themeMode");

        return (
            <>
                <RedirectsFormWorker/>
                <div className="redirect-form">
                    {getRedirectProcessing ? <><h3
                            className="ssl-header text-center mb-20">Update Redirect Rule<Loading/>
                        </h3></> :
                        <>
                            {mode === 'update' ? <><h3 className="ssl-header text-center mb-20">Update Redirect
                                    Rule</h3>
                                    <p className="text-center">
                                        {/* Redirection rules are added to the Nginx configuration of
                                        the<br/>
                                        site and are interpreted as regular expressions.*/}
                                        Redirects are used to navigate a user from one page to another.<br/>This can be done
                                        in a permanent or temporary move.<br/><br/>
                                        Start by selecting the domain you want the redirect to work from.<br/> Then enter
                                        the old path and the destination path.<br/><br/>
                                        If you have any questions please contact our support and we will do our best to
                                        assist you.</p></>
                                :
                                <>
                                    <h3 className="ssl-header text-center mb-20">Add Redirect Rule</h3>
                                    <p className="text-center">
                                        {/*Redirection rules are added to the Nginx configuration
                                        of the<br/>
                                        site and are interpreted as regular expressions.*/}
                                        Redirects are used to navigate a user from one page to another.<br/>This can be
                                        done in a permanent or temporary move.<br/><br/>
                                        Start by selecting the domain you want the redirect to work from.<br/> Then
                                        enter the old path and the destination path.<br/><br/>
                                        If you have any questions please contact our support and we will do our best to
                                        assist you.</p>

                                </>
                            }
                            <form onSubmit={this.redirectFormSubmit}>
                                {mode !== 'update' ?
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mt-20">
                                                {/*<TextField name="domain_id"*/}
                                                {/*           className="form-control"*/}
                                                {/*           value={payload.domain_id}*/}
                                                {/*           label="Domain"*/}
                                                {/*           errors={errors.domain_id}*/}
                                                {/*           onChange={this.onChange}/>*/}
                                                <label>Domain</label>
                                                <CustomReactSelect
                                                    name="domain_id"
                                                    isClearable={clearable}
                                                    value={domainList.filter(option => option.value === (payload.domain_id || payload.all_domains)) || domainList.value}
                                                    placeholder="Select Domain"
                                                    options={domainList}
                                                    onChange={this.onChangeDomain}
                                                    // errors={errors.domain_id}
                                                    isValid={errors && errors.domain_id}
                                                    submitted={submitted}
                                                    menuColor={themeMode == 'true' ? "#383838" : ""}
                                                    themeMode={themeMode}
                                                />
                                            </div>
                                        </div>
                                    </div> : null}
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mt-10">
                                            <TextField name="regex"
                                                       className="form-control"
                                                       value={payload.regex}
                                                       label="Redirect From"
                                                       placeholder="^/oldpath"
                                                       errors={errors.regex}
                                                       onChange={this.onChange}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-10">
                                            <TextField name="destination"
                                                       className="form-control"
                                                       value={payload.destination}
                                                       label="Redirect To"
                                                       placeholder="https://redirect.com/newpath"
                                                       errors={errors.destination}
                                                       onChange={this.onChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6>Redirection Type
                                        </h6>
                                    </div>
                                </div>
                                {/*<br/>*/}
                                <div className="row">
                                    <div className="col-md-12">
                                        <CheckField name="redirect_code_301"
                                                    id="301"
                                                    value={payload.redirect_code}
                                                    label="Permanent Redirect (response status code 301 Moved Permanently)"
                                                    checked={payload.redirect_code === 301}
                                                    onChange={this.onChange}
                                                    errors={errors.redirect_code}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <CheckField name="redirect_code_302"
                                                    id="302"
                                                    value={payload.redirect_code}
                                                    checked={payload.redirect_code === 302}
                                                    label="Temporary Redirect (response status code 302 Found)"
                                                    onChange={this.onChange}
                                                    errors={errors.redirect_code}
                                        />
                                    </div>
                                </div>

                                <div className="button-wrapper inline-buttons text-center mt-30">
                                    <button type="submit" className="btn-theme btn-blue"
                                            disabled={secondToLastItemInURL() === "demo" || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>
                                        {/*{processing ?*/}
                                        {mode === 'update' ?
                                            updateRedirectProcessing ? <>Update Redirect
                                                Rule<Loading/></> : "Update Redirect Rule" :
                                            processing ? <>Add Redirect
                                                Rule<Loading/></> : "Add Redirect Rule"

                                        }
                                        {/*    <Loading/> : i18next.t('sites.inside-site.cache.add-cache.add-ignore.submit', {returnObjects: true})}*/}
                                    </button>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    redirectForm
,
    getSiteStatus
,
    siteDashboard
,
    createRedirect
,
    updateRedirect
,
    getRedirect
,
    getRedirects
,
    getSiteDomainNoPagination
}

= state;

let {success} = getSiteDomainNoPagination
let domainLists = success &&
    success.data &&
    success.data.data


let {site_status} = siteDashboard.data;
let site_search_status_message =
    getSiteStatus &&
    getSiteStatus.success &&
    getSiteStatus.success.message
let {errors, payload, mode, submitted} = redirectForm.data;

return {
    redirectForm,
    errors,
    getSiteStatus,
    site_search_status_message,
    site_status,
    mode,
    payload,
    createRedirect,
    updateRedirect,
    getRedirect,
    getRedirects,
    getSiteDomainNoPagination,
    domainLists,
    submitted
};
}

export default withRouter(connect(mapStateToProps)(RedirectsForm));