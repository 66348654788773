import React from 'react';
import store from '../../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultSslCertificateForm, setSiteId, setSSLDataForUpdate
} from "./SSLForm-Action";
import createSSLCertificates from "../../../../../ajax/actions/ssl/ssl_post";
import updateSSLCertificates from "../../../../../ajax/actions/ssl/ssl_put";

class SSLFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultSslCertificateForm())
        store.dispatch(setSiteId(this.props.match.params.id))
        if (this.props.mode === 'create') {
            store.dispatch(setDefaultSslCertificateForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode} = this.props;
        if (this.props.getSslCertificate !== prev.getSslCertificate) {
            let {success, error} = this.props.getSslCertificate;
            if (success) {
                store.dispatch(setSSLDataForUpdate());
            }

            if (error) {
                //
            }
        }
        if (dispatchAPI) {
            if (mode === 'create') {
                store.dispatch(createSSLCertificates(this.props.match.params.id))
            } else {
                let {id} = this.props.getSslCertificate
                && this.props.getSslCertificate.success
                && this.props.getSslCertificate.success.data.data
                store.dispatch(updateSSLCertificates(this.props.match.params.id, id))
            }
        }
    }


    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {SSLForm, getSslCertificate} = state;
    let {dispatchAPI, mode, payload,} = SSLForm.data;
    return {SSLForm, dispatchAPI, mode, payload, getSslCertificate};
}

export default withRouter(connect(mapStateToProps)(SSLFormWorker));