export function onUserFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-CHANGE', name, value});
    }
}

export function selectCountry(value) {
    let name = "country_id"
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-CHANGE', name, value});
    }
}

export function onUserFormSubmit(e) {
    e && e.preventDefault();
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-SUBMIT'});
    }
}

export function userFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'USER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function setDefaultUserData() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-USER-DATA',});
    }
}

export function cancelUsersFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-USER-FORM-DISPATCH'});
    }
}

export function onImageChange(image) {
    return function (dispatch) {
        dispatch({type: 'IMAGE-CHANGE',image});
    }
}

export function setCustomerDataForUpdate() {
    return function (dispatch, getState) {
        let {getUser, getLoggedInUser} = getState();
        // let user = getUser && getUser.success;
        let user = getLoggedInUser && getLoggedInUser.success.data.data
        dispatch({type: 'SET-SPECIFIC-USER-DATA', user});
    }
}