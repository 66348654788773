import React from "react";
import TextField from "../../../components/shared/form/TextField";
import PasswordField from "../../../components/shared/form/Forgot-password-PasswordField";
import store from "../../../Store";
import {
  onForgotPasswordFormChange,
  onForgotPasswordFormSubmit,
} from "./ForgotPasswordForm-Action";
import ForgotPasswordFormWorker from "./ForgotPasswordFormWorker";
import { connect } from "react-redux";
import Button from "../../../components/shared/buttons/Button";
import Link from "react-router-dom/Link";
import Loading from "../../../components/shared/loaders/Loading";
import SvgShape from "../../../helpers/SvgShape";
import LoginSidebar from "../../../components/layout/LoginSidebar";
import loginImg from "../../../assets/images/login-bg-img.png";
import loginLogo from "../../../assets/images/loginlogo.png";

class ForgotPasswordForm extends React.Component {
  onChange = (e) => {
    let { name, value } = e.target;
    store.dispatch(onForgotPasswordFormChange(name, value));
  };

  handleSubmit = (e) => {
    e && e.preventDefault();
    store.dispatch(onForgotPasswordFormSubmit());
  };

  render() {
    let {
      forgotPasswordForm,
      payload,
      mode,
      createChangePassword,
      createForgotPasswordEmail,
    } = this.props;
    let { errors, message } = forgotPasswordForm.data;
    // let {processing}= createChangePassword ||createForgotPasswordEmail
    let createForgotPasswordEmailProcessing =
      createForgotPasswordEmail.processing;
    let createChangePasswordProcessing = createChangePassword.processing;

    const current_path = this.props.location.pathname;
    const account_paths = ["/se/forgotpassword"];
    const login_en_path = ["/forgotpassword"];
    if (account_paths.includes(current_path)) {
      localStorage.setItem("lang", "sv");
    }
    if (login_en_path.includes(current_path)) {
      localStorage.setItem("lang", "en");
    }
    const lang = localStorage.getItem("lang");

    return (
      <>
        <section className="login newLogin forgot-block">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-5 col-lg-6 col-xl-7">
                <div className="img-block">
                  <img src={loginImg} alt="no-img" />
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="login-page-block page_login">
                  <div className="form-block">
                    <div className="upper-block text-center">
                      <a href="https://keplerhosting.com/" target="_blank">
                        <img src={loginLogo} alt="no-img" />
                      </a>
                      <h3 className="mt-30">
                        {lang === "en"
                          ? "Password Reset Email"
                          : "Återställ ditt lösenord"}
                      </h3>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      {mode && mode === "forgotpassword" ? (
                        <>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <TextField
                                  value={payload.email}
                                  errors={errors.email}
                                  icon="mail"
                                  name="email"
                                  type="email"
                                  label={lang === "en" ? "Email" : "Din E-post"}
                                  placeholder={
                                    lang === "en"
                                      ? "Email"
                                      : "Skriv in din e-post här"
                                  }
                                  message={message ? message : ""}
                                  onChange={this.onChange}
                                />
                                {/*<p style={{color:'red'}}> {forgotPasswordForm?.data?.payload.email !== "" ?forgotPasswordForm?.data.message:null}</p>*/}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/*<input type="hidden" name="email" value={email} id="email"/>*/}
                          {/*<input type="hidden" name="token" value={token} id="token"/>*/}

                          <div className="form-group">
                            <PasswordField
                              value={payload.password}
                              errors={errors && errors.password}
                              icon="key"
                              name="password"
                              autoComplete="new-password"
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="form-group">
                            <PasswordField
                              required
                              name="password_confirmation"
                              icon="lock"
                              defaultValue={null}
                              onChange={this.onChange}
                              autoComplete="new-password"
                              errors={errors && errors.password_confirmation}
                            />
                          </div>
                        </>
                      )}
                      <div className="d-flex button-group button-wrapper inline-buttons text-center justify-content-center mt-120">
                        <Link
                          to={lang === "en" ? "/login" : "/se/login"}
                          className="btn-theme btn-blue"
                        >
                          {lang === "en" ? "Login" : "Logga in"}
                        </Link>

                        <Button
                          className="btn-theme btn-blue"
                          icon="done"
                          type="primary"
                          htmlType="submit"
                          disable={
                            createForgotPasswordEmailProcessing ||
                            createChangePasswordProcessing
                          }
                          iconWidth={11}
                          iconRight={3}
                          text={
                            createForgotPasswordEmailProcessing ||
                            createChangePasswordProcessing ? (
                              <>
                                <Loading color="white" />
                                {lang === "en" ? "Reset" : "Återställa"}
                              </>
                            ) : lang === "en" ? (
                              "Reset"
                            ) : (
                              "Återställa"
                            )
                          }
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ForgotPasswordFormWorker />
      </>
    );
  }
}

function mapStateToProps(state) {
  let { forgotPasswordForm, createForgotPasswordEmail, createChangePassword } =
    state;
  let { payload, errors, mode, message } = forgotPasswordForm.data;
  return {
    forgotPasswordForm,
    payload,
    errors,
    mode,
    message,
    createForgotPasswordEmail,
    createChangePassword,
  };
}

export default connect(mapStateToProps)(ForgotPasswordForm);
