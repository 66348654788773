import React from 'react';
import store from "../../../../Store";
import {toggleSearchAndReplaceFormModal} from "./FeatureBlock-Action";
import searchSvg from "../../../../assets/images/search_replace.svg";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";
import i18next from 'i18next';

class SearchAndReplacePrimaryView extends React.Component {
    render() {
        let {event, getSiteStatus, site_status} = this.props


        return (
            <tr>
                <td>
                    <div className="media">
                        <div className="media-img d-flex mr-3">
                            <img src={searchSvg} alt="" className="d-light"/>
                            <img src={searchSvg} alt="" className="d-dark"/>
                        </div>
                        <div className="media-body">
                            <span>{i18next.t('sites.inside-site.tools.site-tools.heading' ,{returnObjects: true})}</span>
                            <h4>{i18next.t('sites.inside-site.tools.site-tools.6.title' ,{returnObjects: true})}</h4>
                        </div>
                    </div>
                </td>
                <td>
                    <p>{i18next.t('sites.inside-site.tools.site-tools.6.desc' ,{returnObjects: true})}</p>
                </td>
                <td>
                    <div className="btn-wrapper">
                        <button className="btn-theme btn-outline blue-outline" data-toggle="modal"
                                data-target="#public-path"
                                onClick={() => store.dispatch(toggleSearchAndReplaceFormModal())}
                            // disabled={site_status === 'pending' || site_status === 'updating' ||  site_status === 'restoring'? 'disabled' : null}
                        >
                            {event === "search_replace_term" || event === "replace_searched_term" || event === "backup_for_replace_term" ? <>
                                {i18next.t('sites.inside-site.tools.change' ,{returnObjects: true})} <Loading/></> : i18next.t('sites.inside-site.tools.change' ,{returnObjects: true})}
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus, site_status};

}

export default connect(mapStateToProps)(SearchAndReplacePrimaryView);