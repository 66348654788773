export function searchAndReplaceFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'SEARCH-AND-REPLACE-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onSearchAndReplaceFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-SEARCH-AND-REPLACE-FORM-SUBMIT'})
    }
}

export function onSearchAndReplaceFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-SEARCH-AND-REPLACE-FORM-CHANGE', name, value});
    }
}

export function onReplaceFlagChange(name, value) {
    return function (dispatch,getState) {
        let {searchAndReplaceForm} = getState();
        let replace_flag = searchAndReplaceForm && searchAndReplaceForm.data.replace_flag;
        dispatch({type: 'ON-REPLACE-FLAG-CHANGE', name, replace_flag});
    }
}

export function setSearchResult(amount_of_terms,search_term){
    return function (dispatch) {
        dispatch({type: 'ON_SEARCH_NOTIFICATION', amount_of_terms,search_term});
    }
}

export function setFailedSearchResult(failedStatus,errorMessage){
    return function (dispatch) {
        dispatch({type: 'ON_FAILED_SEARCH_NOTIFICATION', failedStatus,errorMessage});
    }
}

export function onSearchAndReplaceFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-SEARCH-AND-REPLACE-FORM-MODE-CHANGE', mode});
    }
}

export function cancelSearchAndReplaceFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-SEARCH-AND-REPLACE-FORM-DISPATCH'});
    }
}

export function setDefaultSearchAndReplaceForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-SEARCH-AND-REPLACE-FORM'});
    }
}
// export function setDefaultSearchForm() {
//     return function (dispatch) {
//         dispatch({type: 'SET-DEFAULT-SEARCH-FORM'});
//     }
// }
// export function setDefaultReplaceForm() {
//     return function (dispatch) {
//         dispatch({type: 'SET-DEFAULT-REPLACE-FORM'});
//     }
// }

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID',site_id});
    }
}


export function searchAndReplaceError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-SEARCH-AND-REPLACE-ERROR', error});
    }
}
