import store from '../../../Store';
import getYourActivityLogs from "../../../ajax/actions/activity-log/your_activity_logs_get";
import {toggleURLQuery} from "../../../helpers/UrlHelper";

export function toggleActivityListFilter(e, history,) {
    return function (dispatch) {
        let {name, value, id} = e.target;
        dispatch({type: 'TOGGLE-ACTIVITY-LIST-FILTER', name, value});
        store.dispatch(getYourActivityLogs(id));
    }
}
export function loadMore(e,history) {
    return function (dispatch) {
        let { name, value ,id} = e.target;
        dispatch({type:'LOAD-MORE', name, value,id, history});
        if(name === 'limit') {
            store.dispatch( getYourActivityLogs(id));
            toggleURLQuery( 'limit', value ,history)
        }
        else {
            store.dispatch(getYourActivityLogs(id));
        }
    }
}

export function setDefaultLimit()
{
    return function (dispatch){
        dispatch({type:'DEFAULT-LIMIT'})
    }
}
