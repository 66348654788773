import React from 'react';
import {Helmet} from 'react-helmet';
import {ConfigApp} from "../../../Config";
import {endOfURL} from "../../../helpers/UrlHelper";


class Page extends React.Component
{

    render()
    {
        let { title, children } = this.props;
        let { Name } = ConfigApp;

        return (

            <div className={endOfURL()==='update' ? "Page animated fadeIn faster boxed-block":"Page animated fadeIn faster"}>
            {/*<div className={endOfURL()==='update' ? "Page animated fadeIn faster boxed-block":""}>*/}
            {/*<div>*/}
                <div className="title-block">

                <Helmet>
                    <title>{ title } - { Name }</title>
                </Helmet>

                { children }
            </div>
            </div>
        )
    }
}
export default Page;