import React from 'react';
import store from '../../../Store';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getSite from '../../../ajax/actions/sites/site_get'

class PlanWorker extends React.Component {

    componentDidMount() {
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/plan'
        if(current_path !== demo_site_path){
            store.dispatch(getSite(this.props.match.params.id));
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let { getSiteStatus, getAccessLogs, getSite} = state;
    return { getSiteStatus, getAccessLogs, getSite};
}

export default withRouter(connect(mapStateToProps)(PlanWorker));