import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import {setSiteIDDomain} from "./Domain-Action";
import {withRouter} from 'react-router';
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";
import getSite from "../../../../ajax/actions/sites/site_get"
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getSites from "../../../../ajax/actions/sites/sites_get";
import getDemoSite from "../../../../ajax/actions/sites/demo_sites_get";

class DomainWorker extends React.Component {
    componentDidMount() {
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/domain'
        if(current_path === demo_site_path){
            store.dispatch(getDemoSite());
        }
        else {
            store.dispatch(setSiteIDDomain(this.props.match.params.id));
            store.dispatch(getSiteDomain(this.props.match.params.id));
            store.dispatch(getSite(this.props.match.params.id));
            store.dispatch(getSiteStatus(this.props.match.params.id));
            store.dispatch(getSites());
            this.periodicFetch()
        }
    }

    componentWillUnmount() {

        clearInterval(this.timer);

    }

    periodicFetch() {
        this.timer = setInterval(() => store.dispatch(getSiteStatus(this.props.match.params.id)), 10000)
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getSiteStatus, getSite, getDemoSite,getSites} = state;
    return {getSiteStatus, getSite, getDemoSite,getSites};
}

export default withRouter(connect(mapStateToProps)(DomainWorker));