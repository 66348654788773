import React from 'react';
import store from '../../../Store';
import {pushToPage} from "../../../helpers/UrlHelper";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {toggleAddCardFormModal} from "../../../pages/account/overview/Overview-Actions";
import i18next from 'i18next';

class NoCard extends React.Component
{
    constructor(props) {
        super(props);

        this.fireBillingButton = this.fireBillingButton.bind(this);
    }

    fireBillingButton(e) {
        e.preventDefault();
        const current_path = this.props.location.pathname;
        const account_paths = [ '/account/activate-account'];
        if( !account_paths.includes( current_path ) ) {
            pushToPage(this.props, `/account/activate-account`)
        }
        // store.dispatch( toggleAddCardFormModal() );
    }
    render()
    {


        let {action, role, getLoggedInUser,sale } = this.props;
        let {billing_type} = getLoggedInUser.success.data.data

        if( getLoggedInUser.success ) {
            if( !sale && !getLoggedInUser.success.data.data.has_active_card && billing_type !== "fortnox" &&
                getLoggedInUser.success && getLoggedInUser?.success?.data?.data?.organization.billable === 1) {
                return (
                    <>
                        <div className="no-active-card">
                            <div className="boxed-block billing-error mt-35">
                                <div className="upper-block bg-blue">
                                    <div className="text-block">
                                        <p>{i18next.t('account.inside-account.payment.no-card.activate' ,{returnObjects: true})}</p>
                                    </div>
                                    {/*<div className="misc-block">*/}
                                    {/*    <p>{i18next.t('account.inside-account.payment.no-card.welcome' ,{returnObjects: true})}!</p>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="lower-block">
                                    <div className="text-block">
                                        {/*<p>We are happy to welcome you to Kepler Hosting. To be able to activate your account Please add your credit/debit card. If you have any questions please contact Kepler Support.</p>*/}
                                        <p>{i18next.t('account.inside-account.payment.no-card.desc.1' ,{returnObjects: true})}<br/>
                                            <hr className="spacer"/>
                                       <b> {i18next.t('account.inside-account.payment.no-card.desc.2' ,{returnObjects: true})}</b><br/>
                                        {i18next.t('account.inside-account.payment.no-card.desc.3' ,{returnObjects: true})}
                                        </p>
                                    </div>
                                    <div className="misc-block">
                                        <a href="#" className="btn-theme btn-blue" onClick={ this.fireBillingButton }>{i18next.t('account.inside-account.payment.no-card.update' ,{returnObjects: true})}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }

        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, loginForm, getLoggedInUser} = state;

    let {action, routes_url} = route.data;

    let sale =
        getLoggedInUser &&
        getLoggedInUser.success &&
        getLoggedInUser.success.data &&
        getLoggedInUser.success.data.data &&
        getLoggedInUser.success.data.data.sale_tracking

    let role = route && route.data.user && route.data.user.role.slug;

    let {redirectTo} = loginForm.data;

    return {route, action, loginForm, redirectTo, routes_url, getLoggedInUser, role, sale};
}

export default withRouter(connect(mapStateToProps)(NoCard));


// export default withRouter( NoCard );