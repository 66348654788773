import React from 'react';
import clearSvg from "../../../../assets/images/clear_cache.svg";
import toggleSvg from "../../../../assets/images/disable_cache.svg";
import {connect} from "react-redux";
import store from "../../../../Store";
import {withRouter} from 'react-router';
import {toggleCacheIgnoreFormModal} from "./Cache-Action";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import enableCache from "../../../../ajax/actions/kepler-tools/enable_cache_post";
import disableCache from "../../../../ajax/actions/kepler-tools/disable_cache_post";
import SiteDashboardHeader from "../../SiteDashboardHeader";
import Page from "../../../../components/shared/pages/Page";
import SiteDashboardLinks from "../../SiteDashboardLinks";
import {endOfURL} from "../../../../helpers/UrlHelper";
import SiteTableCell from "../../list/SiteTableCell";
import DisplayNameForm from "../../change-display-name/form/DisplayNameForm";
import changeDisplayName from "../../../../ajax/actions/sites/site_displayname_post";
import {Link} from "react-router-dom";
import clearCache from "../../../../ajax/actions/kepler-tools/clear_cache_post";
import IgnoreCacheForm from "../ignoreCache/form/IgnoreCacheForm";
// import CacheIgnorePrimaryView from "./cacheIgnorePrimaryView";
import Loading from "../../../../components/shared/loaders/Loading";
import CacheWorker from "./CacheWorker";
import i18next from 'i18next';
import getSite from "../../../../ajax/actions/sites/site_get"
import {onIgnoreCacheFormModeChange, setDefaultIgnoreCacheForm} from "../ignoreCache/form/IgnoreCacheForm-Action"
import deleteCacheIgnore from "../../../../ajax/actions/kepler-tools/cache_ignore_delete"
import getCacheIgnore from "../../../../ajax/actions/kepler-tools/cache_ignore_get"

class Cache extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
        status: false,
    }

 /*   componentDidMount() {
        store.dispatch(getSite(this.props.match.params.id))
    }*/

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.state.edit = false;
    }

   /* onClickIconEdit = (url, ignoreCachePaths) => {
        ignoreCachePaths = ignoreCachePaths.filter(val => !url.includes(val));
        const afterRemovingUnwantedPath = [...ignoreCachePaths]
        store.dispatch(onDeleteIgnoreCacheForm(afterRemovingUnwantedPath))
        store.dispatch(cacheIgnore(this.props.match.params.id, 'edit'))
    }*/

    onEnableCache = (getCacheStatus) => {
        if (!getCacheStatus) {
            store.dispatch(enableCache(this.props.match.params.id));
        }
        // else {
        //     store.dispatch(disableCache(this.props.match.params.id));
        // }
    }

    clearCache = () => {
        store.dispatch(clearCache(this.props.match.params.id));
    }

    onAddNewRule = () =>{
        store.dispatch(setDefaultIgnoreCacheForm());
        store.dispatch(toggleCacheIgnoreFormModal());
    }

    onEditCacheIgnore = (id) => {
        store.dispatch(getCacheIgnore(this.props.match.params.id, id));
        store.dispatch(onIgnoreCacheFormModeChange("update"));
        store.dispatch(toggleCacheIgnoreFormModal());
    }

    onDeleteCacheIgnore = (id) => {
        store.dispatch(deleteCacheIgnore(this.props.match.params.id,id))
    }

    render() {
        let {
            enableCache, cache, domainLists,cacheIgnoreList,
            clearCache, cache_event, ignore_cache, site_status, getSiteStatus, getSite,
            siteListParam, searchFrom, ignoreCacheUpdatingId
        } = this.props
        let {processing} = getSite
        let enableCacheProcessing = enableCache.processing
        let clearCacheProcessing = clearCache.processing
        const current_path = this.props.location.pathname;
        const demo_site_path = '/sites/demo/cache'

        let getCacheStatus = cache
        let ignoreCachePaths = getSite && getSite.success && getSite.success.ignoredCachePaths
        const primaryDomain = (domainLists || []).find(d => d.is_primary === 1) || '';

        return (
            <>
                <SiteDashboardHeader/>
                {siteListParam.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="boxed-block">
                        <div className="heading-block mt-70">
                            <div className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                {this.state.edit === false ? <>
                                        <h2>{current_path === demo_site_path ? "Demo Site" : getSite?.success?.display_name}</h2></> :
                                    <DisplayNameForm
                                        data={current_path === demo_site_path ? "Demo Site" : getSite?.success?.display_name}/>}
                                {this.state.edit === false ?
                                    <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                        <i className="icon-edit"/></a> : <>
                                        <button className="btn-theme btn-black"
                                                onClick={this.updateDisplayName}
                                                disabled={current_path === demo_site_path}>{i18next.t('sites.page-heading.edit.update', {returnObjects: true})}
                                        </button>
                                        <button className="btn-theme btn-black"
                                                onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel', {returnObjects: true})}
                                        </button>
                                    </>}
                            </div>
                            <div className="breadcrumb-block">
                                <span><Link
                                    to={'/sites'}>{i18next.t('sites.page-heading.breadcrum', {returnObjects: true})}</Link></span>
                                <span>{current_path === demo_site_path ? "Demo Site" : getSite?.success?.display_name}</span>
                            </div>
                        </div>
                        <div className="content-menu">
                            <SiteDashboardLinks
                                site_id={current_path === demo_site_path ? "demo" : this.props.match.params.id}/>
                        </div>
                        <Page title={`Cache`}>

                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table tools-table">

                                    <div className="listing-cache">
                                        <div className="clear-cache">
                                            <div className="media">
                                                <img src={clearSvg} alt="" className="d-light"/>
                                                <img src={clearSvg} alt="" className="d-dark"/>
                                            </div>
                                            <div className="media-body">
                                                <span>{i18next.t('sites.inside-site.cache.heading', {returnObjects: true})}</span>
                                                <h4>{i18next.t('sites.inside-site.cache.clear-cache.title', {returnObjects: true})}</h4>
                                            </div>

                                            <div className="btn-wrapper">
                                                <a>
                                                    <button
                                                        className={getCacheStatus ? "btn-theme" : "btn-theme-disabled"}
                                                        disabled={!getCacheStatus || current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                        onClick={() => this.clearCache()}>
                                                        {clearCacheProcessing || (getSiteStatus?.success?.event === "clear_cache" && getSiteStatus?.success?.status === "updating") ?
                                                            <div>
                                                                <Loading/>{i18next.t('sites.inside-site.cache.clear-cache.btn', {returnObjects: true})}
                                                            </div> :
                                                            i18next.t('sites.inside-site.cache.clear-cache.btn', {returnObjects: true})}
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="clear-cache">
                                            <div className="media">
                                                <img src={toggleSvg} alt="" className="d-light"/>
                                                <img src={toggleSvg} alt="" className="d-dark"/>
                                            </div>
                                            <div className="media-body">
                                                <span>{i18next.t('sites.inside-site.cache.heading', {returnObjects: true})}</span>
                                                <h4>{getCacheStatus === 1 ? i18next.t('sites.inside-site.cache.disable-cache.disable', {returnObjects: true})
                                                    : i18next.t('sites.inside-site.cache.disable-cache.enable', {returnObjects: true})} Cache</h4>
                                            </div>

                                            <div className="btn-wrapper">
                                                <a>
                                                    {
                                                        !getCacheStatus ? (
                                                            <button className="btn-theme btn-outline blue-outline "
                                                                    onClick={() => this.onEnableCache(getCacheStatus)}
                                                                    disabled={current_path === demo_site_path || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                            >
                                                                {enableCacheProcessing || (cache_event === "enable_cache") ?
                                                                    <div>
                                                                        {i18next.t('sites.inside-site.cache.disable-cache.enable', {returnObjects: true})}
                                                                        <Loading/>
                                                                    </div> :
                                                                    i18next.t('sites.inside-site.cache.disable-cache.enable', {returnObjects: true})}
                                                            </button>
                                                        ) : (
                                                            // <button className="btn-theme btn-outline blue-outline "
                                                            <button
                                                                className={getCacheStatus ? "btn-theme-disabled" : "btn-theme"}
                                                                disabled>
                                                                {(cache_event === "disable_cache") ?
                                                                    <>
                                                                        {i18next.t('sites.inside-site.cache.disable-cache.disable', {returnObjects: true})}
                                                                        <Loading/>
                                                                    </>
                                                                    :
                                                                    i18next.t('sites.inside-site.cache.disable-cache.disable', {returnObjects: true})
                                                                }
                                                            </button>
                                                        )
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    </div>


                                    {getCacheStatus && cache_event !== "disable_cache" && cache_event !== "enable_cache" ?
                                        <>
                                            <ResponsiveModalForm visible={ignore_cache.mode}
                                                                 closeModal={() => store.dispatch(toggleCacheIgnoreFormModal())}>
                                                <IgnoreCacheForm/>
                                            </ResponsiveModalForm>

                                            <div className="ignore-rules">
                                                <div className="ignore-rules-text">
                                                    <h6>{i18next.t('sites.inside-site.cache.add-cache.title', {returnObjects: true})}</h6>

                                                    <p>
                                                        {i18next.t('sites.inside-site.cache.add-cache.desc.1', {returnObjects: true})}
                                                    </p>

                                                    <div className="ignore-rules-buttons">
                                                        <p>{i18next.t('sites.inside-site.cache.add-cache.desc.2', {returnObjects: true})}
                                                            {/*<a href="#">{i18next.t('sites.inside-site.cache.add-cache.desc.3', {returnObjects: true})}</a>*/}
                                                        </p>
                                                        <div>
                                                            <button
                                                                className={getCacheStatus ? "btn-theme" : "btn-theme-disabled"}
                                                                onClick={() => {
                                                                    this.onAddNewRule()
                                                                    // store.dispatch(toggleCacheIgnoreFormModal())
                                                                }}
                                                                disabled={!getCacheStatus || cache_event === "ignore_cache" || cache_event === "add_ignore_cache" || cache_event === "delete_ignore_cache" }
                                                            >
                                                                {cache_event === "add_ignore_cache" ?
                                                                    <>
                                                                        {i18next.t('sites.inside-site.cache.add-cache.add-new-btn-adding', {returnObjects: true})}
                                                                        <Loading/></>
                                                                    :
                                                                    i18next.t('sites.inside-site.cache.add-cache.add-new-btn', {returnObjects: true})
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ignore-urls">
                                                <table className="url-table">
                                                    <tbody>
                                                    <tr className="url-table-col">
                                                        <th className="url-table-heading">{i18next.t('sites.inside-site.cache.ignore-url.ignore', {returnObjects: true})}</th>
                                                        <th className="url-table-heading"
                                                            id="actions">{i18next.t('sites.inside-site.cache.ignore-url.actions', {returnObjects: true})}</th>
                                                    </tr>
                                                    {
                                                        processing ?
                                                            <td colSpan={5} className="text-center"><Loading/></td>
                                                            :
                                                            <>
                                                                {cacheIgnoreList.length  ?
                                                                    cacheIgnoreList.map(item => {
                                                                            return (
                                                                                <>
                                                                                    <tr className="url-table-col">
                                                                                        <td className="url-table-body">{primaryDomain.name}{item.path}</td>


                                                                                        {(cache_event === "add_ignore_cache" || cache_event === "delete_ignore_cache" || cache_event === "update_ignore_cache") && ignoreCacheUpdatingId === item.id ?
                                                                                            <td className="url-table-body"
                                                                                                id="actions">
                                                                                                {cache_event === "add_ignore_cache" && <>Adding<Loading/></>}
                                                                                                {cache_event === "delete_ignore_cache" && <>Deleting<Loading/></>}
                                                                                                {cache_event === "update_ignore_cache" && <>Updating<Loading/></>}
                                                                                            </td>
                                                                                            :
                                                                                            <td className="url-table-body"
                                                                                                id="actions">
                                                                                                <div
                                                                                                    className="dropdown dropdown-alt text-center">
                                                                                <span className="icon-more"
                                                                                      data-toggle="dropdown"></span>
                                                                                                    <div
                                                                                                        className="dropdown-menu"
                                                                                                        aria-labelledby="dropdownMenuButton">
                                                                                                        <a href="javascript:void(0);"
                                                                                                           className="dropdown-item"
                                                                                                           type="button"
                                                                                                           onClick={() => this.onEditCacheIgnore(item.id)}> Edit
                                                                                                        </a> <a
                                                                                                        href="javascript:void(0);"
                                                                                                        className="dropdown-item"
                                                                                                        type="button"
                                                                                                        onClick={() => this.onDeleteCacheIgnore(item.id)}> Delete
                                                                                                    </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/*<span className="icon-more " data-toggle="dropdown" onClick={this.onClickIconMore()}/>*/}
                                                                                            </td>
                                                                                        }
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })
                                                                        :
                                                                        // <tr>
                                                                            <td colSpan={4} className="text-center  pt-4">No Ignore Rules</td>
                                                                        // </tr>
                                                                }
                                                            </>
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        : null}
                                </div>
                            </div>
                            <CacheWorker/>
                        </Page>
                    </div>}
                {/*</Page>*/}
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
        getSite
        ,
        getSites
        ,
        cacheList
        ,
        getSiteStatus
        ,
        siteDashboard
        ,
        siteList
        ,
        restartPhp
        ,
        debug
        ,
        enableCache
        ,
        disableCache
        ,
        createMonitor
        ,
        clearCache
        ,
        getSiteDomain,
        getsCacheIgnore,
        getCacheIgnore,
    }

        = state;
    let {site_status} = siteDashboard.data;

    let {success} = getsCacheIgnore
    let cacheIgnoreList = success && success.data && success.data.data && success.data.data.data

    let domainLists =
        getSiteDomain &&
        getSiteDomain.success &&
        getSiteDomain.success.data &&
        getSiteDomain.success.data.data &&
        getSiteDomain.success.data.data.data;

    let debug_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let cache_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let ignoreCacheUpdatingId = getSiteStatus && getSiteStatus.success && getSiteStatus.success.ignore_cache_id

    let cache = getSite && getSite.success && getSite.success.site_meta && getSite.success.site_meta.enable_cache
    let {ignore_cache} = cacheList.data;

    let searchFrom = siteList?.data.searchFrom
    let {params: siteListParam, payload} = siteList.data

    return {
        getSite,
        cache_event,
        getSites,
        debug_event,
        cacheList,
        ignore_cache,
        getSiteStatus,
        cache,
        site_status,
        siteDashboard,
        searchFrom,
        siteListParam,
        payload,
        restartPhp,
        debug,
        enableCache,
        disableCache,
        clearCache,
        createMonitor,
        domainLists,
        cacheIgnoreList,
        getsCacheIgnore,
        getCacheIgnore,
        ignoreCacheUpdatingId
    };
}

export default withRouter(connect

(
    mapStateToProps
)(
    Cache
))
;