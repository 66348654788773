// export const loginToken = () => localStorage.getItem('Bearer');
//
// /* Find out if anyone is logged in | @return boolean */
// export const loggedIn   = () => !! localStorage.getItem('Bearer');
//
// export const loggedInUser = (data) =>
// {
//   localStorage.setItem('Bearer',          data.access_token);
//   localStorage.setItem('refresh_token',   data.refresh_token);
//   localStorage.setItem('expires_in',      data.expires_in);
//   localStorage.setItem('token_type',      data.token_type);
// };
//
// export const logOut = ( history ) =>
// {
//   localStorage.removeItem('Bearer');
//   if ( history ) history.push(`/login`);
//   else window.location.href = ('/login');
// };

export const loginToken = () => localStorage.getItem('kepler_c_token');

/* Find out if anyone is logged in | @return boolean */
export const loggedIn = () => !!localStorage.getItem('kepler_c_token');

export const loggedInUser = (data) => {
    localStorage.setItem('kepler_c_token', data);
    // localStorage.setItem('refresh_token',   data.refresh_token);
    // localStorage.setItem('exp ires_in',      data.expires_in);
    // localStorage.setItem('token_type',      data.token_type);
};

// export const theme = (value) => {
//     localStorage.setItem('isDark',value)
// }

export const logOut = (history) => {
    localStorage.removeItem('kepler_c_token');

    const lang = localStorage.getItem('lang')

    if (lang === 'sv' && history) history.push(`/se/login`)
    // else if(history) history.push(`/login`);
    if(lang === 'sv') {
        window.location.href = ('/se/login');}
    if(lang === 'en'){
        window.location.href = ('/login');}

    // else window.location.href = ('/login');
};

