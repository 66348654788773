import React from 'react';
import Select from "react-select";

class CustomReactSelect extends React.Component {
    render() {
        const {
            isValid, submitted, themeMode
        } = this.props

  // customStyles
  const customStyles = {
      control: (base, state) => ({
          ...base,
          borderColor: state.isFocused ?
              '#ddd' : submitted && isValid.length ? 'red' : '#ddd',
          '&:hover': {
              borderColor: state.isFocused ?
                  '#ddd' : submitted && isValid.length ?
                      'red' : '#ddd'
          },
      })
        };


  const darkModeStyles = {
      control: (base, state) => ({
          ...base,
          borderColor: state.isFocused ?
              '#ddd' : submitted && isValid.length ? 'red' : '#ddd',
          '&:hover': {
              borderColor: state.isFocused ?
                  '#ddd' : submitted && isValid.length ?
                      'red' : '#ddd'
          },

          backgroundColor:  ("#383838", ".86"),
          // boxShadow: "none",
          // borderTop: "none",
          // borderLeft: "none",
          // borderRight: "none",
          // borderBottom: "solid 2px #d1d1d1",
          // borderRadius: "none",
      }),
      menu: (provided, state) => ({
          ...provided,
          color:  state.selectProps.menuColor,
          backgroundColor:  '#ddd',
      }),
      placeholder: (provided, state) => ({
          ...provided,
          color:  'white',
      }),
      singleValue: (provided, state) => ({
          ...provided,
          color:  'white',
      })
  }

  return <Select styles={ themeMode == 'true' ? darkModeStyles: customStyles } {...this.props}/>
    }
}
export default CustomReactSelect;


/*
let themeMode = localStorage.getItem("themeMode");
console.log(` <| themeMode |> `, themeMode)
// customStyles
const whiteModeStyles = {
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ?
            '#ddd' : submitted && isValid.length ? 'red' : '#ddd',
        '&:hover': {
            borderColor: state.isFocused ?
                '#ddd' : submitted && isValid.length ?
                    'red' : '#ddd'
        },
    })}


const darkModeStyles = {
    control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ?
            '#ddd' : submitted && isValid.length ? 'red' : '#ddd',
        '&:hover': {
            borderColor: state.isFocused ?
                '#ddd' : submitted && isValid.length ?
                    'red' : '#ddd'
        },

        backgroundColor: themeMode && ("#383838", ".86"),
        // boxShadow: "none",
        // borderTop: "none",
        // borderLeft: "none",
        // borderRight: "none",
        // borderBottom: "solid 2px #d1d1d1",
        // borderRadius: "none",
    }),
    menu: (provided, state) => ({
        ...provided,
        color: themeMode && state.selectProps.menuColor,
        backgroundColor: themeMode && '#ddd',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: themeMode && 'white',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: themeMode && 'white',
    })
}

return <Select styles={ themeMode ? darkModeStyles: whiteModeStyles } {...this.props}/>*/


/*
const customStyles = {
    control: (base, state) => ({
        ...base,
        borderColor:  state.isFocused ?
            '#ddd' :submitted && isValid.length ? 'red' : '#ddd',
        '&:hover': {
            borderColor: state.isFocused ?
                '#ddd' : submitted && isValid.length ?
                    'red' : '#ddd'
        },
        // backgroundColor:("#383838", ".86"),
        // boxShadow: "none",
        // borderTop: "none",
        // borderLeft: "none",
        // borderRight: "none",
        // borderBottom: "solid 2px #d1d1d1",
        // borderRadius: "none",
    })
}
return <Select styles={ customStyles } {...this.props}/>*/
