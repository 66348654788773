import React from "react";
import TextField from "../../components/shared/form/TextField";
import PasswordField from "../../components/shared/form/PasswordField";
import store from "../../Store";
import { connect } from "react-redux";
import { onFormChange } from "./RegisterForm-Actions";
import LoginSidebar from "../../components/layout/LoginSidebar";
import SvgShape from "../../helpers/SvgShape";
import { onRegisterFormSubmit } from "./RegisterForm-Actions";
import RegisterFormWorker from "../register-single/RegisterFormWorker";
import Link from "react-router-dom/Link";
import Loading from "../../components/shared/loaders/Loading";
import loginImg from "../../assets/images/login-bg-img.png";
import loginLogo from "../../assets/images/loginlogo.png";

class RegisterForm extends React.Component {
  onChange = (e) => {
    store.dispatch(onFormChange(e));
  };
  registerFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onRegisterFormSubmit());
  };

  render() {
    let { errors, payload, message, registerUserV2 } = this.props;
    let { first_name, last_name, password, email, password_confirmation } =
      payload;
    let { processing } = registerUserV2;

    const current_path = this.props.location.pathname;
    const account_paths = ["/se/register"];
    const login_en_path = ["/register"];
    if (account_paths.includes(current_path)) {
      localStorage.setItem("lang", "sv");
    }
    if (login_en_path.includes(current_path)) {
      localStorage.setItem("lang", "en");
    }
    const lang = localStorage.getItem("lang");

    return (
      <>
        <section className="login newLogin registerForm">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-md-5 col-lg-6 col-xl-7">
                <div className="img-block">
                  <img src={loginImg} alt="no-img" />
                </div>
              </div>
              <div className="col-md-7 col-lg-6 col-xl-5">
                <div className="login-page-block page_login">
                  <div className="form-block">
                    <div className="upper-block text-center">
                      <a href="https://keplerhosting.com/" target="_blank">
                        <img src={loginLogo} alt="no-img" />
                      </a>
                      <h3 className="mt-30">
                        {lang === "en"
                          ? "Welcome to Kepler"
                          : "Välkommen till Kepler"}
                      </h3>
                      <span>
                        {lang === "en"
                          ? "Create your Account"
                          : "Skapa ditt konto"}
                      </span>
                    </div>
                    <form onSubmit={this.registerFormSubmit}>
                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            {/*<span> {message?message:null}</span>*/}
                            <TextField
                              required
                              value={email}
                              name="email"
                              type="email"
                              icon="mail"
                              label={lang === "en" ? "Email" : "E-post"}
                              autoComplete="nope"
                              errors={errors && errors.email}
                              message={message ? message : ""}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <TextField
                              required
                              value={first_name}
                              name="first_name"
                              icon="user"
                              label={lang === "en" ? "First Name" : "Förnamn"}
                              onChange={this.onChange}
                              errors={errors && errors.first_name}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <TextField
                              required
                              value={last_name}
                              name="last_name"
                              icon="user"
                              label={lang === "en" ? "Last Name" : "Efternamn"}
                              onChange={this.onChange}
                              errors={errors && errors.last_name}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <PasswordField
                              required
                              value={password}
                              name="password"
                              icon="lock"
                              label={lang === "en" ? "Password" : "Lösenord"}
                              defaultValue={null}
                              autoComplete="new-password"
                              onChange={this.onChange}
                              errors={errors && errors.password}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <PasswordField
                              required
                              name="password_confirmation"
                              value={password_confirmation}
                              icon="lock"
                              label={
                                lang === "en"
                                  ? "Password Confirmation"
                                  : "Lösenordsbekräftelse"
                              }
                              defaultValue={null}
                              onChange={this.onChange}
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="button-group button-wrapper text-center mt-20 mb-40">
                        <button
                          type="submit"
                          className="btn-theme btn-blue btn-lg"
                        >
                          {processing ? (
                            <>
                              {lang === "en" ? "Submit" : "Skicka in"}
                              <Loading />
                            </>
                          ) : lang === "en" ? (
                            "Submit"
                          ) : (
                            "Skicka in"
                          )}
                        </button>
                      </div>
                      {/*<p>You are soon there! Just one more step 😋</p>*/}
                      <p>
                        {lang === "en"
                          ? "Already have an account?"
                          : "Har du redan ett konto?"}{" "}
                        <Link to={lang === "en" ? "/login" : "/se/login"}>
                          {lang === "en" ? "Login" : "Logga in"}
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <RegisterFormWorker />
      </>
    );
  }
}

function mapStateToProps(state) {
  let { registerFormV2, registerUserV2 } = state;
  let { payload, mode, errorMessage, message, errors, errorsPresent } =
    registerFormV2.data;
  return {
    errors,
    errorsPresent,
    registerUserV2,
    mode,
    message,
    registerFormV2,
    errorMessage,
    payload,
  };
}

export default connect(mapStateToProps)(RegisterForm);
