import React from "react";
import { connect } from "react-redux";
import SiteFormWorker from "./SiteFormWorker";
import {
  onSiteFormChange,
  onSiteFormCheckedChange,
  onSiteFormCommerceCheckedChange,
  onSiteFormSubmit,
  onDataCenterFormChange,
  onOrganizationFormChange,
  onSelectPlan,
} from "./SiteForm-Action";
import store from "../../../Store";
import TextField from "../../../components/shared/form/TextField";
import SelectField from "../../../components/shared/form/SelectField";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import CustomPasswordField from "../../../components/shared/form/CustomPasswordField";
import { names } from "../../../helpers/StringHelper";
import { selectType } from "../../../static-data/UserData";
import getSiteCreateDataCenters from "../../../ajax/actions/data-center/site_create_data_centers_get";
import i18next from "i18next";

class SiteForm extends React.Component {
  state = {
    value: "",
  };

  componentDidMount() {
    window.dataLayer.push({
      event: "selectSiteType",
      eventCategory: "SelectSiteType",
      eventAction: "ButtonClick",
      eventLabel: "WordPress",
      siteType: "WordPress",
    });
  }

  siteFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onSiteFormSubmit());
  };

  getObjectById = (id) => {
    const { plans } = this.props;
    if (plans && plans.length > 0) {
      const result = plans.find((item) => item.id === Number(id));

      if (result) {
        const { description, price } = result;
        return { description, price };
      }
    }
    return { description: "Not found", price: 0 };
  };

  onChange = (e) => {
    e.preventDefault();
    if (e.target.name === "plan_id") {
      const resultObject = this.getObjectById(e.target.value);
      store.dispatch(
        onSelectPlan(resultObject.description, resultObject.price)
      );
    }
    store.dispatch(onSiteFormChange(e.target.name, e.target.value));
    if (e.target.name === "data_center_id") {
      store.dispatch(onDataCenterFormChange(e.target.value));
    }
    if (e.target.name === "organization_id") {
      store.dispatch(onOrganizationFormChange(e.target.value));
    }
  };

  onChangeDeploySite = (e) => {
    e.preventDefault();
  };

  onCheckedChange = (data) => {
    const selectedSiteType =
      data === "wordpress" ? "WordPress" : "BlankInstall";
    window.dataLayer.push({
      event: "selectSiteType",
      eventCategory: "SelectSiteType",
      eventAction: "ButtonClick",
      eventLabel: selectedSiteType,
      siteType: selectedSiteType,
    });

    store.dispatch(onSiteFormCheckedChange(data));
  };

  onCommerceCheckedChange = (data) => {
    window.dataLayer.push({
      event: "selectSiteType",
      eventCategory: "SelectSiteType",
      eventAction: "ButtonClick",
      eventLabel: "Ecommerce",
      siteType: "Ecommerce",
    });
    store.dispatch(onSiteFormCommerceCheckedChange(data));
  };

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith("-") &&
      userInput !== "-" &&
      !this.state.value.endsWith("-")
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  render() {
    let {
      dataCenters,
      payload,
      createSite,
      errors,
      errorsPresent,
      organization,
      mode,
      plans,
    } = this.props;
    let { processing } = createSite;
    let {
      display_name,
      wp_admin_username,
      wp_admin_password,
      wp_admin_email,
      wp_title,
      site_type,
      plan_id,
    } = payload;

    let dataCentersList = [];
    dataCenters &&
      dataCenters.map((d) => {
        if (d.status === 1) {
          dataCentersList.push(d);
        }
      });

    let plansList = [];
    plans &&
      plans.map((plan) => {
        if (mode === "wordpress") {
          if (plan.site_type === "Wordpress")
            // plansList.push({value: plan.id, label:plan.title})
            plansList.push({ value: plan.id, name: plan.description });
        }
        if (mode === "ecommerce") {
          if (plan.site_type === "Woocommerce")
            plansList.push({ value: plan.id, name: plan.description });
        }
        if (mode === "blankinstall") {
          if (plan.site_type === "Blank")
            plansList.push({ value: plan.id, name: plan.description });
        }
      });

    return (
      <>
        <Page title="Add Site" processing={processing}>
          {/*heading*/}
          <div className="header-block">
            <div className="header-block-wrapper">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder={i18next.t("create-site.placeholder", {
                    returnObjects: true,
                  })}
                />
                <span className="icon-search"> </span>
              </div>

              <div className="button-wrapper">
                <a
                  href="#"
                  className="btn-theme btn-blue disabled"
                  disabled="disabled"
                  onClick={this.onChangeDeploySite}
                >
                  {i18next.t("create-site.button", { returnObjects: true })}
                </a>
              </div>
            </div>
          </div>

          <div className="boxed-block">
            <div className="heading-block mt-70">
              <h2>
                {i18next.t("create-new-site.title", { returnObjects: true })}
              </h2>
            </div>

            <div className="content-menu">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      mode === "wordpress" ? "active" : null
                    }`}
                    onClick={() => this.onCheckedChange("wordpress")}
                  >
                    WordPress
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      mode === "ecommerce" ? "active" : null
                    }`}
                    onClick={() => this.onCommerceCheckedChange("ecommerce")}
                  >
                    E-commerce
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      mode === "blankinstall" ? "active" : null
                    }`}
                    onClick={() => this.onCheckedChange("blankinstall")}
                  >
                    {i18next.t("create-new-site.nav-links.blank", {
                      returnObjects: true,
                    })}
                  </a>
                </li>
                <div
                  className={names(
                    `label-text`,
                    errorsPresent && `has-error`,
                    `animated fadeIn faster`
                  )}
                >
                  {errorsPresent
                    ? errors &&
                      errors.type &&
                      errors.type[0] &&
                      errors.type[0].message + "."
                    : null}
                </div>
              </ul>
            </div>
            <SiteFormWorker organization_id={organization.id} />
            <div className="content-container light-gray-bg">
              <div className="content-form-block">
                <form onSubmit={this.siteFormSubmit}>
                  <div className="form-row">
                    {mode === "ecommerce" ? (
                      <>
                        <div className="col-md-12">
                          <div className="mb-50">
                            <div className="lower-block">
                              <div className="text-block-wrap">
                                <p>
                                  <strong>
                                    {i18next.t(
                                      "create-new-site.e-commerce.title",
                                      { returnObjects: true }
                                    )}
                                  </strong>
                                </p>
                                <p>
                                  {i18next.t(
                                    "create-new-site.e-commerce.desc.1",
                                    { returnObjects: true }
                                  )}
                                </p>
                                <p>
                                  {i18next.t(
                                    "create-new-site.e-commerce.desc.2",
                                    { returnObjects: true }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {mode === "wordpress" ? (
                      <>
                        <div className="col-md-12">
                          <div className="mb-50">
                            <div className="lower-block">
                              <div className="text-block-wrap">
                                <p>
                                  <strong>
                                    {i18next.t(
                                      "create-new-site.wordpress.title",
                                      { returnObjects: true }
                                    )}
                                  </strong>
                                </p>
                                <p>
                                  {i18next.t(
                                    "create-new-site.wordpress.desc.1",
                                    { returnObjects: true }
                                  )}
                                </p>
                                <p>
                                  {i18next.t(
                                    "create-new-site.wordpress.desc.2",
                                    { returnObjects: true }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    {mode === "blankinstall" ? (
                      <>
                        <div className="col-md-12">
                          <div className=" mb-50">
                            <div className="lower-block">
                              <div className="text-block-wrap">
                                <p>
                                  <strong>
                                    {i18next.t("create-new-site.blank.title", {
                                      returnObjects: true,
                                    })}
                                  </strong>
                                </p>
                                <p>
                                  {i18next.t("create-new-site.blank.desc.1", {
                                    returnObjects: true,
                                  })}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  {mode === "wordpress" && (
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <SelectField
                            name="plan_id"
                            options={plansList}
                            errors={errors.plan_id}
                            onChange={this.onChange}
                            _value="value"
                            label="Plans"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {mode === "ecommerce" && (
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <SelectField
                            name="plan_id"
                            options={plansList}
                            errors={errors.plan_id}
                            onChange={this.onChange}
                            _value="value"
                            label="Plans"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {mode === "blankinstall" && (
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <SelectField
                            name="plan_id"
                            options={plansList}
                            errors={errors.plan_id}
                            onChange={this.onChange}
                            _value="value"
                            label="Plans"
                            _name="name"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <hr />

                  <div className="form-row">
                    {mode === "blankinstall" ? (
                      <>
                        <div className=" col-md-6">
                          <div className="form-group">
                            <SelectField
                              name="site_type"
                              value={site_type}
                              options={selectType}
                              errors={errors.status}
                              onChange={this.onChange}
                              label={i18next.t("create-new-site.form.type", {
                                returnObjects: true,
                              })}
                              _value="value"
                              _label="name"
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className=" col-md-6">
                      <div className="form-group">
                        <TextField
                          name="display_name"
                          value={display_name}
                          label={i18next.t(
                            "create-new-site.form.display-name",
                            { returnObjects: true }
                          )}
                          errors={errors.display_name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className=" col-md-6">
                      <div className="form-group">
                        <SelectField
                          name="data_center_id"
                          options={dataCentersList}
                          errors={errors.data_center_id}
                          onChange={this.onChange}
                          _value="id"
                          label={i18next.t("create-new-site.form.data-center", {
                            returnObjects: true,
                          })}
                        />
                      </div>
                    </div>

                    {mode === "wordpress" || mode === "ecommerce" ? (
                      <>
                        <div className=" col-md-6">
                          <div className="form-group">
                            <TextField
                              name="wp_title"
                              value={wp_title}
                              errors={errors.wp_title}
                              onChange={this.onChange}
                              label={i18next.t("create-new-site.form.title", {
                                returnObjects: true,
                              })}
                            />
                          </div>
                        </div>
                        <div className=" col-md-6">
                          <div className="form-group">
                            <TextField
                              name="wp_admin_username"
                              label={i18next.t(
                                "create-new-site.form.username",
                                { returnObjects: true }
                              )}
                              value={wp_admin_username}
                              errors={errors.wp_admin_username}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <div className=" col-md-6">
                          <div className="form-group">
                            <CustomPasswordField
                              name="wp_admin_password"
                              label={i18next.t(
                                "create-new-site.form.password",
                                { returnObjects: true }
                              )}
                              value={wp_admin_password}
                              errors={errors.wp_admin_password}
                              onChange={this.onChange}
                            ></CustomPasswordField>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <TextField
                              name="wp_admin_email"
                              value={wp_admin_email}
                              label={i18next.t("create-new-site.form.email", {
                                returnObjects: true,
                              })}
                              errors={errors.wp_admin_email}
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    ) : null}

                    {/*</div>*/}
                  </div>
                  <div className="col-md-12">
                    <div className="button-wrapper text-right mt-30">
                      <button
                        type="submit"
                        className="btn-theme btn-lg"
                        disabled={processing}
                      >
                        {processing ? (
                          <>
                            {processing
                              ? i18next.t("create-new-site.creating", {
                                  returnObjects: true,
                                })
                              : i18next.t("create-new-site.title", {
                                  returnObjects: true,
                                })}
                            <Loading xs />{" "}
                          </>
                        ) : (
                          i18next.t("create-new-site.title", {
                            returnObjects: true,
                          })
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    getOrganizations,
    getDataCenters,
    userDetail,
    loginForm,
    siteForm,
    createSite,
    getSites,
    getSiteCreateDataCenters,
    route,
    getPlans,
  } = state;
  let { payload, errors, errorsPresent, mode } = siteForm.data;
  let { success } = getSites;
  let total = success && success.data && success.data.data.total;
  let { organization } = route && route.data.user && route.data.user;
  let plans = getPlans && getPlans.success && getPlans.success.plans;
  // let dataCenters =
  //     getDataCenters &&
  //     getDataCenters.success &&
  //     getDataCenters.success.data &&
  //     getDataCenters.success.data.data &&
  //     getDataCenters.success.data.data.data;
  let dataCenters =
    getSiteCreateDataCenters &&
    getSiteCreateDataCenters.success &&
    getSiteCreateDataCenters.success.data &&
    getSiteCreateDataCenters.success.data.data;

  return {
    getOrganizations,
    dataCenters,
    getSiteCreateDataCenters,
    userDetail,
    loginForm,
    payload,
    createSite,
    errors,
    errorsPresent,
    total,
    organization,
    mode,
    plans,
  };
}

export default connect(mapStateToProps)(SiteForm);
