import React from 'react'
import TextField from '../../../components/shared/form/TextField'
import PasswordField from '../../../components/shared/form/PasswordField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onUserFormSubmit,
    onUserFormChange,
    selectCountry,
    onImageChange
} from './UserForm-Actions'
import UserFormWorker from "./UserFormWorker";
import {endOfURL} from "../../../helpers/UrlHelper";
import Loading from "../../../components/shared/loaders/Loading";
import Alert from "react-s-alert";
import {ConfigErrorAlert} from "../../../Config";
import SelectField from "../../../components/shared/form/SelectField";
import EmailField from "../../../components/shared/form/EmailField";
import i18next from 'i18next';

class UserForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {file: '', imagePreviewUrl: ''};
    }


    onChange = (e) => {
        store.dispatch(onUserFormChange(e));
    }

    handleCountry = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.target.value;
        }
        store.dispatch(selectCountry(value));
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
            store.dispatch(onImageChange(this.state.imagePreviewUrl));
        }
        reader.onerror = function () {
            Alert.error(`Error on Uploading Image`, ConfigErrorAlert);
        };
    }


    render() {

        let {
            first_name, middle_name, last_name, email, password, createUser, address, extra_address_line,
            post_code, city, country_id, errors, updateUser, role, user_id, countriesList
        } = this.props;

        let clearable = true;
        let countryList = [];

        countriesList && countriesList.map(c => {
            countryList.push({value: c.name, label: c.name, id: c.id})
        })

        let {processing} = updateUser;

        return (
            <>
                {/*<Page title={endOfURL() === "account" ?*/}
                {/*    "My Account" :*/}
                {/*    "Update User"*/}
                {/*} processing={processing}>*/}
                {/*<Page title="AccountDetails" processing={processing}>*/}

                <UserFormWorker userId={user_id}/>
                <div className={endOfURL() === "update" ? "content-container" : "content-container light-gray-bg"}>
                    <div className="text-block mb-40">
                        {endOfURL() === "update" ? <>
                            <h3>{i18next.t('account.inside-account.account-details.details.update' ,{returnObjects: true})}</h3></> : <>
                            <h3>{i18next.t('account.inside-account.account-details.details.heading' ,{returnObjects: true})}</h3></>}
                    </div>
                    <div className="content-form-block">
                        <form onSubmit={e => store.dispatch(onUserFormSubmit(e))}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField required
                                                   value={first_name}
                                                   name="first_name"
                                                   label={i18next.t('account.inside-account.account-details.details.first-name',{returnObjects: true})}
                                                   icon="user"
                                                   onChange={this.onChange}
                                                   errors={errors && errors.first_name}/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField required
                                                   value={address}
                                                   name="address"
                                                   label={i18next.t('account.inside-account.account-details.details.address',{returnObjects: true})}
                                                   onChange={this.onChange}
                                                   errors={errors && errors.address}/>
                                    </div>
                                </div>

                                {/*<div className="col-md-6">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <TextField*/}
                                {/*            value={middle_name}*/}
                                {/*            name="middle_name"*/}
                                {/*            icon="user"*/}
                                {/*            onChange={this.onChange}*/}
                                {/*            errors={errors && errors.middle_name}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField required
                                                   value={last_name}
                                                   name="last_name"
                                                   label={i18next.t('account.inside-account.account-details.details.last-name',{returnObjects: true})}
                                                   icon="user"
                                                   onChange={this.onChange}
                                                   errors={errors && errors.last_name}/>
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField required
                                                   value={extra_address_line}
                                                   name="extra_address_line"
                                                   label={i18next.t('account.inside-account.account-details.details.extra-address',{returnObjects: true})}
                                                   onChange={this.onChange}
                                                   errors={errors && errors.extra_address_line}/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <EmailField required
                                                    value={email}
                                                    name="email"
                                                    type="email"
                                                    icon="mail"
                                                    label={i18next.t('account.inside-account.account-details.details.email',{returnObjects: true})}
                                                    autoComplete="nope"
                                                    errors={errors && errors.email}
                                                    onChange={this.onChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField required
                                                   value={city}
                                                   name="city"
                                                   icon="user"
                                                   label={i18next.t('account.inside-account.account-details.details.city',{returnObjects: true})}
                                                   onChange={this.onChange}
                                                   errors={errors && errors.city}/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <PasswordField required
                                                       value={password}
                                                       name="password"
                                                       label={i18next.t('account.inside-account.account-details.details.password',{returnObjects: true})}
                                                       icon="lock"
                                                       defaultValue={null}
                                                       autoComplete="new-password"
                                                       onChange={this.onChange}
                                                       errors={errors && errors.password}/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField required
                                                   value={post_code}
                                                   name="post_code"
                                                   icon="user"
                                                   label={i18next.t('account.inside-account.account-details.details.post-code',{returnObjects: true})}
                                                   onChange={this.onChange}
                                                   errors={errors && errors.post_code}/>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <PasswordField required
                                                       name="password_confirmation"
                                                       label={i18next.t('account.inside-account.account-details.details.password-confirm',{returnObjects: true})}
                                                       icon="lock"
                                                       defaultValue={null}
                                                       onChange={this.onChange}
                                                       autoComplete="new-password"
                                                       errors={errors && errors.password}/>
                                    </div>
                                </div>

                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*<div className="col-md-6">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <NewSearchableSelect*/}
                                {/*            name="country"*/}
                                {/*            isClearable={clearable}*/}
                                {/*            onChange={this.handleCountry}*/}
                                {/*            // placeholder={endOfURL() === 'update' ? country : "Select..."}*/}
                                {/*            placeholder={country ? country : "Select..."}*/}
                                {/*            options={countryList}*/}
                                {/*            errors={errors && errors.country}*/}
                                {/*            Searchable*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <SelectField name="country"
                                                     options={countryList}
                                                     value={country_id}
                                                     errors={errors && errors.country_id}
                                                     onChange={this.handleCountry}
                                                     label={i18next.t('account.inside-account.account-details.details.country',{returnObjects: true})}
                                                     _value="id"
                                                     _label="value"/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="button-wrapper text-right mt-30">
                                        <button type="submit"
                                                className="btn-theme btn-lg" disabled={processing}>
                                            {
                                                processing ? <><Loading xs/>{i18next.t('account.inside-account.general.submit' ,{returnObjects: true})}</> : i18next.t('account.inside-account.general.submit' ,{returnObjects: true})
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                {/*</Page>*/}
            </>
        )
    }
}

function mapStateToProps(state) {
    let {userForm, getUser, createUser, updateUser, getCountries} = state;

    let countriesList = getCountries?.success?.data.data

    let {payload, mode, errorMessage} = userForm.data;

    let {errors, errorsPresent} = userForm.data;

    let {
        first_name, middle_name, last_name, email, password, address, extra_address_line,
        post_code, city, country_id, role
    } = payload;

    return {
        errors, errorsPresent, first_name,
        middle_name, last_name, email, password, createUser, updateUser, getUser, mode, getCountries,
        userForm, address, extra_address_line, post_code, city, country_id, role, errorMessage, countriesList
    };
}

export default connect(mapStateToProps)(UserForm);
