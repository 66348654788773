const DefaultSSLFormState = {
    payload: {
        name: '',
        key_content: '',
        certificate:''
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: `create`,
    message: ``,
    site_id: ``,
};

export const SSLFormState = {data: DefaultSSLFormState};