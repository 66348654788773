import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loggedIn, loginToken} from "../../../helpers/LocalStorageHelper";
import {pushToPage} from "../../../helpers/UrlHelper";
import getLoggedInUser from "./user_loggedin_get";
import store from "../../../Store"

function _success(success) {
    return {type: 'USER_ACTIVATION_FROM_EMAIL_SUCCESS', success};
}

function _error(error) {
    return {type: 'USER_ACTIVATION_FROM_EMAIL_ERROR', error};
}

function _processing(processing) {
    return {type: 'USER_ACTIVATION_FROM_EMAIL_PROCESSING', processing};
}

function userActivationFromEmail(token) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let headerData =
            {
                'Authorization': loginToken() ? 'Bearer ' + loginToken() : '',
                'lang': lang
            }
        axios({
            url: apiBaseUrl(`customer/verify/${token}`),
            method: "get",
            dataType: 'json',
            // headers: {'lang':lang}
            headers: headerData
        })
            .then(function (res) {
                dispatch(_success(res.data));
                dispatch(_processing(false));
                if(loggedIn()){
                if(res.data && !res.data.same_user ){
                    localStorage.removeItem('kepler_c_token')
                    window.location.href = `/verify/${token}/success`
                }
                else if(res.data && res.data.same_user){
                    store.dispatch(getLoggedInUser())
                }
                }
            }).catch(function (error) {
                    dispatch(_error(error));
                    dispatch(_processing(false));
            });
    }
}


export default userActivationFromEmail;