import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
import getSiteStatus from "../sites/site_status_get";
import store from "../../../Store";

function _success(success) {
    return {type: 'GET_CREDENTIAL_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_CREDENTIAL_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_CREDENTIAL_PROCESSING', processing};
}

function getCredential() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let {SftpList} = getState();

        let {site_id} = SftpList.data;


        const request = axios({

            url: apiBaseUrl(`customer/sites/${site_id}/credentials`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res.data.data));
                dispatch(_processing(false));
                store.dispatch(getSiteStatus(site_id))

            }).catch(function (error) {

                if (error && error.response &&
                    error.response.status === 401) {
                    logOut()
                } else {
                    handleError(error)
                    dispatch(_error(error));
                    dispatch(_processing(false));
                }
            });

        return {
            type: 'GET_CREDENTIAL_SUCCESS',
            payload: request
        }
    }
}


export default getCredential;