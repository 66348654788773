import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import getActivityLogs from "../../../ajax/actions/activity-log/activity_logs_get";
import {setDefaultLimit} from "./ActivityList-Actions"

class ActivityWorker extends React.Component {
    componentDidMount() {
        let {customer_id} = this.props;
        store.dispatch(getActivityLogs(customer_id))
        store.dispatch(setDefaultLimit())
    }

    componentDidUpdate(prev) {
        if(this.getActivityLogs !== prev.getActivityLogs)
        {
            store.dispatch(getActivityLogs(this.props.customer_id))
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {route,getActivityLogs} = state;

    let customer_id = route && route.data.user && route.data.user.id;

    return {route, customer_id,getActivityLogs}
}

export default withRouter(connect(mapStateToProps)(ActivityWorker));