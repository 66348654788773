import React from 'react';
import Sftp from "../sftp-database/list/SftpList";
import SiteDashboardLinks from "../SiteDashboardLinks";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import SiteDashboardHeader from "../SiteDashboardHeader";
import store from "../../../Store";
import getSiteStatus from "../../../ajax/actions/sites/site_status_get";
import getSiteDomain from "../../../ajax/actions/domain/site_domain_get";
import {endOfURL, secondToLastItemInURL} from "../../../helpers/UrlHelper";
import SiteTableCell from "../list/SiteTableCell";
import getSites from "../../../ajax/actions/sites/sites_get";
import getSiteListStatus from "../../../ajax/actions/sites/site_list_status_get";
import changeDisplayName from "../../../ajax/actions/sites/site_displayname_post";
import DisplayNameForm from "../../site/change-display-name/form/DisplayNameForm";
import {Link} from "react-router-dom";
import Page from "../../../components/shared/pages/Page";
import getDemoSite from "../../../ajax/actions/sites/demo_sites_get";
import Loading from "../../../components/shared/loaders/Loading";
import getCredentials from "../../../ajax/actions/sftp-database/credential_get";
import getSite from "../../../ajax/actions/sites/site_get";
import i18next from 'i18next';


class LunchPad extends React.Component {
    state = {
        edit: false,
        cancel: false,
        update: false,
        searching: false
    }

    updateDisplayName = (e) => {
        store.dispatch(changeDisplayName(this.props.match.params.id));
        this.setState({edit: false});
    }

    componentDidMount() {
        const current_path = this.props.location.pathname;
        if (current_path === '/sites/demo/launchpad') {
            store.dispatch(getDemoSite());
        } else {
            store.dispatch(getSiteDomain(this.props.match.params.id));
            store.dispatch(getSites());
            store.dispatch(getSite(this.props.match.params.id))
            store.dispatch(getSiteStatus(this.props.match.params.id));
            // store.dispatch(getSiteListStatus());
            store.dispatch(getCredentials(this.props.match.params.id));
            this.periodicFetch()
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    periodicFetch() {
        this.timer = setInterval(() => store.dispatch(getSiteStatus(this.props.match.params.id)), 10000)
    }

    render() {
        let {getSite, searchFrom, params, demoSite, getCredentials, getDemoSite} = this.props;
        let {success} = getDemoSite

        return (
            <>
                <SiteDashboardHeader/>
                {/*<InfoWorker/>*/}
                {params.q !== '' && searchFrom === endOfURL() ?
                    <>
                        <div className="boxed-block">
                            <div className="listing-table-block mt-60">
                                <div className="table-responsive listing-table">
                                    <table>
                                        <tbody>
                                        <SiteTableCell/>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    secondToLastItemInURL() !== 'demo' ? <>
                            <div className="boxed-block">
                                <div className="heading-block mt-70">
                                    <div
                                        className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                        {this.state.edit === false ? <>
                                                <h2>{getSite?.success?.display_name}</h2></> :
                                            <DisplayNameForm data={getSite?.success?.display_name}/>}
                                        {this.state.edit === false ?
                                            <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                                <i className="icon-edit"/></a> : <>
                                                <button className="btn-theme btn-black"
                                                        onClick={this.updateDisplayName}>{i18next.t('sites.page-heading.edit.update' ,{returnObjects: true})}
                                                </button>
                                                <button className="btn-theme btn-black"
                                                        onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel' ,{returnObjects: true})}
                                                </button>
                                            </>}
                                    </div>
                                    <div className="breadcrumb-block">
                                        <span><Link to={'/sites'}>{i18next.t('sites.page-heading.breadcrum' ,{returnObjects: true})}</Link></span>
                                        <span>{getSite?.success?.display_name}</span>
                                    </div>
                                </div>

                                <div className="content-menu">

                                    <SiteDashboardLinks site_id={this.props.match.params.id}/>
                                </div>
                                <Page title={i18next.t('sites.inside-site.site-nav.launch-pad' ,{returnObjects: true})}>

                                    <div className="content-container light-gray-bg">
                                        {getCredentials ? <Sftp/> : <Loading/>}
                                    </div>
                                </Page>
                            </div>
                        </> :
                        success && demoSite && secondToLastItemInURL() === 'demo' &&
                        <div className="boxed-block">
                            <div className="heading-block mt-70">
                                <div
                                    className={this.state.edit === true ? "heading-content edit-on" : "heading-content"}>
                                    {this.state.edit === false ? <>
                                            <h2>{demoSite.display_name}</h2></> :
                                        <DisplayNameForm data={demoSite.display_name}/>}
                                    {this.state.edit === false ?
                                        <a href="javascript:void(0);" onClick={() => this.setState({edit: true})}>
                                            <i className="icon-edit"/></a> : <>
                                            <button className="btn-theme btn-black"
                                                    disabled>{i18next.t('sites.page-heading.edit.update' ,{returnObjects: true})}
                                            </button>
                                            <button className="btn-theme btn-black"
                                                    onClick={() => this.setState({edit: false})}>{i18next.t('sites.page-heading.edit.cancel' ,{returnObjects: true})}
                                            </button>
                                        </>}
                                </div>
                                <div className="breadcrumb-block">
                                    <span><Link to={'/sites'}>{i18next.t('sites.page-heading.breadcrum' ,{returnObjects: true})}</Link></span>
                                    <span>{demoSite.display_name}</span>
                                </div>
                            </div>

                            <div className="content-menu">
                                <SiteDashboardLinks site_id={'demo'}/>
                            </div>
                            <Page title={i18next.t('sites.inside-site.site-nav.launch-pad' ,{returnObjects: true})}>

                                <div className="content-container light-gray-bg">
                                    <Sftp type={'demo'}/>
                                </div>
                            </Page>
                        </div>
                }
                {/*</Page>*/}
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
        getSite,
        getCredentials,
        getSites,
        siteList,
        getDemoSite
    } = state;
    let searchFrom = siteList?.data.searchFrom
    let {success} = getSites;
    let list =
        success &&
        success.data &&
        success.data.data.data;
    let demoSite = getDemoSite && getDemoSite.success && getDemoSite.success.data && getDemoSite.success.data.data && getDemoSite.success.data.data.site
    let {params, payload} = siteList.data

    return {
        getSite,
        getCredentials,
        getSites,
        siteList,
        searchFrom,
        list,
        params,
        payload,
        demoSite,
        getDemoSite
    }
}

export default withRouter(connect(mapStateToProps)(LunchPad));