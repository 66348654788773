const DefaultCurrentHostFormState = {
    payload: {
       /* current_host: '',
        username: '',
        password: '',
        hosting_login_url: '',*/
        server_ip: ``,
        server_port:``,
        server_username:``,
        server_password:``
    },
    errors: {},
    errorsPresent: false,
    serverValidationErrors: {},
    submitted: false,
    message: ``
};

export const CurrentHostFormState = {data: DefaultCurrentHostFormState}

