import React from 'react';
import {connect} from 'react-redux'
import Loading from "../../../components/shared/loaders/Loading";
import {withRouter} from "react-router";
import MigrationFormWorker from "./MigrationFormWorker";
import {ucFirst} from "../../../helpers/StringHelper";
import TextField from "../../../components/shared/form/TextField";
import Page from "../../../components/shared/pages/Page";
import {Link} from "react-router-dom";
import i18next from "i18next";


class MigrationDetailForm extends React.Component {

    render() {
        let {getMigration, payload} = this.props;
        let {processing} = getMigration

        return (
            <>
                <MigrationFormWorker/>

                {processing ?
                    <div className="boxed-block">
                        <div className="heading-block mt-70">
                            <div className="title-block">
                                <h2>Migration Detail<Loading/></h2>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="boxed-block">

                            <div className="heading-block mt-70">
                                <div className="heading-content">
                                    <div className="title-block">
                                        <h2>Migration Detail</h2>
                                    </div>
                                </div>
                                <div className="breadcrumb-block">
                                <span><Link
                                    to={'/migration'}>Migration</Link></span>
                                    <span>{payload.site_name}</span>
                                </div>
                            </div>
                            <Page title="Migration Detail">
                                <form className="mt-30 domain-form">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="site_name"
                                                    label="Site Name"
                                                    value={payload.site_name}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="status"
                                                    label="Status"
                                                    value={ucFirst(payload.status)}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="type"
                                                    label="Type"
                                                    value={payload.type === "ftp"?"FTP":"SFTP"}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="server_ip"
                                                    label="Server IP Address"
                                                    value={payload.server_ip}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="server_port"
                                                    label="Server Port"
                                                    value={payload.server_port}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="server_username"
                                                    label="Server Username"
                                                    value={payload.server_username}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="server_password"
                                                    label="Server Password"
                                                    value={payload.server_password}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="Create New Site"
                                                    label="Create a New Site?"
                                                    value={payload.createNewSite === 1 ? "yes" : "No"}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="db_login_url"
                                                    label="Database Login URL"
                                                    value={payload.db_login_url}
                                                    disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField
                                                    name="Is it an Ecommerce, Community or Member site?"
                                                    label="Is it an Ecommerce, Community or Member site?"
                                                    value={payload.isEcommerce === 1 ? "yes" : "No"}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField name="wordpress_username"
                                                           label="Wordpress UserName"
                                                           value={payload.wordpress_username}
                                                           disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField name="WordPress Password"
                                                           label="Wordpress Password"
                                                           value={payload.wordpress_password}
                                                           disabled
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField name="WordPress Admin Url"
                                                           label="Wordpress Admin Url"
                                                           value={payload.wordpress_admin_url}
                                                           disabled
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <TextField name="IsMultisite"
                                                           label="Is it a Multisite Installation?"
                                                           value={payload.isMultisite === 1 ? "yes" : "No"}
                                                           disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Page>
                        </div>
                    </>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
    getMigration
,
    migrationDetailsForm
}

= state;
let {payload} = migrationDetailsForm.data

return {getMigration, payload};
}

export default withRouter(connect(mapStateToProps)(MigrationDetailForm));