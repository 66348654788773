import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {setMigrationDataForDetails} from "../../../pages/manual-migration/form/MigrationForm-Action";

function _success(success) {
    return {type: 'GET_MIGRATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'GET_MIGRATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'GET_MIGRATION_PROCESSING', processing}
}

function getMigration(id) {
    return (dispatch) => {
        dispatch(_error(null))
        dispatch(_processing(true))
        const lang = localStorage.getItem('lang') || 'en'

        axios({
            url: apiBaseUrl(`customer/manual-migration/${id}`),
            method: 'get',
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang,
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                // store.dispatch(setMigrationDataForDetails())


            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default getMigration
