const DefaultSiteFormState = {
  payload: {
    data_center_id: ``,
    organization_id: "",
    display_name: ``,
    document_root_directory: ``,
    wp_flag: 1,
    wp_admin_username: ``,
    wp_admin_password: ``,
    wp_admin_email: ``,
    wp_title: ``,
    wp_locale: `en_US`,
    wp_enable_cache: 1,
    site_type: `normal`,
    plan_id: ``,
    plan_description: ``,
    plan_price: ``,
    //normal,ecommerce
  },
  mode: "wordpress",
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
};

export const SiteFormState = { data: DefaultSiteFormState };
