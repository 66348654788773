import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import store from "../../../Store";
import {setCacheIgnoreDataForUpdate} from "../../../pages/site/cache/ignoreCache/form/IgnoreCacheForm-Action";

function _success(success) {
  return{ type: 'GET_CACHE_IGNORE_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_CACHE_IGNORE_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_CACHE_IGNORE_PROCESSING', processing };
}

function getCacheIgnore(site_id,id) {
  return ( dispatch ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'

      axios({
        url: apiBaseUrl(`customer/sites/${site_id}/tools/cache/ignore/${id} `),
        method: "get",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken(),'lang': lang
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res));
        store.dispatch(setCacheIgnoreDataForUpdate());

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default getCacheIgnore;
