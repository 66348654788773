import store from "../../../Store";
import {stepChangeSecondStep} from "../MigrationForm-Actions";

export function onFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-FORM-CHANGE', name, value});
    }
}

export function changeType(types) {
    return function (dispatch) {
        dispatch({type: 'ON-TYPE-CHANGE', types});
    }
}

export function initializeMigrationDetailsFormStep2Error() {
    return function (dispatch) {
        dispatch({type: 'INITIALIZE-MIGRATION-DETAILS-FORM-STEP2-ERRORS'});
    }
}

export function migrationDetailsFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MIGRATION-DETAILS-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
        store.dispatch(stepChangeSecondStep(validationErrors))
    }
}

export function setDefaultMigrationDetailsData() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATION-DETAILS-DATA',});
    }
}

export function setDefaultMigrationDetailsFormStep2() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATION-DETAILS-FORM-STEP1'});
    }
}