const DefaultManualBackupFormState = {
    payload: {
        name: ``,
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    message: ``,
};

export const ManualBackupFormState = {data: DefaultManualBackupFormState};