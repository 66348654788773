const DefaultMigrationDetailsFormStep2State = {
    payload: {
       /* domain: ``,
        is_ecommerce: ``,
        wordpress_user: ``,
        wordpress_password: ``,
        wordpress_admin_url: ``,
        is_multisite: ``,
        is_https: ``,*/

        type: ``,
        db_login_url:``,
        is_multi_site: ``,
        is_ecommerce: ``,
        wordpress_username: ``,
        wordpress_password: ``,
        wordpress_admin_url: ``,
    },
    selectedRole: ``,
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    serverValidationErrors: {},

    message: ``,
    user_mode: ``,
    action: 'loader',
};

export const MigrationDetailsFormStep2State = {data: DefaultMigrationDetailsFormStep2State}

