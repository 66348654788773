import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import getRedirects from "./redirects_get";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
  return{ type: 'REDIRECT_DELETE_SUCCESS',success };
}
function _error(error) {
  return{ type: 'REDIRECT_DELETE_ERROR',error };
}
function _processing(processing) {
  return{ type: 'REDIRECT_DELETE_PROCESSING', processing };
}

function deleteRedirect(site_id,id) {
  return ( dispatch ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'


    axios({
        url: apiBaseUrl(`/customer/sites/${site_id}/redirect_rule/${id}`),
        method: "delete",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken(),'lang': lang
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res));
        handleSuccess( res )
        store.dispatch(getSiteStatus(site_id))
        store.dispatch(getRedirects(site_id));

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default deleteRedirect;
