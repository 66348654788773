import React from "react";
import errorSound from "./assets/sounds/error.wav";
import BillingForm from "./pages/register/billing-form/Billingform";
import Customerform from "./pages/register/customer-form/Customerform";
import CurrentHostForm from "./pages/manual-migration/current-host-form/CurrentHostForm";
import MigrationDetailsForm from "./pages/manual-migration/migration-details-form/MigrationDetailsForm";
import MigrateForm from "./pages/manual-migration/migration-final-form/MigrateForm";

export const ConfigApp = {
  Name: `Kepler Customer`,
  Production: true, // comment this out for Staging Server, uncomment for Live Server
};

// Note feedbucket key is different in live and customer in public->index.html. live -> Bdh4KoNabLGTTd0pwB9m, staging ->U2EJ8zMB2XkUAZoacQ0L

export const ConfigIntercom = {
  AppID:
    // "lxjolfzh", // comment this out when on Live Server=>stagingkey
    "e6us7mot", // comment this out when on Staging Server=>livekey
};

export const TheApp = {
  Engine: {
    Production: true,
    Maintenance: true,
  },
  Title: "kepler-hosting",

  Pages: {
    Tree: [{ page: `login`, permission: "all" }],
  },

  /* fa - FontAwesome | ii - IonIcon */
  icons: [
    { id: `login`, icon: `clipboard-list`, type: `fa` },

    // { id: `history`, icon: `map-marker-alt`, type: `fa` },
    // { id: `customerComments`, icon: `comment-alt`, type: `fa` },
  ],
};

export const ReCAPTCHA_SiteKey = {
  siteKey: `6LeaZSoaAAAAAPSdKGIxK6fckax41YFR-ei0Aa5O`,
  RECAPTCHA_SERVER_KEY: `6LeaZSoaAAAAAObSZSZPLoaVWi8xKczKF61aT_2W`,
};

export const ConfigErrorAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: errorSound,
  stack: 1,
};
export const ConfigSuccessAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: false,
  stack: 1,
};

export const ConfigNotificationSuccessAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 6000,
  beep: false,
  stack: 1,
};

export const ConfigModal = {
  width: 650,
  height: 500,
  effect: `fadeInUp`,
};

export const ConfigBranchFormSteps = [
  {
    id: 1,
    content: <Customerform />,
  },
  {
    id: 2,
    content: <BillingForm />,
  },
];

export const ConfigMigrationFormSteps = [
  {
    id: 1,
    content: <CurrentHostForm />,
  },
  {
    id: 2,
    content: <MigrationDetailsForm />,
  },
  {
    id: 3,
    content: <MigrateForm />,
  },
];

export const ConfigSider = {
  MenuItems: {
    admin: [
      // { link: `/site/3`, label: `Sites`, icon:'icon-browser', class:'files-link' },
      // { link: `/users`, label: `Users`, icon:'icon-user', class:'users-link' },
      // { link: `/role`, label: `Role`, icon:'icon-user', class:'users-link' },
      // // { link: `/permission`, label: `Permission`, icon:'icon-user', class:'users-link' },
      // { link: `/activity-log`, label: `Activity Log`, icon:'icon-bar_diagram', class:'activity-link' },
      // { link: `/analytics`, label: `Analytics`, icon:'icon-pie_chart', class:'analytics-link' },
      // { link: `/billing`, label: `Billing`, icon:'icon-pie_chart', class:'billing-link' },
    ],
  },
};
