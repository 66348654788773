import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import withRouter from "react-router/withRouter";
import {
  onForgotPasswordFormModeChange,
  setDefaultForgotPasswordForm,setTokenFromUrl,setEmailFromUrl
} from "./ForgotPasswordForm-Action";
import createForgotPasswordEmail from "../../../ajax/actions/forgot-password/forgot_password_post";
import changePassword from "../../../ajax/actions/forgot-password/change_password_post";
import {endOfURL, secondToLastItemInURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";

class ForgotPasswordFormWorker extends React.Component
{

  constructor(props) {
    super(props);

     if(  endOfURL() === 'forgotpassword') {
       store.dispatch(setDefaultForgotPasswordForm())
       // store.dispatch(onForgotPasswordFormModeChange('reset'));
     }
     else {
       store.dispatch(onForgotPasswordFormModeChange('reset'));
       store.dispatch(setTokenFromUrl(secondToLastItemInURL()));
       store.dispatch(setEmailFromUrl(endOfURL()));
     }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI } = this.props;

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( endOfURL() === 'forgotpassword' ) {
          store.dispatch( createForgotPasswordEmail() )
        } else {
          store.dispatch(changePassword())
        }
      }
    }
    if (this.props.createForgotPasswordEmail !== prev.createForgotPasswordEmail) {
      let {success} = this.props.createForgotPasswordEmail;
      if (success) {
        this.props.history.push(urlTrailWithoutLastItem());
      }
    }
    if (this.props.createChangePassword !== prev.createChangePassword) {
      let {success} = this.props.createChangePassword;
      if (success) {
        this.props.history.push('/');
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { forgotPasswordForm ,createChangePassword,createForgotPasswordEmail } = state;
  let {dispatchAPI, mode, payload} = forgotPasswordForm.data;
  return { forgotPasswordForm, dispatchAPI, mode, payload ,createChangePassword,createForgotPasswordEmail};
}
export default withRouter(connect( mapStateToProps )( ForgotPasswordFormWorker ));