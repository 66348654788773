import {emailReplace} from "../../../helpers/StringHelper"

export function forgotPasswordFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'FORGOT-PASSWORD-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onForgotPasswordFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-FORGOT-PASSWORD-FORM-SUBMIT' })
  }
}

export function onForgotPasswordFormChange( name, value )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-FORGOT-PASSWORD-FORM-CHANGE', name, value} );
  }
}

export function onForgotPasswordFormModeChange( mode )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-FORGOT-PASSWORD-FORM-MODE-CHANGE', mode} );
  }
}
export function setTokenFromUrl( token )
{
  return function ( dispatch )
  {
    dispatch({ type:'SET-TOKEN-FROM-URL', token} );
  }
}

export function setEmailFromUrl( emails )
{
  return function ( dispatch )
  {
    let email =emailReplace( emails )
    dispatch({ type:'SET-EMAIL-FROM-URL', email} );
  }
}

// export function setEmailToken()
// {
//   return function ( dispatch , getState)
//   {
//     let { forgotPasswordForm} = getState();
//
//     dispatch({type:'SET-CHANGE-PASSWORD-FORM',forgotPasswordForm})
//
//   }
// }

export function cancelForgotPasswordFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-FORGOT-PASSWORD-FORM-DISPATCH' });
  }
}

export function setDefaultForgotPasswordForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-FORGOT-PASSWORD-FORM'});
  }
}

export function forgotPasswordError(error)
{
  return function( dispatch )
  {
    dispatch({type:'SET-FORGOT-PASSWORD-ERROR' , error});
  }
}