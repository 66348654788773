import React from 'react';
import {setRedirectToPageAfterLogin} from "./login/Login-Actions";
import store from '../Store';
import {pushToPage} from "../helpers/UrlHelper";
import {withRouter} from "react-router";

class RedirectTo extends React.Component
{
    componentDidMount()
    {
        let { path } = this.props;
        if ( path ) {
            store.dispatch( setRedirectToPageAfterLogin( path ))
        }
        pushToPage(this.props, `/login`)
    }

    render()
    {
        return (

            null

        )
    }
}
export default withRouter( RedirectTo );