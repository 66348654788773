export default function getCredentialReducer
    (state = { error:null, success:null, processing:false }, action) {

  switch (action.type)
  {
    case 'GET_CREDENTIAL_SUCCESS':
      return {...state, ...{success: action.success }};

    case 'GET_CREDENTIAL_ERROR':
      return {...state, ...{ error: action.error }};

    case 'GET_CREDENTIAL_PROCESSING':
      return {...state, ...{ processing: action.processing }};

    default: return state;
  }
}