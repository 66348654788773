import React from 'react';
import {connect} from "react-redux";
import Tooltip from "../../../../components/shared/tooltip/Tooltip";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import store from "../../../../Store";
import {toggleDomainFormModal} from "./Domain-Action";
import i18next from 'i18next';

class PrimaryDomainView extends React.Component {
    state = {
        primary_domain: false,
        data_center: false,
        dns: false,
    }

    componentDidUpdate(prev) {
        if (prev.primary_domain !== this.state.primary_domain) {
            this.state.primary_domain = false
        }
        if (prev.data_center !== this.state.data_center) {
            this.state.data_center = false
        }
        if (prev.dns !== this.state.dns) {
            this.state.dns = false
        }
    }

    render() {
        let {domainList, site_status, type} = this.props;

        return (

            domainList && domainList.length ?
                domainList.map((d, key) => {
                    if (d.is_primary === 1) {
                        return (
                            <>
                                <div className="content-details-wrapper">
                                    <div className="row">
                                        <div className="col-xl-12 mb-5">
                                            <p>
                                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.1' ,{returnObjects: true})}
                                                <br/>
                                                <br/>
                                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.2' ,{returnObjects: true})}
                                                <br/>
                                                <br/>
                                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.3' ,{returnObjects: true})}
                                                <br/>
                                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.4' ,{returnObjects: true})}

                                            </p>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                            <div className="content-label">{i18next.t('sites.inside-site.domain.primary-domain-view.ip-address' ,{returnObjects: true})}</div>
                                            <div className="content-info">
                                                <span>{d.dns_ip_address}</span>
                                                <div className="text-center action-btn-copy">
                                                    <CopyToClipboard
                                                        text={d.dns_ip_address}
                                                        onCopy={() => this.setState({dns: true})}>
                                                        <button className="btn-sm">
                                                            {!this.state.dns ?
                                                                <Tooltip title={<span
                                                                    className={`icon-copy`}/>}
                                                                         tooltip={this.state.dns ? null : `${i18next.t('sites.inside-site.domain.primary-domain-view.copy' ,{returnObjects: true})}`}/>
                                                                :
                                                                this.state.dns ?
                                                                    <div>
                                                                        <Tooltip title={<span
                                                                            className={`icon-checkmark `}/>}
                                                                                 tooltip={this.state.dns ? `${i18next.t('sites.inside-site.domain.primary-domain-view.copied' ,{returnObjects: true})}` : null}/>
                                                                    </div>
                                                                    : null}
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                            <div className="content-label">{i18next.t('sites.inside-site.domain.primary-domain-view.primary-domain' ,{returnObjects: true})}</div>
                                            <div className="content-info">
                                                <span>{d.name}</span>
                                                <div className="text-center action-btn-copy">
                                                    <CopyToClipboard
                                                        text={d.name}
                                                        onCopy={() => this.setState({primary_domain: true})}>
                                                        <button className="btn-sm">
                                                            {!this.state.primary_domain ?
                                                                <Tooltip title={<span
                                                                    className={`icon-copy`}/>}
                                                                         tooltip={this.state.primary_domain ? null : `${i18next.t('sites.inside-site.domain.primary-domain-view.copy' ,{returnObjects: true})}`}/>
                                                                :
                                                                this.state.primary_domain ?
                                                                    <div>
                                                                        <Tooltip title={<span
                                                                            className={`icon-checkmark `}/>}
                                                                                 tooltip={this.state.primary_domain ? `${i18next.t('sites.inside-site.domain.primary-domain-view.copied' ,{returnObjects: true})}` : null}/>
                                                                    </div>
                                                                    : null}
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                            <div className="content-label">{i18next.t('sites.inside-site.domain.primary-domain-view.location-data' ,{returnObjects: true})}</div>
                                            <div className="content-info">
                                                <span>{d.data_center}</span>
                                                <div className="text-center action-btn-copy">
                                                    <CopyToClipboard
                                                        text={d.data_center}
                                                        onCopy={() => this.setState({data_center: true})}>
                                                        <button className="btn-sm">
                                                            {!this.state.data_center ?
                                                                <Tooltip title={<span
                                                                    className={`icon-copy`}/>}
                                                                         tooltip={this.state.data_center ? null : `${i18next.t('sites.inside-site.domain.primary-domain-view.copy' ,{returnObjects: true})}`}/>
                                                                :
                                                                this.state.data_center ?
                                                                    <div>
                                                                        <Tooltip title={<span
                                                                            className={`icon-checkmark `}/>}
                                                                                 tooltip={this.state.data_center ? `${i18next.t('sites.inside-site.domain.primary-domain-view.copied' ,{returnObjects: true})}` : null}/>
                                                                    </div>
                                                                    : null}
                                                        </button>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="content-details-button mt-30">
                                    <div className="btn-wrapper">
                                        <button className="btn-theme btn-lg" data-toggle="modal"
                                                data-target="#add-domain"
                                                disabled={site_status === `pending` || site_status === 'updating' || site_status === 'restoring'}
                                                onClick={() => store.dispatch(toggleDomainFormModal())}>{i18next.t('sites.inside-site.domain.primary-domain-view.add-domain' ,{returnObjects: true})}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                }) :
                type === 'demo' &&
                <>
                    <div className="content-details-wrapper">
                    <div className="row">
                        <div className="col-xl-12 mb-5">
                            <p>
                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.1' ,{returnObjects: true})}
                                <br/>
                                <br/>
                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.2' ,{returnObjects: true})}
                                <br/>
                                <br/>
                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.3' ,{returnObjects: true})}
                                <br/>
                                {i18next.t('sites.inside-site.domain.primary-domain-view.desc.4' ,{returnObjects: true})}

                            </p>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                            <div className="content-label">IP address for DNS</div>
                            <div className="content-info">
                                <span>{"123.123.123.123"}</span>
                                <div className="text-center action-btn-copy">
                                    <CopyToClipboard
                                        text={"123.123.123.123"}
                                        onCopy={() => this.setState({dns: true})}>
                                        <button className="btn-sm">
                                            {!this.state.dns ?
                                                <Tooltip title={<span
                                                    className={`icon-copy`}/>}
                                                         tooltip={this.state.dns ? null : `${i18next.t('sites.inside-site.domain.primary-domain-view.copy' ,{returnObjects: true})}`}/>
                                                :
                                                this.state.dns ?
                                                    <div>
                                                        <Tooltip title={<span
                                                            className={`icon-checkmark `}/>}
                                                                 tooltip={this.state.dns ? `${i18next.t('sites.inside-site.domain.primary-domain-view.copied' ,{returnObjects: true})}` : null}/>
                                                    </div>
                                                    : null}
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                            <div className="content-label">{i18next.t('sites.inside-site.domain.primary-domain-view.primary-domain' ,{returnObjects: true})}</div>
                            <div className="content-info">
                                <span>{"keplerdemo.keplerhosting.cloud"}</span>
                                <div className="text-center action-btn-copy">
                                    <CopyToClipboard
                                        text={"keplerdemo.keplerhosting.cloud"}
                                        onCopy={() => this.setState({primary_domain: true})}>
                                        <button className="btn-sm">
                                            {!this.state.primary_domain ?
                                                <Tooltip title={<span
                                                    className={`icon-copy`}/>}
                                                         tooltip={this.state.primary_domain ? null : `${i18next.t('sites.inside-site.domain.primary-domain-view.copy' ,{returnObjects: true})}`}/>
                                                :
                                                this.state.primary_domain ?
                                                    <div>
                                                        <Tooltip title={<span
                                                            className={`icon-checkmark `}/>}
                                                                 tooltip={this.state.primary_domain ? `${i18next.t('sites.inside-site.domain.primary-domain-view.copied' ,{returnObjects: true})}` : null}/>
                                                    </div>
                                                    : null}
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                            <div className="content-label">{i18next.t('sites.inside-site.domain.primary-domain-view.location-data' ,{returnObjects: true})}</div>
                            <div className="content-info">
                                <span>{"Netherlands"}</span>
                                <div className="text-center action-btn-copy">
                                    <CopyToClipboard
                                        text={"Netherlands"}
                                        onCopy={() => this.setState({data_center: true})}>
                                        <button className="btn-sm">
                                            {!this.state.data_center ?
                                                <Tooltip title={<span
                                                    className={`icon-copy`}/>}
                                                         tooltip={this.state.data_center ? null : `${i18next.t('sites.inside-site.domain.primary-domain-view.copy' ,{returnObjects: true})}`}/>
                                                :
                                                this.state.data_center ?
                                                    <div>
                                                        <Tooltip title={<span
                                                            className={`icon-checkmark `}/>}
                                                                 tooltip={this.state.data_center ? `${i18next.t('sites.inside-site.domain.primary-domain-view.copied' ,{returnObjects: true})}` : null}/>
                                                    </div>
                                                    : null}
                                        </button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                    <div className="content-details-button mt-30">
                        <div className="btn-wrapper">
                            <button className="btn-theme btn-lg" data-toggle="modal"
                                    data-target="#add-domain"
                                    disabled={type === 'demo' || site_status === `pending` || site_status === 'updating' || site_status === 'restoring'}
                                    onClick={() => store.dispatch(toggleDomainFormModal())}>{i18next.t('sites.inside-site.domain.primary-domain-view.add-domain' ,{returnObjects: true})}
                            </button>
                        </div>
                    </div>
                </>
        )
    }
}

function mapStateToProps(state) {
    let {getSiteDomain, siteDashboard } = state;
    let {site_status} = siteDashboard.data;
    let {success} = getSiteDomain;
    let domainList = success &&
        success.data &&
        success.data.data &&
        success.data.data.data;
    return {getSiteDomain, domainList, site_status};
}

export default connect(mapStateToProps)(PrimaryDomainView);
