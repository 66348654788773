import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import {
    toggleCacheIgnoreFormModal,
} from "../../../pages/site/cache/list/Cache-Action";

function _success(success) {
    return {type: 'CACHE_IGNORE_SUCCESS', success}
}

function _error(error) {
    return {type: 'CACHE_IGNORE_ERROR', error}
}

function _processing(processing) {
    return {type: 'CACHE_IGNORE_PROCESSING', processing}
}

function ignoreCache(id=null,edit=false) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        const lang = localStorage.getItem('lang') || 'en'

        let {SftpList,ignoreCacheForm} = getState();
        let site_id = id || SftpList.data.site_id
        let payload = ignoreCacheForm.data.payload
        let finalPayload = {
            "path" : "/"+payload.path
        }
        axios({
            url: apiBaseUrl(`customer/sites/${site_id}/tools/cache/ignore`),
            method: 'post',
            dataType: 'json',
            data: finalPayload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(getSiteStatus(site_id));
                {
                    !edit &&
                    store.dispatch(toggleCacheIgnoreFormModal());
                }
                store.dispatch(getSite(site_id))

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        });
    }
}

export default ignoreCache;