import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelDisplayNameFormDispatch,
    DisplayNameFormServerValidationErrors
} from "../../../pages/site/change-display-name/form/DisplayNameForm-Action";
import siteDetails from "./site_get";

function _success(success) {
    return {type: 'CREATE_SITE_DISPLAY_NAME_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_SITE_DISPLAY_NAME_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_SITE_DISPLAY_NAME_PROCESSING', processing}
}

function createSiteDisplayName(id) {
    return (dispatch, getState) => {
        let {displayNameForm} = getState();
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        const lang = localStorage.getItem('lang') || 'en'


        axios({
            url: apiBaseUrl(`customer/sites/${id}/displayname`),
            method: 'post',
            dataType: 'json',
            data: displayNameForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),'lang': lang
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(siteDetails(id))

            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelDisplayNameFormDispatch())
            if (error.response && error.response.status === 422) {
                validationError(error.response.data.message,error.response.status)
                store.dispatch(DisplayNameFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })

    }
}

export default createSiteDisplayName
