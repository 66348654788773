const DefaultCouponFormState = {
    payload: {
        coupon: '',
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
   };

export const CouponFormState = {data: DefaultCouponFormState};